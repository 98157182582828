import React from 'react';
import styled from 'styled-components';

import { Title, AlertCard, Button} from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
display: grid;
  gap: 10px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
  'Title Title Title Title' max-content
  'Area1 Area2 Area3 Area4' max-content
  'Area5 Area6 Area7 Area8' max-content
  'Area9 Area10 Area11 Area12' max-content
  ' .  .  .  . ' 100px
  ' .  .  .  Button1' max-content
  ' .  .  .  Button2' max-content/
  max-content max-content max-content max-content
`;



export const AlarmSkeleton: React.FC = () => (
  <StyledContainer>
    <Title area="Title" size='large' />
    <AlertCard area="Area1" />
    <AlertCard area="Area2" />
    <AlertCard area="Area3" />
    <AlertCard area="Area4" />
    <AlertCard area="Area5" />
    <AlertCard area="Area6" />
    <AlertCard area="Area7" />
    <AlertCard area="Area8" />
    <AlertCard area="Area9" />
    <AlertCard area="Area10" />
    <AlertCard area="Area11" />
    <AlertCard area="Area12" />
    <Button area="Button1" size="auto" />
    <Button area="Button2" size="auto" />
  </StyledContainer>
);