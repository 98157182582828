import { useEffect, useState } from 'react';

export function useCountdown(
  date: () => Date | string | number,
  intervalTime = 1000
): number {
  const now = () => Date.now();

  const [timeLeft, setTimeLeft] = useState(
    () => new Date(date()).valueOf() - new Date(now()).valueOf()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((current) => {
        if (current <= 0) {
          clearInterval(interval);
          return 0;
        }

        return current - intervalTime;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [intervalTime]);

  return timeLeft;
}
