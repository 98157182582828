import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const LineGraph: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.79244 9.92912C1.46246 9.92912 1.19497 9.68279 1.19497 9.37891V0.0251465H0V9.37891C0 10.2905 0.802508 11.0296 1.79244 11.0296H11.9496V9.92912H1.79244Z"
        fill={fill}
      />
      <path
        d="M10.9926 1.11987L7.68539 5.89336L5.40048 3.32144L2.39258 8.1745L3.4304 8.72001L5.57648 5.25741L7.80532 7.76619L12 1.71176L10.9926 1.11987Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
