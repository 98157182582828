import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowDownStroke: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53303 6.99999C3.64116 7.00036 3.74942 6.95601 3.82721 6.86698L6.54556 3.75587C6.68687 3.59414 6.67032 3.34846 6.50859 3.20714C6.34685 3.06583 6.10118 3.08238 5.95986 3.24412L3.9193 5.57951L3.9193 0.388889C3.9193 0.174111 3.74518 -1.26606e-07 3.53041 -1.35995e-07C3.31563 -1.45383e-07 3.14152 0.174111 3.14152 0.388889L3.14152 5.57269L1.10075 3.2437C0.959205 3.08217 0.713508 3.06596 0.551971 3.20751C0.390435 3.34905 0.374229 3.59475 0.515775 3.75628L3.20829 6.82907C3.27822 6.93222 3.3964 7 3.53041 7C3.53128 7 3.53216 7 3.53303 6.99999Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
