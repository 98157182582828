import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const sizes = {
  small: { font: '11.2px', box: '13px' },
  medium: { font: '12.5px', box: '15px' },
  large: { font: '15px', box: '18px' },
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: 'white',
  black: 'black',
  dark: `${theme.pallete.border.main}`,
  main: `${theme.pallete.primary.main}`,
  light: `${theme.pallete.primary.light}`,
  secondary: `${theme.pallete.secondary.main}`,
  success: `${theme.pallete.success.main}`,
  error: `${theme.pallete.error.main}`,
  neutral: `${theme.pallete.neutral.light}`,
  darkgray: '#626262',
  inactive: `${theme.pallete.neutral.light}`,
  disabled: `${theme.pallete.neutral.light}`,
});
type Colors = keyof ReturnType<typeof colors>;

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const Content = styled.div<ContentProps>`
  display: grid;

  grid-template-columns: auto auto;
  gap: 4px;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

type StyledCheckBoxProps = {
  scale: { font: string; box: string };
  bold: boolean;
  color: Colors;
};

export const Label = styled.label<StyledCheckBoxProps>`
  font-family: ${(props) => props.theme.global.fontFamily};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  font-size: ${(props) => props.scale.font};
  color: ${(props) => colors(props.theme)[props.color ?? 'black']};
  opacity: 1;

  ${Content}:hover & {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const StyledCheckBox = styled.input<StyledCheckBoxProps>`
  &,
  svg {
    width: ${(props) => props.scale.box};
    height: ${(props) => props.scale.box};
    display: block;
  }
  appearance: radio; 
  position: relative;
  outline: none;
  background: var(--background);
  border: 1px solid ${(props) => colors(props.theme)[props.color ?? 'black']};
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

  &:hover {
    opacity: 0.8;
    --s: 2px;
    --b: var(--border-hover);
  }
  &:checked {
    border-color: red;
    --b: var(--border-active);
  }

  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    & {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    --stroke: var(--tick);
    & {
      &:checked {
        --s: 11px;
        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }
`;

export type CheckBoxProps = {
  /**
   * Id is needed to link the label to the input
   */
  id?: string;
  /**
   * It can be any text or number.
   */
  label: string;
  /**
   * This fills the marker,
   * it only appears when the field is empty.
   */
  checked?: boolean;
  /**
   * It as color of the box and label.
   */
  color?: Colors;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /*
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "Text".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * General proposal of the component,
 * check options to submit a form or perform configuration
 */
export const CheckBox: React.FC<CheckBoxProps> = ({
  id = 'check',
  size = 'medium',
  bold = false,
  checked = false,
  area = 'checkbox',
  color = 'darkgray',
  ...props
}: CheckBoxProps) => {
  const { pad, padAll, padCross, halign, valign, label, onChange } = props;
  return (
    <Content
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
    >
      <StyledCheckBox
        type="checkbox"
        id={id}
        scale={sizes[size]}
        bold={bold}
        checked={checked}
        color={color}
        onChange={onChange}
      />
      <Label scale={sizes[size]} bold={bold} htmlFor={id} color={color}>
        {label}
      </Label>
    </Content>
  );
};
