import React from 'react';
import { Title, SCalendar, SChart, Button, DaySelect } from '../../Skeleton';
import styled from 'styled-components';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 25px 60px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title .' 27px
    'Chart RightContainer' max-content /
    900px 260px;

  @media (max-width: 1250px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Chart' max-content /
      100%;
  }

  @media (max-width: 800px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Chart' max-content /
      100%;
  }
`;

const StyledRightContainer = styled.div`
  display: grid;
  grid-area: RightContainer;
  gap: 10px;
  justify-items: center;

  grid-template:
    'Calendar' max-content
    'Clear' max-content
    'Week' max-content
    'ProgrammingDrop' max-content
    'NewProgramming' max-content
    'StrategyDrop' max-content /
    100%;

  @media (max-width: 1250px) {
    grid-template:
      'Calendar Week  ProgrammingDrop' max-content
      'Calendar Week  NewProgramming' max-content
      'Calendar Clear StrategyDrop' max-content
      'Calendar .     .' max-content
      'Calendar .     .' max-content /
      1fr 1fr 1fr;
  }

  @media (max-width: 825px) {
    grid-template:
      'Calendar Week' max-content
      'Calendar Clear' max-content
      'Calendar ProgrammingDrop' max-content
      'Calendar NewProgramming' max-content
      'Calendar StrategyDrop' max-content /
      1fr 1fr;
  }

  @media (max-width: 580px) {
    grid-template:
      'Calendar' max-content
      'Clear' max-content
      'Week' max-content
      'ProgrammingDrop' max-content
      'NewProgramming' max-content
      'StrategyDrop' max-content /
      100%;
  }
`;

const CalendarContainer = styled.div`
  display: grid;
  grid-area: Calendar;
`;

const ChartContainer = styled.div`
  grid-area: Chart;
`;

/**
 * Sensing Config Skeleton
 */
export const ScheduleConfigSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" size="medium" />
      <ChartContainer>
        <SChart title="Programação" />
      </ChartContainer>
      <StyledRightContainer>
        <CalendarContainer>
          <SCalendar />
        </CalendarContainer>
        <Button area="Clear" size="large" />
        <DaySelect area="Week" />
        <Button area="ProgrammingDrop" size="large" />
        <Button area="NewProgramming" size="large" />
        <Button area="StrategyDrop" size="large" />
      </StyledRightContainer>
    </StyledContainer>
  );
};
