import { useState } from 'react';

/**
 * Task may be something like 'signin', 'register', 'delete-user' etc.
 */
export type Feedback<Task, Result> = {
  task: Task | 'none';
  result?: Result;
};
export type ResetFeedbackCallback = () => void;

/**
 * Manages feedback for any hook with external side effects.
 */
export function useFeedback<Task, Result>(): [
  Feedback<Task, Result>,
  (task: Task, result: Result) => void,
  ResetFeedbackCallback
] {
  const [feedback, setFeedback] = useState<Feedback<Task, Result>>({
    task: 'none',
  });

  const registerFeedback = (task: Task, result: Result) => {
    setFeedback({ task, result });
  };

  const resetFeedback = () => {
    setFeedback({ task: 'none' });
  };

  return [feedback, registerFeedback, resetFeedback];
}
