import React from 'react';
import styled, { DefaultTheme, StyledProps, css } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const radii = {
  none: '0px',
  small: '4px',
  medium: '10px',
  large: '20px',
} as const;
type Radius = keyof typeof radii;

const widths = {
  medium: '210px',
  large: '240px',
  xlarge: '260px',
  auto: '100%',
} as const;
type Width = keyof typeof widths;

const heights = {
  medium: '38px',
  auto: '100%',
} as const;
type Height = keyof typeof heights;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: css`
    background-color: white;
  `,
  black: css`
    background-color: black;
  `,
  main: css`
    background-color: ${theme.pallete.primary.main};
  `,
  dark: css`
    background-color: ${theme.pallete.primary.main};
  `,
  light: css`
    background-color: ${theme.pallete.primary.light};
  `,
  gradient: css`
    background-image: linear-gradient(
      180.14deg,
      ${theme.pallete.accent.main} 30.49%,
      ${theme.pallete.accent.dark} 230.93%
    );
  `,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledHeaderProps = {
  radius: { top?: Radius; bottom?: Radius };
  color: Colors;
  area: string;
  pad: Directional<Pad>;
  width: Width;
  height: Height;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledHeaderLike = styled.div<StyledProps<StyledHeaderProps>>`
  display: grid;
  grid-template: 'cardcontent' 1fr / auto;
  grid-area: ${(props) => props.area};

  ${(props) => colors(props.theme)[props.color]}

  border-top-left-radius: ${(props) => radii[props.radius.top ?? 'none']};
  border-top-right-radius: ${(props) => radii[props.radius.top ?? 'none']};
  border-bottom-left-radius: ${(props) => radii[props.radius.bottom ?? 'none']};
  border-bottom-right-radius: ${(props) =>
    radii[props.radius.bottom ?? 'none']};

  width: ${(props) => widths[props.width ?? 'medium']};
  height: ${(props) => heights[props.height ?? 'medium']};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

export interface HeaderLikeProps {
  children?: React.ReactNode;
  /**
   * Color of the header.
   */
  color?: Colors;
  /**
   * Width of the header like object. Defaults to medium. Use auto to fill.
   */
  width?: Width;
  /**
   * Height of the header like object. Defaults to medium. Use auto to fill.
   */
  height?: Height;
  /**
   * Top's and Bottom's radii, defaults to top as medium and bottom as none.
   */
  radius?: { top?: Radius; bottom?: Radius };
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
}

/**
 * Header and Footer of card areas.
 *
 * ```tsx
 * <HeaderLike color="main" valign="center" halign="center">
 *  <CardContent
 *     halign="center"
 *     valign="center"
 *       salign={{ horizontal: 'center', vertical: 'center' }}
 *     >
 *     <Title
 *        value="Potência Ativa"
 *        size="small"
 *        color="white"
 *        halign="center"
 *        valign="center"
 *         bold
 *     </CardContent>
 *   </HeaderLike>
 * ```
 */
export const HeaderLike: React.FC<HeaderLikeProps> = ({
  area = 'unset',
  radius = { top: 'medium', bottom: 'none' },
  color = 'main',
  width = 'medium',
  height = 'medium',
  salign,
  ...props
}: HeaderLikeProps) => {
  const { children, pad, padAll, padCross, halign, valign } = props;
  return (
    <StyledHeaderLike
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      salign={salign}
      radius={radius}
      color={color}
      width={width}
      height={height}
    >
      {children}
    </StyledHeaderLike>
  );
};
