import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltT: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0568 0.0431531C12.244 0.132712 12.3432 0.340797 12.2949 0.542569L10.4495 8.25625H14.3358C14.5014 8.25625 14.6529 8.34914 14.728 8.49666C14.8031 8.64418 14.7891 8.82137 14.6918 8.95526L6.79107 19.8187C6.66763 19.9884 6.44152 20.0474 6.25092 19.9596C6.06031 19.8717 5.95824 19.6615 6.00707 19.4574L7.85244 11.7437H3.47236C3.30451 11.7437 3.15127 11.6483 3.07729 11.4976C3.00331 11.3469 3.02146 11.1673 3.12409 11.0345L11.5186 0.171046C11.6454 0.00688143 11.8697 -0.046406 12.0568 0.0431531ZM4.36869 10.8635H8.41029C8.54504 10.8635 8.67236 10.9252 8.75583 11.031C8.83929 11.1368 8.8697 11.275 8.83834 11.406L7.37629 17.5174L13.4715 9.13652H9.89167C9.75692 9.13652 9.6296 9.07479 9.54614 8.969C9.46267 8.86322 9.43227 8.72503 9.46362 8.59398L10.9619 2.33105L4.36869 10.8635Z"
        fill={fill}
      />
      <path
        d="M3.71289 2.04188H2.55094V5.33171H1.16195V2.04188H0V1H3.71289V2.04188Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
