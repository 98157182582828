import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const PhaseA: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect y="0.65625" width="23" height="23.2396" fill="white" />
          <path
            d="M14.2357 14.787H8.39463L7.24312 17.5187H5.00684L10.2638 5.71497H12.3999L17.6735 17.5187H15.4039L14.2357 14.787ZM13.518 13.067L11.3151 7.90709L9.12893 13.067H13.518Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <rect
        mask={`url(#${idCut})`}
        y="0.65625"
        width="23"
        height="23.2396"
        rx="11.5"
        fill={fill}
      />
    </svg>
  );
};
