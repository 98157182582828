import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '../../components';
import { Title } from '../../components/Title';
import { useMedia } from '../../scripts/hooks/mediaQueryHook';

export type SSideBarItemProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Title of the menu item.
   */
  title: string;
  /**
   * Current active menu item.
   */
  currentActiveMenuItem?: boolean;
  /**
   * Size of the menu item.
   */
  options?: { title: string; onClick?: () => void; visible: boolean }[];
  /**
   * If true, the menu item won't accept any actions.
   */
  disabled?: boolean;
  /**
   * If the Menu is clickable or not (in DropDown case). Default is false.
   */
  clickable?: boolean;
  /**
   * Action when the item is clicked.
   */
  onClick?: () => void;
};

const StyledItem = styled.a<{ active: boolean }>`
  display: grid;
  grid-template-columns: max-content max-content;
  padding: 6px 8px 6px 16px;

  background-color: ${(props) => (props.active ? 'white' : 'none')};
  border-left: ${(props) => (props.active ? '5px solid rgb(23,154,242, 0.8)' : 'none')};
  width: 270px;
  height: 40px;

  text-align: left;
  outline: none;

  text-decoration: none;
  cursor: pointer;

  align-content: center;

  &:hover {
    background-color: ${(props) => (props.active ? 'none' : 'rgb(236, 235, 237, 0.4)')};
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;

type DropDownContainerProps = {
  active: boolean;
  collapse: boolean;
};
const DropDownContainer = styled.div<DropDownContainerProps>`
  display: ${(props) => (props.collapse ? 'none' : 'block')};
  padding-left: 8px;
  height: ${(props) => (props.collapse ? '0' : 'max-content')};
  background-color: ${(props) => (props.active ? 'white' : 'none')};
  border-left: ${(props) => (props.active ? '5px solid rgb(23,154,242, 0.8)' : 'none')};
  transition: linear 0.25s;

  &:hover {
    background-color: ${(props) => (props.active ? 'none' : 'rgb(236, 235, 237, 0.4)')};
  }
`;

export const SSideBarItem: React.FC<SSideBarItemProps> = ({
  title,
  options,
  currentActiveMenuItem = false,
  onClick,
}: SSideBarItemProps) => {
  const Mobile = useMedia('(max-width: 580px)');
  const [collapse, setCollapse] = useState(true);
  const [currentActiveSubMenuItem, setCurrentActiveSubMenuItem] = useState(-1);
  const [currentActive, setCurrentActive] = useState(currentActiveMenuItem);

  useEffect(() => {
    setCurrentActive(currentActiveMenuItem);
    if (currentActiveMenuItem === false) {
      setCurrentActiveSubMenuItem(-1);
    }
  }, [currentActiveMenuItem]);

  const action = () => {
    setCollapse(!collapse);
  };
  return (
    <>
      {options ? (
        <>
          <StyledItem active={!!currentActive} onClick={() => action()}>
            <Title
              area='unset'
              color={'darkblue'}
              size={Mobile ? 'small' : 'lsmall'}
              txtalign='initial'
              halign='start'
              value={title}
              bold={currentActiveMenuItem}
            />
            <Icon
              area='unset'
              name='ArrowDownThin'
              size='xsmall'
              valign='center'
              color={currentActive ? 'white' : 'black'}
              padAll={{ left: 'small' }}
            />
          </StyledItem>
          {options.map((item, i) => (
            <DropDownContainer collapse={collapse} key={i} active={currentActiveSubMenuItem === i}>
              {item.visible && (
                <StyledItem
                  key={i}
                  active={currentActiveSubMenuItem === i}
                  onClick={() => {
                    item.onClick?.call(this);
                    onClick?.call(this);
                    setCurrentActiveSubMenuItem(i);
                    setCurrentActive(true);
                  }}>
                  <Title
                    area='unset'
                    color={'darkblue'}
                    size={Mobile ? 'smallx' : 'small'}
                    txtalign='initial'
                    halign='start'
                    value={item.title}
                    bold={currentActiveSubMenuItem === i}
                  />
                </StyledItem>
              )}
            </DropDownContainer>
          ))}
        </>
      ) : (
        <StyledItem active={!!currentActive} onClick={() => onClick?.call(this)}>
          <Title
            area='unset'
            color={'darkblue'}
            size={Mobile ? 'small' : 'lsmall'}
            txtalign='initial'
            halign='start'
            value={title}
            bold={currentActiveMenuItem}
          />
        </StyledItem>
      )}
    </>
  );
};
