import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BarGraph: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.79247 9.92738C1.46249 9.92738 1.19499 9.68103 1.19499 9.37715V0.0231934H0V9.37715C0 10.2888 0.802524 11.0278 1.79247 11.0278H11.9498V9.92738H1.79247Z"
        fill={fill}
      />
      <rect
        x="2.76367"
        y="0.920898"
        width="1.10509"
        height="7.36723"
        fill={fill}
      />
      <rect
        x="5.52539"
        y="2.7627"
        width="1.10508"
        height="5.52542"
        fill={fill}
      />
      <rect
        x="8.28906"
        y="5.52539"
        width="1.10509"
        height="2.76271"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="11.0508" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
