import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Laptop: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 564 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8252 439.754H551.227C558.233 439.754 563.912 434.076 563.912 427.069V415.65C563.921 415.46 563.97 415.283 563.97 415.09C563.97 413.019 563.473 411.065 562.593 409.339L507.538 270.75V12.9611C507.538 5.95438 501.859 0.275543 494.852 0.275543H69.2022C62.1955 0.275543 56.5166 5.95438 56.5166 12.9611V270.75L1.03413 410.408C0.785661 411.032 0.611734 411.669 0.465415 412.313C0.429526 412.476 0.401918 412.638 0.37155 412.804C0.233513 413.544 0.147931 414.292 0.14517 415.04C0.14517 415.057 0.139648 415.073 0.139648 415.09V427.069C0.139648 434.076 5.81848 439.754 12.8252 439.754ZM227.467 398.815L239.073 362.926H324.979L336.585 398.815H227.467ZM93.8114 32.6397H470.243V253.499H93.8114V32.6397Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
