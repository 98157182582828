import React from 'react';

import { CardContent, HeaderLike, Icon, Title } from '../../components';
import { HeaderLikeProps } from '../../components/HeaderLike';
import { ItemAlign, LineAlign } from '../../scripts/types';

export type SHeaderSelectorProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  color?: HeaderLikeProps['color'];
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Width of the header selector. Defaults to large. Use auto to fill.
   */
  width?: HeaderLikeProps['width'];
  /**
   * Label with current value being displayed. The parent component should deal
   * with the formatting logic.
   */
  label: string;
  /**
   * Fired when the left arrow is clicked
   */
  onClickArrowLeft: () => void;
  /**
   * Fired when the right arrow is clicked
   */
  onClickArrowRight: () => void;
};

/**
 * Selector notably used for selecting month and year on the charts page. It
 * has two arrows, and can go left and right, changing the selection. The
 * selection logic must be dealt outside and can be very generic.
 */
export const SHeaderSelector: React.FC<SHeaderSelectorProps> = ({
  area = 'unset',
  color = 'main',
  halign = 'center',
  valign = 'center',
  width = 'large',
  salign = { horizontal: 'center', vertical: 'center' },
  label,
  onClickArrowLeft,
  onClickArrowRight,
}: SHeaderSelectorProps) => {
  return (
    <HeaderLike
      area={area}
      color={color}
      radius={{ top: 'medium', bottom: 'medium' }}
      valign={valign}
      halign={halign}
      width={width}
      salign={salign}>
      <CardContent
        template='iconsAround'
        halign='center'
        valign='center'
        salign={{ horizontal: 'center', vertical: 'center' }}>
        <Icon
          area='icon-left'
          name='ArrowLeftCircle'
          color={color === 'white' ? 'light' : 'white'}
          size='small'
          halign='start'
          onClick={() => onClickArrowLeft()}
          padAll={{ right: 'small' }}
        />
        <Title
          value={label}
          size='small'
          color={color === 'white' ? 'light' : 'white'}
          halign='center'
          valign='center'
          bold
        />
        <Icon
          area='icon-right'
          name='ArrowRightCircle'
          color={color === 'white' ? 'light' : 'white'}
          size='small'
          halign='end'
          onClick={() => onClickArrowRight()}
          padAll={{ left: 'small' }}
        />
      </CardContent>
    </HeaderLike>
  );
};
