import {
  css,
  DefaultTheme,
  FlattenSimpleInterpolation,
} from 'styled-components';

export const Gradient = (theme: DefaultTheme): string => `linear-gradient(
  172.14deg,
  ${theme.pallete.accent.main} 12.49%,
  ${theme.pallete.accent.dark} 198.93%
)`;

export const GradientFade = (
  theme: DefaultTheme
): FlattenSimpleInterpolation => css`
  transition: background 0.8s;

  background-position: center;
  background-color: ${theme.pallete.accent.main};
  background-image: linear-gradient(
    172.14deg,
    transparent 12.49%,
    ${theme.pallete.accent.dark} 198.93%
  );

  &:hover {
    background-color: ${theme.pallete.accent.main};
    background-size: 100%;
  }

  &:active {
    background-color: ${theme.pallete.border.main};
    background-size: 1300%;
    transition: background 0s;
  }
`;
