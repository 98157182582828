import React from 'react';
import styled from 'styled-components';

import { SBStringCell, SBStringCellProps } from '../SBStringCell';
import { Icon } from '../../components/Icons';
import { Title } from '../../components/Title';

const StyledSBString = styled.div<{ area: string }>`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  gap: 10px;
  width: 100%;
  grid-area: ${(props) => props.area};
`;

const StyledBottomContent = styled.div`
  grid-area: 3 / 2;
  display: grid;
  grid-template-columns: repeat(6, min-content);
  gap: 5px;
  height: 25px;
  justify-content: start;
`;

const StyledHorizontalContent = styled.div<{ hidx: number[] }>`
  grid-area: 1 / 2;
  display: grid;
  grid-template-columns: ${(props) =>
    'repeat(' + props.hidx.length + ', 60px)'};
  gap: 10px;
`;

const StyledVerticalContent = styled.div<{ vidx: number[] }>`
  grid-area: 2 / 1;
  display: grid;
  grid-template-rows: ${(props) => 'repeat(' + props.vidx.length + ', 60px)'};
  gap: 10px;
  align-items: center;
`;

const StyledVoltageContent = styled.div<{ columns: number }>`
  grid-area: 2 / 2;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => 'repeat(' + props.columns + ', 60px)'};
  gap: 10px;
`;

const StyledLabel = styled.div`
  display: grid;
  color: grey;
  justify-content: center;
  align-items: center;
`;

export type SBStringProps = {
  /**
   * Voltage of multiples cells.
   */
  voltages: number[];
  /**
   * Temperature of multiples cells.
   */
  temperatures: number[];
  /**
   * Colors of each cell.
   */
  colors: SBStringCellProps['color'][];
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Total number of cells per page. Default to 60.
   */
  total?: number;
  /**
   * Max number of columns. Default to 12.
   */
  columns?: number;
  /**
   * Current page Index
   */
  pageIndex: number;
};

/**
 * This component holds multiples StringCells.
 */
export const SBString: React.FC<SBStringProps> = ({
  area = 'unset',
  voltages = [],
  temperatures = [],
  colors = [],
  total = 60,
  columns = 12,
  pageIndex = 0,
}: SBStringProps) => {
  /*const hidx = Array.from(Array(Math.min(columns, total)).keys()).map(
    (id) => (id + 1) + pageIndex*Math.min(columns, total)
  );
  const vidx = Array.from(Array(Math.ceil(total / columns)).keys()).map(
    (id) => id + 1
  );*/

  const complete = Array(total - voltages.length).fill(0);

  return (
    <StyledSBString area={area}>
      {/*<StyledHorizontalContent hidx={hidx}>
        {hidx.map((label, idx) => (
          <StyledLabel key={idx}>{label}</StyledLabel>
        ))}
      </StyledHorizontalContent>*/}

      {/*<StyledVerticalContent vidx={vidx}>
        {vidx.map((label, idx) => (
          <StyledLabel key={idx}>{label}</StyledLabel>
        ))}
        </StyledVerticalContent>*/}

      <StyledVoltageContent columns={columns}>
        {voltages.map((volt, idx) => (
          <SBStringCell
            key={idx}
            id={(pageIndex*total + idx + 1).toString()}
            voltage={volt}
            temperature={temperatures[idx]}
            color={colors[idx]}
          />
        ))}
        {complete.map((_v, idx) => (
          <SBStringCell
            key={idx}
            id={(pageIndex*total + idx + 1).toString()}
            voltage={0}
            temperature={0}
            color="white"
            content={false}
          />
        ))}
      </StyledVoltageContent>

      <StyledBottomContent>
        <Icon
          area="1/1"
          name="Circle"
          color="secondary"
          size="xsmall"
          valign="center"
        />
        <Title
          area="1/2"
          value="Baixa"
          size="xsmall"
          color="black"
          bold
          halign="start"
          valign="center"
          padAll={{ right: 'medium' }}
        />
        <Icon
          area="1/3"
          name="Circle"
          color="main"
          size="xsmall"
          valign="center"
        />
        <Title
          area="1/4"
          value="Normal"
          size="xsmall"
          color="black"
          bold
          halign="start"
          valign="center"
          padAll={{ right: 'medium' }}
        />
        <Icon
          area="1/5"
          name="Circle"
          color="error"
          size="xsmall"
          valign="center"
        />
        <Title
          area="1/6"
          value="Alta"
          size="xsmall"
          color="black"
          bold
          halign="start"
          valign="center"
        />
      </StyledBottomContent>
    </StyledSBString>
  );
};
