import React, { useLayoutEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Icon } from "../../components/Icons";
import { useMedia } from "../../scripts/hooks/mediaQueryHook";

const templates = {
  message: css`
    grid-template:
      ".         close" min-content
      "gif       gif" 100px
      "message   message" min-content
      "operation operation" min-content /
      max-content max-content;
    align-content: center;
  `,
  confirmation: css`
    grid-template:
      ".           close" min-content
      "gif         gif" 100px
      "message     message" min-content
      "operation   operation" min-content
      ".           ." 30px
      "button-left button-right" min-content /
      1fr 1fr;
    align-content: center;
    column-gap: 5px;
  `,
  control: css`
    grid-template:
      ".             .             .         close" min-content
      "message       message       message   message" min-content
      "operation     operation     operation operation" min-content
      ".             .             .         ." 30px
      "information   information   warning   warning" min-content
      "information   information   review    review" max-content
      ".             .             review    review" 30px
      "twofactor     twofactor     review    review" max-content
      ".             .             review    review" 30px
      "button-left   button-right  review    review" min-content
      ".             .             review    review" 30px
      "button-bottom button-bottom review    review" min-content /
      min-content min-content 300px min-content;

    column-gap: 20px;

    @media handheld and (orientation: landscape), (max-width: 830px) {
      grid-template:
        ".             ." 30px
        "message       message" min-content
        "operation     operation" min-content
        ".             ." 30px
        "warning       warning" max-content
        "review        review" max-content
        ".             ." 30px
        "information   information" min-content
        "information   information" max-content
        ".             ." 30px
        "twofactor     twofactor" max-content
        ".             ." 30px
        "button-left   button-right" min-content
        ".             ." 30px
        "button-bottom button-bottom" min-content /
        min-content min-content;

      max-height: 100vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.pallete.neutral.light};
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.pallete.primary.main};
      }
    }
  `,
  loading: css`
    grid-template:
      ".           close" min-content
      "spinner     spinner" max-content /
      max-content max-content;
  `,
  singleControl: css`
    grid-template:
      ". close" 30px
      "message       message" min-content
      "operation     operation" min-content
      ".             .        " 30px
      "information   information" min-content
      "information   information" max-content
      ".             .          " 30px
      "twofactor     twofactor       " max-content
      ".             .               " 30px
      "button-left   button-right    " min-content
      ".             .               " 15px
      "button-bottom button-bottom   " min-content /
      160px 160px;

    column-gap: 20px;

    @media handheld and (orientation: landscape), (max-width: 830px) {
      grid-template:
        ".             ." 30px
        "message       message" min-content
        "operation     operation" min-content
        ".             ." 30px
        "information   information" min-content
        "information   information" max-content
        ".             ." 30px
        "twofactor     twofactor" max-content
        ".             ." 30px
        "button-left   button-right" min-content
        ".             ." 30px
        "button-bottom button-bottom" min-content /
        min-content min-content;

      max-height: 100vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.pallete.neutral.light};
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.pallete.primary.main};
      }
    }
  `,
} as const;
type Template = keyof typeof templates;

type StyledDialogProps = {
  template: Template;
};
const StyledDialog = styled.div<StyledDialogProps>`
  display: grid;

  ${({ template }) => templates[template]}

  justify-content: center;
  align-items: center;

  padding: 15px;

  background: white;
  border-radius: 10px;

  @media only screen and (max-device-width: 830px) {
    height: 100%;
    width: 100vw;

    padding: 0px;
  }
`;

const Overlay = styled.div<{ hide: boolean }>`
  display: grid;

  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;

  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: visibility 0.4s, opacity 0.4s linear;

  background: rgba(0, 0, 0, 0.3);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
`;

export type DialogProps = {
  children?: React.ReactNode;
  /**
   * If true, the dialog is hidden.
   */
  hide?: boolean;
  /**
   * Dialog template. Use different templates for different dialogs. It works
   * the same as card content, where you need to fill the template with
   * components with respective area names. For already filled templates,
   * use a specific use-case component, which means, the ones that start
   * with a 'S'.
   */
  template?: Template;
  /**
   * Fired when the user clicks the close button.
   */
  onClose?: () => void;
};

/**
 * Container dialog component. Don't use this component unless you are
 * modifying the underlying structure of the dialogs or creating a new
 * dialog.
 */
export const Dialog: React.FC<DialogProps> = ({
  hide = true,
  template = "message",
  onClose,
  children,
}: DialogProps) => {
  const [visible, setVisible] = useState(!hide);
  const mobile = useMedia(
    "only screen and (orientation: landscape), (max-width: 830px)"
  );

  useLayoutEffect(() => {
    setVisible(!hide);
  }, [hide]);

  return (
    <Overlay hide={!visible}>
      <StyledDialog template={template}>
        {!mobile && (
          <Icon
            area="close"
            name="CrossCircle"
            color="disabled"
            size="large"
            halign="end"
            onClick={() => onClose?.call(this)}
          />
        )}
        {children}
      </StyledDialog>
    </Overlay>
  );
};
