import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Wave: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 70 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.6471 50.1548C43.5648 50.1548 43.5648 50.1548 43.4824 50.1548C42.4942 50.0777 41.6706 49.4605 41.3412 48.6118L25.9411 8.34071L16.2235 26.239C15.8118 27.0104 14.9881 27.4733 14 27.4733H2.47052C1.07062 27.4733 0 26.4705 0 25.159C0 23.8474 1.07062 22.8446 2.47052 22.8446H12.5176L24.2118 1.39746C24.6235 0.548826 25.6119 0.0858946 26.6 0.163124C27.5882 0.240353 28.4118 0.857448 28.7413 1.70608L44.0589 41.9772L53.7765 24.0789C54.1882 23.3075 55.0119 22.8446 56 22.8446H67.5295C68.9295 22.8446 70 23.8475 70 25.159C70 26.4705 68.9294 27.4733 67.5295 27.4733H57.4824L45.8706 48.9204C45.4589 49.692 44.553 50.1548 43.6471 50.1548Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
