import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const PhaseB: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect y="0.65625" width="23" height="23.2396" fill="white" />
          <path
            d="M14.9534 11.6406C15.5986 11.8542 16.1104 12.2139 16.4887 12.7198C16.867 13.2144 17.0561 13.8327 17.0561 14.5747C17.0561 15.5977 16.6667 16.3902 15.8879 16.9523C15.1091 17.5031 13.9743 17.7785 12.4834 17.7785H6.67578V5.97479H12.1497C13.5293 5.97479 14.5918 6.25021 15.3372 6.80105C16.0826 7.34065 16.4553 8.08822 16.4553 9.04377C16.4553 9.62833 16.3218 10.1454 16.0548 10.5951C15.7878 11.0448 15.4206 11.3933 14.9534 11.6406ZM8.84531 7.69477V10.9492H11.916C12.6726 10.9492 13.2511 10.8143 13.6516 10.5445C14.0633 10.2635 14.2691 9.85879 14.2691 9.33043C14.2691 8.79083 14.0633 8.38613 13.6516 8.11633C13.2511 7.83529 12.6726 7.69477 11.916 7.69477H8.84531ZM12.3499 16.0586C14.0299 16.0586 14.8699 15.4909 14.8699 14.3555C14.8699 13.22 14.0299 12.6523 12.3499 12.6523H8.84531V16.0586H12.3499Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <rect
        mask={`url(#${idCut})`}
        y="0.65625"
        width="23"
        height="23.2396"
        rx="11.5"
        fill={fill}
      />
    </svg>
  );
};
