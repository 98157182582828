import React from 'react';
import {
  Cross,
  Directional,
  LineAlign,
  ItemAlign,
  units,
  Units,
} from '../../scripts/types';
import { solve } from '../../scripts/common';

import styled, { DefaultTheme, StyledProps, css } from 'styled-components';

const sizes = {
  xsmall: { value: '10px', unit: '10px' },
  small: { value: '13px', unit: '13px' },
  medium: { value: '18px', unit: '18px' },
  large: { value: '24px', unit: '13px' },
} as const;
type Sizes = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: css`
    color: white;
  `,
  black: css`
    color: black;
  `,
  main: css`
    color: ${theme.pallete.primary.main};
  `,
  dark: css`
    color: ${theme.pallete.border.main};
  `,
  light: css`
    color: ${theme.pallete.primary.light};
  `,
  disabled: css`
    color: ${theme.pallete.neutral.light};
  `,
  gradient: css`
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      172.14deg,
      ${theme.pallete.accent.main} 12.49%,
      ${theme.pallete.accent.dark} 198.93%
    );
  `,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledTextProps = {
  fontSize: string;
  color: Colors;
  bold: boolean;
};
const StyledText = styled.span<StyledProps<StyledTextProps>>`
  font-size: ${(props) => props.fontSize};
  font-family: ${(props) => props.theme.global.fontFamily};
  ${(props) => colors(props.theme)[props.color]}
  font-weight: ${(props) => (props.bold ? 'bolder' : 'normal')};
`;

type ContainerProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const Container = styled.div<ContainerProps>`
  display: grid;
  grid-auto-flow: column;
  grid-area: ${(props) => props.area};
  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};
`;
export type QuantityProps = {
  /**
   * Measurable quantity value, as float.
   */
  value?: number | string;
  /**
   * Measurable quantity unit.
   */
  unit: Units;
  /**
   * Size of the value and unit. When large, the unit is smaller than the value.
   * It defaults to medium.
   */
  size?: Sizes;
  /**
   * Color of both the value and unit. If no gradient and no color is provided,
   * the default text is black.
   */
  color?: Colors;
  /**
   * Font weight as bolder if true.
   */
  bold?: boolean;
  /**
   * The minimum number of decimal places used by the value. Defaults to 0.
   */
  minDecimals?: number;
  /**
   * The maximum number of decimal places used by the value. Defaults to 2, as
   * most numbers will have two decimals. Use zero if you want no decimals.
   */
  maxDecimals?: number;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "quantity".
   */
  area?: string;
  /**
   * Padding for all directions
   */
  pad?: Pad;
  /**
   * Padding in each directions
   */
  padAll?: Directional<Pad>;
  /**
   * Padding in cross directions (vertical, horizontal)
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
};

/**
 * Quantity should be used whenever you have a value and a unit. Even dimentionless
 * values under some circumstances can be represented as a Quantity. For instance,
 * values seen on cards are considered quantities as well.
 */
export const Quantity: React.FC<QuantityProps> = ({
  bold = false,
  size = 'medium',
  minDecimals = 0,
  maxDecimals = 2,
  area = 'quantity',
  color = 'black',
  ...props
}: QuantityProps) => {
  const { value, unit, pad, padAll, padCross, halign, valign } = props;
  return (
    <Container
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
    >
      <StyledText fontSize={sizes[size].value} color={color} bold={bold}>
        {value?.toLocaleString(navigator.language, {
          maximumFractionDigits: maxDecimals,
          minimumFractionDigits: minDecimals,
        })}
      </StyledText>

      <StyledText fontSize={sizes[size].unit} color={color} bold={bold}>
        &nbsp;{units[unit]}
      </StyledText>
    </Container>
  );
};
