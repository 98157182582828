import React from 'react';

import { Title, Button, SCalendar, SChart } from '../../Skeleton';
import styled from 'styled-components';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 25px 60px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title .' 27px
    'Chart RightContainer' max-content /
    900px 260px;

  @media (max-width: 1250px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Chart' max-content /
      100%;
  }

  @media (max-width: 800px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Chart' max-content /
      100%;
  }
`;

const StyledRightContainer = styled.div`
  display: grid;
  grid-area: RightContainer;
  gap: 10px;
  justify-items: center;

  grid-template:
    'Calendar' max-content
    'AccordeonOne' max-content
    'AccordeonTwo' max-content/
    100%;

  @media (max-width: 1250px) {
    grid-template:
      'Calendar AccordeonOne' max-content
      'Calendar AccordeonTwo' max-content /
      1fr 1fr;
  }

  @media (max-width: 580px) {
    grid-template:
      'Calendar' max-content
      'AccordeonOne' max-content
      'AccordeonTwo' max-content /
      100%;
  }
`;

const ChartContainer = styled.div`
  grid-area: Chart;
`;

/**
 * Charts Skeleton
 */
export const ChartsSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" size="medium" />
      <ChartContainer>
        <SChart />
      </ChartContainer>
      <StyledRightContainer>
        <SCalendar area="Calendar" />
        <Button area="AccordeonOne" size="auto" />
        <Button area="AccordeonTwo" size="auto" />
      </StyledRightContainer>
    </StyledContainer>
  );
};
