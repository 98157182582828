import React from "react";
import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from "styled-components";
import { ItemAlign, LineAlign } from "../../scripts/types";

const sizes = {
  small: { value: "8px" },
  medium: { value: "15px" },
  large: { value: "20px" },
} as const;
type Sizes = keyof typeof sizes;

const round = {
  none: { value: "0px" },
  small: { value: "4px" },
  medium: { value: "10px" },
  large: { value: "20px" },
} as const;
type Radius = keyof typeof round;

export const colors = (theme: DefaultTheme) =>
  ({
    lighter: css`
      background-color: white;
    `,
    light: css`
      background-color: ${theme.pallete.primary.light};
    `,
    main: css`
      background-color: ${theme.pallete.primary.main};
    `,
    dark: css`
      background-color: ${theme.pallete.border.main};
    `,
    modeDark: css`
      background-color: #212529;
    `,
    disable: css`
      background-color: #696969;
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

export const cursor = () =>
  ({
    pointer: css`
      cursor: pointer;
    `,
    default: css`
      cursor: default;
    `,
  } as const);
type Cursor = keyof ReturnType<typeof cursor>;

const gradient = (theme: DefaultTheme) => css`
  background-image: linear-gradient(172.14deg, ${theme.pallete.accent.main} 12.49%, ${theme.pallete.accent.dark} 198.93%);
`;

export type SlideButtonProps = {
  children?: React.ReactNode;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  width?: string;
  /**
   * Size of the text inside the button. it defaults to medium.
   */
  size?: Sizes;
  /**
   * Radius of the button. It defaults to medium.
   */
  radius?: Radius;
  /**
   * Theme of the button. It defaults to main. The theme changes the color of
   * both the button and its text in a way that it's always in constrast.
   */
  color?: Color;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  cursor?: Cursor;
  gradient?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "button".
   */
  area?: string;
  /**
   * If disabled is true, the onClick action is ignored and the color is set to
   * a gray tone. Defaults to false.
   */
  disabled?: boolean;
  /**
   * Status of the button.
   */
  checked?: boolean;
  /**
   * Change action. If no change action is provided, the button does nothing, but
   * doesn't fail.
   */
  onClick?: () => void;
  /**
   * Change action. If no change action is provided, the button does nothing, but
   * doesn't fail.
   */
  onCheck?: () => void;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };

  onClickText?: () => void;

  SlideDisabled?: boolean;
};

const StyledBox = styled.div`
  display: grid;
  grid-area: "button";
  position: relative;
`;

const StyledLabel = styled.span`
  position: absolute;
  top: -1px;
  left: 1px;
  width: 45px;
  height: 27px;
  border-radius: 15px;
  background: #bebebe;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 3px;
    background: white;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

type StyledTextProps = {
  color?: Color;
  gradient: boolean;
  size: Sizes;
};

const StyledText = styled.div<StyledTextProps>`
  display: grid;
  grid-area: text;
  text-align: start;
  align-items: center;
  font-family: ${(props) => props.theme.global.fontFamily};
  font-size: ${(props) => props.size};
  color: ${(props) => (props.color == "lighter" ? props.theme.pallete.primary.light : "#fff")};
  height: 25px;
`;

type StyledButtonProps = {
  color: Color;
  gradient: boolean;
  width: string;
  size: string;
  radius: string;
  checked: boolean;
  cursor: Cursor;
};

export const ButtonChecked = (color: string): FlattenSimpleInterpolation => css`
  ${StyledLabel} {
    background: ${color};
    &:after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const StyledButton = styled.button<StyledButtonProps>`
  display: grid;
  cursor: ${(props) => (props.cursor == "default" ? "default" : "pointer")};
  grid-template:
    "text button" 44px
    / auto 47px;
  ${(props) => (props.gradient ? gradient(props.theme) : colors(props.theme)[props.color])};
  position: relative;
  border: none;
  border-radius: ${(props) => props.radius};
  padding: 8px 8px 8px 15px;
  width: ${(props) => props.width ?? "210px"};
  height: ${(props) => (props.size == "small" ? "24px" : "40px")};
  outline: none;

  ${(props) => (props.checked ? ButtonChecked(props.theme.pallete.secondary.main) : "")}

  &&:disabled,
  &&[disabled] {
    opacity: 40%;
    filter: grayscale(100%);
    cursor: default;
  }
`;

type StyledContainerProps = {
  area: string;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  width: min-content;

  justify-content: ${(props) => props.halign ?? "start"};
  align-items: ${(props) => props?.valign ?? "stretch"};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

/**
 * Button with a slide radio buttom. It accepts one action when clicked and can
 * be disabled at will.
 */
export const SlideButton: React.FC<SlideButtonProps> = ({
  width = "210px",
  size = "medium",
  radius = "medium",
  color = "main",
  gradient = false,
  disabled = false,
  area = "slidebutton",
  checked = false,
  halign,
  valign,
  salign,
  cursor = "pointer",
  ...props
}: SlideButtonProps) => {
  const { children, onClick, onClickText, SlideDisabled = disabled } = props;

  return (
    <StyledContainer area={area} halign={halign} valign={valign} salign={salign}>
      <StyledButton
        width={width}
        size={sizes[size].value}
        radius={round[radius].value}
        color={color}
        gradient={gradient}
        disabled={disabled}
        checked={checked}
        cursor={cursor}
      >
        <StyledText onClick={onClickText} size="small" gradient={false} color={color}>
          {children}
        </StyledText>
        <StyledBox
          onClick={
            SlideDisabled == false
              ? onClick
              : () => {
                  null;
                }
          }
        >
          <StyledLabel />
        </StyledBox>
      </StyledButton>
    </StyledContainer>
  );
};
