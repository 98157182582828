import React from 'react';
import styled from 'styled-components';

export type TableHeadProps = {
  children?: React.ReactNode;
  items: { title: string; hideOn?: string }[];
};

export const Thead = styled.thead``;

export const Row = styled.tr``;

type CellProps = {
  hide?: string;
};
export const Cell = styled.td<CellProps>`
  @media (max-width: 720px) {
    display: ${(props) => props.hide == 'medium' && 'none'};
  }

  @media (max-width: 480px) {
    display: ${(props) => props.hide == 'small' && 'none'};
  }
`;

const Th = styled.th<{ hide?: string }>`
  @media (max-width: 720px) {
    display: ${(props) => props.hide == 'medium' && 'none'};
  }

  @media (max-width: 480px) {
    display: ${(props) => props.hide == 'small' && 'none'};
  }
`;

export const TableHead: React.FC<TableHeadProps> = ({
  items,
}: TableHeadProps) => {
  return (
    <Thead>
      <Row>
        {items.map((item, i) => (
          <Th key={i} hide={item.hideOn}>
            {item.title}
          </Th>
        ))}
      </Row>
    </Thead>
  );
};
