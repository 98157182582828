import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
    body {
      display: grid;

      width: 100%;
      height: 100%;
      margin: 0px;
      justify-content: center;
      align-items: center;
      background-color: #F3F4F7;
      overflow-x: hidden;
    }
`;

export const GlobalSignIn = createGlobalStyle`
    body {
      display: grid;

      width: 100%;
      height: 100%;
      margin: 0px;
      justify-content: center;
      align-items: center;
      background-color: #000E23;
      overflow-x: hidden;

    }
`;
export const GlobalDark = createGlobalStyle`
    body {
      display: grid;

      width: 100%;
      height: 100%;

      justify-content: center;
      align-items: center;
      background: #000E23;
      overflow-x: hidden;
    }
`;

export const GlobalWithMenu = createGlobalStyle`
  body {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #F3F4F7;
    margin:0px;

    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.pallete.neutral.light};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.pallete.primary.light};
    }
  }
`;
export const GlobalWithMenuAlert = createGlobalStyle`
  body {
    justify-content: center;
    align-items: center;
    border: 10px red solid;
    height: 100vh;
    padding-bottom: calc(50vh + 50px);

    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;


    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.pallete.neutral.light};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.pallete.primary.light};
    }
    }
    @media (max-width: 1160px) {
      body {
        justify-content: center;
        align-items: center;
        border: 10px red solid;
        height: 100vh;
        padding-bottom: 380px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin: 0px;


        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.pallete.neutral.light};
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: ${({ theme }) => theme.pallete.primary.light};
        }
      }
    }
`;
//#212529
export const GlobalWithMenuAlertDark = createGlobalStyle`
  body {
    justify-content: center;
    align-items: center;
    border: 5px red solid;
    background: #000E23;
    height: 100vh;
    padding-bottom: calc(50vh + 50px);;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;


    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.pallete.neutral.light};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.pallete.primary.light};
    }
  }
  @media (max-width: 1160px) {
      body {
        justify-content: center;
        align-items: center;
        border: 10px red solid;
        background: #000E23;
        height: 100vh;
        padding-bottom: 380px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin: 0px;


        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
        }

        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.pallete.neutral.light};
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: ${({ theme }) => theme.pallete.primary.light};
        }
      }
    }
`;
export const GlobalWithMenuDark = createGlobalStyle`
  body {
    justify-content: center;
    align-items: center;
    background: #000E23;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.pallete.neutral.light};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.pallete.primary.light};
    }
  }
`;
