import { ActuatorsConfig } from "../../../models/ActuatorsConfig";
import { ApplicationArbFVConfig } from "../../../models/ApplicationArbFVConfig";
import { ApplicationCorrFPConfig } from "../../../models/ApplicationCorrFPConfig";
import { ApplicationEqualizationConfig } from "../../../models/ApplicationEqualizationConfig";
import { ApplicationManualConfig } from "../../../models/ApplicationManualConfig";
import { ApplicationMenuConfig } from "../../../models/ApplicationMenuConfig";
import { ApplicationSuavFVConfig } from "../../../models/ApplicationSuavFVConfig";
import { ApplicationVoltageControlConfig } from "../../../models/ApplicationVoltageControlConfig";
import { ApplicationVRSConfig } from "../../../models/ApplicationVRSConfig";
import { BreaksConfig } from "../../../models/BreaksConfig";
import { ChgDsgConfig } from "../../../models/ChgDsgConfig";
import { ControlConfig } from "../../../models/ControlConfig";
import { DayConfig } from "../../../models/DayConfig";
import { FailResetConfig } from "../../../models/FailResetConfig";
import { GeneralBESSConfig } from "../../../models/GeneralBESSConfig";
import { GeneralCellConfig } from "../../../models/GeneralCellConfig";
import { GeneralStringConfig } from "../../../models/GeneralStringConfig";
import { GeneralElectropostConfig } from "../../../models/GeneralElectropostConfig";
import { GeneralFVConfig } from "../../../models/GeneralFVConfig";
import { GeneralGmgConfig } from "../../../models/GeneralGmgConfig";
import { GeneralNetworkConfig } from "../../../models/GeneralNetworkConfig";
import { GeneralPcsConfig } from "../../../models/GeneralPcsConfig";
import { GeneralSensConfig } from "../../../models/GeneralSensConfig";
import { MonetaryConfig } from "../../../models/MonetaryConfig";
import { PcsConfig } from "../../../models/PcsConfig";
import { ProtectionCellConfig } from "../../../models/ProtectionCellConfig";
import { ProtectionClusterConfig } from "../../../models/ProtectionClusterConfig";
import { ProtectionPcsConfig } from "../../../models/ProtectionPcsConfig";
import { ProtectionSensBConfig } from "../../../models/ProtectionSensBConfig";
import { ProtectionSensPConfig } from "../../../models/ProtectionSensPConfig";
import { ProtectionStringConfig } from "../../../models/ProtectionStringConfig";
import { SecConfig } from "../../../models/SecConfig";
import { SecConfigOncorp } from "../../../models/SecConfigOncorp";
import { SecConfigCPFL } from "../../../models/SecConfigCPFL";
import { ApplicationPreChargeConfig } from "./../../../models/ApplicationPreChargeConfig.d";

type valueof<T> = T[keyof T];
export type ObjectId = string;
export type Token = string;

export type Role = keyof typeof userRoles;
export type RoleValue = valueof<typeof userRoles>;
export const userRoles = {
  root: 1, // Dev Root
  admin: 2, // Root Moura
  mouraDev: 4, // Moura developer
  manager: 8, // Moura director
  administrative: 16, // Administrative agent
  operator: 32, // Employee
  financial: 64, // Finance
  seller: 128, // Seller
  client: 256, // Client [last value ever]
} as const;

export type Perkeurt = {
  Crate: number;
  I: number;
}[];

export type Sec = {
  DC: boolean;
  DS: boolean;
}[];

export type Pcs = {
  name: string;
  id: number;
};

export type Cluster = {
  name: string;
  id: number;
};

export type Cell = {
  name: string;
  id: number;
};
export type FV = {
  name: string;
  id: number;
};
export type GMG = {
  name: string;
  id: number;
};

export type PcsEnnom = {
  ennom: number;
};

export type Gender = keyof typeof genders;
export type GenderValues = valueof<typeof genders>;
const genders = {
  male: 0,
  female: 1,
  others: 2,
} as const;

export type SafeUser = Omit<User, "password">;
export type User = {
  _id: string;
  first_name: string; // From splitting full name on spaces, first part
  last_name: string; // From splitting full name on spaces, second part to the end
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  permissions: {
    bess: string;
    role: Role;
    code: number;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  }[];
};

export type CreateUser = {
  full_name: string;
  password: string;
  email: string;
  active: boolean;
  phone: string;
  permissions: {
    bess: string;
    code: string;
  }[];
};

export type CreateBess = {
  name: string;
  description: string;
  addresses: [BessAddress];
};

export type BessAddress = {
  /** ? */
  addressline1?: string;
  /** ? */
  addressline2?: string;
  /** The street address associated with the bess. */
  throughfare?: string;
  /** Additional street-level information for the bess. */
  subThroughfare?: string;
  /** The state or province associated with the bess */
  administrativeArea?: string;
  /** Additional administrative area information for the bess */
  subAdministrativeArea?: string;
  /** The city associated with the bess. */
  locality?: string;
  /** The postal code associated with the bess. */
  postalCode?: string;
  /** The name of the country or region associated with the bess. */
  country?: string;
  /** The abbreviated country or region name. */
  isoCode?: string;
  /** The time zone associated with the bess. */
  timezone?: string;
  /** Coordinates of the bess */
  location?: {
    type: string;
    coordinates: [number, number];
  };
};

export type Bess = {
  _id: string;
  name: string;
  description: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  addresses: BessAddress[];
  features: string[];
};

export type Permission = {
  _id?: ObjectId | string;
  bess: string;
  user: string;
  code: RoleValue;
  role: Role;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};

export type Individual = {
  _id: string;
  gender: Gender;
  birthdate: Date;
  photo: string;
  documents: ObjectId[]; // to type Document
  phones: ObjectId[]; // to type Phone
  addresses: ObjectId[]; // to type Address
};

export type Document = {
  _id: string;
  code: string;
  description: string;
  medias: ObjectId[]; // to type Media
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type Media = {
  _id: string;
  bess: ObjectId; // to type Bess
  user: ObjectId; // to type User
  url: string;
  key: string; // For interacting with Amazon AWS S3
  name: string; // For interacting with Amazon AWS S3
  size: number;
  description: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
};

// Don't know yet if this is a valid response from the API
export type Bmu = {
  _id: ObjectId;
  bms: ObjectId;
  bess: ObjectId;
  stringCode: string;
  Status: boolean;
  Battery: { Voltage: number; Current: number; Soc: number; Soh: number }[];
  Statistic: {
    VoltageMean: number;
    VoltageMax: number;
    VoltageArgmax: number;
    VoltageMin: number;
    VoltageArgmin: number;
    VoltageSd: number;
    TemperatureMean: number;
    TemperatureMax: number;
    TemperatureArgmax: number;
    TemperatureMin: number;
    TemperatureArgmin: number;
    TemperatureSd: number;
  }[];
  Bmu: {
    Voltage: number[];
    Temperature: number[];
  }[];
};

// From the model ChgDsgConfig, the API strips away everything but this
export type BmuLimits = {
  CellVoltageMaxLimit: number;
  CellVoltageMinLimit: number;
  CellVoltageDeltaMaxLimit: number;
  CellTempMaxLimit: number;
  CellTempMinLimit: number;
  CellTempDeltaMaxLimit: number;
};

export type Notification = {
  _id?: ObjectId;
  bess: ObjectId;
  Code: number;
  Name: string;
  Message: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ChartSpecAxis = {
  source: string;
  option: string;
  fields: { label: string; field: string }[];
};

export type ChartSpec = {
  _id: ObjectId;
  bess: ObjectId;
  title: string;
  type: "bar" | "line";
  selection: "date" | "year" | "month";
  axis: ChartSpecAxis[];
  createdAt: Date;
  updatedAt: Date;
};

export type GeneratedChart = {
  type: "bar" | "line";
  title: string;
  axis: { label: string; X: Array<string>; Y: Array<number> }[];
  review?: boolean;
};

export type ScheduleConfig = {
  Date: Date;
  PGridMax: {
    value: number;
    beginHour: number;
    beginMinute: number;
    endHour: number;
    endMinute: number;
  };
  PPCSChgMax: {
    value: number;
    beginHour: number;
    beginMinute: number;
    endHour: number;
    endMinute: number;
  };
  PPCSDsgMax: {
    value: number;
    beginHour: number;
    beginMinute: number;
    endHour: number;
    endMinute: number;
  };
  Fp: {
    value: number;
    beginHour: number;
    beginMinute: number;
    endHour: number;
    endMinute: number;
  };
};

export type Download = {
  _id: string;
  name: string;
  size: number;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export type MonthlyFinance = {
  month: number; // 1 based
  peakTime: number; // In hours
  blackouts: number;
  saved: number;
};

export type CurrentFinance = {
  peakTime: {
    value: number;
    trend: boolean;
  };
  contractedDemand: {
    value: number;
    trend: boolean;
  };
  powerFactor: {
    value: number;
    trend: boolean;
  };
};

export type OperationSummary = {
  energy: {
    peakTime: number;
    demandControl: number;
  };
  blackouts: {
    count: number;
    timeOperation: number;
  };
  peakTime: {
    dailyPercent: number;
    monthlyPercent: number;
  };
};

export type Schedule = {
  _id: string;
  name: string;
  pgridMax: number[];
  ppcsChgMax: number[];
  ppcsDsgMax: number[];
  fp: number[];
};
export type Arb = {
  _id: string;
  name: string;
  pgridMax: number[];
  ppcsChgMax: number[];
  ppcsDsgMax: number[];
};

export type CorrFP = {
  _id: string;
  name: string;
  fp: number[];
};
export type ScheduleProg = {
  _id: string;
  name: string;
  p: number[];
  q: number[];
};

export type ChatMessage = {
  _id?: string;
  name: string;
  message: string;
  time: string;
  controller?: boolean;
};

export type ControlRequestMessage = {
  member: BessMember;
  status: string;
};

export type BessMember = {
  _id: string;
  username: string;
  chatRoom: string;
  dataRoom: string;
  role: string;
  socketId: string;
};

export type GravatarEntry = {
  id: string;
  hash: string;
  requestHash: string;
  preferredUsername: string;
  thumbnailUrl: string;
};

export type GravatarResponse = {
  entry: GravatarEntry[];
};

export type SystemControlCommand = PcsConfig["Data"] &
  ChgDsgConfig["Data"] &
  ControlConfig["Data"];
export type FinancialControlCommand = MonetaryConfig["Data"];
export type SensingControlCommand = ActuatorsConfig["Data"];
export type ScheduleControlCommand = Omit<DayConfig["Data"], "Day" | "Month">;
export type BreaksControlCommand = BreaksConfig["Data"];
export type SecControlCommand = SecConfig["Data"];
export type SecControlOncorpCommand = SecConfigOncorp["Data"];
export type SecControlCPFLCommand = SecConfigCPFL["Data"];
export type GeneralPCSControlCommand = GeneralPcsConfig["Data"];
export type GeneralBESSControlCommand = GeneralBESSConfig["Data"];
export type GeneralElectropostControlCommand = GeneralElectropostConfig["Data"];
export type GeneralCellControlCommand = GeneralCellConfig["Data"];
export type GeneralSensControlCommand = GeneralSensConfig["Data"];
export type GeneralStringControlCommand = GeneralStringConfig["Data"];
export type GeneralFVControlCommand = GeneralFVConfig["Data"];
export type GeneralGMGControlCommand = GeneralGmgConfig["Data"];
export type FailResetConfigCommand = FailResetConfig["Data"];
export type ProtectionPCSControlCommand = ProtectionPcsConfig["Data"];
export type ProtectionCellControlCommand = ProtectionCellConfig["Data"];
export type ProtectionClusterControlCommand = ProtectionClusterConfig["Data"];
export type ProtectionStringControlCommand = ProtectionStringConfig["Data"];
export type ProtectionSensBControlCommand = ProtectionSensBConfig["Data"];
export type ProtectionSensPControlCommand = ProtectionSensPConfig["Data"];
export type ApplicationVoltageControlCommand = ApplicationVoltageControlConfig["Data"];
export type ApplicationSuavFVCommand = ApplicationSuavFVConfig["Data"];
export type ApplicationPreChargeCommand = ApplicationPreChargeConfig["Data"];
export type ApplicationArbFVCommand = ApplicationArbFVConfig["Data"];
export type ApplicationEqualizationCommand = ApplicationEqualizationConfig["Data"];
export type ApplicationVRSCommand = ApplicationVRSConfig["Data"];
export type ApplicationManualCommand = ApplicationManualConfig["Data"];
export type ApplicationCorrFPCommand = ApplicationCorrFPConfig["Data"];
export type GeneralChargeControlCommand = GeneralNetworkConfig["Data"];
export type ApplicationMenuCommand = ApplicationMenuConfig["Data"];

export type Maintenance = {
  _id?: string;
  equalization: boolean;
  date: string;
  user: string;
  createdAt: string;
  updatedAt: string;
};

export type APITime<T> = T & { createdAt: Date; updatedAt: Date };
