import React from 'react';

import { Icon } from '../../components/Icons';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { CardContent } from '../../components/CardContent';

export type SSensingButtonProps = {
  /**
   * Action fired when clicking this button.
   */
  onClick: () => void;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
};

/**
 * Button to see the cameras
 */
export const SSensingButton: React.FC<SSensingButtonProps> = ({
  area = 'unset',
  onClick,
}: SSensingButtonProps) => (
  <Button
    area={area}
    onClick={() => onClick?.call(this)}
    color="dark"
    size="xxlarge"
  >
    <CardContent>
      <Icon
        color="secondary"
        name="ArrowRight"
        size="xsmall"
        padCross={{ horizontal: 'medium', vertical: 'small' }}
      />
      <Title
        color="white"
        value="Acesso às Câmeras"
        size="small"
        valign="center"
      />
    </CardContent>
  </Button>
);
