import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: css`
    background-color: white;
  `,
  black: css`
    background-color: black;
  `,
  main: css`
    background-color: ${theme.pallete.primary.main};
  `,
  dark: css`
    background-color: ${theme.pallete.border.main};
  `,
  error: css`
    background-color: ${theme.pallete.error.main};
  `,
  warning: css`
    background-color: ${theme.pallete.secondary.main};
  `,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledStringCellProps = {
  radius?: string;
  color: Colors;
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const StyledStringCell = styled.div<StyledStringCellProps>`
  display: grid;
  grid-template:
    'cardcontent' 1fr
    / min-content;
  grid-area: ${(props) => props.area};
  ${(props) => colors(props.theme)[props.color]}

  border-top-left-radius: ${(props) => props.radius ?? '0px'};
  border-top-right-radius: ${(props) => props.radius ?? '0px'};
  border-bottom-left-radius: ${(props) => props.radius ?? '0px'};
  border-bottom-right-radius: ${(props) => props.radius ?? '0px'};

  width: 60px;
  height: 60px;

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

export interface BStringCellProps {
  children?: React.ReactNode;
  /**
   * Color of the cell.
   */
  color?: Colors;
  /**
   * Border radius of the cell.
   */
  radius?: string;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "bstringcell".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
}

/**
 * String Cell of BMS, content cards with tiltle as quantity and quantity.
 * 
 * ```tsx
      <BStringCell color="light" valign="center" halign="center">
      <CardContent
        halign="start"
        valign="end"
        salign={{ horizontal: 'center', vertical: 'center' }}
        padAll={{ left: 'medium' }}
      >
        <Quantity
          area="title"
          value={2.16}
          unit="voltage"
          size="xsmall"
          color="white"
          valign="baseline"
          minDecimals={1}
          bold
        />
        <Quantity
          value={16.0}
          unit="temperature"
          size="xsmall"
          color="light"
          minDecimals={1}
          valign="baseline"
          bold
        />
      </CardContent>
    </BStringCell>
    ```
 */
export const BStringCell: React.FC<BStringCellProps> = ({
  area = 'unset',
  radius = '15px',
  color = 'main',
  ...props
}: BStringCellProps) => {
  const { children, pad, padAll, padCross, halign, valign } = props;
  return (
    <StyledStringCell
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      radius={radius}
      color={color}
    >
      {children}
    </StyledStringCell>
  );
};
