import React from 'react';
import styled from 'styled-components';

import { HeaderLike, HeaderLikeProps } from '../../components/HeaderLike';
import { solve } from '../../scripts/common';
import { Directional, Cross, LineAlign, ItemAlign } from '../../scripts/types';
import { Title } from '../../components/Title';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const radii = {
  none: '0px',
  small: '4px',
  medium: '10px',
  large: '20px',
} as const;
type Radius = keyof typeof radii;

const sizes = {
  small: { width: '220px', height: 'max-content' },
  medium: { width: '260px', height: 'max-content' },
  auto: { width: '100%', height: '100%' },
} as const;
type Size = keyof typeof sizes;

const gapSizes = {
  none: '0px',
  small: '5px',
  medium: '10px',
  large: '20px',
  xlarge: '30px',
};
type GapSize = keyof typeof gapSizes;

type StyledContainerProps = {
  area: string;
  size?: { width: string; height: string };

  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};
const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  grid-template:
    'formgroupheader' min-content
    'formgroupcontent' 1fr /
    1fr;

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};

  width: ${(props) => props.size?.width};
  height: ${(props) => props.size?.height};
`;

type StyledBorderProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  title?: string;
  columnTemplate?: string;
  radius: { top?: Radius; bottom?: Radius };
  size?: { width: string; height: string };
  gap: { col: GapSize; row: GapSize };
};
const StyledBorder = styled.div<StyledBorderProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  grid-template-columns: ${(props) => props.columnTemplate};
  column-gap: ${(props) => gapSizes[props.gap?.col]};
  row-gap: ${(props) => gapSizes[props.gap?.row]};

  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.pallete.neutral.light};
  ${(props) => (props.title ? 'border-top: none' : '')};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  border-top-left-radius: ${(props) => radii[props.radius.top ?? 'none']};
  border-top-right-radius: ${(props) => radii[props.radius.top ?? 'none']};
  border-bottom-left-radius: ${(props) => radii[props.radius.bottom ?? 'none']};
  border-bottom-right-radius: ${(props) =>
    radii[props.radius.bottom ?? 'none']};

  width: ${(props) => props.size?.width};
  height: ${(props) => props.size?.height};
`;

export type SBorderContainerProps = {
  children?: React.ReactNode;
  /**
   * Grid area of the component.
   */
  area?: string;
  /**
   * Title of the form group, appearing on the header
   */
  title?: string;
  /**
   * Size width of the form group
   */
  size?: Size;
  /**
   * This is a set column and row gap sizes.
   */
  gap?: { col: GapSize; row: GapSize };
  /**
   * Header background color. Default to gradient.
   */
  color?: HeaderLikeProps['color'];
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Column Template of Form grid.
   */
  columnTemplate?: string;
  /**
   * Radius of the div. It defaults to none.
   */
  radius?: { top?: Radius; bottom?: Radius };
};

/**
 * Group of form parts with a header, used on visual organization
 */
export const SBorderContainer: React.FC<SBorderContainerProps> = ({
  area = 'unset',
  color = 'gradient',
  title,
  children,
  halign,
  valign,
  salign,
  pad,
  padAll,
  padCross,
  columnTemplate = '1fr',
  radius = { top: 'none', bottom: 'none' },
  size = 'auto',
  gap = { col: 'none', row: 'none' },
}: SBorderContainerProps) => {
  return (
    <StyledContainer area={area} salign={salign} size={sizes[size]}>
      {title && (
        <HeaderLike
          area="formgroupheader"
          width="auto"
          color={color}
          radius={{ top: radius.top, bottom: 'none' }}
          halign="center"
          valign="center"
        >
          <Title area="cardcontent" size="small" color="white" value={title} />
        </HeaderLike>
      )}
      <StyledBorder
        area="formgroupcontent"
        size={sizes[size]}
        radius={title ? { top: 'none', bottom: radius.bottom } : radius}
        gap={gap}
        pad={solve(pad, padCross, padAll)}
        halign={halign}
        valign={valign}
        title={title}
        columnTemplate={columnTemplate}
      >
        {children}
      </StyledBorder>
    </StyledContainer>
  );
};
