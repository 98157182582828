import axios from 'axios';
import dotenv from 'dotenv';
import { FunctionalComponent, render } from 'preact';
import AsyncRoute from 'preact-async-route';
import { Router, route } from 'preact-router';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { ThemeProvider } from 'styled-components';

import { fetchBess /*, hasMeters*/, me } from './ems-core/scripts/api';
import { Bess, SafeUser, userRoles } from './ems-core/scripts/api/types';
import { routeToOwnArea } from './ems-core/scripts/common/routing';
import { useLocalStorage } from './ems-core/scripts/hooks/localStorageHook';
import defaultTheme from './ems-core/theme';
import LoadingPage from './pages/LoadingPage';
import { Feedback } from './pages/SigninPage';

dotenv.config();

const API_BASE_URL = process.env.API_URI;

const API = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const App: FunctionalComponent = () => {
  const [feedback, setFeedback] = useState<Feedback>(undefined);
  const [token, setToken] = useLocalStorage<string>('token', '');
  const [bess, setBess] = useLocalStorage<string>('bess', '');
  const [features, setFeatures] = useLocalStorage<Array<string>>('features', []);
  const [bessName, setBessName] = useLocalStorage<string>('bessName', '');
  const [bessHasMeters /*, setBessHasMeters*/] = useLocalStorage<boolean>('bessHasMeters', false);
  const [user, setUser] = useState<SafeUser | undefined>(undefined);
  const [userController, setUserController] = useState<boolean>(false);

  const onLogout = useCallback((error?: string) => {
    if (error === 'noSocket') setFeedback('noSocket');
    else setFeedback('loggedOut');
    setUser(undefined);
    setBess('');
    setToken('');
    route('/');
    window.location.reload();
  }, []);

  const onDeny = useCallback(() => {
    setFeedback('unauthorized');
    setUser(undefined);
    setBess('');
    setToken('');
    route('/');
    window.location.reload();
  }, []);

  // If we have a new token, update our user and our headers
  useEffect(() => {
    if (token.length > 0) {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setFeedback('loggedIn');
    }
  }, [token]);

  useEffect(() => {
    if (user === undefined && token.length > 0) {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      me(API).then((data) => {
        setUser(data);
      });
    }
  }, [token, user]);

  // Once we have a user, but not a bess, route to bess selection page
  useEffect(() => {
    if (user?._id == process.env.ID_CLIENT_STATIC_CPFL && process.env.IS_LOCAL == 'true') {
      setBess(process.env.IS_BESS_CPFL ?? '');
      route('/client/station');
    } else if (user !== undefined && bess.length === 0) route('/select');
  }, [bess, bess.length, setBess, user]);

  useEffect(() => {
    try {
      routeToOwnArea(user, bess, route);
    } catch (err) {
      onDeny();
    }
  }, [bess, onDeny, token.length, user]);

  useEffect(() => {
    if (user != undefined) {
      fetchBess(API, bess).then((bess) => {
        if (bess?.features) setFeatures(bess.features);
        else setFeatures([]);

        if (bess?.name) setBessName(bess.name);
        else setBessName('');
      });
    }
  }, [bess, user]);

  const filterBess = useCallback(
    (bess: Bess) => {
      if (user) {
        return user.permissions.some(
          (permission) =>
            permission.bess === bess._id ||
            (permission.code !== userRoles.manager && permission.code <= userRoles.operator)
        );
      }
      return false;
    },
    [user]
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Router
        onChange={async ({ url }) => {
          /**
           *
           * ROUTE RESTRICTIONS
           *
           */
          let permissions = user?.permissions;

          if (user === undefined && token.length > 0) {
            API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const meUser = await me(API);
            setUser(meUser);
            permissions = meUser.permissions;
          }
          const isDevRoute = url.startsWith('/dev');
          const isClientRoute = url.startsWith('/client');
          const isOperatorRoute = url.startsWith('/operator');

          const isBessSelectRoute = url === '/select';
          const isRestricted = isDevRoute || isClientRoute || isOperatorRoute;

          // User is attempting to access select area without logging in
          if (isBessSelectRoute && (token.length === 0 || !user)) {
            onDeny(); // User needs to signin first
          }
          if (url.match('/control/') && !userController) {
            onDeny();
          }

          // All permissions to the current bess being accessed
          const permissionsToThisbess = permissions?.find(
            (permission) => permission.bess === bess || permission.code <= userRoles.operator
          );

          // User is attempting to access a restrict area
          if (isRestricted) {
            /**
             * Something weird happened, should have at least some permissions,
             * better be safe and force logout this user.
             */
            if (!permissions) {
              onDeny();
            }

            /**
             * User is attempting to access a page without a bess, or a bess
             * that the user doesn't have access to. Make the user select
             * a valid bess first.
             */

            if (bess.length === 0 || !permissionsToThisbess) {
              setBess('');
              route('/select');
            }

            /**
             * Only a user with permission 'client', 'mouradev', 'admin',
             * 'root' and 'manager' can access the client area.
             */
            if (
              isClientRoute &&
              !permissions?.find(({ code }) => code === userRoles.client || code <= userRoles.mouraDev)
            ) {
              onDeny();
            }

            /**
             * Only 'operator', 'mouradev', 'admin', 'root' and 'manager' can
             * access the operator area
             */

            if (isOperatorRoute && !permissions?.find(({ code }) => code <= userRoles.operator)) {
              onDeny();
            }

            /**
             * Only 'mouradev', 'admin', 'root' and 'manager' can access the
             * dev area.
             */
            if (isDevRoute && !permissions?.find(({ code }) => code <= userRoles.manager)) {
              onDeny();
            }
          }

          // User redirect do '/', but already logged in system.
          else if (url === '/' && permissions) {
            // User does not have bess or permission to curret one.
            if (bess.length === 0 || !permissionsToThisbess) {
              setBess('');
              route('/select');
            }

            // User is MouraDev or higher role.
            else if (permissions?.find(({ code }) => code <= userRoles.mouraDev || code === userRoles.manager)) {
              route('/dev');
            }

            // User is Operator or higher role.
            else if (permissions?.find(({ code }) => code > userRoles.mouraDev && code <= userRoles.operator)) {
              route('/operator');
            }
            // User is Client or higher role.
            else if (permissions?.find(({ code }) => code === userRoles.client || code <= userRoles.mouraDev)) {
              route('/client');
            }
          }
        }}>
        {/** SIGNIN */}
        <AsyncRoute
          path='/'
          api={API}
          feedback={feedback}
          setToken={setToken}
          setUser={setUser}
          loading={() => <LoadingPage />}
          getComponent={async () => (await import('./pages/SigninPage')).default}
        />
        {/** BESS SELECT */}
        <AsyncRoute
          path='/select'
          api={API}
          token={token}
          setBess={(selected: string) => {
            if (bess === selected) {
              try {
                routeToOwnArea(user, bess, route);
              } catch (err) {
                onDeny();
              }
            } else setBess(selected);
          }}
          onGoBack={onLogout}
          role={user?.permissions[0].code}
          loading={() => <LoadingPage />}
          filterBess={filterBess}
          getComponent={async () => (await import('./pages/BessSelectionPage')).default}
        />
        {/** RECOVERY PAGE */}
        <AsyncRoute
          path='/recover'
          api={API}
          getComponent={async () => (await import('./pages/RecoveryPasswordPage')).default}
        />
        {/** DEVELOPER AREA */}
        <AsyncRoute
          path='/dev/:rest*'
          token={token}
          bess={bess}
          control={userController}
          features={features}
          bessName={bessName}
          hasMeters={bessHasMeters}
          api={API}
          setController={(ctrl: boolean) => {
            setUserController(ctrl);
          }}
          username={user?.email?.split('@')[0] ?? 'Desconhecido'}
          email={user?.email}
          userid={user?._id}
          role={user?.permissions[0].code}
          onLogOut={onLogout}
          loading={() => <LoadingPage />}
          getComponent={async () => (await import('./DevArea')).default}
        />
        {/** OPERATOR AREA */}
        <AsyncRoute
          path='/operator/:rest*'
          token={token}
          bess={bess}
          features={features}
          bessName={bessName}
          control={userController}
          hasMeters={bessHasMeters}
          api={API}
          setController={(ctrl: boolean) => {
            setUserController(ctrl);
          }}
          username={user?.email?.split('@')[0] ?? 'Desconhecido'}
          role={user?.permissions[0].code}
          email={user?.email}
          userid={user?._id}
          onLogOut={onLogout}
          loading={() => <LoadingPage />}
          getComponent={async () => (await import('./OperatorArea')).default}
        />
        {/** CLIENT AREA */}
        <AsyncRoute
          path='/client/:rest*'
          token={token}
          bess={bess}
          features={features}
          bessName={bessName}
          hasMeters={bessHasMeters}
          api={API}
          username={user?.email?.split('@')[0] ?? 'Desconhecido'}
          role={user?.permissions[0].code}
          email={user?.email}
          userid={user?._id}
          onLogOut={onLogout}
          loading={() => <LoadingPage />}
          getComponent={async () => (await import('./ClientArea')).default}
        />
      </Router>
    </ThemeProvider>
  );
};

const target = document.getElementById('root') as Element;
render(<App />, target);
