import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltTCircle: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0568 1.04315C16.244 1.13271 16.3432 1.3408 16.2949 1.54257L14.4495 9.25625H18.3358C18.5014 9.25625 18.6529 9.34914 18.728 9.49666C18.8031 9.64418 18.7891 9.82137 18.6918 9.95526L10.7911 20.8187C10.6676 20.9884 10.4415 21.0474 10.2509 20.9596C10.0603 20.8717 9.95824 20.6615 10.0071 20.4574L11.8524 12.7437H7.47236C7.30451 12.7437 7.15127 12.6483 7.07729 12.4976C7.00331 12.3469 7.02146 12.1673 7.12409 12.0345L15.5186 1.17105C15.6454 1.00688 15.8697 0.953594 16.0568 1.04315ZM8.36869 11.8635H12.4103C12.545 11.8635 12.6724 11.9252 12.7558 12.031C12.8393 12.1368 12.8697 12.275 12.8383 12.406L11.3763 18.5174L17.4715 10.1365H13.8917C13.7569 10.1365 13.6296 10.0748 13.5461 9.969C13.4627 9.86322 13.4323 9.72503 13.4636 9.59398L14.9619 3.33105L8.36869 11.8635Z"
        fill={fill}
      />
      <path
        d="M4.91211 3.92411H4.09062V6.25H3.1086V3.92411H2.28711V3.1875H4.91211V3.92411Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5625 4.50002C6.5625 6.13617 5.23614 7.46252 3.6 7.46252C1.96386 7.46252 0.6375 6.13617 0.6375 4.50002C0.6375 2.86388 1.96386 1.53752 3.6 1.53752C5.23614 1.53752 6.5625 2.86388 6.5625 4.50002ZM3.6 8.10002C5.58822 8.10002 7.2 6.48825 7.2 4.50002C7.2 2.5118 5.58822 0.900024 3.6 0.900024C1.61177 0.900024 0 2.5118 0 4.50002C0 6.48825 1.61177 8.10002 3.6 8.10002ZM3.408 7.5566C1.80607 7.45751 0.5375 6.12691 0.5375 4.50002C0.5375 2.80865 1.90863 1.43752 3.6 1.43752C3.66435 1.43752 3.72824 1.43951 3.79161 1.44342C3.72811 1.43949 3.66409 1.4375 3.59961 1.4375C1.90824 1.4375 0.537109 2.80863 0.537109 4.5C0.537109 6.12702 1.80589 7.4577 3.408 7.5566ZM3.6 8.00002C5.533 8.00002 7.1 6.43302 7.1 4.50002C7.1 2.64058 5.64998 1.11981 3.81901 1.00677C5.6498 1.12 7.09961 2.64069 7.09961 4.5C7.09961 6.433 5.53261 8 3.59961 8C3.52606 8 3.45303 7.99773 3.3806 7.99326C3.45316 7.99775 3.52631 8.00002 3.6 8.00002Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
