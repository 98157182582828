import React from 'react';

import { Gradient } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const LineVertical: React.FC<Props> = ({ gradient = false, ...props }: Props) => {
  const { size, color } = props;

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 4V20M10 4V20' stroke={color} stroke-width='2' stroke-linecap='round' />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
