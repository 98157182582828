import { AxiosInstance } from 'axios';
import urlcat from 'urlcat';
import { CountableList } from '../types';
import { ChartSpec, ChartSpecAxis, GeneratedChart } from './types';

/**
 * Returns a specific chart, only the metadata. In order to get the chart
 * data, you must use gatherChartData.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the charts from.
 * @param id Id of the chart to be fetched. It's unique across all besses.
 */
export async function fetchChart(
  api: AxiosInstance,
  bess: string,
  id: string
): Promise<ChartSpec> {
  return await api
    .get<ChartSpec>(urlcat('/besses/:bess/charts/:id', { bess, id }))
    .then((res) => res.data);
}

/**
 * Gathers all data from multiple collections in order to create a chart.
 * Charts are configurable by API calls, the interval is configurable in
 * this call.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the charts from.
 * @param id Id of the chart to have the data gathered.
 * @param from Begin datetime of the chart to be generated, normally the
 * start of a day.
 * @param to End datetime of the chart to be generated, normally the end
 * of a day.
 */
export async function gatherChartData(
  api: AxiosInstance,
  bess: string,
  id: string,
  from: Date,
  to: Date
): Promise<GeneratedChart> {
  return await api
    .get<GeneratedChart>(
      urlcat('/besses/:bess/charts/gather/:id', { bess, id, from, to })
    )
    .then((res) => res.data);
}

/**
 * Returns a list of the charts of a given bess.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the charts from.
 * @param limit The number of charts to be retorned.
 * @param offset The number of charts to be skipped, unordered.
 */
export async function listCharts(
  api: AxiosInstance,
  bess: string,
  limit = 10,
  offset = 0
): Promise<CountableList<ChartSpec>> {
  return await api
    .get<CountableList<ChartSpec>>(
      urlcat('/besses/:bess/charts', { bess, limit, offset })
    )
    .then((res) => res.data);
}

/**
 * Creates a new chart for a specific bess. The multiple variables are
 * fetched from multiple collections and then stitched together into a
 * single chart.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the charts from.
 * @param title Title of the chart, it's the string that the user sees.
 * @param type Type of the chart, bar, line etc.
 * @param axis All axis of the chart, fetched from multiple collections.
 */
export async function createChart(
  api: AxiosInstance,
  bess: string,
  title: string,
  type: 'bar' | 'line',
  axis: ChartSpecAxis[]
): Promise<ChartSpec> {
  const params = { bess, title, type, axis };
  return await api
    .post<ChartSpec>(urlcat('/besses/:bess/charts', { bess }), params)
    .then((res) => res.data);
}

/**
 * Deletes a specific chart. Id is unique across all charts.
 *
 * @param api Axios instance with correct headers.
 * @param id The id of the chart to be deleted.
 */
export async function deleteChart(
  api: AxiosInstance,
  id: string
): Promise<unknown> {
  return await api
    .delete(urlcat('/charts/:id', { id }))
    .then((res) => res.data);
}

/**
 * Updates the specific chart with the provided information.
 *
 * @param api Axios instance with correct headers.
 * @param id The id of the chart to be updated.
 * @param bess The id of the bess to fetch the charts from.
 * @param title Title of the chart, it's the string that the user sees.
 * @param type Type of the chart, bar, line etc.
 * @param axis All axis of the chart, fetched from multiple collections.
 */
export async function updateChart(
  api: AxiosInstance,
  id: string,
  bess: string,
  title?: string,
  type?: 'bar' | 'line',
  axis?: ChartSpecAxis[]
): Promise<unknown> {
  const params = { bess, title, type, axis };
  return await api
    .patch(urlcat('/besses/:bess/charts/:id', { bess, id }), params)
    .then((res) => res.data);
}
