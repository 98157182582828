import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Calendar: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const stroke = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.720703 4.34869V12.0009C0.720703 12.1444 0.816355 12.24 0.959834 12.24H11.0033C11.1468 12.24 11.2425 12.1444 11.2425 12.0009V4.34869"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.37246 2.19647H3.58984"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M0.720703 4.34869H11.2425"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M2.63379 5.78345H9.32945V10.8052H7.17728H2.63379V5.78345Z"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.63379 7.4574H9.32945"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.63379 9.13129H9.32945"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98145 5.78345V10.8052"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.30762 5.78345V10.8052"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62598 5.78345V10.8052"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2421 4.34865V2.4356C11.2421 2.29212 11.1465 2.19647 11.003 2.19647H9.3291"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.63375 2.19647H0.959834C0.816355 2.19647 0.720703 2.29212 0.720703 2.4356V4.34865"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M9.09044 3.15304H8.61218C8.4687 3.15304 8.37305 3.05738 8.37305 2.91391V1.47912C8.37305 1.33564 8.4687 1.23999 8.61218 1.23999H9.09044C9.23392 1.23999 9.32957 1.33564 9.32957 1.47912V2.91391C9.32957 3.05738 9.23392 3.15304 9.09044 3.15304Z"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M3.35118 3.15304H2.87292C2.72944 3.15304 2.63379 3.05738 2.63379 2.91391V1.47912C2.63379 1.33564 2.72944 1.23999 2.87292 1.23999H3.35118C3.49466 1.23999 3.59031 1.33564 3.59031 1.47912V2.91391C3.59031 3.05738 3.49466 3.15304 3.35118 3.15304Z"
        stroke={stroke}
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
