import React, { useEffect, useState } from 'react';

import { Title } from '../../components/Title';
import { Dialog } from '../Dialog';

const animations = {
  checkmark:
    'https://bess-static.s3.us-east-2.amazonaws.com/checkmark-ok_200.gif',
  warning: 'https://bess-static.s3.us-east-2.amazonaws.com/warning-2_200.gif',
  checkbox: 'https://bess-static.s3.us-east-2.amazonaws.com/check_200.gif',
  download: 'https://bess-static.s3.us-east-2.amazonaws.com/download_200.gif',
} as const;
type Animations = keyof typeof animations;

export const colors = () =>
  ({
    white: 'white',
    light: 'light',
    main: 'main',
    dark: 'dark',
    success: 'success',
    error: 'error',
    secondary: 'secondary',
    gradient: 'gradient',
    inactive: 'inactive',
    disabled: 'disabled',
    black: 'black',
    neutral: 'neutral',
    darkgray: 'darkgray',
  } as const);
type Color = keyof ReturnType<typeof colors>;

export type SDialogMessageProps = {
  /**
   * This is an animated gif, displayed in the dialog.
   */
  animation: Animations;
  /**
   * The message displayed. You can find it by looking for the text in black.
   */
  message: string;
  /**
   * Message that says which operation is being performed. It's the blueish
   * text.
   */
  operation: string;
  /*
   * Color of the message, default is "light".
   */
  color?: Color;
  /**
   * If true, the dialog is hidden.
   */
  hide?: boolean;
  /**
   * After the message is visible, it will hide itself after 'hideDelay'
   * milliseconds.
   */
  hideDelay?: number;
  /**
   * Fired when the user clicks the close button or outside the area of the
   * dialog.
   */
  onClose?: () => void;
};

/**
 * Dialog component for displaying messages to the user, there is no prompt
 * involved.
 */
export const SDialogMessage: React.FC<SDialogMessageProps> = ({
  hide = true,
  animation,
  message,
  operation,
  onClose,
  color = 'light',
}: SDialogMessageProps) => {
  const [visible, setVisible] = useState(!hide);

  useEffect(() => {
    setVisible(!hide);
  }, [hide]);

  return (
    <Dialog hide={!visible} onClose={onClose} template="message">
      <img
        style={{ display: 'grid', gridArea: 'gif', justifySelf: 'center' }}
        width="75"
        height="75"
        src={animations[animation]}
        alt="gif"
      />
      <Title area="message" size="large" value={message} />
      <Title area="operation" size="large" color={color} value={operation} />
    </Dialog>
  );
};
