import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Star: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 364 348"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M363.422 132.281C362.169 128.423 358.835 125.61 354.82 125.028L242.051 108.64L191.617 6.45063C189.823 2.81292 186.117 0.510223 182.061 0.510223C178.004 0.510223 174.3 2.81292 172.504 6.45063L122.068 108.64L9.29909 125.028C5.28553 125.61 1.94978 128.423 0.696479 132.281C-0.557536 136.139 0.488305 140.374 3.3935 143.206L84.9933 222.745L65.7333 335.059C65.047 339.058 66.6911 343.098 69.9728 345.483C71.8293 346.831 74.0283 347.518 76.2379 347.518C77.9346 347.518 79.6369 347.114 81.1957 346.294L182.064 293.264L282.925 346.293C286.517 348.181 290.867 347.867 294.149 345.482C297.43 343.098 299.075 339.057 298.39 335.058L279.123 222.744L360.73 143.197C363.635 140.367 364.681 136.131 363.426 132.273L363.422 132.281Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
