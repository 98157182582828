import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { SolidRipple } from '../../css/Ripple';
import { solve } from '../../scripts/common';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';

const pads = {
  none: '0px',
  xsmall: '5px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export const colors = (theme: DefaultTheme) =>
  ({
    light: theme.pallete.primary.light,
    main: theme.pallete.primary.main,
    black: 'black',
    white: 'white',
    dark: theme.pallete.border.main,
    grey: '##f3f4f8',
  } as const);
type Color = keyof ReturnType<typeof colors>;

type StyledCalendarCellProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const StyledCalendarCell = styled.div<StyledCalendarCellProps>`
  display: grid;
  grid-template:
    'day      day' 18px
    'iconLeft iconRight' min-content /
    10px 10px;

  width: 20px;
  height: 23px;

  grid-area: ${(props) => props.area};
  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

type StyledCellNumberProps = {
  color: Color;
  disabled?: boolean;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const StyledCellNumber = styled.div<StyledCellNumberProps>`
  display: grid;
  grid-area: day;

  width: 100%;
  height: 100%;

  border-radius: 12px;

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  ${(props) => SolidRipple(colors(props.theme)[props.color], props.theme.pallete.primary.light, 1.5)}

  &:hover {
    border: ${(props) => !props.disabled && css`2px solid ${props.theme.pallete.primary.light}`};
    margin-left: ${(props) => !props.disabled && '-2px'};
    cursor: ${(props) => (!props.disabled ? 'pointer' : '')};
  }

  &:active {
    outline: none;
  }
`;

export type CalendarCellProps = {
  /**
   * Day Title with Day number.
   */
  children?: React.ReactNode;
  /**
   * Day bottom left icon.
   */
  iconLeft?: React.ReactNode;
  /**
   * Day bottom right icon.
   */
  iconRight?: React.ReactNode;
  /**
   * If cell should be disabled. Default to false.
   */
  disabled?: boolean;
  /**
   * Background color of the card. Defaults to "white"
   */
  color?: Color;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  value?: string;
  /**
   * Action activated when changing the hover state of the component.
   */
  onHover?: (isHover: boolean) => void;
  /**
   * Action activated when clicking the component.
   */
  onClick?: () => void;
};

/**
 * This Calendar Cell can hold one Callendar Day content, centralized. The usage
 * of this component:
 *
 * ```tsx
 *    <CalendarCell
 *    color="light"
 *    valign="center"
 *    halign="center"
 *    icon1={
 *      <Icon
 *        area="icon1"
 *        name="Circle"
 *        size="xxsmall"
 *        color="error"
 *        halign="center"
 *      />
 *    }
 *  >
 *    <Title
 *      area="unset"
 *      value="10"
 *      size="xsmall"
 *      color="white"
 *      valign="center"
 *      halign="center"
 *    />
 *  </CalendarCell>
 * ```
 */
export const CalendarCell: React.FC<CalendarCellProps> = ({
  color = 'white',
  area = 'unset',
  disabled = false,
  children,
  pad,
  padAll,
  padCross,
  halign,
  valign,
  iconLeft,
  iconRight,
  onHover,
  onClick,
  value,
}: CalendarCellProps) => {
  return (
    <StyledCalendarCell area={area} pad={solve(pad, padCross, padAll)} halign={halign} valign={valign}>
      <StyledCellNumber
        id={disabled ? '' : value}
        disabled={disabled}
        color={color}
        onClick={() => {
          if (!disabled) onClick?.call(this);
        }}
        onMouseOver={() => onHover?.call(this, true)}
        onMouseOut={() => onHover?.call(this, false)}>
        {children}
      </StyledCellNumber>
      {iconLeft}
      {iconRight}
    </StyledCalendarCell>
  );
};
