import { css, FlattenSimpleInterpolation } from 'styled-components';

/**
 * Creates a Ripple effect, of a circle expanding inside the button. This
 * requires a solid component, which means that the background color isn't
 * a gradient.
 *
 * @param color Background color of the component before the Ripple effect is
 * applied. Defaults to white.
 * @param effectColor Color once the ripple effect is applied. It will cover
 * the entire component, up to the point when the user release the component's
 * click action. Defaults to black.
 * @param duration Duration of the effect in seconds
 */
export const SolidRipple = (
  color = 'white',
  effectColor = 'black',
  duration = 0.8
): FlattenSimpleInterpolation => css`
  background-color: ${color};
  background-position: center;
  transition: background ${duration}s;

  &:hover {
    background: ${color}
      radial-gradient(circle, transparent 1%, ${effectColor} 1%) center/15000%;
  }

  &:active {
    background-color: ${effectColor};
    background-size: 100%;
    transition: background 0s;
  }
`;
