import React from 'react';

import { CalendarCell } from '../../components/CalendarCell';
import { Icon } from '../../components/Icons';
import { Title } from '../../components/Title';
import { solveCalendarCellColor } from '../../scripts/common/calendar';
import { ItemAlign, LineAlign } from '../../scripts/types';

export type SCalendarCellProps = {
  /**
   * Number of the day.
   */
  asValue: number;
  /**
   * If that day is selected.
   */
  isSelected?: boolean;
  /**
   * If that day is today.
   */
  isToday?: boolean;
  /**
   * If exists a programming in that day.
   */
  hasProgramming?: boolean;
  /**
   * If exists a maintenance in that day.
   */
  hasMaintenance?: boolean;
  /**
   * If it shoul be desabled. Defaults to false
   */
  isDisabled?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "singlecard".
   */
  area?: string;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Action activated when clicking cell.
   */
  onClick?: () => void;
  /**
   * Action activated when hovering cell.
   */
  onHover?: (isHover: boolean) => void;
};

/**
 * This is an simple form of Calendar Cell. The usage
 * of this component:
 *
 * ```tsx
   <SCalendarCell isSelected hasProgrammin hasMaintenance />
 * ```
 */
export const SCalendarCell: React.FC<SCalendarCellProps> = ({
  area = 'unset',
  isSelected = false,
  isToday = false,
  hasProgramming = false,
  hasMaintenance = false,
  isDisabled = false,
  halign = 'center',
  valign = 'center',
  onHover,
  onClick,
  asValue,
}: SCalendarCellProps) => {
  return (
    <CalendarCell
      value={asValue.toString()}
      area={area}
      color={isSelected ? 'dark' : 'grey'}
      valign={valign}
      halign={halign}
      disabled={isDisabled}
      onClick={onClick}
      onHover={onHover}
      iconLeft={hasProgramming && <Icon area='iconLeft' name='Circle' size='xxsmall' color='light' halign='center' />}
      iconRight={
        hasMaintenance && <Icon area='iconRight' name='Circle' size='xxsmall' color='error' halign='center' />
      }>
      <Title
        area='unset'
        value={asValue.toString()}
        size='xsmall'
        color={solveCalendarCellColor(isDisabled, isToday, isSelected)}
        valign='center'
        halign='center'
      />
    </CalendarCell>
  );
};
