import { APITime, SafeUser } from './types';
import { CountableList } from '../types';

import { AxiosInstance } from 'axios';
import urlcat from 'urlcat';

/**
 * Returns the active user with the given id.
 *
 * @param id The id of the user to fetch.
 * @param api Axios instance with correct headers.
 * @param bess Which bess to return the list of users from.
 */
export async function fetchUser(
  api: AxiosInstance,
  bess: string,
  id: string
): Promise<APITime<SafeUser>> {
  return await api
    .get<APITime<SafeUser>>(urlcat('/besses/:bess/users/:id', { bess, id }))
    .then((res) => res.data);
}

/**
 * Returns a list of users.
 *
 * @param offset How many users from the beginning to skip.
 * @param bess Which bess to return the list of users from.
 * @param limit How many users to fetch starting from offset.
 * @param api Axios instance with correct headers.
 * @param bess Which bess to return the list of users from.
 */
export async function listUsers(
  api: AxiosInstance,
  bess: string,
  offset = 0,
  limit = 10
): Promise<CountableList<APITime<SafeUser>>> {
  return await api
    .get<CountableList<APITime<SafeUser>>>(
      urlcat('/besses/:bess/users', { bess, limit, offset })
    )
    .then((res) => res.data);
}

/**
 * Creates a new user. The intent is that the user A can create a user B for a
 * client for example.
 *
 * @param username Username of the user. For now it's the same as the email.
 * @param full_name Name of the user, with surname separated by a space.
 * @param email Valid user email. It will be used to confirm the account.
 * @param password Plain text password. It will be hashed soon after.
 * @param api Axios instance with correct headers.
 * @param bess Which bess to return the list of users from.
 */
export async function createUser(
  api: AxiosInstance,
  bess: string,
  full_name: string,
  email: string,
  password: string,
  permissions: {
    bess: string;
    code: string;
  }[]
): Promise<SafeUser> {
  const params = { full_name, email, password, permissions };
  return await api
    .post<SafeUser>(urlcat('/besses/:bess/users', { bess }), params)
    .then((res) => res.data);
}

/**
 * Updates an existing user. All fields are required.
 *
 * @param full_name Name of the user, with surname separated by a space.
 * @param permissions User permissions. It will be used to confirm the account.
 * @param password Plain text password. It will be hashed soon after.
 * @param phone User's phone number.
 * @param id User valid Id.
 * @param api Axios instance with correct headers.
 * @param bess Which bess to return the list of users from.
 */
export async function updateUser(
  api: AxiosInstance,
  bess: string,
  id: string,
  full_name?: string,
  password?: string,
  phone?: string,
  permissions?: {
    _id: string;
    code: string;
  }[]
): Promise<unknown> {
  const params = {
    full_name,
    password,
    phone,
    permissions,
  };
  (Object.keys(params) as (keyof typeof params)[]).forEach((key) => {
    if (params?.[key] === undefined || params?.[key]?.length === 0)
      delete params[key];
  });
  return await api
    .patch(urlcat('/besses/:bess/users/:id', { bess, id }), params)
    .then((res) => res.data);
}

/**
 * Activates or deactivates the user depending on the value of "active".
 *
 * @param api Axios instance with correct headers.
 * @param bess Which bess to return the list of users from.
 * @param id The id of the user to activate / deactivate.
 * @param active The active state of the user to be set.
 */
export async function activateUser(
  api: AxiosInstance,
  bess: string,
  id: string,
  active: boolean
): Promise<unknown> {
  return await api
    .patch(urlcat('/besses/:bess/users/:id', { bess, id }), { active })
    .then((res) => res.data);
}

/**
 * Deletes for real an user and all data related to it.
 *
 * @param api Axios instance with correct headers.
 * @param bess Which bess to return the list of users from.
 * @param id The id of the user to delete.
 */
export async function deleteUser(
  api: AxiosInstance,
  bess: string,
  id: string
): Promise<SafeUser> {
  return await api
    .delete(urlcat('/besses/:bess/users/:id', { bess, id }))
    .then((res) => res.data);
}
