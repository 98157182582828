import React from 'react';
import styled from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const sizes = {
  small: { font: '11px', box: '11px' },
  medium: { font: '12.5px', box: '15px' },
  large: { font: '15px', box: '18px' },
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export type RadioButtonProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Id is needed to link the label to the input
   */
  id?: string;
  /**
   * It can be any text or number.
   */
  label: string;
  /**
   * This fills the marker,
   * it only appears when the field is empty.
   */
  checked?: boolean;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /*
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "Text".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
};

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const Content = styled.div<ContentProps>`
  display: grid;

  grid-template-columns: auto auto;
  gap: 4px;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

type StyledRadioButtonProps = {
  scale: { font: string; box: string };
  bold: boolean;
};

export const Label = styled.label<StyledRadioButtonProps>`
  font-family: ${(props) => props.theme.global.fontFamily};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  font-size: ${(props) => props.scale.font};
  color: #8b8b8b;

  ${Content}:hover & {
    cursor: pointer;
    color: #333333;
  }
`;

export const StyledRadioButton = styled.input<StyledRadioButtonProps>`
  cursor: pointer;
  transition: all 0.5s;
  width: ${(props) => props.scale.box};
  height: ${(props) => props.scale.box};
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #c2c1c1;
  -webkit-appearance: none;
  outline: none;
  box-shadow: inset 0px 0px 0px 2px #ffffff;

  &&:hover {
    border: 3px solid #333333;
  }

  ${Content}:hover & {
    border: 3px solid #333333;
  }

  &&:checked {
    background-color: ${(props) => props.theme.pallete.primary.light};
    border: 3px solid #c2c1c1;

    &&:hover {
      border: 2px solid #333333;
    }

    ${Content}:hover & {
      border: 2px solid #333333;
    }
  }
`;

/**
 * General proposal of the component,
 * check options to submit a form or perform configuration
 */
export const RadioButton: React.FC<RadioButtonProps> = ({
  id = 'check',
  size = 'medium',
  bold = false,
  checked = false,
  area = 'radiobutton',
  ...props
}: RadioButtonProps) => {
  const { pad, padAll, padCross, halign, valign, label, onChange } = props;
  return (
    <Content
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
    >
      <StyledRadioButton
        type="radio"
        id={id}
        scale={sizes[size]}
        bold={bold}
        checked={checked}
        onChange={onChange}
      />
      <Label scale={sizes[size]} bold={bold} htmlFor={id}>
        {label}
      </Label>
    </Content>
  );
};
