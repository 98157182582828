import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { CardContent } from '../../components/CardContent';
import { DropDown } from '../../components/DropDown';
import { GridContainer } from '../../components/GridContainer';
import { Icon, IconProps } from '../../components/Icons';
import { RadioButton } from '../../components/RadioButton';
import { Title } from '../../components/Title';
import { useOutsideClick } from '../../scripts/hooks';

const StyledContainer = styled.div<{ area: string }>`
  grid-area: ${(props) => props.area};
  padding: '5px';
  width: min-content;
  gap: 20px;
`;

const StyledDropContainer = styled.div`
  padding-bottom: 5px;
`;

export type SAcordeonGroupProps = {
  /*
   * Title of the Acordeon, it's the header of the component.
   */
  titles: string[];
  /*
   *  Icon on beggining of the Title.
   */
  icons?: IconProps['name'][];
  /*
   * Options is a list of items names that composes the acordeon.
   */
  options: string[][];
  /*
   * onClickItem is the function called when item is selected. It
   * receives the item index as parameter.
   */
  onClickItem?: (acordeon: number, index: number, name: string) => void;
  /*
   * onClick is the function called when the acordeon is clicked.
   */
  onClick?: (acordeon: number) => void;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
};

export const SAcordeonGroup: React.FC<SAcordeonGroupProps> = ({
  titles,
  options = [],
  icons,
  onClickItem,
  onClick,
  area = 'unset',
}: SAcordeonGroupProps) => {
  const [drop, setDrop] = useState<boolean[]>(Array(titles.length).fill(true));
  const action = (idx: number) => {
    const tempDrop = drop.map(() => true);
    tempDrop[idx] = !drop[idx];
    setDrop(tempDrop);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(
    wrapperRef,
    useCallback(() => setDrop(Array(titles.length).fill(true)), [setDrop, titles])
  );

  const optionsObj: { [key: string]: boolean } = {};
  options.map((value, idx) => {
    value.map((v) => {
      optionsObj[v + idx.toString()] = false;
    });
  });
  const [option, setOption] = useState(optionsObj);

  return (
    <StyledContainer ref={wrapperRef} area={area}>
      {titles.map((title, index) => {
        if (options[index].length > 0) {
          return (
            <StyledDropContainer key={index}>
              <DropDown
                zoffset={0}
                size='acordeon'
                clickable
                collapse={drop[index]}
                staticPos
                dropButton={
                  <Button
                    color='main'
                    halign='center'
                    size='acordeon'
                    radius='small'
                    onClick={() => {
                      action(index);
                      onClick?.call(this, index);
                    }}>
                    <CardContent halign='center'>
                      {icons && (
                        <Icon
                          name={icons[index]}
                          valign='start'
                          size='small'
                          color='secondary'
                          padAll={{ right: 'small' }}
                        />
                      )}
                      <Title size='small' color='white' value={title} halign='center' />
                    </CardContent>
                  </Button>
                }>
                <GridContainer
                  row='small'
                  column='large'
                  valignItems='center'
                  valignLines='center'
                  gap='large'
                  padAll={{ top: 'small' }}>
                  {options[index].map((v, i) => {
                    return (
                      <div
                        key={`${index}-${i}`}
                        style={{
                          width: '255px',
                        }}>
                        <RadioButton
                          id={`check${index}-${i}`}
                          label={v}
                          size='medium'
                          halign='start'
                          bold
                          valign='center'
                          pad='medium'
                          checked={option[v + index.toString()]}
                          onChange={(evt) => {
                            setOption({
                              ...optionsObj,
                              [v + index.toString()]: evt.target.checked,
                            });
                            onClickItem?.call(this, index, i, v);
                          }}
                        />
                      </div>
                    );
                  })}
                </GridContainer>
              </DropDown>
            </StyledDropContainer>
          );
        }
      })}
    </StyledContainer>
  );
};
