import React from 'react';
import styled, { css } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const rowSizes = {
  small: '32px',
  medium: '60px',
  large: '130px',
  auto: 'max-content',
};
type RowSize = keyof typeof rowSizes;

const colSizes = {
  xsmall: '32px',
  small: '100px',
  medium: '210px',
  large: '255px',
  xlarge: '430px',
  auto: 'max-content',
};
type ColSize = keyof typeof colSizes;

const gapSizes = {
  small: '5px',
  medium: '10px',
  large: '20px',
  xlarge: '20px 150px',
};
type GapSize = keyof typeof gapSizes;

const widths = {
  initial: 'initial',
  auto: 'max-content',
};
type Width = keyof typeof widths;

const heights = {
  initial: 'initial',
  auto: 'max-content',
  viewPort: '100vh',
};
type Height = keyof typeof heights;

const dynamicCells = (colSize: ColSize, rowSize: RowSize) => ({
  infinity: css`
    grid-auto-columns: ${colSizes[colSize]};
    grid-auto-rows: ${rowSizes[rowSize]};
  `,
  fitColumns: css`
    grid-template-columns: repeat(auto-fit, minmax(${colSizes[colSize]}, 1fr));
    grid-auto-rows: ${rowSizes[rowSize]};
  `,
  fillColumns: css`
    grid-template-columns: repeat(auto-fill, minmax(${colSizes[colSize]}, 1fr));
    grid-auto-rows: ${rowSizes[rowSize]};
  `,
  fitRows: css`
    grid-template-rows: repeat(auto-fit, minmax(${rowSizes[rowSize]}, 1fr));
    grid-auto-columns: ${colSizes[colSize]};
  `,
  fillRows: css`
    grid-template-rows: repeat(auto-fill, minmax(${rowSizes[rowSize]}, 1fr));
    grid-auto-columns: ${colSizes[colSize]};
  `,
});
type DynamicCells = keyof ReturnType<typeof dynamicCells>;

type FixedCells = { rows: number; cols: number };
const fixedCells = (
  cells: FixedCells,
  colSize: ColSize,
  rowSize: RowSize
) => css`
  grid-template-columns: repeat(${cells.cols}, ${colSizes[colSize]});
  grid-template-rows: repeat(${cells.rows}, ${rowSizes[rowSize]});
`;

type ContainerProps = {
  area: string;
  colSize: ColSize;
  rowSize: RowSize;
  gapSize: GapSize;
  width: Width;
  height: Height;
  dynamicCells: DynamicCells;
  fixedCells?: FixedCells;
  pad: Directional<Pad>;
  halignLines?: LineAlign;
  valignLines?: LineAlign;
  halignItems?: ItemAlign;
  valignItems?: ItemAlign;
};

const Container = styled.div<ContainerProps>`
  display: grid;
  gap: ${(props) => gapSizes[props.gapSize]};
  ${(props) =>
    props.fixedCells
      ? fixedCells(props.fixedCells, props.colSize, props.rowSize)
      : dynamicCells(props.colSize, props.rowSize)[props.dynamicCells]}

  width: ${(props) => widths[props.width]};
  height: ${(props) => heights[props.height]};

  grid-area: ${(props) => props.area};
  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halignItems ?? 'start'};
  justify-items: ${(props) => props.valignItems ?? 'start'};

  align-content: ${(props) => props.halignLines ?? 'stretch'};
  align-items: ${(props) => props?.valignLines ?? 'stretch'};
`;

export type GridContainerProps = {
  children?: React.ReactNode;
  /**
   * Fixed width of the container. Defaults to no fixed width.
   */
  width?: Width;
  /**
   * Fixed height of the container. Defaults to no fixed height.
   */
  height?: Height;
  /**
   * Gap size between elements inside the container.
   */
  gap?: GapSize;
  /**
   * Column size for each column of the container.
   */
  column?: ColSize;
  /**
   * Row size for each row of the container.
   */
  row?: RowSize;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * A dynamic number of grid cells per column or rows. The size of the cells
   * can adapt to the screen size automatically.
   */
  dynamicCells?: DynamicCells;
  /**
   * A fixed number of cells for rows and columns. It takes precedence over
   * dynamic cells if specified.
   */
  fixedCells?: FixedCells;
  /**
   * Padding for all directions
   */
  pad?: Pad;
  /**
   * Padding in each directions
   */
  padAll?: Directional<Pad>;
  /**
   * Padding in cross directions (vertical, horizontal)
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal line alignemnt. Align the columns itself.
   */
  halignLines?: LineAlign;
  /**
   * Vertical row alignemnt. Align the rows itself.
   */
  valignLines?: LineAlign;
  /**
   * Horizontal item alignemnt. Align the items whithin the columns.
   */
  halignItems?: ItemAlign;
  /**
   * Vertical item alignemnt. Align the items whithin the rows.
   */
  valignItems?: ItemAlign;
};

/**
 * GridContainer is used to assemble pages and whatnot. It's easy to specify
 * with the area how many tiles each element should ocuppy. If you need a
 * different grid profile, feel free to add it to the sizes object.
 */
export const GridContainer: React.FC<GridContainerProps> = ({
  children,
  column = 'medium',
  row = 'medium',
  gap = 'medium',
  width = 'initial',
  height = 'initial',
  area = 'unset',
  dynamicCells = 'infinity',
  fixedCells,
  halignLines,
  valignLines,
  halignItems,
  valignItems,
  ...props
}: GridContainerProps) => {
  const { pad, padAll, padCross } = props;
  return (
    <Container
      width={width}
      height={height}
      area={area}
      rowSize={row}
      colSize={column}
      gapSize={gap}
      dynamicCells={dynamicCells}
      fixedCells={fixedCells}
      pad={solve(pad, padCross, padAll)}
      halignLines={halignLines}
      valignLines={valignLines}
      halignItems={halignItems}
      valignItems={valignItems}
    >
      {children}
    </Container>
  );
};
