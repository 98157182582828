import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const CheckCircle: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 496 496"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M496 248C496 384.967 384.967 496 248 496C111.033 496 0 384.967 0 248C0 111.033 111.033 0 248 0C384.967 0 496 111.033 496 248ZM219.314 379.314L403.314 195.314C409.562 189.066 409.562 178.935 403.314 172.687L380.687 150.06C374.439 143.811 364.308 143.811 358.059 150.06L208 300.118L137.941 230.059C131.693 223.811 121.562 223.811 115.313 230.059L92.686 252.686C86.438 258.934 86.438 269.065 92.686 275.313L196.686 379.313C202.935 385.562 213.065 385.562 219.314 379.314V379.314Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
