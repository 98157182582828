import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const PhaseC: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect y="0.65625" width="23" height="23.2396" fill="white" />
          <path
            d="M12.0824 18.5324C10.903 18.5324 9.83497 18.2739 8.87815 17.7567C7.93246 17.2284 7.18704 16.5033 6.64188 15.5815C6.10784 14.6597 5.84082 13.6198 5.84082 12.4619C5.84082 11.304 6.1134 10.2642 6.65856 9.34235C7.20373 8.42054 7.94915 7.70107 8.89484 7.18396C9.85166 6.6556 10.9197 6.39142 12.0991 6.39142C13.0559 6.39142 13.9293 6.56004 14.7192 6.89729C15.5091 7.23454 16.1767 7.72356 16.7218 8.36433L15.32 9.69647C14.4744 8.77465 13.4342 8.31374 12.1992 8.31374C11.3981 8.31374 10.6805 8.49361 10.0464 8.85334C9.41219 9.20183 8.91709 9.69085 8.56107 10.3204C8.20505 10.9499 8.02703 11.6638 8.02703 12.4619C8.02703 13.2601 8.20505 13.9739 8.56107 14.6035C8.91709 15.233 9.41219 15.7276 10.0464 16.0874C10.6805 16.4358 11.3981 16.6101 12.1992 16.6101C13.4342 16.6101 14.4744 16.1436 15.32 15.2105L16.7218 16.5595C16.1767 17.2003 15.5035 17.6893 14.7025 18.0265C13.9126 18.3638 13.0392 18.5324 12.0824 18.5324Z"
            fill="black"
          />
        </mask>
      </defs>
      <rect
        mask={`url(#${idCut})`}
        y="0.65625"
        width="23"
        height="23.2396"
        rx="11.5"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
