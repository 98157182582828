import React, { useState } from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { Button } from "../../components/Button";
import { CardContent } from "../../components/CardContent";
import { DropDown, DropDownBorder } from "../../components/DropDown";
import { Icon } from "../../components/Icons";
import { Title } from "../../components/Title";
import { GradientFade } from "../../css/Gradient";
import { SolidRipple } from "../../css/Ripple";
import { ItemAlign, LineAlign } from "../../scripts/types";

type StyledContainerProps = {
  area: string;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  width: min-content;

  justify-content: ${(props) => props.halign ?? "start"};
  align-items: ${(props) => props?.valign ?? "stretch"};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

const StyledAutoGrid = styled.div`
  grid-template-columns: auto;
`;

const sizes = {
  xsmall: { width: "74px", height: "25.6px" },
  small: { width: "112px", height: "26px" },
  medium: { width: "132px", height: "31.6px" },
  large: { width: "270px", height: "39.6px" },
  xlarge: { width: "320px", height: "39.6px" },
  xxlarge: { width: "210px", height: "60px" },
  dialog: { width: "132px", height: "60px" },
  dropdown: { width: "240px", height: "40px" },
  acordeon: { width: "255px", height: "38px" },
} as const;
type Size = keyof typeof sizes;

export const colors = (theme: DefaultTheme) =>
  ({
    white: css`
      ${SolidRipple("white", theme.pallete.secondary.main)}
    `,
    light: css`
      ${SolidRipple(theme.pallete.primary.light, theme.pallete.border.main)}
    `,
    main: css`
      ${SolidRipple(theme.pallete.primary.main, theme.pallete.border.main)}
    `,
    dark: css`
      ${SolidRipple(theme.pallete.border.main, theme.pallete.primary.main)}
    `,
    success: css`
      ${SolidRipple(theme.pallete.success.main, "white")}
    `,
    error: css`
      ${SolidRipple(theme.pallete.error.main, "white")}
    `,
    secondary: css`
      ${SolidRipple(theme.pallete.secondary.main, theme.pallete.primary.main)}
    `,
    gradient: css`
      ${GradientFade(theme)};
    `,
    inactive: css`
      ${SolidRipple(theme.pallete.neutral.light, theme.pallete.primary.light)}
    `,
    disabled: css`
      background-color: ${theme.pallete.neutral.light};
      cursor: default;
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

export type SBooleanDropDownProps = {
  /**
   * Grid area of the component
   */
  area?: string;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Text of the dropdown when the user isn't over it
   */
  title: string;
  /**
   * Options and actions to select
   */
  options: [string, string];
  /**
   * Fired when an option is clicked
   */
  onOptionClick: (isFirstOption: boolean, optionName: string) => void;
  /**
   * Initial state of the option, whether the first option is selected
   */
  defaultIsFirstSelected: boolean;
  /**
   * Color of the dropdown
   */
  color?: Color;
  /**
   * Size of the dropdown
   */
  size?: Size;
};

/**
 * Dropdown with a set of options with different actions, normally used when
 * a slider isn't enough, and there is the possibily of more options later on.
 */
export const SBooleanDropDown: React.FC<SBooleanDropDownProps> = ({
  title,
  options,
  onOptionClick,
  defaultIsFirstSelected = true,
  halign,
  valign,
  salign,
  area = "unset",
  color = "dark",
  size = "dropdown",
}: SBooleanDropDownProps) => {
  const [drop, setDrop] = useState(true);
  const action = () => {
    setDrop(!drop);
  };

  const [select, setSelect] = useState(defaultIsFirstSelected);

  return (
    <StyledContainer
      area={area}
      valign={valign}
      halign={halign}
      salign={salign}
    >
      <DropDown
        topOffset={40}
        collapse={drop}
        size={size}
        dropButton={
          <Button onClick={action} size={size} color={color}>
            <CardContent template="iconsAround" halign="center" valign="center">
              <Title size="small" color="white" value={title} />
              <Icon
                area="icon-right"
                name="ArrowDown"
                size="xsmall"
                color="light"
                padAll={{ left: "small" }}
              />
            </CardContent>
          </Button>
        }
      >
        <StyledAutoGrid>
          <Button
            onClick={() => {
              onOptionClick(true, options[0]);
              setSelect(true);
              setDrop(true);
            }}
            radius="none"
            size={size}
            color={!select ? "white" : "secondary"}
          >
            <Title size="small" color="gradient" value={options[0]} />
          </Button>
          <Button
            onClick={() => {
              onOptionClick(false, options[0]);
              setSelect(false);
              setDrop(true);
            }}
            radius="none"
            size={size}
            color={select ? "white" : "secondary"}
          >
            <Title size="small" color="gradient" value={options[1]} />
          </Button>
          <DropDownBorder />
        </StyledAutoGrid>
      </DropDown>
    </StyledContainer>
  );
};
