import React from 'react';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import {
  TitleSizes,
  ProfilePicSizes,
  InputSizes,
  ButtonSizes,
  buttonSizes,
  inputSizes,
  profilePicSizes,
  titleSizes,
  LabelSizes,
  labelSizes,
} from './sizes';

const SkeletonAnimation = keyframes`
 0% { opacity: 0.4 }
 20% { opacity: 0.7 }
 40% { opacity: 1 }
 80% { opacity: 0.7 }
 100% { opacity: 0.4 }
`;

export type SkeletonProps = {
  /**
   * Grid area for positioning the Skeleton
   */
  area?: string;
  /**
   * Special attribute for centering some Skeletons with padding. Check the desired skeleton
   * to see if it supports this attribute.
   */
  marginTop?: boolean;
  /**
   * Special attribute for centering some Skeletons with padding. Check the desired skeleton
   * to see if it supports this attribute.
   */
  marginBottom?: boolean;
};

const SkeletonCore = (theme: DefaultTheme) => css`
  display: grid;
  position: relative;
  border-radius: 8px;

  animation: ${SkeletonAnimation} 1.5s linear infinite forwards;
  background-color: ${theme.pallete.skeleton.main};
`;

export const SkeletonBase = styled.div<SkeletonProps>`
  grid-area: ${(props) => props.area ?? 'unset'};
  ${(props) => SkeletonCore(props.theme)}
`;

export const SSingleCard = styled(SkeletonBase)`
  width: 210px;
  height: 60px;
`;

const StyledSDoubleCard = styled(SkeletonBase)`
  width: 430px;
  height: 60px;

  @media (max-width: 440px) {
    width: 210px;
  }
`;

type SDoubleCardProps = {
  area?: string;
  split?: boolean;
  single?: boolean;
};

export const SDoubleCard: React.FC<SDoubleCardProps> = ({
  split = false,
  area = 'unset',
  single = false,
}: SDoubleCardProps) => {
  if (split === false) return <StyledSDoubleCard area={area} />;
  return (
    <>
      {single ? (
        <SSingleCard area={area === 'unset' ? 'unset' : `${area}-left`} />
      ) : (
        <>
          <SSingleCard area={area === 'unset' ? 'unset' : `${area}-left`} />
          <SSingleCard area={area === 'unset' ? 'unset' : `${area}-right`} />
        </>
      )}
    </>
  );
};

export const SVerticalCard = styled(SkeletonBase)`
  width: 210px;
  height: 270px;
`;

export const SVerticalStatusCard = styled(SkeletonBase)`
  width: 210px;
  height: 120px;
`;

export const SThreePhaseCard = styled(SkeletonBase)`
  width: 210px;
  height: 200px;
`;

export const SThreeSwitchCard = styled(SkeletonBase)`
  width: 210px;
  height: 200px;
`;

export const SStatusCard = styled(SkeletonBase)`
  width: 210px;
  height: 60px;
`;

export const SSensingButton = styled(SkeletonBase)`
  width: 210px;
  height: 60px;
`;

export const Title = styled(SkeletonBase)<{ size?: TitleSizes }>`
  width: ${(props) => (props.size ? titleSizes[props.size] : '250px')};
  height: 35px;
  margin-top: ${(props) => (props.marginTop ? '15px' : '')};
  margin-bottom: ${(props) => (props.marginBottom ? '5px' : '')};

  @media (max-width: 440px) {
    width: ${(props) =>
      props.size == 'small' ? titleSizes[props.size] : '210px'};
  }
`;

export const Logo = styled(SkeletonBase)`
  width: 111px;
  height: 62px;
  margin: 10px;
`;

export const Icon = styled(SkeletonBase)`
  width: 20px;
  height: 20px;
`;

export const SMenuItem = styled(SkeletonBase)`
  width: 112px;
  height: 26px;
  background-size: 300px 100%;
`;

export const SAlertsDropDown = styled(SkeletonBase)`
  width: 25px;
  height: 25px;
  margin: 20px;
  background-size: 25px 100%;
`;

export const ProfilePic = styled(SkeletonBase)<{ size?: ProfilePicSizes }>`
  width: ${(props) => (props.size ? profilePicSizes[props.size] : '32px')};
  height: ${(props) => (props.size ? profilePicSizes[props.size] : '32px')};
  border-radius: 100px;
`;

export const ProfileLabel = styled(SkeletonBase)`
  width: 85px;
  height: 18px;
`;

export const SClock = styled(SkeletonBase)`
  width: 60px;
  height: 30px;
  margin: 10px;
`;

export const SlideButton = styled(SkeletonBase)`
  width: 240px;
  height: 40px;
`;

export const Label = styled(SkeletonBase)<{ size?: LabelSizes }>`
  width: ${(props) => (props.size ? labelSizes[props.size] : '85px')};
  height: 15px;
`;

export const Input = styled(SkeletonBase)<{ size?: InputSizes }>`
  width: ${(props) => (props.size ? inputSizes[props.size] : '196px')};
  height: 38px;
`;

export const Button = styled(SkeletonBase)<{ size?: ButtonSizes }>`
  width: ${(props) => (props.size ? buttonSizes[props.size] : '260px')};
  height: ${(props) => (props.size == 'xsmall' ? '25.6px' : '38px')};
  border-radius: ${(props) => (props.size == 'xsmall' ? '20px' : '8px')};
`;

export const DropDown = styled(SkeletonBase)`
  width: 128px;
  height: 32px;
`;

export const SCalendar = styled(SkeletonBase)`
  width: 260px;
  height: 231px;
`;

export const SPagination = styled(SkeletonBase)`
  width: 125px;
  height: 32px;
  justify-content: end;
`;

export const DaySelect = styled(SkeletonBase)`
  width: 260px;
  height: 68px;
`;

export const SChart = styled(SkeletonBase)`
  width: 100%;
  height: 340px;
`;

export const SBallon = styled(SkeletonBase)`
  width: 275px;
  height: 114px;
`;

export const BStringCell = styled(SkeletonBase)`
  width: 60px;
  height: 60px;
  border-radius: 15px;
`;
export const AlertCard = styled(SkeletonBase)`
  width: 240px;
  height: 40px;
`;

export const SHomeAnimation = styled(SkeletonBase)`
  width: 800px;
  height: 472px;

  @media (max-width: 875px) {
    width: 560px;
    height: 306px;
  }

  @media (max-width: 580px) {
    width: 275px;
    height: 150px;
  }
`;

export const SBreaksImage = styled(SkeletonBase)`
  width: 560px;
  height: 472px;

  @media (max-width: 875px) {
    width: 560px;
    height: 306px;
  }

  @media (max-width: 580px) {
    width: 275px;
    height: 150px;
  }
`;

export const Row = styled.tr``;

export const Cell = styled.td<{ hide?: string }>`
  @media (max-width: 720px) {
    display: ${(props) => props.hide == 'medium' && 'none'};
  }

  @media (max-width: 480px) {
    display: ${(props) => props.hide == 'small' && 'none'};
  }
`;

const Table = styled.table<{ area: string }>`
  grid-area: ${(props) => (props.area ? props.area : 'unset')};
  font-family: ${(props) => props.theme.global.fontFamily};
  width: 100%;
  border-collapse: collapse;
  animation: ${SkeletonAnimation} 1.5s linear infinite forwards;

  th,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: 13px;
    border-collapse: none;
  }

  td {
    color: #8c8e8f;
    word-wrap: break-word; /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;
    border-bottom: 1px solid #e6e6e6;
  }

  th {
    width: 120px;
    height: 20px;
    background-color: ${(props) => props.theme.pallete.skeleton.main};
  }

  th:first-of-type {
    border-radius: 30px 0 0 30px;
  }

  th:last-of-type {
    border-radius: 0 30px 30px 0;
  }
`;

const Th = styled.th<{ hide?: string }>`
  @media (max-width: 720px) {
    display: ${(props) => props.hide == 'medium' && 'none'};
  }

  @media (max-width: 480px) {
    display: ${(props) => props.hide == 'small' && 'none'};
  }
`;

const StyledBStringContainer = styled.div<{ columns: number }>`
  display: grid;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => 'repeat(' + props.columns + ', 60px)'};
  gap: 10px;
`;

const StyledSBString = styled.div<{ area: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
  padding: 20px;
`;
type SBStringProps = {
  area: string;
  columns: number;
};
export const SBString: React.FC<SBStringProps> = ({
  area = 'unset',
  columns = 12,
}: SBStringProps) => {
  const rows = [];
  for (let i = 0; i < 60; i++) {
    rows.push(<BStringCell key={i} />);
  }
  return (
    <StyledSBString area={area}>
      <StyledBStringContainer columns={columns}>{rows}</StyledBStringContainer>
    </StyledSBString>
  );
};

const StyledSBorderContainer = styled.div<{ area: string }>`
  display: grid;
  width: 260px;
  height: 145px;
  grid-area: ${(props) => props.area};
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 20px;
  padding-bottom: 20px;
`;
type SBorderContainerProps = {
  area: string;
  size?: InputSizes;
};
export const SBorderContainer: React.FC<SBorderContainerProps> = ({
  area = 'unset',
}: SBorderContainerProps) => {
  return (
    <StyledSBorderContainer area={area}>
      <Label area="1 / 1 / auto / span 3" />
      <Icon area="2 / 1" />
      <Label area="2 / 2" size="large" />
      <Label area="2 / 3" size="xxsmall" />
      <Icon area="3 / 1" />
      <Label area="3 / 2" size="large" />
      <Label area="3 / 3" size="xxsmall" />
    </StyledSBorderContainer>
  );
};

export const Content = styled.div<{ area: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
`;
type FormFieldProps = {
  area: string;
  size?: InputSizes;
};
export const FormField: React.FC<FormFieldProps> = ({
  area = 'unset',
  size = 'medium',
}: FormFieldProps) => {
  return (
    <Content area={area}>
      <Label />
      <Input size={size} />
    </Content>
  );
};

export const STable: React.FC<{ area: string }> = ({
  area = 'unset',
}: {
  area: string;
}) => {
  return (
    <Table area={area}>
      <Row>
        <Th />
        <Th hide="medium" />
        <Th hide="small" />
        <Th />
      </Row>
      <Row>
        <Cell>
          <Label />
        </Cell>
        <Cell hide="medium">
          <Label size="xsmall" />
        </Cell>
        <Cell hide="small">
          <Button size="xsmall" />
        </Cell>
        <Cell>
          <Label size="xsmall" />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label size="medium" />
        </Cell>
        <Cell hide="medium">
          <Label size="large" />
        </Cell>
        <Cell hide="small">
          <Button size="xsmall" />
        </Cell>
        <Cell>
          <Label />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label size="large" />
        </Cell>
        <Cell hide="medium">
          <Label size="medium" />
        </Cell>
        <Cell hide="small">
          <Button size="xsmall" />
        </Cell>
        <Cell>
          <Label />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label />
        </Cell>
        <Cell hide="medium">
          <Label />
        </Cell>
        <Cell hide="small">
          <Button size="xsmall" />
        </Cell>
        <Cell>
          <Label size="medium" />
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label size="large" />
        </Cell>
        <Cell hide="medium">
          <Label size="medium" />
        </Cell>
        <Cell hide="small">
          <Button size="xsmall" />
        </Cell>
        <Cell>
          <Label size="small" />
        </Cell>
      </Row>
    </Table>
  );
};
