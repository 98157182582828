import { GravatarEntry, GravatarResponse } from './types';

import axios from 'axios';
import urlcat from 'urlcat';
import crypto from 'crypto';

/**
 * @param email Email of the user to get the profile picture from
 * @returns The information about the user's profile picture
 */
export async function fetchGravatar(email: string): Promise<GravatarEntry[]> {
  const id = crypto.createHash('md5').update(email).digest('hex');
  return await axios
    .get<GravatarResponse>(urlcat('https://www.gravatar.com/:id.json', { id }))
    .then((res) => res.data.entry);
}

/**
 * @param email Email of the user to get the profile picture url from
 * @returns The url of the main gravatar image of this profile
 */
export function staticGravatarOf(email?: string): string | undefined {
  if (email) {
    const id = crypto.createHash('md5').update(email).digest('hex');
    return urlcat('https://www.gravatar.com/avatar/:id', { id });
  }
}
