import { startOfDay, add, isWithinInterval } from 'date-fns';

/**
 * Returns OPTIONS split by Hour and Minute
 *
 */
export function getOptions(): number[][] {
  const midnight = startOfDay(new Date());
  const OPTIONS = new Array(96).fill(midnight).map((v, i) => {
    const date = add(v, { minutes: 15 * i });
    return [date.getHours(), date.getMinutes()];
  });
  return OPTIONS;
}

/**
 * Returns all timestamps in string format for Charts
 *
 */
export function getDefaultXTimestamps(): string[] {
  const midnight = startOfDay(new Date());
  const OPTIONS = new Array(96).fill(midnight).map((v, i) => {
    const date = add(v, { minutes: 15 * i });
    return date.toISOString();
  });
  return OPTIONS;
}

/**
 * Returns value array with valur in selected interval.
 *
 */
export function getIntervalValues(
  interval: {
    value: number;
    beginHour: number;
    beginMinute: number;
    endHour: number;
    endMinute: number;
  },
  currValue: number[]
): number[] {
  const midnight = startOfDay(new Date());
  const start = add(midnight, {
    hours: interval.beginHour,
    minutes: interval.beginMinute,
  });
  const end = add(midnight, {
    hours: interval.endHour,
    minutes: interval.endMinute,
  });

  const OPTIONS = new Array(96).fill(midnight).map((v, i) => {
    const date = add(v, { minutes: 15 * i });
    if (isWithinInterval(date, { start, end })) {
      return interval.value;
    } 
    return currValue[i];
  });
  return OPTIONS;
}

// /**
//  * Returns the value and start and end of the interval.
//  *
//  */
// export function getIntervalFromValues(
//   values: number[] | undefined
// ): {
//   value: number;
//   beginHour: number;
//   beginMinute: number;
//   endHour: number;
//   endMinute: number;
// } {
//   const midnight = startOfDay(new Date());
//   const OPTIONS = new Array(96).fill(midnight).map((v, i) => {
//     const date = add(v, { minutes: 15 * i });
//     return [date.getHours(), date.getMinutes()];
//   });
//   if (values) {
//     const int = {
//       value: 0,
//       beginHour: -1,
//       beginMinute: -1,
//       endHour: -1,
//       endMinute: -1,
//     };
//     values.forEach((v, i) => {
//       if (v !== 0) {
//         if (int.beginHour === -1 && int.beginMinute === -1) {
//           int.value = v;
//           int.beginHour = OPTIONS[i][0];
//           int.beginMinute = OPTIONS[i][1];
//         }
//       }
//       if (
//         v === 0 &&
//         int.value !== 0 &&
//         int.endHour === -1 &&
//         int.endMinute === -1
//       ) {
//         int.endHour = OPTIONS[i][0];
//         int.endMinute = OPTIONS[i][1];
//       }
//     });
//     return int;
//   }
//   return {
//     value: 0,
//     beginHour: 0,
//     beginMinute: 0,
//     endHour: 23,
//     endMinute: 45,
//   };
// }

/**
 * Returns a list with hour and minute of the idx.
 * @param idx Index of the time option
 */
export function getOption(idx: number): number[] {
  const midnight = startOfDay(new Date());
  const OPTIONS = new Array(96).fill(midnight).map((v, i) => {
    const date = add(v, { minutes: 15 * i });
    return [date.getHours(), date.getMinutes()];
  });
  return OPTIONS[idx];
}

/**
 * Returns the index in options of the hour and minute parameters
 *
 * @param hour The option hour of the searching index
 * @param minute The option minute of the seraching index
 */
export function getOptionsIdx(hour: number, minute: number): number {
  const OPTIONS = getOptions();
  const idx = OPTIONS.findIndex((opt) => {
    return opt[0] === hour && opt[1] === minute;
  });
  return idx ? idx : 0;
}
