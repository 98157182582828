import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Battery: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.589 1.46226H11.2141V1.12251C11.2141 0.941592 11.0856 0.788879 10.9108 0.74143V0.407842C10.9108 0.182966 10.7181 0 10.4811 0H9.74462C9.50769 0 9.31491 0.182944 9.31491 0.407842V0.74143C9.14012 0.788879 9.01168 0.941592 9.01168 1.12251V1.46226H8.67364V0.602687C8.67364 0.430465 8.526 0.290343 8.34455 0.290343H7.40222C7.30495 0.290343 7.22608 0.365198 7.22608 0.457515C7.22608 0.549833 7.30495 0.624688 7.40222 0.624688H8.32139V1.62946C8.32139 1.72177 8.40026 1.79663 8.49753 1.79663H11.5889C11.6213 1.79663 11.6477 1.82163 11.6477 1.85235V2.78645C11.6477 2.81717 11.6213 2.84217 11.5889 2.84217H2.84166C2.74439 2.84217 2.66553 2.91703 2.66553 3.00934C2.66553 3.10166 2.74439 3.17651 2.84166 3.17651H11.0513V8.66465C11.0513 8.81828 10.9196 8.94325 10.7577 8.94325H9.54008C9.44281 8.94325 9.36395 9.01811 9.36395 9.11042C9.36395 9.20274 9.44281 9.2776 9.54008 9.2776H10.7577C11.1138 9.2776 11.4035 9.00265 11.4035 8.66465V3.17647H11.5889C11.8155 3.17647 11.9999 3.00149 11.9999 2.78642V1.85233C11.9999 1.63726 11.8156 1.46226 11.589 1.46226ZM9.74464 0.334344H10.4812C10.5239 0.334344 10.5586 0.367311 10.5586 0.407842V0.725881H9.6672V0.407842C9.6672 0.367311 9.70194 0.334344 9.74464 0.334344ZM9.36397 1.12251C9.36397 1.08814 9.39341 1.0602 9.42962 1.0602H10.7962C10.8324 1.0602 10.8618 1.08814 10.8618 1.12251V1.46226H9.36397V1.12251Z"
        fill={fill}
      />
      <path
        d="M8.83571 8.94321H1.24217C1.08031 8.94321 0.948636 8.81824 0.948636 8.66461V3.17647H2.13727C2.23454 3.17647 2.31341 3.10162 2.31341 3.0093C2.31341 2.91698 2.23454 2.84213 2.13727 2.84213H0.410955C0.378587 2.84213 0.352244 2.81712 0.352244 2.7864V1.85231C0.352244 1.82159 0.378587 1.79658 0.410955 1.79658H3.50237C3.59964 1.79658 3.67851 1.72173 3.67851 1.62941V0.624665H6.69788C6.79514 0.624665 6.87401 0.549811 6.87401 0.457493C6.87401 0.365176 6.79517 0.290321 6.69788 0.290321H3.65533C3.47387 0.290321 3.32624 0.430443 3.32624 0.602665V1.46224H2.9882V1.12251C2.9882 0.941592 2.85976 0.788879 2.68496 0.74143V0.407842C2.68496 0.182966 2.49219 0 2.25526 0H1.51876C1.28183 0 1.08905 0.182944 1.08905 0.407842V0.74143C0.914254 0.788879 0.785816 0.941592 0.785816 1.12251V1.46226H0.410955C0.18436 1.46226 0 1.63726 0 1.85233V2.78642C0 3.00149 0.18436 3.17647 0.410955 3.17647H0.596393V8.66461C0.596393 9.00258 0.886105 9.27755 1.24219 9.27755H8.83571C8.93298 9.27755 9.01185 9.20272 9.01185 9.11038C9.01185 9.01804 8.93298 8.94321 8.83571 8.94321ZM1.51876 0.334344H2.25528C2.29799 0.334344 2.33272 0.367312 2.33272 0.407842V0.725881H1.44132V0.407842C1.44132 0.367312 1.47605 0.334344 1.51876 0.334344ZM1.13808 1.12251C1.13808 1.08814 1.16752 1.0602 1.20373 1.0602H2.57031C2.60652 1.0602 2.63596 1.08814 2.63596 1.12251V1.46226H1.13808V1.12251Z"
        fill={fill}
      />
      <path
        d="M6.51477 5.97935C6.4838 5.92589 6.42467 5.89268 6.36041 5.89268H5.96347L6.37257 5.28134C6.42472 5.20341 6.40044 5.10011 6.31831 5.05061C6.23619 5.00112 6.12737 5.02416 6.07522 5.10211L5.49425 5.97023C5.42372 6.06899 5.50118 6.22676 5.64291 6.22702H6.03985L5.63072 6.83834C5.52952 6.99875 5.7589 7.20997 5.92808 7.01761L6.50905 6.1495C6.54352 6.09798 6.54568 6.03278 6.51477 5.97935Z"
        fill={fill}
      />
      <path
        d="M8.09204 6.05981C8.09204 4.96618 7.15458 4.07642 6.00232 4.07642C4.85003 4.07642 3.9126 4.96618 3.9126 6.05981C3.9126 7.15347 4.85003 8.04321 6.00232 8.04321C7.15458 8.04321 8.09204 7.15347 8.09204 6.05981ZM4.26484 6.05981C4.26484 5.15052 5.04426 4.41074 6.00232 4.41074C6.96035 4.41074 7.73979 5.1505 7.73979 6.05981C7.73979 6.96913 6.96037 7.70889 6.00232 7.70889C5.04426 7.70889 4.26484 6.96913 4.26484 6.05981Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
