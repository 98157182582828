import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const sizes = {
  small: '12.5px',
  medium: '15px',
  large: '18px',
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: css`
    color: white;
  `,
  black: css`
    color: black;
  `,
  dark: css`
    color: ${theme.pallete.border.main};
  `,
  main: css`
    color: ${theme.pallete.primary.main};
  `,
  light: css`
    color: ${theme.pallete.primary.light};
  `,
  secondary: css`
    color: ${theme.pallete.secondary.main};
  `,
  success: css`
    color: ${theme.pallete.success.main};
  `,
  error: css`
    color: ${theme.pallete.error.main};
  `,
  neutral: css`
    color: ${theme.pallete.neutral.light};
  `,
  darkgray: css`
    color: #626262;
  `,
  inactive: css`
    color: ${theme.pallete.neutral.light};
  `,
  disabled: css`
    color: ${theme.pallete.neutral.light};
  `,
  gradient: css`
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      172.14deg,
      ${theme.pallete.accent.main} 12.49%,
      ${theme.pallete.accent.dark} 198.93%
    );
  `,
});
export type Colors = keyof ReturnType<typeof colors>;

export type FormFieldProps = {
  label: string;
  children: React.ReactNode;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  color?: Colors;
  /*
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * If disabled is true, the color is set to gray tone and input
   * is ignored. Defaults to false.
   */
  disabled?: boolean;
  /*
   * Grid area name. Use different areas for different grids. Defaults to
   * "FormField".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Horizontal line alignemnt. Align the columns itself.
   */
  halignLines?: LineAlign;
  /**
   * Vertical row alignemnt. Align the rows itself.
   */
  valignLines?: LineAlign;
  /**
   * Horizontal item alignemnt. Align the items whithin the columns.
   */
  halignItems?: ItemAlign;
  /**
   * Vertical item alignemnt. Align the items whithin the rows.
   */
  valignItems?: ItemAlign;
  /**
   * Label text align.
   */
  txtalign?: LineAlign;
  invert?: boolean;
};

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  halignLines?: LineAlign;
  valignLines?: LineAlign;
  halignItems?: ItemAlign;
  valignItems?: ItemAlign;
  disabled: boolean;
};

export const Content = styled.div<ContentProps>`
  display: grid;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-content: ${(props) => props.halignItems ?? 'start'};
  justify-items: ${(props) => props.valignItems ?? 'start'};

  align-content: ${(props) => props.halignLines ?? 'stretch'};
  align-items: ${(props) => props?.valignLines ?? 'stretch'};

  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
`;

type LabelProps = {
  size: string;
  bold: boolean;
  disabled: boolean;
  txtalign?: LineAlign;
  color?: Colors;
};

export const Label = styled.label<LabelProps>`
  font-family: ${(props) => props.theme.global.fontFamily};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  ${(props) => colors(props.theme)[props.color ?? 'black']}
  text-align: ${(props) => props.txtalign ?? 'none'};
  opacity: 0.8;

  ${Content}:hover & {
    opacity: 1;
  }
`;

/**
 * General proposal of the component,
 * arranges text boxes in a grid and adds a label
 */
export const FormField: React.FC<FormFieldProps> = ({
  size = 'small',
  bold = false,
  disabled = false,
  area = 'unset',
  invert = false,
  color = 'black',
  pad,
  padAll,
  padCross,
  halign,
  valign,
  children,
  label,
  txtalign,
  halignLines,
  valignLines,
  halignItems,
  valignItems,
}: FormFieldProps) => {
  return (
    <Content
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      disabled={disabled}
      halignLines={halignLines}
      valignLines={valignLines}
      halignItems={halignItems}
      valignItems={valignItems}
    >
      {invert ? (
        <>
          {children}
          <Label
            size={sizes[size]}
            txtalign={txtalign}
            bold={bold}
            disabled={disabled}
            color={color}
          >
            {label}
          </Label>
        </>
      ) : (
        <>
          <Label
            size={sizes[size]}
            txtalign={txtalign}
            bold={bold}
            disabled={disabled}
            color={color}
          >
            {label}
          </Label>
          {children}
        </>
      )}
    </Content>
  );
};
