import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Battery50: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.563 1c-0.937 0-1.25 0.211-1.25 1.25v0.937h-2.813c-1.847 0-2.187 0.341-2.187 2.187v23.438c0 1.847 0.341 2.187 2.187 2.187h15c1.847 0 2.187-0.341 2.187-2.187v-23.438c0-1.847-0.341-2.187-2.187-2.187h-2.813v-0.937c0-1.038-0.312-1.25-1.25-1.25h-6.875zM8.5 7.563c0-2.5-0.312-2.187 2.187-2.187 1.25 0 9.688 0 10.625 0 2.5 0 2.187-0.312 2.187 2.187 0 1.25 0 17.813 0 19.063 0 2.5 0.312 2.187-2.187 2.187-1.25 0-9.375 0-10.625 0-2.5 0-2.187 0.312-2.187-2.187 0-1.251 0-17.813 0-19.063zM10.062 17.563h11.875c0.345 0 0.625 0.28 0.625 0.625v3.438c0 0.345-0.28 0.625-0.625 0.625h-11.875c-0.345 0-0.625-0.28-0.625-0.625v-3.438c0-0.345 0.28-0.625 0.625-0.625zM10.062 23.188h11.875c0.345 0 0.625 0.28 0.625 0.625v3.438c0 0.345-0.28 0.625-0.625 0.625h-11.875c-0.345 0-0.625-0.28-0.625-0.625v-3.438c0-0.345 0.28-0.625 0.625-0.625z"
        fill={fill}
      ></path>
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
