import React, { useContext } from 'react';
import styled, { css, DefaultTheme, FlattenSimpleInterpolation, ThemeContext } from 'styled-components';

import { solve } from '../../scripts/common';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import * as names from './components';
type Name = keyof typeof names;

export const sizes = {
  xxsmall: '5px',
  xsmall: '12px',
  lsmall: '16px',
  small: '20px',
  medium: '25px',
  mlarge: '28px',
  large: '32px',
  xlarge: '50px',
  xxlarge: '70px',
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xxsmall: '1px',
  xsmall: '4px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: 'white',
  black: 'black',
  dark: theme.pallete.border.main,
  main: theme.pallete.primary.main,
  light: theme.pallete.primary.light ?? 'white',
  info: theme.pallete.primary.light ?? 'white',
  secondary: theme.pallete.secondary.main,
  success: theme.pallete.success.main,
  error: theme.pallete.error.main,
  disabled: theme.pallete.neutral.light ?? 'white',
  warning: theme.pallete.secondary.main,
  darkgray: '#626262',
  orange: '#ef9301',
  darkblue: '#000d22',
});
type Color = keyof ReturnType<typeof colors>;

export const ActionAnimation = (): FlattenSimpleInterpolation => css`
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

type StyledAreaProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  hasAction?: boolean;
};

const StyledArea = styled.div<StyledAreaProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};

  ${(props) => (props.hasAction ? ActionAnimation() : '')}
`;

export type IconProps = {
  /**
   * Click action. If no click action is provided, the icon button does nothing,
   * but doesn't fail.
   */
  onClick?: () => void;
  /**
   * Name of the icon, required.
   */
  name: Name;
  /**
   * Size of the icon, defaults to small.
   */
  size?: Size;
  /**
   * Color of the icon, defaults to black. If primary light isn't defined on
   * the theme, then the value 'primaryLight' defaults to white.
   */
  color?: Color;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "icon".
   */
  area?: string;
  /**
   * Padding for all directions
   */
  pad?: Pad;
  /**
   * Padding in each directions
   */
  padAll?: Directional<Pad>;
  /**
   * Padding in cross directions (vertical, horizontal)
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
};

/**
 * Every single image that is fixed in this project will be considered a icon
 * and tentatively be used as SVG since most "icons" we have are very simple.
 * There is also a requirement for icons to scale well, so, it's straightforward
 * to [recommend SVG over PNG](http://bit.do/fL3mn).
 *
 * For most use cases you will be using the icons in the format described below:
 *
 * ```typescript
 *  <Icon name="Bolt" color="primaryMain" size="medium" />
 * ```
 *
 * Check the description of the possible props to see more examples on how to
 * use this component.
 **/
export const Icon: React.FC<IconProps> = ({
  size = 'small',
  color = 'black',
  gradient = false,
  area = 'icon',
  name,
  pad,
  padAll,
  padCross,
  halign,
  valign,
  onClick,
}: IconProps) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledArea
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      onClick={() => onClick?.call(this)}
      hasAction={onClick !== undefined}>
      {React.createElement(names[name], {
        size: sizes[size],
        color: colors(theme)[color],
        gradient,
      })}
    </StyledArea>
  );
};
