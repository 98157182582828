import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltCheck: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9572 1.01112C13.1444 1.10044 13.2436 1.30797 13.1953 1.5092L11.3499 9.20236H15.2362C15.4017 9.20236 15.5533 9.295 15.6284 9.44213C15.7035 9.58925 15.6895 9.76598 15.5921 9.8995L7.69146 20.734C7.56802 20.9033 7.34191 20.9621 7.15131 20.8745C6.9607 20.7869 6.85863 20.5773 6.90746 20.3737L8.75284 12.6806H4.37275C4.2049 12.6806 4.05166 12.5854 3.97768 12.4351C3.9037 12.2848 3.92185 12.1057 4.02448 11.9732L12.419 1.13867C12.5458 0.974942 12.7701 0.921796 12.9572 1.01112ZM5.26908 11.8026H9.31068C9.44543 11.8026 9.57275 11.8642 9.65622 11.9697C9.73968 12.0752 9.77009 12.213 9.73874 12.3437L8.27668 18.4389L14.3719 10.0803H10.7921C10.6573 10.0803 10.53 10.0187 10.4465 9.91321C10.3631 9.80771 10.3327 9.66989 10.364 9.53919L11.8623 3.29293L5.26908 11.8026Z"
        fill={fill}
      />
      <path
        d="M4.81311 3.10883C5.00838 2.91408 5.32496 2.91408 5.52022 3.10883C5.71548 3.30357 5.71548 3.61931 5.52022 3.81405L2.52022 6.80607C2.32496 7.00081 2.00838 7.00081 1.81311 6.80607L0.146447 5.14384C-0.0488155 4.94909 -0.0488155 4.63335 0.146447 4.43861C0.341709 4.24387 0.658291 4.24387 0.853553 4.43861L2.16667 5.74823L4.81311 3.10883Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
