import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Signal: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0725 0.928945C16.8437 0.700117 16.4727 0.700117 16.2439 0.928945C16.0151 1.15777 16.0151 1.52875 16.2439 1.75758C17.9101 3.42379 18.8277 5.64074 18.8277 8C18.8277 10.3593 17.9101 12.5762 16.2439 14.2424C16.0151 14.4712 16.0151 14.8422 16.2439 15.0711C16.4731 15.3003 16.8441 15.2995 17.0725 15.0711C18.9601 13.1835 19.9996 10.6723 19.9996 8C19.9996 5.32769 18.9601 2.81648 17.0725 0.928945Z"
        fill={fill}
      />
      <path
        d="M15.2991 2.70313C15.0703 2.47434 14.6992 2.47434 14.4704 2.70313C14.2417 2.93196 14.2417 3.30294 14.4704 3.53176C15.6631 4.72438 16.32 6.31126 16.32 8.00001C16.32 9.68876 15.6632 11.2756 14.4704 12.4682C14.2416 12.6971 14.2416 13.0681 14.4704 13.2969C14.6992 13.5256 15.0702 13.5257 15.2991 13.2969C16.7131 11.8829 17.4919 10.0018 17.4919 7.99997C17.4919 5.99817 16.7131 4.11708 15.2991 2.70313Z"
        fill={fill}
      />
      <path
        d="M13.5227 4.47858C13.2939 4.24979 12.9229 4.24979 12.6941 4.47858C12.4653 4.70741 12.4653 5.07843 12.6941 5.30722C13.4128 6.02589 13.8087 6.98222 13.8087 7.99996C13.8087 9.01769 13.4128 9.97403 12.6941 10.6927C12.4653 10.9215 12.4653 11.2925 12.6941 11.5213C12.9229 11.7501 13.2938 11.7502 13.5227 11.5213C14.4628 10.5813 14.9805 9.3307 14.9805 7.99992C14.9805 6.66914 14.4628 5.41862 13.5227 4.47858Z"
        fill={fill}
      />
      <path
        d="M1.17188 8.00035C1.17188 5.64109 2.08949 3.42414 3.75574 1.75793C3.98457 1.52914 3.98457 1.15812 3.75574 0.929297C3.52695 0.700508 3.15598 0.700469 2.92711 0.929297C1.03953 2.81684 0 5.32805 0 8.00035C0 10.6727 1.03953 13.1839 2.92711 15.0714C3.15586 15.3002 3.52691 15.3003 3.75574 15.0714C3.98457 14.8426 3.98457 14.4716 3.75574 14.2428C2.08949 12.5766 1.17188 10.3596 1.17188 8.00035Z"
        fill={fill}
      />
      <path
        d="M3.68066 8.00001C3.68066 6.31126 4.3375 4.72442 5.53023 3.53176C5.75906 3.30294 5.75906 2.93196 5.53023 2.70313C5.30145 2.4743 4.93043 2.47434 4.7016 2.70313C3.28754 4.11708 2.50879 5.99825 2.50879 8.00001C2.50879 10.0018 3.28754 11.8829 4.7016 13.2969C4.93074 13.526 5.30172 13.5254 5.53023 13.2969C5.75902 13.0681 5.75902 12.6971 5.53023 12.4683C4.3375 11.2756 3.68066 9.68876 3.68066 8.00001Z"
        fill={fill}
      />
      <path
        d="M7.305 4.47863C7.07621 4.2498 6.70519 4.2498 6.47636 4.47863C5.53629 5.41867 5.01855 6.66925 5.01855 8.00003C5.01855 9.33082 5.53629 10.5814 6.47636 11.5214C6.70551 11.7505 7.07648 11.75 7.305 11.5214C7.53382 11.2926 7.53379 10.9216 7.305 10.6928C6.58625 9.97414 6.19043 9.01781 6.19043 8.00007C6.19043 6.98234 6.58625 6.02601 7.305 5.30734C7.53379 5.07847 7.53379 4.70746 7.305 4.47863Z"
        fill={fill}
      />
      <path
        d="M9.99999 5.53125C8.63871 5.53125 7.53125 6.63875 7.53125 7.99999C7.53125 9.36124 8.63875 10.4687 9.99999 10.4687C11.3612 10.4687 12.4687 9.36124 12.4687 7.99999C12.4687 6.63875 11.3613 5.53125 9.99999 5.53125ZM9.99999 9.29686C9.28488 9.29686 8.70312 8.71507 8.70312 7.99999C8.70312 7.28492 9.28492 6.70312 9.99999 6.70312C10.7151 6.70312 11.2969 7.28492 11.2969 7.99999C11.2969 8.71507 10.7151 9.29686 9.99999 9.29686Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
