import { Bess, BessAddress } from './types';

import { AxiosInstance } from 'axios';
import { CountableList } from '../types';

/**
 * Returns the active bess with the given id.
 *
 * @param id The id of the bess to fetch.
 * @param api Axios instance with correct headers.
 */
export async function fetchBess(api: AxiosInstance, id: string): Promise<Bess | undefined> {
  return await (await api.get<CountableList<Bess>>(`/besses?limit=50&offset=0`).then((res) => res.data)).items.find(bess => bess._id == id);
}

/**
 * Returns a list of besses.
 *
 * @param offset How many besses from the beginning to skip.
 * @param limit How many besses to fetch starting from offset.
 * @param api Axios instance with correct headers.
 */
export async function listBesses(
  api: AxiosInstance,
  offset = 0,
  limit = 10
): Promise<CountableList<Bess>> {
  return await api
    .get<CountableList<Bess>>(`/besses?limit=${limit}&offset=${offset}`)
    .then((res) => res.data);
}

/**
 * Creates a new bess.
 *
 * @param name Nickname of the bess.
 * @param description Description of the bess.
 * @param addresses List of addresses of the bess, if more than one.
 * @param api Axios instance with correct headers.
 */
export async function createBess(
  api: AxiosInstance,
  name: string,
  description: string,
  addresses: BessAddress[]
): Promise<Bess> {
  const params = { name, description, addresses };
  return await api.post<Bess>('/besses', params).then((res) => res.data);
}
