import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

import { DropDown, Icon } from '../../components';
import { Button, ButtonProps } from '../../components/Button';
import { DropDownBorder } from '../../components/DropDown';
import { Title } from '../../components/Title';
import { GradientFade } from '../../css/Gradient';
import { SolidRipple } from '../../css/Ripple';

export const colors = (theme: DefaultTheme) =>
  ({
    black: css`
      ${SolidRipple('black', 'white')}
    `,
    neutral: css`
      ${SolidRipple(theme.pallete.neutral.light, 'black')}
    `,
    darkgray: css`
      ${SolidRipple('#626262', 'white')}
    `,
    white: css`
      ${SolidRipple('white', theme.pallete.border.main)}
    `,
    light: css`
      ${SolidRipple(theme.pallete.primary.light, theme.pallete.border.main)}
    `,
    main: css`
      ${SolidRipple(theme.pallete.primary.main, theme.pallete.border.main)}
    `,
    dark: css`
      ${SolidRipple(theme.pallete.border.main, theme.pallete.primary.main)}
    `,
    success: css`
      ${SolidRipple(theme.pallete.success.main, 'white')}
    `,
    error: css`
      ${SolidRipple(theme.pallete.error.main, 'white')}
    `,
    secondary: css`
      ${SolidRipple(theme.pallete.secondary.main, theme.pallete.primary.main)}
    `,
    gradient: css`
      ${GradientFade(theme)};
    `,
    inactive: css`
      ${SolidRipple(theme.pallete.neutral.light, theme.pallete.neutral.main)}
    `,
    lighter: css`
      ${SolidRipple(theme.pallete.neutral.lighter, theme.pallete.neutral.main)}
    `,
    orange: css`
      ${SolidRipple('#ef9301', '#6e4503')}
    `,
    Alarmlevel1: css`
      background-color: #f5efd9;
    `,
    Alarmlevel2: css`
      background-color: #fff7ea;
    `,
    Alarmlevel3: css`
      background-color: #fdedea;
    `,
    transparent: css`
      background: transparent;
    `,
    disabled: css`
      background-color: ${theme.pallete.neutral.light};
      cursor: default;
    `,
  } as const);
type Colors = keyof ReturnType<typeof colors>;

export type SMenuItemProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Title of the menu item.
   */
  title: string;
  /**
   * Current active menu item.
   */
  currentActiveMenuItem?: boolean;
  /**
   * Size of the menu item.
   */
  options?: { title: string; onClick?: () => void; visible?: boolean }[];
  /**
   * Size of the menu item.
   */
  size?: ButtonProps['size'];
  /**
   * If true, the menu item won't accept any actions.
   */
  disabled?: boolean;
  /**
   * If the Menu is clickable or not (in DropDown case). Default is false.
   */
  clickable?: boolean;
  /**
   * Action when the item is clicked.
   */
  onClick?: () => void;
  /**
   * Color of the button when is active. If no gradient and no color is provided,
   * the default text color is black.
   */
  activeColor?: Colors;

  border?: string;

  color?:
    | 'main'
    | 'white'
    | 'black'
    | 'neutral'
    | 'darkgray'
    | 'light'
    | 'dark'
    | 'success'
    | 'error'
    | 'secondary'
    | 'gradient'
    | 'inactive'
    | 'disabled'
    | 'gray'
    | 'orange'
    | undefined;

  sizeText?: 'small' | 'xxsmall' | 'xsmall' | 'xmedium' | 'medium' | 'large' | 'xlarge' | 'smallx';

  radius?: 'small' | 'medium' | 'xmedium' | 'large' | 'xlarge' | 'none';

  bold?: boolean;

  colorInactive?: Colors;
};

const DropDownContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
/**
 * Menu button component. It accepts one action when clicked and can
 * be disabled at will.
 *
 * ```tsx
 * <SMenuItem title='Press' onClick={handleClick}/>
 * ```
 */
export const SMenuItem: React.FC<SMenuItemProps> = ({
  border,
  activeColor = 'black',
  area = 'unset',
  size = 'small',
  sizeText = 'xsmall',
  radius = 'large',
  colorInactive = 'inactive',
  bold = false,
  title,
  options,
  disabled = false,
  clickable = false,
  currentActiveMenuItem = false,
  onClick,
  color,
}: SMenuItemProps) => {
  const [drop, setDrop] = useState(true);
  const [currentActiveSubMenuItem, setCurrentActiveSubMenuItem] = useState(-1);
  const [currentActive, setCurrentActive] = useState(currentActiveMenuItem);

  useEffect(() => {
    setCurrentActive(currentActiveMenuItem);
    if (currentActiveMenuItem === false) {
      setCurrentActiveSubMenuItem(-1);
    }
  }, [currentActiveMenuItem]);

  const action = () => {
    setDrop(!drop);
  };

  return (
    <React.Fragment>
      {options ? (
        <DropDown
          size='auto'
          topOffset={27}
          collapse={drop}
          clickable={clickable}
          halign='start'
          dropButton={
            <Button
              radius='large'
              size={size}
              color={currentActive ? activeColor : 'inactive'}
              disabled={disabled}
              area={area}
              onClick={() => {
                action();
              }}>
              <DropDownContainer>
                <Title area='unset' size='xsmall' color={color} value={title} />
                <Icon area='unset' color={color} name='ArrowDown' size='xsmall' halign='start' />
              </DropDownContainer>
            </Button>
          }>
          <div
            style={{
              gridTemplateColumns: '200px',
              height: 'max-content',
            }}>
            {options.map(
              (item, i) =>
                item.visible && (
                  <React.Fragment key={i}>
                    <Button
                      radius='none'
                      size={size}
                      color={currentActiveSubMenuItem === i ? activeColor : 'white'}
                      disabled={disabled}
                      onClick={() => {
                        item.onClick?.call(this);
                        onClick?.call(this);
                        setCurrentActiveSubMenuItem(i);
                        setCurrentActive(true);
                      }}>
                      <Title
                        size='xsmall'
                        color={currentActiveSubMenuItem === i ? 'white' : 'gradient'}
                        value={item.title}
                      />
                    </Button>
                    <DropDownBorder />
                  </React.Fragment>
                )
            )}
          </div>
        </DropDown>
      ) : (
        <Button
          radius={radius}
          size={size}
          color={currentActiveMenuItem ? activeColor : colorInactive}
          disabled={disabled}
          area={area}
          border={border}
          onClick={() => onClick?.call(this)}>
          <Title size={sizeText} color={color} value={title} bold={bold} />
        </Button>
      )}
    </React.Fragment>
  );
};
