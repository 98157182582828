import React from 'react';
import { Icon, IconProps } from '../../components/Icons';
import { Title } from '../../components/Title';
import styled, { DefaultTheme } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';
import { Spinner } from '../../components';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '8px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const margins = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Margin = keyof typeof margins;

const sizes = {
  small: '410px',
  medium: '448px',
  large: '500px',
  auto: '100%',
} as const;
type Size = keyof typeof sizes;

const colors = (theme: DefaultTheme) => ({
  success: theme.pallete.success.main,
  info: theme.pallete.primary.light,
  error: theme.pallete.error.main,
});
export type Color = keyof ReturnType<typeof colors>;

const allIcons = {
  exit: 'SignOut' as IconProps['name'],
  check: 'Check' as IconProps['name'],
  operation: 'Gear' as IconProps['name'],
  plug: 'Plug' as IconProps['name'],
  warning: 'Warning' as IconProps['name'],
  error: 'Cross' as IconProps['name'],
} as const;
type Icons = keyof typeof allIcons;

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  margin: Directional<Margin>;
  halign?: LineAlign;
  valign?: ItemAlign;
  color: Color;
  size: string;
};

const Content = styled.div<ContentProps>`
  position: relative;
  display: grid;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  margin-left: ${(props) => margins[props.margin.left ?? 'none']};
  margin-right: ${(props) => margins[props.margin.right ?? 'none']};
  margin-top: ${(props) => margins[props.margin.top ?? 'none']};
  margin-bottom: ${(props) => margins[props.margin.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  grid-template-rows: 7px 28px 33px 7px;
  background-color: ${(props) => colors(props.theme)[props.color]};
  width: ${(props) => props.size};
  height: 75px;
  border-radius: 10px;
`;

export type SFormStatusProps = {
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Margin for all directions (top, bottom, left, right)
   */
  margin?: Margin;
  /**
   * Individual margin for each direction.
   */
  marginAll?: Directional<Margin>;
  /**
   * Margin vertical and horizontal.
   */
  marginCross?: Cross<Margin>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Current Icon.
   */
  icon: Icons;
  /**
   * If true, it will show the icon with a spinner
   */
  spinner?: boolean;
  /**
   * Current Status.
   */
  color: Color;
  /**
   * Current Message.
   */
  message: string;
};

/**
 * Login Status component. It accepts one status and its message.
 *
 * ```tsx
 * <SFormStatus status="operation" message="Aguardando resposta do servidor" />
 * ```
 */
export const SFormStatus: React.FC<SFormStatusProps> = ({
  area = 'unset',
  color = 'info',
  size = 'small',
  spinner = false,
  pad,
  padAll,
  padCross,
  margin,
  marginAll,
  marginCross,
  halign,
  valign,
  message,
  icon,
}: SFormStatusProps) => {
  return (
    <Content
      area={area}
      pad={solve(pad, padCross, padAll)}
      margin={solve(margin, marginCross, marginAll)}
      halign={halign}
      valign={valign}
      color={color}
      size={sizes[size]}
    >
      {spinner ? (
        <Spinner
          area="2/1"
          background={color}
          color="white"
          icon={allIcons[icon]}
          size="xsmall"
          visible
        />
      ) : (
        <Icon
          area="2/1"
          name={allIcons[icon]}
          color="white"
          size="small"
          valign="center"
          halign="center"
        />
      )}
      <Title
        area="3/1"
        value={message}
        color="white"
        size="xsmall"
        valign="center"
        halign="center"
        txtalign="center"
      />
    </Content>
  );
};
