import React from 'react';
import styled from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';
import { SolidRipple } from '../../css/Ripple';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

type StyledPageCellProps = {
  radius?: string;
  active: boolean;
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const StyledPageCell = styled.div<StyledPageCellProps>`
  display: grid;
  grid-template:
    'title' 1fr
    / min-content;
  grid-area: ${(props) => props.area};

  background-color: ${(props) =>
    props.active
      ? props.theme.pallete.primary.light
      : SolidRipple('white', props.theme.pallete.neutral.light, 0.5)};

  border-radius: ${(props) => props.radius ?? '0px'};

  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 7px 10px rgba(77, 124, 254, 0.08));

  width: 32px;
  height: 32px;

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.active ? 'none' : 'auto')};

  &:hover {
    filter: ${(props) =>
      props.active
        ? 'none'
        : `drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.2))
           drop-shadow(0px 7px 10px rgba(77, 124, 254, 0.3));
    `};
  }
`;

export interface PageCellProps {
  children?: React.ReactNode;
  /**
   * Border radius of the cell.
   */
  radius?: string;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * If active, then it's the current page, therefore, there is no action
   * possible and the button will be deactivated.
   */
  active?: boolean;
  /**
   * Click action. If no click action is provided, the button does nothing, but
   * doesn't fail.
   */
  onClick?: () => void;
}

/**
 * Page Cell, used for pagination. Can receive a title.
 * 
 * ```tsx
      <PageCell valign="center" halign="center">
        <Title value="1" size="small" color="light" bold />
      </PageCell>
    ```
 */
export const PageCell: React.FC<PageCellProps> = ({
  area = 'unset',
  radius = '8px',
  active = false,
  onClick,
  ...props
}: PageCellProps) => {
  const { children, pad, padAll, padCross, halign, valign } = props;
  return (
    <StyledPageCell
      area={area}
      halign={halign}
      valign={valign}
      radius={radius}
      active={active}
      pad={solve(pad, padCross, padAll)}
      onClick={() => onClick?.call(this)}
    >
      {children}
    </StyledPageCell>
  );
};
