import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { Table } from '../../components';
import { Row, Cell } from '../../components/Table/Table';
import { TableHead } from '../../components/TableHead';
import { SNoData } from '../SNoData';

const Container = styled.div<{ area: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
`;

export type STableProps<T> = {
  area?: string;
  data: T[];
  headers: {
    title: string;
    hideOn?: 'medium' | 'small';
    mapTo: (item: T & { index: number }) => string | ReactNode;
  }[];
};

export function STable<T>({
  area,
  data,
  headers,
}: STableProps<T>): ReactElement | null {
  return (
    <Container area={area ?? ''}>
      <Table size="auto">
        <TableHead items={headers} />
        <tbody>
          {data.map((line, i) => (
            <Row key={i}>
              {headers.map((header, index) => (
                <Cell key={index} hide={header.hideOn}>
                  {header.mapTo({ ...line, index })}
                </Cell>
              ))}
            </Row>
          ))}
        </tbody>
      </Table>
      {data.length === 0 && (
        <SNoData value="Não há dados para a data selecionada." />
      )}
    </Container>
  );
}
