import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowRightCircle: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect width="11.2696" height="11.2696" fill="white" />
          <path
            fill="black"
            d="M6.57987 5.41178L4.17229 3.0042C3.94257 2.77448 3.94257 2.40203 4.17229 2.17231C4.40201 1.94259 4.77446 1.94259 5.00418 2.17231L7.82771 4.99584C8.05743 5.22556 8.05743 5.59801 7.82771 5.82773L5.00418 8.65126C4.77446 8.88098 4.40201 8.88098 4.17229 8.65126C3.94257 8.42154 3.94257 8.04909 4.17229 7.81937L6.57987 5.41178Z"
          />
        </mask>
      </defs>
      <rect
        mask={`url(#${idCut})`}
        width="11.2696"
        height="11.2696"
        rx="5.63478"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
