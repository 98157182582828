import { APITime, SafeUser, Token } from './types';

import { AxiosInstance } from 'axios';

/**
 * Does signin the user with the given email and plain password.
 *
 * @param email Valid user email. It's be used to confirm the account.
 * @param password Plain text password.
 * @param api Axios instance with correct headers.
 */
export async function signin(
  api: AxiosInstance,
  email: string,
  password: string
): Promise<SafeUser & { token: Token }> {
  const params = { email, password };
  return await api
    .post<SafeUser & { token: Token }>('/auth/signin', params)
    .then((res) => res.data);
}

/**
 * Does signup of the user. The user will be still inactive until the email
 * and/or two factor authentication confirmation is completed. It's possible
 * to add more information later on.
 *
 * @param username Username of the user. For now it's the same as the email.
 * @param full_name Name of the user, with surname separated by a space.
 * @param email Valid user email. It will be used to confirm the account.
 * @param password Plain text password.
 * @param api Axios instance with correct headers.
 */
export async function signup(
  api: AxiosInstance,
  username: string,
  full_name: string,
  email: string,
  password: string
): Promise<SafeUser & { token: Token }> {
  const params = { username, password, full_name, email };
  return await api
    .post<SafeUser & { token: Token }>('/auth/signup', params)
    .then((res) => res.data);
}

/**
 * All information of the current logged in user.
 *
 * @param api Axios instance with correct headers.
 */
export async function me(
  api: AxiosInstance
): Promise<
  APITime<SafeUser> & {
    token: Token;
  }
> {
  return await api
    .get<
      APITime<SafeUser> & {
        token: Token;
      }
    >('/auth/me')
    .then((res) => res.data);
}

/**
 * Requests a new password when the user forgot or lost the current password
 *
 * @param api Axios instance with correct headers.
 * @param email Email of the user to recover the password from.
 */
export async function requestNewPassword(
  api: AxiosInstance,
  email: string
): Promise<undefined> {
  return await api
    .post('/auth/password/new', { email })
    .then((res) => res.data);
}

/**
 * Requests a new pin when the user lost the current pin
 *
 * @param api Axios instance with correct headers.
 * @param email Email of the user to recover the password from.
 */
export async function requestNewPin(
  api: AxiosInstance,
  email: string
): Promise<undefined> {
  return await api.post('/auth/pin/new', { email }).then((res) => res.data);
}

/**
 * Changes the password of the user, can be triggered even unauthenticated.
 *
 * @param api Axios instance with correct headers.
 * @param email Email of the user that is recovering the password
 * @param password Password of the user that is recovering the password
 * @param token Comes as query in the url. See preact router's way to handle
 * this (comes like this: ...?token=aldaljsdliasd) in the url.
 * @param pin Current pin of the user that is recovering the password
 */
export async function recoverPassword(
  api: AxiosInstance,
  email: string,
  password: string,
  pin: string,
  token: string
): Promise<undefined> {
  return await api
    .post('/auth/recover', { password, email, pin, token })
    .then((res) => res.data);
}
