import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ClockStroke: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const stroke = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="6.5" cy="6.45077" rx="5.5" ry="5.48536" stroke={stroke} />
      <path
        d="M6.5 3.43383V6.45078L8.15 8.09639"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
