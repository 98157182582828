import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Hamburguer: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      id="Layer_1"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
      />
      <path
        fill={fill}
        d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
      />
      <path
        fill={fill}
        d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
