/**
 * Repeats a type T, applying for each direction.
 */
export type Directional<T> = {
  left?: T;
  top?: T;
  right?: T;
  bottom?: T;
};

/**
 * Repeats a type T, applying for a horizontal and vertical option.
 */
export type Cross<T> = {
  vertical: T; // top bottom
  horizontal: T; // left right
};

/**
 * Countable list of items
 */
export type CountableList<T> = { items: T[]; total: number };

/**
 * Normally linked to the css justify-content property
 */
export type LineAlign =
  | 'start'
  | 'end'
  | 'center'
  | 'stretch'
  | 'space-around'
  | 'space-between'
  | 'space-evenly';

/**
 * Normally linked to the css align-items property
 */
export type ItemAlign = 'start' | 'end' | 'center' | 'stretch' | 'baseline';

/**
 * Possible quantity units
 */
export const units = {
  dimensionless: '', // Dimensionless measurement. Example: Power Factor
  percentage: '%',
  concentration: 'ppm', // Concentration in parts per million
  hazardLimit: '%LEL', // Percentage of minimal concentration for hazardous reaction
  energyInMega: 'MWh',
  energy: 'kWh', // Energy in Kilowatt hours
  activePower: 'kW', // Active Power in Kilowatt
  reactivePower: 'kvar', // Reactive Power in Kilovolt-Ampere Reactance
  apparentPower: 'kVA', // Apparent Power in Kilovolt-Ampere
  voltage: 'V', // Voltage in Volts
  current: 'A', // Current in Amperes
  temperature: 'ºC', // Temperature in Celsius degrees
  frequency: 'Hz', // Frequency in Hertz
  counting: 'x', // The letter x is understood as 'times', as in 10x (10 times)
  minutes: 'min', // Time in minutes
  hours: 'h', // Time in hours
  rotation: 'rpm', // Rotation in revolutions per minute
} as const;
export type Units = keyof typeof units;

export type valueof<T> = T[keyof T];

export type ConfigStatus = 'IDLE' | 'OK' | 'ERROR' | 'TIMEOUT';
export type ConfigMessage<D, S> = {
  status: S;
  data: D;
};

export type ReceivedData<D, S> = { data: D; status: S };
export type RawData<D, S> = string | ConfigMessage<D, S>;
