/**
 * Calculates the perfect size of the spinner
 *
 * @param icon Icon width, where its widht is equal its height
 * @param thickness Thickness of the spinner
 */
export function diameter(icon: number, thickness: number): number {
  return Math.sqrt(2) * icon + thickness / 2;
}

/**
 * Calculates the top displacement for the icon to be at the center of the
 * spinner.
 *
 * @param icon Icon width, where its widht is equal its height
 * @param thickness Thickness of the spinner
 */
export function topIconDisplacement(icon: number, thickness: number): number {
  return thickness + (diameter(icon, thickness) - icon) / 2;
}

/**
 * Calculates the left displacement for the icon to be at the center of the
 * spinner.
 *
 * @param icon Icon width, where its widht is equal its height
 */
export function leftIconDisplacement(icon: number): number {
  return -icon / 2;
}
