import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const TrashCan: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 4.66667H12.25V12.25C12.25 13.2165 11.4665 14 10.5 14H3.5C2.5335 14 1.75 13.2165 1.75 12.25V4.66667ZM5.25 11.0833V7H4.08333V11.0833H5.25ZM7.58333 11.0833V7H6.41667V11.0833H7.58333ZM9.91667 11.0833V7H8.75V11.0833H9.91667ZM13.4167 2.33333C13.7388 2.33333 14 2.5945 14 2.91667C14 3.23883 13.7388 3.5 13.4167 3.5H0.583333C0.261167 3.5 0 3.23883 0 2.91667C0 2.5945 0.261167 2.33333 0.583333 2.33333H4.08333V0.583333C4.08333 0.261167 4.3445 0 4.66667 0H9.33333C9.6555 0 9.91667 0.261167 9.91667 0.583333V2.33333H13.4167ZM5.25 1.16667V2.33333H8.75V1.16667H5.25Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
