import React from 'react';
import styled, { StyledProps } from 'styled-components';

const sizes = {
  small: '500px',
  medium: '720px',
  large: '860px',
  auto: '100%',
} as const;
type Size = keyof typeof sizes;

export type TableProps = {
  children?: React.ReactNode;
  area?: string;
  size?: Size;
};

export const Row = styled.tr``;

type CellProps = {
  hide?: string;
};
export const Cell = styled.td<CellProps>`
  @media (max-width: 720px) {
    display: ${(props) => props.hide == 'medium' && 'none'};
  }

  @media (max-width: 480px) {
    display: ${(props) => props.hide == 'small' && 'none'};
  }
`;

type StyledTableProps = {
  area?: string;
  size: string;
};

export const StyledTable = styled.table<StyledProps<StyledTableProps>>`
  grid-area: ${(props) => (props.area ? props.area : 'unset')};
  font-family: ${(props) => props.theme.global.fontFamily};
  width: ${(props) => props.size};
  border-collapse: collapse;

  th,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: 13px;
    border-collapse: none;
  }

  td {
    color: #8c8e8f;
    word-wrap: break-word; /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;
    border-bottom: 1px solid #e6e6e6;
  }

  th {
    width: 120px;
    color: white;
    background-color: ${(props) => props.theme.pallete.primary.light};
  }

  th:first-of-type {
    border-radius: 30px 0 0 30px;
  }

  th:last-of-type {
    border-radius: 0 30px 30px 0;
  }
`;

export const Table: React.FC<TableProps> = ({
  size = 'medium',
  children,
  area,
}: TableProps) => {
  return (
    <StyledTable area={area} size={sizes[size]}>
      {children}
    </StyledTable>
  );
};
