import React from 'react';
import styled from 'styled-components';
import { useMedia } from '../../../scripts/hooks/mediaQueryHook';

import {
  SDoubleCard,
  SThreeSwitchCard,
  SStatusCard,
  SSensingButton,
  SVerticalStatusCard,
} from '../../Skeleton';

const StyledContainer = styled.div`
  display: grid;
  gap: 10px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Sensors Actuators' 1fr /
    430px 430px;

  @media (max-width: 900px) {
    grid-template:
      'Sensors Actuators' 1fr /
      210px 430px;
  }

  @media (max-width: 660px) {
    grid-template:
      'Sensors Actuators' 1fr /
      210px 210px;
  }

  @media (max-width: 440px) {
    grid-template:
      'Sensors' min-content
      'Actuators' min-content /
      210px;
  }
`;

const StyledSensorsContainer = styled.div`
  display: grid;
  grid-area: Sensors;

  gap: 10px;

  grid-template:
    'H2               H2              ' 1fr
    'H2S              H2S             ' 1fr
    'TemperaturePoint TemperaturePoint' 1fr
    'Temperature      Temperature     ' 1fr /
    215px 215px;

  @media (max-width: 900px) {
    grid-template:
      'H2-left' 60px
      'H2S-left' 60px
      'TemperaturePoint-left' 60px
      'TemperaturePoint-right' 60px
      'Temperature-left' 60px
      'Temperature-right' 60px /
      210px;
  }

  align-items: center;
  justify-content: center;
`;

const StyledActuatorsContainer = styled.div`
  display: grid;
  grid-area: Actuators;
  gap: 10px;

  grid-template:
    'Exhaust     FireControl' 60px
    'Exhaust     Cameras    ' 60px
    'Exhaust     Doors      ' 25px
    'Environment Doors      ' 25px
    'Environment Doors      ' 60px
    'Environment Doors      ' 60px /
    210px 210px;

  @media (max-width: 900px) {
    grid-template:
      'Exhaust     Cameras' 60px
      'Exhaust     Doors  ' 60px
      'Exhaust     Doors  ' 25px
      'Environment Doors  ' 25px
      'Environment Doors  ' 60px
      'Environment .      ' 60px
      'FireControl .      ' 60px /
      210px 210px;
  }

  @media (max-width: 660px) {
    grid-template:
      'Doors      ' 60px
      'Doors      ' 60px
      'Doors      ' 60px
      'FireControl' 60px
      'Exhaust    ' 60px
      'Exhaust    ' 60px
      'Environment' 60px
      'Environment' 60px
      'Cameras    ' 60px /
      210px;
  }

  align-items: center;
  justify-content: center;
`;

export const SensingSkeleton: React.FC = () => {
  const splitDoubleCards = useMedia('(max-width: 900px)');
  return (
    <StyledContainer>
      <StyledSensorsContainer>
        <SDoubleCard area="H2" split={splitDoubleCards} single />
        <SDoubleCard area="H2S" split={splitDoubleCards} single />
        <SDoubleCard area="TemperaturePoint" split={splitDoubleCards} />
        <SDoubleCard area="Temperature" split={splitDoubleCards} />
      </StyledSensorsContainer>
      <StyledActuatorsContainer>
        <SVerticalStatusCard area="Exhaust" />
        <SVerticalStatusCard area="Environment" />
        <SStatusCard area="FireControl" />
        <SSensingButton area="Cameras" />
        <SThreeSwitchCard area="Doors" />
      </StyledActuatorsContainer>
    </StyledContainer>
  );
};
