import React, { useCallback, useRef, useState } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { Button } from '../../components/Button';
import { CardContent } from '../../components/CardContent';
import { DropDown, DropDownBorder } from '../../components/DropDown';
import { Icon, IconProps } from '../../components/Icons';
import { Title } from '../../components/Title';
import { GradientFade } from '../../css/Gradient';
import { SolidRipple } from '../../css/Ripple';
import { useOutsideClick } from '../../scripts/hooks';
import { ItemAlign, LineAlign } from '../../scripts/types';

type StyledContainerProps = {
  area: string;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  width: min-content;

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

const StyledAutoGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  max-height: 206px;

  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.pallete.neutral.light};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.pallete.primary.light};
  }
`;

const sizes = {
  xsmall: { width: '74px', height: '25.6px' },
  small: { width: '112px', height: '26px' },
  medium: { width: '132px', height: '31.6px' },
  xxmedium: { width: '220px', height: '40px' },
  large: { width: '260px', height: '39.6px' },
  xlarge: { width: '320px', height: '39.6px' },
  xxlarge: { width: '210px', height: '60px' },
  dialog: { width: '132px', height: '60px' },
  dropdown: { width: '240px', height: '40px' },
  acordeon: { width: '255px', height: '38px' },
} as const;
type Size = keyof typeof sizes;

export const colors = (theme: DefaultTheme) =>
  ({
    white: css`
      ${SolidRipple('white', theme.pallete.secondary.main)}
    `,
    light: css`
      ${SolidRipple(theme.pallete.primary.light, theme.pallete.border.main)}
    `,
    main: css`
      ${SolidRipple(theme.pallete.primary.main, theme.pallete.border.main)}
    `,
    dark: css`
      ${SolidRipple(theme.pallete.border.main, theme.pallete.primary.main)}
    `,
    success: css`
      ${SolidRipple(theme.pallete.success.main, 'white')}
    `,
    error: css`
      ${SolidRipple(theme.pallete.error.main, 'white')}
    `,
    secondary: css`
      ${SolidRipple(theme.pallete.secondary.main, theme.pallete.primary.main)}
    `,
    gradient: css`
      ${GradientFade(theme)};
    `,
    inactive: css`
      ${SolidRipple(theme.pallete.neutral.light, theme.pallete.primary.light)}
    `,
    disabled: css`
      background-color: ${theme.pallete.neutral.light};
      cursor: default;
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

const Header = styled.div<{ size: Size }>`
  width: ${(props) => sizes[props.size].width};
  height: ${(props) => sizes[props.size].height};
`;

export type SConfigDropDownProps = {
  /**
   * Grid area of the component
   */
  area?: string;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Text of the dropdown when the user isn't over it
   */
  title: string;
  /**
   * Options and actions to select
   */
  options: { name: string; action: () => void }[];
  /**
   * If drops is clickable or in hover.
   */
  clickable?: boolean;
  /**
   * If resetable, on clicking an already selected element, the element will
   * be unselected, which means the element 0 will be selected.
   */
  resetable?: boolean;
  /**
   * Current selected option
   */
  selected?: number;
  /**
   * Icon of the first fixed option, if provided
   */
  headerIcon?: IconProps['name'];
  /**
   * Action of the first fixed option, if provided
   */
  headerAction?: () => void;
  /**
   * Color of the dropdown
   */
  color?: Color;
  /**
   * Size of the dropdown
   */
  size?: Size;
};

/**
 * Dropdown with a set of options with different actions, normally used when
 * a slider isn't enough, and there is the possibily of more options later on.
 */
export const SConfigDropDown: React.FC<SConfigDropDownProps> = ({
  title,
  options,
  selected,
  halign,
  valign,
  salign,
  clickable = false,
  resetable = false,
  area = 'unset',
  color = 'dark',
  size = 'dropdown',
  headerIcon,
  headerAction,
}: SConfigDropDownProps) => {
  const [drop, setDrop] = useState(true);
  const action = () => {
    setDrop(!drop);
  };

  const [select, setSelect] = useState(selected);

  const wrapperRef = useRef(null);
  useOutsideClick(
    wrapperRef,
    useCallback(() => setDrop(true), [setDrop])
  );

  return (
    <StyledContainer
      area={area}
      valign={valign}
      halign={halign}
      salign={salign}
      ref={wrapperRef}
    >
      <DropDown
        topOffset={40}
        collapse={drop}
        clickable={clickable}
        zoffset={2}
        size={size}
        dropButton={
          <Button radius="small" onClick={action} size={size} color={color}>
            <CardContent template="iconsAround" halign="center" valign="center">
              <Title size="small" color="white" value={title} />
              <Icon
                area="icon-right"
                name="ArrowDown"
                size="xsmall"
                color={color == 'light' ? 'white' : 'light'}
                padAll={{ left: 'small' }}
              />
            </CardContent>
          </Button>
        }
      >
        <StyledAutoGrid>
          {headerIcon && headerAction && (
            <Header key="header" size={size}>
              <Icon
                area="button"
                halign="center"
                valign="center"
                color="light"
                name={headerIcon}
                padAll={{ top: 'small', bottom: 'small' }}
                onClick={headerAction}
              />
              <DropDownBorder />
            </Header>
          )}
          {options.map(({ action: optionAction, name }, i) => {
            return (
              <div key={i}>
                <Button
                  onClick={() => {
                    optionAction();
                    if (select === i && resetable) setSelect(undefined);
                    else setSelect(i);
                    setDrop(true);
                  }}
                  radius="none"
                  size={size}
                  color={select != i ? 'white' : 'secondary'}
                >
                  <Title size="small" color="gradient" value={name} />
                </Button>
                {i < options.length - 1 && <DropDownBorder />}
              </div>
            );
          })}
        </StyledAutoGrid>
      </DropDown>
    </StyledContainer>
  );
};
