import React from 'react';
import styled from 'styled-components';

import { CardContent } from '../../components/CardContent';
import { Icon } from '../../components/Icons';
import { Title } from '../../components/Title';

const Line = styled.div`
  grid-area: line;
  background: #d0c9d6;
  margin-left: 47%;
  width: 2%;
  height: 100%;
`;

export type SAlertCellProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Core of the Alert.
   */
  code: number;
  /**
   * Title of the alert.
   */
  title: string;
  /**
   * Time since alert occurried.
   */
  time: string;
  /**
   * Description of the alert.
   */
  description: string;
  /**
   * If true, the line below is hidden. Use it for the last item.
   */
  hideLine?: boolean;

  size?: 'small' | 'xsmall' | 'medium' | 'large' | 'xlarge' | 'xxsmall' | 'lsmall' | 'xxlarge' | undefined;
};

/**
 * String Cell of BMS, content cards with title, quantity and another quantity.
 *
 * ```tsx
 *  <SBStringCell color="main" voltage={1.16} temperature={40.0} />
 * ```
 */
export const SAlertCell: React.FC<SAlertCellProps> = ({
  area = 'unset',
  size = 'xsmall',
  code,
  title,
  time,
  hideLine = false,
  description,
}: SAlertCellProps) => {
  const color = code > 1999 ? (code < 2999 ? 'secondary' : 'error') : 'success';
  return (
    <CardContent
      area={area}
      template='alert'
      halign='center'
      valign='center'
      salign={{ horizontal: 'center', vertical: 'center' }}
      padAll={{ left: 'xsmall', right: 'small' }}>
      <Icon area='icon' color={color} name='CircleStroke' size={size} valign='baseline' />
      <Title area='title' halign='start' value={title} size='xsmall' color='darkgray' padAll={{ left: 'small' }} />
      <Title area='title' halign='end' value={time} size='xsmall' color={color} />
      <Title
        area='description'
        halign='start'
        value={description}
        size='xsmall'
        color='gray'
        padAll={{ left: 'small', top: 'xsmall', bottom: 'small' }}
      />
      {!hideLine && <Line />}
    </CardContent>
  );
};
