import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '2.5px',
  small: '8px',
  medium: '20px',
  large: '36px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export const colors = (theme: DefaultTheme) =>
  ({
    light: css`
      background-color: ${theme.pallete.primary.light};
    `,
    main: css`
      background-color: ${theme.pallete.primary.main};
    `,
    dark: css`
      background-color: ${theme.pallete.border.main};
    `,
    white: css`
      background-color: white;
    `,
    black: css`
      background-color: black;
    `,
    gradient: css`
      background-image: linear-gradient(
        172.14deg,
        ${theme.pallete.accent.main} 12.49%,
        ${theme.pallete.accent.dark} 198.93%
      );
    `,
  } as const);
export const contrast = (theme: DefaultTheme) =>
  ({
    light: theme.pallete.border.main,
    main: theme.pallete.primary.light,
    dark: theme.pallete.primary.light,
    white: theme.pallete.primary.light,
    black: 'white',
    gradient: theme.pallete.primary.light,
  } as const);
type Color = keyof ReturnType<typeof colors>;

type StyledDoubleCardProps = {
  color: Color;
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const StyledDoubleCard = styled.div<StyledDoubleCardProps>`
  display: grid;
  grid-template:
    'content-left .          middle content-right .          ' 1fr
    'content-left index-left middle content-right index-right' 0.25fr
    / minmax(min-content, 175px) 30px 20px minmax(min-content, 175px) 30px;

  ${(props) => colors(props.theme)[props.color]};
  box-shadow: rgba(77, 124, 254, 0.08) 0em 0.4375em 0.625em;
  border-radius: 8px;

  width: 430px;
  height: 60px;

  grid-area: ${(props) => props.area};
  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};
`;

type StyledVerticalStrokeProps = {
  area: string;
  thickDivider: boolean;
};
const StyledVerticalStroke = styled.div<StyledVerticalStrokeProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  border-left: ${(props) => (props.thickDivider ? 'thick' : '2px')} solid
    ${(props) => props.theme.pallete.neutral.lighter};

  height: ${(props) => (props.thickDivider ? '100%' : '90%')};
  width: 2px;

  align-self: center;
  justify-self: center;
`;

type StyledIndexProps = { area: string; color: Color };
const StyledIndex = styled.div<StyledIndexProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  font-family: ${(props) => props.theme.global.fontFamily};
  color: ${(props) => contrast(props.theme)[props.color]};
  font-size: 12px;
  font-weight: bolder;

  padding-bottom: ${pads.small};

  justify-self: center;
`;

export type DoubleCardProps = {
  children?: React.ReactNode;
  /**
   * Background color of the card.
   */
  color?: Color;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "doublecard".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Indexes to be displayed at the bottom right of each card content.
   */
  indexes?: [number] | [number, number];
  /**
   * If true, the dividing vertical line at the center is hidden. Defaults to
   * false.
   */
  hideDivider?: boolean;
  /**
   * If true, the dividing vertical line at the center is as high as the card
   * and also pretty thick. Defaults to false.
   */
  thickDivider?: boolean;
};

/**
 * This "double" card container can hold up to two card contents, centralized.
 * The usage of this component, albeit a bit verbose, but highly readable
 * will be:
 *
 * ```tsx
 *   <DoubleCard
      color="white"
      valign="center"
      halign="center"
      indexes={[122, 15]}
    >
      <CardContent halign="center" area="content-left">
        <Icon
          name="Bolt"
          size="small"
          color="main"
          valign="center"
          padAll={{ right: 'small' }}
        />
        <Title
          value="Tensão Máxima"
          size="xsmall"
          color="main"
          halign="start"
        />
        <Quantity
          value={30.9}
          unit="voltage"
          size="large"
          color="main"
          halign="start"
          valign="baseline"
          minDecimals={3}
          maxDecimals={3}
          bold
        />
      </CardContent>
      <CardContent halign="center" area="content-right">
        <Icon
          name="Bolt"
          size="small"
          color="white"
          valign="center"
          padAll={{ right: 'small' }}
        />
        <Title
          value="Tensão Mínima"
          size="xsmall"
          color="main"
          halign="start"
        />
        <Quantity
          value={12.9}
          unit="voltage"
          size="large"
          color="main"
          halign="start"
          valign="baseline"
          minDecimals={3}
          maxDecimals={3}
          bold
        />
      </CardContent>
    </DoubleCard>
 * ```
 *
 * You can refer a index for each card content. You can also put only one
 * card content if needed.
 */
export const DoubleCard: React.FC<DoubleCardProps> = ({
  color = 'main',
  area = 'doublecard',
  hideDivider = false,
  thickDivider = false,
  ...props
}: DoubleCardProps) => {
  const { children, pad, padAll, padCross, halign, valign, indexes } = props;
  return (
    <StyledDoubleCard
      color={color}
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
    >
      <StyledIndex area="index-left" color={color}>
        {indexes && indexes[0]}
      </StyledIndex>
      <StyledIndex area="index-right" color={color}>
        {indexes && indexes[1]}
      </StyledIndex>
      {!hideDivider && (
        <StyledVerticalStroke thickDivider={thickDivider} area="middle" />
      )}
      {children}
    </StyledDoubleCard>
  );
};
