import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Time: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.4 0C0.179086 0 0 0.179086 0 0.4C0 0.620914 0.179086 0.8 0.4 0.8H0.948795C1.05073 2.59462 1.82588 3.95463 2.59271 4.87482C3.00392 5.36828 3.41497 5.73829 3.72434 5.98579L3.7738 6.025L3.72434 6.06422C3.41497 6.31171 3.00392 6.68172 2.59271 7.17518C1.82588 8.09537 1.05073 9.45538 0.948795 11.25H0.4C0.179086 11.25 0 11.4291 0 11.65C0 11.8709 0.179086 12.05 0.4 12.05H1.3375H9.4625H10.4C10.6209 12.05 10.8 11.8709 10.8 11.65C10.8 11.4291 10.6209 11.25 10.4 11.25H9.8512C9.74927 9.45538 8.97412 8.09537 8.20729 7.17518C7.79608 6.68172 7.38503 6.31171 7.07566 6.06422L7.0262 6.025L7.07566 5.98579C7.38503 5.73829 7.79608 5.36828 8.20729 4.87482C8.97412 3.95463 9.74927 2.59462 9.8512 0.8H10.4C10.6209 0.8 10.8 0.620914 10.8 0.4C10.8 0.179086 10.6209 0 10.4 0H9.4625H1.3375H0.4ZM2.27933 2.9C2.00323 2.29696 1.80162 1.59718 1.75029 0.8H9.04971C8.99838 1.59718 8.79677 2.29696 8.52067 2.9H2.27933ZM4.27322 6.65C4.25721 6.66255 4.24083 6.67552 4.2241 6.68891C3.94753 6.91017 3.57733 7.24328 3.20729 7.68732C2.52486 8.50624 1.85059 9.69212 1.75029 11.25H9.04971C8.94941 9.69212 8.27514 8.50624 7.59271 7.68732C7.22267 7.24328 6.85248 6.91017 6.5759 6.68891C6.55917 6.67552 6.54279 6.66255 6.52678 6.65H4.27322Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
