//import { format } from 'date-fns';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Warnings } from '../../../models/Warnings';
import { Icon } from '../../components';
import { LogoInLine } from '../../components/LogoInLineDark';
import { Title } from '../../components/Title';
import { staticGravatarOf } from '../../scripts/api/gravatar';
import { RoleValue } from '../../scripts/api/types';
import { useMedia } from '../../scripts/hooks/mediaQueryHook';
import { SClock, SDialogConfirmation } from '../../specific';
// import { SClock, SDialogConfirmation } from '../../specific';
//import { SAlertsDropDown } from '../../specific/SAlertsDropDown';
import { SAlertsDropDown } from '../../specific/SAlertsDropDown';
import { SMenuItemClient } from '../../specific/SMenuItem';
//import { SlideMode } from '../../specific/SModeDark';
import { SSideBar } from '../../specific/SSideBar';
//import { SUserDropDown } from '../../specific/SUserDropDown';

export type MenuItemClient = {
  title: string;
  onClick?: () => void;
  visible: boolean;
  options?: { title: string; onClick?: () => void; visible: boolean }[];
};

const ContainerDesktop = styled.div`
  display: grid;

  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #1e2838;

  grid-template:
    'Left  Icons' 80px /
    auto 220px;
`;

const StyledLeftContent = styled.div`
  display: grid;
  grid-area: Left;
  gap: 40px;
  padding-left: 20px;
  justify-content: center;
  align-items: center;
  grid-template:
    'Logo Menu' max-content /
    max-content max-content;
`;

const StyledIcon = styled.div`
  display: grid;
  grid-area: Icons;
  gap: 15px;
  justify-content: center;
  align-items: center;
  grid-template:
    'Alerts UserOptions Line SignOut' max-content /
    max-content max-content max-content max-content;
`;

type ContainerMenuProps = { area: string };
const ContainerMenu = styled.div<ContainerMenuProps>`
  grid-area: ${({ area }) => area};
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 10px;
`;

const ContainerMobile = styled.div`
  display: inline-flex;
  gap: 15px;
  width: 100%;
  height: 80px;
  justify-content: start;
  align-items: center;
  background-color: #1e2838;
  grid-template:
    'SideBar Logo' max-content /
    max-content max-content;
`;

const StyledTitleBess = styled.div`
  display: grid;
  width: 100%;
  height: 35px;
  justify-content: space-between;
  padding: 0px 0px 10px 10px;
  background-color: white;
  box-shadow: rgba(77, 124, 254, 0.08) 0em 0.4375em 0.625em;
  grid-template:
    'TitleShould Clock' max-content /
    250px 165px;
`;

export type ViewMenuClientProps = {
  /**
   * Current selected bess is offline.
   */
  offline: boolean;
  /**
   * Current selected bess name.
   */
  bessName: string;
  /**
   * Name of the page items of the menu.
   */
  menuItems: MenuItemClient[];
  /**
   * Recent alerts.
   */
  alertList?: Warnings[];
  /**
   * Current user's name.
   */
  username?: string;
  /**
   * Current user's email.
   */
  email?: string;
  /**
   * Current user's role.
   */
  permission?: RoleValue;
  /**
   * Opens the users' management page. If null, this option is hidden.
   */
  onUserManagement?: () => void;
  /**
   * Opens the users' management page. If null, this option is hidden.
   */
  onCustomProfile?: () => void;
  /**
   * Logout action.
   */
  onLogOut: () => void;
  /**
   * Opens the alerts page.
   */
  onAlertPage: () => void;
  /**
   * Current PLC local time of the current bess as ISOString.
   */
  time?: string;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;

  modeDark?: () => void;
  modeBoolean?: boolean;
  features: Array<string>;
  userid: string | undefined;
};

/**
 * Menu Page
 */
export const ViewMenuClient: React.FC<ViewMenuClientProps> = ({
  offline,
  bessName,
  email,
  permission,
  // area = 'unset',
  menuItems = [],
  time,
  alertList,
  onUserManagement,
  onCustomProfile,
  onLogOut,
  onAlertPage,
  //modeDark,
  modeBoolean,
  features,
  userid,
}: ViewMenuClientProps) => {
  const isMobile = useMedia('(max-width: 1050px)');

  const [profilePicture, setProfilePicture] = useState<string | undefined>();
  const [hideConfirmation, setHideConfirmation] = useState(true);
  const [currentActiveMenuItem, setCurrentActiveMenuItem] = useState(0);
  const [options] = useState(() => {
    if (onUserManagement)
      return [
        { name: 'Gestão de Usuários', action: () => onUserManagement() },
        { name: 'Sair', action: () => setHideConfirmation(false) },
      ];
    else if (onCustomProfile)
      return [
        { name: 'Editar Perfil', action: () => onCustomProfile() },
        { name: 'Sair', action: () => setHideConfirmation(false) },
      ];
    return [{ name: 'Sair', action: () => setHideConfirmation(false) }];
  });

  useEffect(() => {
    if (email) setProfilePicture(staticGravatarOf(email));
  }, [email]);

  return (
    <>
      {!hideConfirmation && (
        <SDialogConfirmation
          hide={hideConfirmation}
          animation='signout'
          message='Você deseja'
          operation='Sair?'
          onClose={() => setHideConfirmation(true)}
          onChooseOption={(button: 'left' | 'right') => {
            if (button === 'left') {
              onLogOut();
            }
            setHideConfirmation(true);
          }}
        />
      )}
      {!isMobile && (
        <>
          <ContainerDesktop>
            <StyledLeftContent>
              <LogoInLine width='200' height='100' area='Logo' halign='start' pad='small' />
              <ContainerMenu area='Menu'>
                {menuItems.map(
                  (item, idx) =>
                    item.visible &&
                    (!features.includes('STATIC_STATION') || userid !== process.env.ID_CLIENT_STATIC_CPFL ? (
                      <SMenuItemClient
                        size='xmedium'
                        activeColor={offline ? 'darkgray' : 'activeClient'}
                        disabled={item.title == 'TEMPO REAL' && offline}
                        title={item.title}
                        currentActiveMenuItem={idx === currentActiveMenuItem}
                        key={idx}
                        options={item.options}
                        onClick={() => {
                          item.onClick?.call(this);
                          setCurrentActiveMenuItem(idx);
                        }}
                        color={modeBoolean ? 'dark' : 'white'}
                        sizeText='small'
                      />
                    ) : null)
                )}
              </ContainerMenu>
            </StyledLeftContent>
            <StyledIcon>
              <SAlertsDropDown
                area='Alerts'
                onAlertPage={() => {
                  setCurrentActiveMenuItem(-1);
                  onAlertPage();
                }}
                count={alertList?.length}
                alerts={alertList}
                color={'white'}
                sizeIcon='medium'
                size='xxxsmall'
              />
              <Icon area='UserOptions' name='Profile' color='white' size='medium' onClick={onCustomProfile} />
              <Icon area='Line' name='LineVertical' color='white' size='medium' />
              <Icon
                area='SignOut'
                name='SignOut'
                color='white'
                size='medium'
                onClick={() => setHideConfirmation(false)}
              />
            </StyledIcon>
          </ContainerDesktop>
          <StyledTitleBess>
            <Title area='TitleShould' value={bessName} size='large' color={'main'} halign='center' bold />
            <SClock
              area='Clock'
              value={time ? format(new Date(time), 'HH:mm') : undefined}
              pad='small'
              color={modeBoolean ? '#fafafa' : '#6c757d'}
            />
          </StyledTitleBess>
        </>
      )}
      {isMobile && (
        <ContainerMobile>
          <SSideBar
            area='SideBar'
            color='white'
            size='medium'
            currentActiveMenuItem={currentActiveMenuItem}
            onChangeActiveMenu={(idx) => setCurrentActiveMenuItem(idx)}
            items={menuItems}
            profilePicture={profilePicture}
            permission={permission}
            userOptions={options}
            onAlertPage={onAlertPage}
            alertList={alertList}
          />
          <LogoInLine area='Logo' width='200' height='100' halign='start' pad='small' />
          {/* <Title area='Title' value={bessName} size='medium' color='gradient' bold /> */}
        </ContainerMobile>
      )}
    </>
  );
};
