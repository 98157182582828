import React from 'react';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

import styled, { DefaultTheme, css } from 'styled-components';

const sizes = {
  xxsmall: { width: '70px', height: '3px' },
  xsmall: { width: '100px', height: '3px' },
  small: { width: '145px', height: '4px' },
  medium: { width: '132px', height: '6.6px' },
  large: { width: '270px', height: '9.6px' },
  xlarge: { width: '320px', height: '11.6px' },
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: css`
    background-color: white;
  `,
  black: css`
    background-color: black;
  `,
  main: css`
    background-color: ${theme.pallete.primary.main};
  `,
  dark: css`
    background-color: ${theme.pallete.border.main};
  `,
  light: css`
    background-color: ${theme.pallete.primary.light};
  `,
  secondary: css`
    background-color: ${theme.pallete.secondary.main};
  `,
  sucess: css`
    background-color: ${theme.pallete.success.main};
  `,
  error: css`
    background-color: ${theme.pallete.error.main};
  `,
  gradient: css`
    background-image: linear-gradient(
      180.14deg,
      ${theme.pallete.accent.main} 30.49%,
      ${theme.pallete.accent.dark} 230.93%
    );
  `,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledProgressProps = {
  radius?: string;
  color: Colors;
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  size: Size;
};

const StyledProgress = styled.progress<StyledProgressProps>`
  grid-area: ${(props) => props.area};
  width: ${(props) => sizes[props.size].width ?? '0px'};
  height: ${(props) => sizes[props.size].height ?? '0px'};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
  border-radius: ${(props) => props.radius ?? '0px'};

  ::-webkit-progress-bar {
    background-color: ${(props) => props.theme.pallete.neutral.lighter};
    border-radius: ${(props) => props.radius ?? '0px'};
  }
  ::-webkit-progress-value {
    border-radius: ${(props) => props.radius ?? '0px'};
    ${(props) => colors(props.theme)[props.color]}
  }
`;

export interface ProgressProps {
  /**
   * Color of the Progress bar.
   */
  color?: Colors;
  /**
   * Tops border radius. Defaults to "1px".
   */
  radius?: string;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "progress".
   */
  area?: string;
  /**
   * Size of the Progress bar completed.
   */
  size?: Size;
  /**
   * Percentage of the Progress bar completed.
   */
  progress?: number;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
}

/**
 * Progress bar. Maximum of 1, so 50% is 0.5.
 *
 * ```tsx
 *   <Progress progress={0.5} color="main"/>
 * ```
 */
export const Progress: React.FC<ProgressProps> = ({
  area = 'progress',
  radius = '1px',
  color = 'main',
  size = 'small',
  progress = 0,
  ...props
}: ProgressProps) => {
  const { pad, padAll, padCross, halign, valign } = props;
  return (
    <StyledProgress
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      radius={radius}
      color={color}
      size={size}
      value={(progress | 0) / 100}
      max={1}
    />
  );
};
