import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export const colors = (theme: DefaultTheme) =>
  ({
    white: css`
      background-color: white;
    `,
    lighter: css`
      background-color: ${theme.pallete.neutral.lighter};
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

type StyledDividerProps = { area: string; thickDivider: boolean };
const StyledDivider = styled.div<StyledDividerProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  border-bottom: ${(props) => (props.thickDivider ? 'thick' : '1px')} solid
    ${(props) => props.theme.pallete.neutral.lighter};

  width: 80%;
  height: 2px;

  align-self: center;
  justify-self: center;
`;

type StyledGridProps = {
  area: string;
  color: Color;
  radius: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template:
    'header        ' min-content
    '.             ' auto
    'content-top   ' 40px
    'divider-top   ' 2px
    'content-middle' 40px
    'divider-bottom' 2px
    'content-bottom' 40px
    '.             ' auto
    / 210px;

  height: 200px;
  width: 210px;
  box-shadow: rgba(77, 124, 254, 0.08) 0em 0.4375em 0.625em;

  ${(props) => colors(props.theme)[props.color]}

  border-radius: ${(props) => props.radius};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  grid-area: ${(props) => props.area};
  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

export type TripleVerticalCardProps = {
  children?: React.ReactNode;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "threephase".
   */
  area?: string;
  /**
   * Color of the card, not counting the top header.
   */
  color?: Color;
  /**
   * Radius of the border of the card.
   */
  radius?: string;
  /**
   * Gap between columns, for default it's 0.
   */
  gap?: string;
  /**
   * If true, the divider between contents is hidden.
   */
  hideDividers?: boolean;
  /**
   * If true, the divider size will be increased.
   */
  thickDividers?: boolean;
  /**
   * Padding for all directions
   */
  pad?: Pad;
  /**
   * Padding in each directions
   */
  padAll?: Directional<Pad>;
  /**
   * Padding in cross directions (vertical, horizontal)
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
};

/**
 * This card has three vertical content slots and an optional header.
 */
export const TripleVerticalCard: React.FC<TripleVerticalCardProps> = ({
  area = 'triplevertical',
  radius = '15px',
  color = 'white',
  hideDividers = false,
  thickDividers = false,
  ...props
}: TripleVerticalCardProps) => {
  const { children, pad, padAll, padCross, halign, valign } = props;
  return (
    <StyledGrid
      area={area}
      radius={radius}
      color={color}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
    >
      {children}
      {!hideDividers && (
        <>
          <StyledDivider area="divider-top" thickDivider={thickDividers} />
          <StyledDivider area="divider-bottom" thickDivider={thickDividers} />
        </>
      )}
    </StyledGrid>
  );
};
