import { RefObject, useEffect } from 'react';

/**
 * Triggers an effect if a html object is clicked, except if it's the one
 * passed as reference.
 *
 * @param ref Reference to object that doesn't count to be clicked on to
 * trigger this effect.
 * @param callback Callback to be called once this effect is triggered.
 */
export function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        event.target instanceof Element &&
        !ref.current.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);
}