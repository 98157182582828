import { useCallback, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

/**
 * Subscribes to a socketio topic and registers the last received data.
 *
 * @param socket Connected socketio client
 * @param topic Topic to subscribe
 */
export function useSubscription<T>(socket: typeof Socket | undefined, topic: string): T | undefined {
  const [data, setData] = useState<T>();

  useEffect(() => {
    const connected = socket?.connected;
    // Wait for it to connect
    if (socket && connected) {
      // Asks for last data from topic, if we use a _config, asks for the _ok
      const _topic = topic.endsWith('_config') ? `${topic}_ok` : topic;
      socket.on(_topic, (message: T | string) => {
        if (typeof message === 'string') {
          setData(JSON.parse(message) as T);
        } else {
          setData(message);
        }
      });

      //socket.emit('lastData', _topic);
      return () => {
        socket.off(_topic);
      };
    }
  }, [socket, topic]);
  return data;
}

/**
 * Subscribes to a socketio topic and registers the last received data.
 *
 * @param socket Connected socketio client
 * @param topic Topic to subscribe
 */
export function useSubscriptionNonEmpty<T>(socket: typeof Socket | undefined, topic: string): T | undefined {
  const [data, setData] = useState<T>();

  useEffect(() => {
    const connected = socket?.connected;
    // Wait for it to connect
    if (socket && connected) {
      // Asks for last data from topic, if we use a _config, asks for the _ok
      const _topic = topic.endsWith('_config') ? `${topic}_ok` : topic;
      socket.on(_topic, (message: T | string) => {
        if (typeof message === 'string') {
          setData(JSON.parse(message) as T);
        } else {
          setData(message);
        }
      });

      socket.emit('lastData', _topic);
      return () => {
        socket.off(_topic);
      };
    }
  }, [socket, topic]);
  return data;
}

/**
 * Subscribes to a socketio topic and registers the last received data. What
 * is returned is a memory which the user can clear at will.
 *
 * @param socket Connected socketio client
 * @param topic Topic to subscribe
 */
export function useMemoSubscription<T>(
  socket: typeof Socket | undefined,
  topic: string
): [memory: T | undefined, clearMemory: () => void] {
  const [memory, setMemory] = useState<T | undefined>();
  const data = useSubscription<T>(socket, topic);

  useEffect(() => setMemory(data), [data]);
  const clearMemory = useCallback(() => setMemory(undefined), []);
  return [memory, clearMemory];
}

/**
 * Subscribes to a socketio topic and registers all past received messages
 *
 * @param socket Connected socketio client
 * @param topic Topic to subscribe
 */
export function useBufferedSubscription<T>(
  socket: typeof Socket | undefined,
  topic: string
): [history: T[], clear: () => void] {
  const [history, setHistory] = useState<T[]>([]);
  const data = useSubscription<T>(socket, topic);
  useEffect(() => {
    if (data) setHistory((current) => [...current, data]);
    else console.error(`data came undefined on ${topic}`);
  }, [data, topic]);

  const clear = useCallback(() => setHistory([]), []);
  return [history, clear];
}

/**
 * Subscribes to a socketio topic and registers all past received messages
 *
 * @param socket Connected socketio client
 * @param topic Topic to subscribe
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useBufferedSubscriptionTopic<T>(socket: typeof Socket | undefined, topic: string): T | undefined {
  const [history, setHistory] = useState<T>();
  const data = useSubscription<T>(socket, topic);
  useEffect(() => {
    if (data !== undefined) setHistory(data);
    else console.error(`data came undefined on ${topic}`);
  }, [data, topic]);

  if (history) return history;
}
