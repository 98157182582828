import React from 'react';

import styled from 'styled-components';
import {
  Title,
  SBallon,
  SHomeAnimation,
  SBorderContainer,
} from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 25px 100px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title          .' 27px
    'LeftContainer  RightContainer' max-content /
    max-content 260px;

  @media (max-width: 1255px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'LeftContainer' max-content /
      max-content;
  }

  @media (max-width: 875px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'LeftContainer' max-content /
      max-content;
  }

  @media (max-width: 580px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'LeftContainer' max-content /
      260px;
  }
`;

const StyledLeftContainer = styled.div`
  display: grid;
  grid-area: LeftContainer;
  gap: 18px 10px;

  justify-content: center;
  justify-items: center;

  grid-template:
    'BallonBess   BallonEnergy BallonPhotovoltaic' max-content
    'GifStatus    GifStatus    GifStatus' max-content
    'BallonCharge .            BallonPower' max-content /
    max-content max-content max-content;

  @media (max-width: 875px) {
    grid-template:
      'BallonBess         BallonEnergy' max-content
      'BallonPhotovoltaic BallonCharge' max-content
      'GifStatus          GifStatus' max-content
      'BallonPower        BallonPower' max-content /
      max-content max-content;
  }

  @media (max-width: 580px) {
    grid-template:
      'BallonBess' max-content
      'BallonEnergy' max-content
      'BallonCharge' max-content
      'BallonPhotovoltaic' max-content
      'GifStatus' max-content
      'BallonPower' max-content /
      max-content;
  }
`;
const StyledRightContainer = styled.div`
  display: grid;
  grid-area: RightContainer;
  gap: 10px;

  justify-content: center;
  justify-items: center;
  align-items: center;

  grid-template:
    'CardOne' max-content
    'CardTwo' max-content
    'CardThree' max-content /
    100%;

  @media (max-width: 1255px) {
    grid-template:
      'CardTwo CardOne CardThree' max-content /
      1fr 1fr 1fr;
  }

  @media (max-width: 875px) {
    grid-template:
      'CardOne   CardOne' max-content
      'CardTwo   CardThree' max-content /
      max-content max-content;
  }

  @media (max-width: 580px) {
    grid-template:
      'CardOne' max-content
      'CardTwo' max-content
      'CardThree' max-content /
      100%;
  }
`;

/**
 * Home View
 */
export const HomeSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" />
      <StyledLeftContainer>
        <SBallon area="BallonBess" />
        <SBallon area="BallonEnergy" />
        <SBallon area="BallonPhotovoltaic" />
        <SBallon area="BallonCharge" />
        <SBallon area="BallonPower" />
        <SHomeAnimation area="GifStatus" />
      </StyledLeftContainer>

      <StyledRightContainer>
        <SBorderContainer area="CardOne" />
        <SBorderContainer area="CardTwo" />
        <SBorderContainer area="CardThree" />
      </StyledRightContainer>
    </StyledContainer>
  );
};
