import React, { useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { solve } from '../../scripts/common';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';

const sizes = {
  xxsmall: '60px',
  xsmall: '80px',
  small: '115px',
  medium: '170px',
  xmedium: '200px',
  large: '260px',
  xlarge: '318px',
  chat: '260px',
  auto: '100%',
} as const;
export type Size = keyof typeof sizes;

const fonts = {
  small: '12px',
  medium: '15px',
  large: '18px',
} as const;
type Font = keyof typeof fonts;

const colorT = {
  black: '#495057',
  white: '#FFF',
} as const;
type ColorText = keyof typeof colorT;

const colorB = {
  black: '#1f1f1f',
  white: '#FFF',
  input: 'rgba(233, 230, 230, 0.20)',
} as const;
type ColorBackground = keyof typeof colorB;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const round = {
  none: '0px',
  small: '4px',
  medium: '10px',
  large: '20px',
  diagonal1: '10px 0px 0px 0px',
  diagonal2: '0px 10px 0px 0px',
  diagonal3: '0px 0px 10px 0px',
  diagonal4: '0px 0px 0px 10px',
} as const;
type Radius = keyof typeof round;

type Types = 'text' | 'password' | 'email' | 'time';

type StyledInputProps = {
  scale: string;
  bold: boolean;
  radius: string;
  fontSize: string;
  colorBackground: string;
  colorText: string;
  border: string;
};

const StyledInput = styled.input<StyledInputProps>`
  font-family: ${(props) => props.theme.global.fontFamily};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  width: ${(props) => props.scale};
  line-height: 28px;
  padding: 4px 12px;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.colorText};
  background-color: ${(props) => props.colorBackground};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.radius};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.pallete.primary.light};
    caret-color: ${(props) => props.theme.pallete.primary.light};
  }
`;

type IconProps = {
  icon: boolean;
  type: string;
  visible: boolean;
};

export const PasswordIcon = (visible = true): FlattenSimpleInterpolation => css`
  cursor: pointer;
  position: relative;

  &&:hover {
    &:before {
      background: ${visible
        ? `url("data:image/svg+xml,%3Csvg width='157' height='100' viewBox='0 0 157 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M155.188 43.378C139.82 17.3774 111.156 0 78.5 0C45.8299 0 17.1731 17.3897 1.81162 43.378C0.625535 45.3845 0 47.6711 0 50.0001C0 52.3292 0.625535 54.6157 1.81162 56.6223C17.1804 82.6226 45.8435 100 78.5 100C111.17 100 139.827 82.6103 155.188 56.622C156.374 54.6155 157 52.329 157 50C157 47.671 156.374 45.3845 155.188 43.378V43.378ZM78.5 86.9565C50.5464 86.9565 26.1419 72.0921 13.0834 50C25.1193 29.6378 46.794 15.4158 72.0025 13.3133C74.6726 16.0538 76.3194 19.7908 76.3194 23.913C76.3194 32.3174 69.4856 39.1304 61.0556 39.1304C52.6256 39.1304 45.7917 32.3174 45.7917 23.913L45.792 23.9016C43.0099 29.0878 41.4306 35.012 41.4306 41.3043C41.4306 61.7149 58.0271 78.2609 78.5 78.2609C98.9729 78.2609 115.569 61.7149 115.569 41.3043C115.569 32.872 112.735 25.1008 107.966 18.8818C123.121 25.1734 135.742 36.1701 143.917 50C130.858 72.0921 106.454 86.9565 78.5 86.9565Z' fill='%2396989A'/%3E%3C/svg%3E%0A")
    center / contain no-repeat`
        : `url("data:image/svg+xml,%3Csvg width='157' height='140' viewBox='0 0 157 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M74.3303 98.202C52.3931 95.7381 37.2031 74.4442 42.4588 52.5266L74.3303 98.202ZM78.5001 107.187C50.5465 107.187 26.1417 92.2302 13.0835 70C19.0132 59.9055 27.2843 51.3128 37.1633 44.9373L29.6565 34.1795C18.2625 41.6265 8.70082 51.6089 1.81162 63.3366C0.625536 65.3557 0 67.6565 0 70.0001C0 72.3437 0.625536 74.6446 1.81162 76.6637C17.1731 102.814 45.8299 120.312 78.5001 120.312C82.112 120.313 85.7207 120.097 89.3069 119.665L80.579 107.157C79.8862 107.177 79.1932 107.187 78.5001 107.187ZM155.189 76.6634C146.131 92.0836 132.451 104.483 116.06 112.071L129.175 130.867C130.211 132.352 129.851 134.398 128.371 135.437L122.719 139.407C121.239 140.446 119.2 140.085 118.164 138.6L27.8251 9.13264C26.7891 7.64815 27.1491 5.60202 28.6289 4.56296L34.2812 0.593469C35.761 -0.445865 37.8006 -0.084655 38.8364 1.39983L53.971 23.0902C61.7676 20.8751 69.9981 19.6876 78.5001 19.6876C111.17 19.6876 139.827 37.1859 155.189 63.3366C156.375 65.3557 157 67.6565 157 70C157 72.3435 156.375 74.6443 155.189 76.6634ZM143.917 70C131.881 49.5105 110.206 35.1997 84.9976 33.0841C82.3275 35.8417 80.6807 39.602 80.6807 43.75C80.6807 52.2069 87.5145 59.0625 95.9445 59.0625C104.375 59.0625 111.208 52.2069 111.208 43.75L111.208 43.7386C119.558 59.4002 115.771 79.3565 101.143 90.6929L108.419 101.12C123.372 94.76 135.824 83.7774 143.917 70Z' fill='%2396989A'/%3E%3C/svg%3E%0A")
    center / contain no-repeat`};
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    width: 20px;

    background: ${visible
      ? `url("data:image/svg+xml,%3Csvg width='157' height='100' viewBox='0 0 157 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M155.188 43.378C139.82 17.3774 111.156 0 78.5 0C45.8299 0 17.1731 17.3897 1.81162 43.378C0.625535 45.3845 0 47.6711 0 50.0001C0 52.3292 0.625535 54.6157 1.81162 56.6223C17.1804 82.6226 45.8435 100 78.5 100C111.17 100 139.827 82.6103 155.188 56.622C156.374 54.6155 157 52.329 157 50C157 47.671 156.374 45.3845 155.188 43.378V43.378ZM78.5 86.9565C50.5464 86.9565 26.1419 72.0921 13.0834 50C25.1193 29.6378 46.794 15.4158 72.0025 13.3133C74.6726 16.0538 76.3194 19.7908 76.3194 23.913C76.3194 32.3174 69.4856 39.1304 61.0556 39.1304C52.6256 39.1304 45.7917 32.3174 45.7917 23.913L45.792 23.9016C43.0099 29.0878 41.4306 35.012 41.4306 41.3043C41.4306 61.7149 58.0271 78.2609 78.5 78.2609C98.9729 78.2609 115.569 61.7149 115.569 41.3043C115.569 32.872 112.735 25.1008 107.966 18.8818C123.121 25.1734 135.742 36.1701 143.917 50C130.858 72.0921 106.454 86.9565 78.5 86.9565Z' fill='%23BBBBBB'/%3E%3C/svg%3E%0A")
      center / contain no-repeat`
      : `url("data:image/svg+xml,%3Csvg width='157' height='140' viewBox='0 0 157 140' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M74.3303 98.202C52.3931 95.7381 37.2031 74.4442 42.4588 52.5266L74.3303 98.202ZM78.5001 107.187C50.5465 107.187 26.1417 92.2302 13.0835 70C19.0132 59.9055 27.2843 51.3128 37.1633 44.9373L29.6565 34.1795C18.2625 41.6265 8.70082 51.6089 1.81162 63.3366C0.625536 65.3557 0 67.6565 0 70.0001C0 72.3437 0.625536 74.6446 1.81162 76.6637C17.1731 102.814 45.8299 120.312 78.5001 120.312C82.112 120.313 85.7207 120.097 89.3069 119.665L80.579 107.157C79.8862 107.177 79.1932 107.187 78.5001 107.187ZM155.189 76.6634C146.131 92.0836 132.451 104.483 116.06 112.071L129.175 130.867C130.211 132.352 129.851 134.398 128.371 135.437L122.719 139.407C121.239 140.446 119.2 140.085 118.164 138.6L27.8251 9.13264C26.7891 7.64815 27.1491 5.60202 28.6289 4.56296L34.2812 0.593469C35.761 -0.445865 37.8006 -0.084655 38.8364 1.39983L53.971 23.0902C61.7676 20.8751 69.9981 19.6876 78.5001 19.6876C111.17 19.6876 139.827 37.1859 155.189 63.3366C156.375 65.3557 157 67.6565 157 70C157 72.3435 156.375 74.6443 155.189 76.6634ZM143.917 70C131.881 49.5105 110.206 35.1997 84.9976 33.0841C82.3275 35.8417 80.6807 39.602 80.6807 43.75C80.6807 52.2069 87.5145 59.0625 95.9445 59.0625C104.375 59.0625 111.208 52.2069 111.208 43.75L111.208 43.7386C119.558 59.4002 115.771 79.3565 101.143 90.6929L108.419 101.12C123.372 94.76 135.824 83.7774 143.917 70Z' fill='%23BBBBBB'/%3E%3C/svg%3E%0A")
      center / contain no-repeat`};
  }
`;

export const LoginIcon = (icon = true): FlattenSimpleInterpolation => css`
  &:before {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    width: 20px;

    background: ${icon
      ? `url("data:image/svg+xml,%3Csvg width='104' height='119' viewBox='0 0 104 119' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.9999 59.5C68.4124 59.5 81.7142 46.1822 81.7142 29.75C81.7142 13.3178 68.4124 0 51.9999 0C35.5874 0 22.2857 13.3178 22.2857 29.75C22.2857 46.1822 35.5874 59.5 51.9999 59.5ZM72.7999 66.9375H68.9232C63.7696 69.3082 58.0357 70.6562 51.9999 70.6562C45.9642 70.6562 40.2535 69.3082 35.0767 66.9375H31.1999C13.9749 66.9375 -6.10352e-05 80.9293 -6.10352e-05 98.175V107.844C-6.10352e-05 114.003 4.99101 119 11.1428 119H92.8571C99.0089 119 104 114.003 104 107.844V98.175C104 80.9293 90.0249 66.9375 72.7999 66.9375Z' fill='%23BBBBBB'/%3E%3C/svg%3E%0A")
      center / contain no-repeat`
      : 'none'};
  }
`;
export const TimeIcon = () => css`
  &:before {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
  }
`;

const Icon = styled.span<IconProps>`
  cursor: ${(props) => (props.type == 'password' || props.type == 'time' ? 'pointer' : 'text')};
  position: relative;

  ${(props) =>
    props.type === 'time'
      ? TimeIcon()
      : props.type == 'password'
      ? PasswordIcon(props.visible)
      : LoginIcon(props.icon)};
`;

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const Content = styled.div<ContentProps>`
  position: relative;
  display: grid;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

const Span = styled.span``;

export type InputProps = {
  /**
   * It can be any number, it fills in the typing field.
   */
  value?: string | number;
  /**
   * This fills the marker,
   * it only appears when the field is empty.
   */
  placeholder?: string;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /**
   * Set font size It defaults to medium.
   */
  fontSize?: Font;
  /**
   * Radius of the button. It defaults to medium.
   */
  colorText?: ColorText;
  colorBackground?: ColorBackground;
  radius?: Radius;
  /*
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * This enables or disables the input field.
   */
  disabled?: boolean;
  /**
   * If there is an icon on the right of the input. When false, the icon
   * remains hidden.
   */
  required?: boolean;
  icon?: boolean;
  /**
   * Type of the input, being password, text, etc.
   */
  type?: Types;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "input".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Fired when the user types in this input
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Fired when the user finishes to type a string or number
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Fired when the used presses enter with the input focused
   */
  onPressEnter?: () => void;

  border?: string;
};

/**
 * General purpose input component
 */
export const Input: React.FC<InputProps> = ({
  size = 'medium',
  radius = 'medium',
  border = '1px solid #ced4da',
  bold = false,
  disabled = false,
  required = false,
  type = 'text',
  icon = false,
  area = 'unset',
  fontSize = 'medium',
  colorText = 'black',
  colorBackground = 'white',
  pad,
  padAll,
  padCross,
  halign,
  valign,
  value,
  placeholder,
  onChange,
  onBlur,
  onPressEnter,
}: InputProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <Content area={area} pad={solve(pad, padCross, padAll)} halign={halign} valign={valign}>
      <Span>
        <StyledInput
          type={visible ? 'text' : type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={(event) => (event.key === 'Enter' ? onPressEnter?.call(this) : undefined)}
          placeholder={placeholder}
          scale={sizes[size]}
          fontSize={fonts[fontSize]}
          colorText={colorT[colorText]}
          colorBackground={colorB[colorBackground]}
          radius={round[radius]}
          bold={bold}
          disabled={disabled}
          required={required}
          border={border}
        />
        <Icon visible={visible} icon={icon} type={type} onClick={() => setVisible(!visible)} />
      </Span>
    </Content>
  );
};
