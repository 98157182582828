import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const PlusCircle: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46154 5.53846H8.51748C8.77238 5.53846 8.97902 5.7451 8.97902 6C8.97902 6.2549 8.77238 6.46154 8.51748 6.46154H6.46154V8.51748C6.46154 8.77238 6.2549 8.97902 6 8.97902C5.7451 8.97902 5.53846 8.77238 5.53846 8.51748V6.46154H3.48252C3.22762 6.46154 3.02098 6.2549 3.02098 6C3.02098 5.7451 3.22762 5.53846 3.48252 5.53846H5.53846V3.48252C5.53846 3.22762 5.7451 3.02098 6 3.02098C6.2549 3.02098 6.46154 3.22762 6.46154 3.48252V5.53846ZM6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM6 11.0769C8.80391 11.0769 11.0769 8.80391 11.0769 6C11.0769 3.19609 8.80391 0.923077 6 0.923077C3.19609 0.923077 0.923077 3.19609 0.923077 6C0.923077 8.80391 3.19609 11.0769 6 11.0769Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
