import { useRef, useEffect, useState } from 'react';

/**
 * Sets a timeout and cleans it if the component is destroyed.
 *
 * @param callback Function to be called on timeout.
 * @param delay Delay to call the timeout.
 */
export function useTimeout(
  delay: number,
  callback: () => void
): { active: boolean; counting: number; timer: (active: boolean) => void } {
  const [active, timer] = useState(false);
  const [counting, setCounting] = useState(delay);

  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (active) setCounting(delay);
  }, [delay, active]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counting <= 0 && active) {
        savedCallback.current();
        setCounting(0);
        timer(false);
      } else if (active) {
        setCounting((counting) => counting - 1000);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [delay, active, counting]);

  return { active, counting, timer };
}
