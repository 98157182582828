import { CreateUser } from '../api/types';

export const checkValidUser = (
  user: CreateUser,
  tempPassword: string
): 'PASS' | 'NAME' | 'EMAIL' | 'MATCH' | undefined => {
  const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (user.full_name.length < 5) {
    return 'NAME';
  } else if (!emailRE.test(user.email.toLowerCase())) {
    return 'EMAIL';
  }
  return checkPasswordValid(user.password, tempPassword);
};

export const checkPasswordValid = (
  password: string,
  confirmPassword: string
): 'PASS' | 'MATCH' | undefined => {
const passRE = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-={};':"])(?=.*[0-9])(?=.*[a-z]).{8,}$/gm;
  if (password.length < 3 || password != confirmPassword) {
    return 'MATCH';
  } else if (!passRE.test(password)) {
    return 'PASS';
  }
  return undefined;
};
