import React from 'react';
import styled from 'styled-components';

import { Title } from '../../components';
import { Icon } from '../../components/Icons';

const StyledContainer = styled.div<{ area: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
  grid-template:
    'Icon Icon Count Count' 1fr
    / max-content;
  justify-content: center;
`;

const StyledCount = styled.div`
  display: grid;
  grid-area: Count / 2 / Count / Count;
  background-color: ${(props) => props.theme.pallete.error.main};
  border-radius: 5px;
  padding: 2px;
  height: max-content;
  z-index: 0;
`;

export type SAlertsCountProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Show one cell with number of new alerts on system.
   */
  count?: number;
  onClick?: () => void;
  color?:
    | 'main'
    | 'white'
    | 'black'
    | 'dark'
    | 'light'
    | 'info'
    | 'secondary'
    | 'success'
    | 'error'
    | 'disabled'
    | 'warning'
    | 'darkgray'
    | undefined;
  size?: 'small' | 'xsmall' | 'medium' | 'large' | 'xlarge' | 'xxsmall' | 'lsmall' | 'xxlarge' | undefined;
};

export const SAlertsCount: React.FC<SAlertsCountProps> = ({
  area = 'unset',
  size = 'medium',
  count = 0,
  onClick,
  color = 'main',
}: SAlertsCountProps) => {
  return (
    <StyledContainer area={area}>
      <Icon
        area='Icon'
        name='Bell'
        valign='start'
        halign='center'
        size={size}
        color={color}
        onClick={() => onClick?.call(this)}
      />
      {count > 0 && (
        <StyledCount>
          <Title area='unset' halign='center' size='xxsmall' color='white' value={`${count}`} bold />
        </StyledCount>
      )}
    </StyledContainer>
  );
};
