import { Cross, Directional } from '../types';

/**
 * Receives a set of possible input values, being a single value, a cross value
 * or a directional value. For the one provided, it's converted and returned.
 *
 * If more than one of them is provided, the more specific, the more
 * priority the argument has (directional > cross > base).
 *
 * @param base Single value to be converted to directional, if provided.
 * @param cross Cross value to be converted to directional, if provided.
 * @param directional Already a directional value, just returning it.
 * @return The directional value.
 */
export function solve<T>(
  base?: T,
  cross?: Cross<T>,
  directional?: Directional<T>
): Directional<T> {
  if (directional) {
    return directional;
  } else if (cross) {
    return crossToDirectional(cross);
  } else if (base) {
    return toDirectional(base);
  } 
  return {};
}

/**
 * Transforms a cross element to a directional element by repeating the
 * horizontal for left and tight and repeating the vertical for top and
 * bottom.
 *
 * @param element Any cross element that we want to convert to a directional.
 */
export function crossToDirectional<T>(element: Cross<T>): Directional<T> {
  return {
    top: element.vertical,
    bottom: element.vertical,
    left: element.horizontal,
    right: element.horizontal,
  };
}

/**
 * Transforms any element in a directional, by repeating the element for each
 * direction.
 *
 * @param element Any element that we want to transform in a directional
 */
export function toDirectional<T>(element: T): Directional<T> {
  return {
    top: element,
    bottom: element,
    left: element,
    right: element,
  };
}
