import React from 'react';

import { Icon } from '../../components/Icons';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { CardContent } from '../../components/CardContent';

export type SBMSButtonProps = {
  /**
   * Action fired when clicking this button.
   */
  onClick: () => void;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
};

/**
 * Button to see more information about the strings on the BMS page
 */
export const SBMSButton: React.FC<SBMSButtonProps> = ({
  area = 'unset',
  onClick,
}: SBMSButtonProps) => (
  <Button
    onClick={() => onClick?.call(this)}
    area={area}
    color="main"
    size="xxlarge"
  >
    <CardContent valign="center">
      <Icon
        name="PlusCircle"
        valign="start"
        size="small"
        color="secondary"
        padAll={{ left: 'medium', right: 'small' }}
      />
      <Title size="small" color="white" value="Acesso às Tensões Individuais" />
    </CardContent>
  </Button>
);
