import React, { useEffect, useRef } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const sizes = {
  small: '105px',
  medium: '250px',
  large: '325px',
  xlarge: '440px',
  auto: '100%',
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const margins = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Margin = keyof typeof margins;

const colors = (theme: DefaultTheme) => ({
  white: 'white',
  black: 'black',
  main: theme.pallete.primary.main,
  dark: theme.pallete.border.main,
  error: theme.pallete.error.main,
  warning: theme.pallete.secondary.main,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledScrollableProps = {
  color: Colors;
  scale: string;
  area: string;
  pad: Directional<Pad>;
  margin: Directional<Margin>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledScrollable = styled.div<StyledScrollableProps>`
  display: grid;
  grid-area: ${({ area }) => area};
  max-height: ${({ scale }) => scale};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  margin-left: ${(props) => margins[props.margin.left ?? 'none']};
  margin-right: ${(props) => margins[props.margin.right ?? 'none']};
  margin-top: ${(props) => margins[props.margin.top ?? 'none']};
  margin-bottom: ${(props) => margins[props.margin.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'stretch'};
  align-items: ${(props) => props?.valign ?? 'stretch'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.pallete.neutral.light};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => colors(props.theme)[props.color]};
  }
`;

const Bottom = styled.div``;

export type ScrollableProps = {
  children?: React.ReactNode;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /*
   * Scroll color on hover
   * Default is "main".
   */
  color?: Colors;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "Text".
   */
  area?: string;
  /**
   * This is to identify if the component is being used for a chat.
   */
  isChat?: boolean;
  /**
   * Margin for all directions (top, bottom, left, right)
   */
  margin?: Margin;
  /**
   * Individual margin for each direction.
   */
  marginAll?: Directional<Margin>;
  /**
   * Margin vertical and horizontal.
   */
  marginCross?: Cross<Margin>;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

/**
 * General proposal of the component,
 * returns div with scroll
 */
export const Scrollable: React.FC<ScrollableProps> = ({
  size = 'medium',
  area = 'scrollable',
  color = 'main',
  isChat = false,
  pad,
  padAll,
  padCross,
  margin,
  marginAll,
  marginCross,
  halign,
  valign,
  salign,
  children,
}: ScrollableProps) => {
  const messagesRef = useRef<null | HTMLElement>(null);
  const scrollToBottom = () => {
    messagesRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (isChat) {
      scrollToBottom();
    }
  }, [children, isChat]);

  return (
    <StyledScrollable
      area={area}
      color={color}
      pad={solve(pad, padCross, padAll)}
      margin={solve(margin, marginCross, marginAll)}
      halign={halign}
      valign={valign}
      salign={salign}
      scale={sizes[size]}
    >
      {children}
      <Bottom ref={messagesRef} />
    </StyledScrollable>
  );
};
