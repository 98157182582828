import React from 'react';
import styled from 'styled-components';
import { Icon, Title } from '../../components';

export type SNoDataProps = {
  area?: string;
  value: string;
};

const StyledNoData = styled.div`
  display: flex-wrap;
  justify-content: center;
  padding: 30px;
  opacity: 0.6;
`;

export const SNoData: React.FC<SNoDataProps> = ({
  area = 'unset',
  value,
}: SNoDataProps) => {
  return (
    <StyledNoData>
      <Icon
        area={area}
        name="Calendar"
        size="xxlarge"
        color="disabled"
        halign="center"
      />
      <Title
        area="unset"
        value={value}
        size="large"
        color="disabled"
        txtalign="center"
        bold
      />
    </StyledNoData>
  );
};
