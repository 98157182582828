import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Clock: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const stroke = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.75299 3.59999C8.75299 3.18366 8.41549 2.84616 7.99916 2.84616C7.58283 2.84616 7.24533 3.18366 7.24533 3.59999V7.99999C7.24533 8.19992 7.32475 8.39166 7.46613 8.53303L9.86613 10.933C10.1605 11.2274 10.6378 11.2274 10.9322 10.933C11.2266 10.6386 11.2266 10.1613 10.9322 9.86695L8.75299 7.68775V3.59999Z"
        fill={stroke}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
