import React from 'react';
import styled from 'styled-components';

import { solve } from '../../scripts/common';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export interface LogoInLineProps {
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;

  width?: string;

  height?: string;
}

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const Content = styled.div<ContentProps>`
  display: grid;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

/**
 * The logoInLine will be rendered in two single places at first: the login form and
 * at the top left part of the menu. There are no props.
 */
export const LogoInLine: React.FC<LogoInLineProps> = ({ area = 'logoInLine', ...props }: LogoInLineProps) => {
  const { pad, padAll, padCross, halign, valign, width, height } = props;
  return (
    <Content area={area} pad={solve(pad, padCross, padAll)} halign={halign} valign={valign}>
      <svg width={width} height={height} viewBox='0 0 1803 471' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 329.828V471L345 212.172V71L0 329.828Z' fill='#FFBF31' />
        <path d='M502 471L628 376.865V177L502 271.135V471Z' fill='#0033AB' />
        <path d='M345 212L235 294.425V471L345 388.76V212Z' fill='#EFA903' />
        <path d='M628 176.908L345 389V212.092L628 0V176.908Z' fill='#006EC7' />
        <path
          d='M896.385 150.78L669.994 377.171L737.977 377.194L851.126 263.999V377.171L964.578 263.367V377.194L1009.88 377.171L1009.86 150.593L896.385 264.069V150.78Z'
          fill='white'
        />
        <path
          d='M1151.49 337.622C1110.82 337.622 1077.84 304.648 1077.84 263.976C1077.84 223.303 1110.82 190.33 1151.49 190.33C1192.16 190.33 1225.14 223.303 1225.14 263.976C1225.11 304.648 1192.14 337.622 1151.49 337.622ZM1151.49 145C1085.78 145 1032.51 198.263 1032.51 263.952C1032.51 329.665 1085.78 382.928 1151.49 382.928C1217.2 382.928 1270.44 329.665 1270.44 263.952C1270.44 198.263 1217.18 145 1151.49 145Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M1601.88 190.213L1610.36 190.26L1650.04 150.593H1596.22C1541.46 150.593 1497.09 194.987 1497.09 249.724V377.194H1542.42V249.701C1542.4 216.844 1569.03 190.213 1601.88 190.213Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M1383.78 377.195C1333.72 377.195 1293.14 336.616 1293.14 286.559V150.593H1338.47V286.559C1338.47 311.599 1358.76 331.888 1383.8 331.888H1423.47V150.617H1468.8V377.218H1383.78V377.195Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M1688.28 278.04H1757.67V331.865H1688.28C1673.42 331.865 1661.37 319.813 1661.37 304.953C1661.37 290.092 1673.42 278.04 1688.28 278.04ZM1695.35 190.213L1644.38 190.26L1684 150.593H1703.87C1758.63 150.593 1803 194.987 1803 249.724V377.194H1685.31C1646.98 377.194 1615.92 346.117 1615.92 307.808C1615.92 269.358 1647.05 238.421 1685.45 238.421L1756.13 238.772C1749.69 210.362 1724.44 190.213 1695.35 190.213Z'
          fill='white'
        />
      </svg>
    </Content>
  );
};
