import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Bolt: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02461 1.00843C9.21175 1.09775 9.31094 1.30528 9.26267 1.50652L7.41729 9.19967H11.3036C11.4691 9.19967 11.6207 9.29232 11.6958 9.43944C11.7709 9.58657 11.7569 9.76329 11.6595 9.89682L3.75884 20.7314C3.6354 20.9006 3.4093 20.9594 3.21869 20.8718C3.02809 20.7842 2.92601 20.5746 2.97484 20.371L4.82022 12.6779H0.440135C0.272284 12.6779 0.119047 12.5827 0.0450634 12.4324C-0.0289198 12.2821 -0.0107666 12.103 0.0918654 11.9705L8.48635 1.13598C8.6132 0.972256 8.83747 0.919111 9.02461 1.00843ZM1.33646 11.8H5.37807C5.51281 11.8 5.64014 11.8615 5.7236 11.967C5.80706 12.0725 5.83747 12.2104 5.80612 12.3411L4.34406 18.4362L10.4393 10.0776H6.85944C6.7247 10.0776 6.59737 10.016 6.51391 9.91053C6.43045 9.80502 6.40004 9.6672 6.43139 9.5365L7.9297 3.29024L1.33646 11.8Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
