import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Plug: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 384 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M256 144V32C256 14.327 270.327 0 288 0C305.673 0 320 14.327 320 32V144H256ZM368 160H16C7.163 160 0 167.163 0 176V208C0 216.837 7.163 224 16 224H32V256C32 333.406 86.969 397.971 160 412.796V512H224V412.796C297.031 397.971 352 333.406 352 256V224H368C376.837 224 384 216.837 384 208V176C384 167.163 376.837 160 368 160ZM128 144V32C128 14.327 113.673 0 96 0C78.327 0 64 14.327 64 32V144H128Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
