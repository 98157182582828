import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Warnings } from '../../../models/Warnings';
import { Icon } from '../../components';
import { LogoDark } from '../../components/LogoDark';
import { RoleValue, userRoles } from '../../scripts/api/types';
import { useOutsideClick } from '../../scripts/hooks';
import { useMedia } from '../../scripts/hooks/mediaQueryHook';
import { MenuItem } from '../../views/Menu';
import { SAlertsCount } from '../SAlertsCount';
import { SSideBarItem } from '../SSideBarItem';
import { SUserDropDown } from '../SUserDropDown';

export type SSideBarProps = {
  /**
   * Grid Area of the SSideBar. Default is unset.
   */
  area?: string;
  /**
   * User's profile picture
   */
  profilePicture?: string;
  /**
   * User's permission
   */
  permission?: RoleValue;
  /**
   * List of SMenuItems
   */
  items: MenuItem[];
  /**
   * Current active menu item.
   */
  currentActiveMenuItem: number;
  /**
   * On change of active Menu item.
   */
  onChangeActiveMenu: (idx: number) => void;
  /**
   * User DropDown Options.
   */
  userOptions: {
    name: string;
    action: () => void;
  }[];
  /**
   * Alerts list.
   */
  alertList?: Warnings[];
  /**
   * OnClick Alert page.
   */
  onAlertPage: () => void;

  color?:
    | 'main'
    | 'warning'
    | 'white'
    | 'black'
    | 'dark'
    | 'light'
    | 'secondary'
    | 'success'
    | 'error'
    | 'darkgray'
    | 'disabled'
    | 'orange'
    | 'darkblue'
    | 'info'
    | undefined;

  size?: 'small' | 'xxsmall' | 'xsmall' | 'lsmall' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | undefined;
};

const StyledSideBar = styled.div<{ hide: boolean }>`
  display: grid;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  width: 300px;
  height: 100%;
  position: fixed;
  z-index: 1;
  gap: 30px 0px;
  top: 0;
  opacity: ${(props) => (props.hide ? '0' : '1')};
  left: ${(props) => (props.hide ? '-202px' : '0')};
  overflow-x: hidden;

  background-color: rgb(232, 241, 251, 1);
  backdrop-filter: blur(4px);
  transition: linear 0.25s;

  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07)) drop-shadow(0px 7px 10px rgba(77, 124, 254, 0.08));

  grid-template:
    'Logo ' max-content
    'Profile ' max-content
    'Items ' max-content /
    300px;

  @media (max-width: 580px) {
    padding-top: 40px;
    width: 200px;
    grid-template:
      'Profile ' max-content
      'Items ' max-content /
      200px;
  }
`;

const StyledItems = styled.div`
  display: grid;
  grid-area: Items;
  height: 45px;
  padding-bottom: 12px;
  gap: 5px;
  align-items: center;
  justify-content: center;

  grid-template:
    'Area1' max-content
    'Area2' max-content
    'Area3' max-content
    'Area4' max-content
    'Area5' max-content
    'Area6' max-content /
    max-content;

  @media (max-width: 580px) {
    grid-template:
      'Area1' max-content
      'Area2' max-content
      'Area3' max-content
      'Area4' max-content
      'Area5' max-content
      'Area6' max-content /
      200px;
  }
`;

const StyledUserContainer = styled.div`
  display: grid;
  grid-area: Profile;
  height: 45px;
  padding-bottom: 12px;
  gap: 20px;
  align-items: center;
  justify-content: center;

  grid-template:
    'Profile Alert' max-content /
    max-content max-content;
`;

export const SSideBar: React.FC<SSideBarProps> = ({
  color = 'main',
  size = 'small',
  items,
  currentActiveMenuItem = 0,
  userOptions,
  alertList,
  permission,
  profilePicture,
  onAlertPage,
  onChangeActiveMenu,
}: SSideBarProps) => {
  const Mobile = useMedia('(max-width: 580px)');
  const [hidden, setHidden] = useState(true);

  const action = () => {
    setHidden(!hidden);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(
    wrapperRef,
    useCallback(() => setHidden(true), [setHidden])
  );
  const [currentActive, setCurrentActiveMenuItem] = useState(currentActiveMenuItem);

  useEffect(() => {
    setCurrentActiveMenuItem(currentActiveMenuItem);
  }, [currentActiveMenuItem]);

  return (
    <>
      <Icon
        name='Hamburguer'
        valign='start'
        size={size}
        padAll={{ right: 'small', left: 'small' }}
        color={color}
        onClick={() => action()}
      />
      <StyledSideBar hide={hidden} ref={wrapperRef}>
        {!Mobile && <LogoDark halign='center' padAll={{ bottom: 'medium' }} width='250' height='200' area='Logo' />}
        <StyledUserContainer>
          <SUserDropDown
            area='Profile'
            profilePicture={profilePicture}
            icon={
              permission === userRoles.root || permission === userRoles.admin
                ? 'Bolt'
                : permission === userRoles.mouraDev
                ? 'Laptop'
                : permission === userRoles.operator
                ? 'GearTool'
                : permission === userRoles.client
                ? 'Star'
                : 'Warning'
            }
            options={userOptions}
            anyAction={() => {
              onChangeActiveMenu(-1);
              setHidden(true);
            }}
          />
          <SAlertsCount
            area='Alert'
            count={alertList?.length}
            onClick={() => {
              onChangeActiveMenu(-1);
              setHidden(true);
              onAlertPage();
            }}
          />
        </StyledUserContainer>
        <StyledItems>
          {items.map((item, idx) => {
            return (
              item.visible && (
                <SSideBarItem
                  area={`Area${idx}`}
                  title={item.title}
                  currentActiveMenuItem={idx === currentActive}
                  key={idx}
                  options={item.options}
                  onClick={() => {
                    item.onClick?.call(this);
                    onChangeActiveMenu(idx);
                    setHidden(true);
                  }}
                />
              )
            );
          })}
        </StyledItems>
      </StyledSideBar>
    </>
  );
};
