import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Cross: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.285 2.00116C14.4084 1.87818 14.5064 1.73205 14.5732 1.57116C14.64 1.41026 14.6744 1.23776 14.6744 1.06354C14.6744 0.889322 14.64 0.716819 14.5732 0.555922C14.5064 0.395024 14.4084 0.248898 14.285 0.125922C14.1863 0.0445173 14.0623 0 13.9343 0C13.8063 0 13.6823 0.0445173 13.5835 0.125922L7.5 6.21271L2.00116 0.714966C1.87818 0.591563 1.73205 0.493649 1.57116 0.426839C1.41026 0.36003 1.23776 0.325639 1.06354 0.325639C0.889322 0.325639 0.716819 0.36003 0.555922 0.426839C0.395024 0.493649 0.248898 0.591563 0.125922 0.714966C0.0445173 0.813739 0 0.93775 0 1.06575C0 1.19374 0.0445173 1.31775 0.125922 1.41652L6.21271 7.5L0.714966 12.9988C0.591563 13.1218 0.493649 13.2679 0.426839 13.4288C0.36003 13.5897 0.325639 13.7622 0.325639 13.9365C0.325639 14.1107 0.36003 14.2832 0.426839 14.4441C0.493649 14.605 0.591563 14.7511 0.714966 14.8741C0.813739 14.9555 0.93775 15 1.06575 15C1.19374 15 1.31775 14.9555 1.41652 14.8741L7.5 8.78729L12.9988 14.2861C13.1218 14.4095 13.2679 14.5075 13.4288 14.5743C13.5897 14.6411 13.7622 14.6755 13.9365 14.6755C14.1107 14.6755 14.2832 14.6411 14.4441 14.5743C14.605 14.5075 14.7511 14.4095 14.8741 14.2861C14.9555 14.1874 15 14.0634 15 13.9354C15 13.8074 14.9555 13.6834 14.8741 13.5846L8.78729 7.5L14.285 2.00116Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
