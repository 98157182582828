import React from 'react';
import styled from 'styled-components';
import { CardContent } from '../../components/CardContent/CardContent';
import { Icon } from '../../components/Icons';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export type SClockProps = {
  /**
   * This is filled with the timetable,
   * if the timetable is empty the icon is gray.
   */
  value?: string;
  /**
   * If true, the menu item won't show hours.
   */
  disabled?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Individual padding for each direction.
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * If disabled is true, the onClick action is ignored and the color is set to
   * a gray tone. Defaults to false.
   */
  color?: "#6c757d"| "#fafafa";
};

const Time = styled.div`
  font-family: ${(props) => props.theme.global.fontFamily};
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.color};
  display: grid;
  grid-area: title;
  align-content: center;
  justify-content: center;
`;

/**
 * General proposal of the component,
 * show the time to the user along with a clock icon
 */
export const SClock: React.FC<SClockProps> = ({
  value = '--:--',
  area = 'clock',
  disabled = false,
  color = '#6c757d',
  ...props
}: SClockProps) => {
  const { pad, padAll, padCross, halign, valign } = props;
  return (
    <CardContent
      area={area}
      pad={pad}
      padAll={padAll}
      padCross={padCross}
      halign={halign}
      valign={valign}
    >
      <Icon
        area="icon"
        color={disabled === true ? 'disabled' : 'light'}
        name="Clock"
        size="small"
        valign="center"
      />
      {disabled === false ? <Time color={color}>{value}</Time> : <Time color={color} >{'--:--'}</Time>}
    </CardContent>
  );
};
