import React from 'react';
import styled from 'styled-components';

import { Title, SDoubleCard, SStatusCard } from '../../Skeleton';

const StyledContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-area: Items;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title              Title              .                  .            ' 60px
    'GlobalAcPower      GlobalAcPower      GlobalDcPower      GlobalDcPower' 60px
    'Subtitle           Subtitle           Status             .        ' 60px
    'GlobalAcPowerTwo   GlobalAcPowerTwo   GlobalDcPowerTwo   GlobalDcPowerTwo' 60px
    'SubtitleTwo        SubtitleTwo        StatusTwo          .        ' 60px
    'GlobalAcPowerThree GlobalAcPowerThree GlobalDcPowerThree GlobalDcPowerThree' 60px
    'SubtitleThree      SubtitleThree      StatusThree        .        ' 60px
    'GlobalAcPowerFour  GlobalAcPowerFour  GlobalDcPowerFour  GlobalDcPowerFour' 60px /
    210px 210px 210px 210px;

  @media (max-width: 900px) {
    grid-template:
      'Title              Title        ' 60px
      'GlobalAcPower      GlobalAcPower' 60px
      'GlobalDcPower      GlobalDcPower' 60px
      'Subtitle           Status       ' 60px
      'GlobalAcPowerTwo   GlobalAcPowerTwo' 60px
      'GlobalDcPowerTwo   GlobalDcPowerTwo' 60px
      'SubtitleTwo        StatusTwo       ' 60px
      'GlobalAcPowerThree GlobalAcPowerThree' 60px
      'GlobalDcPowerThree GlobalDcPowerThree' 60px
      'SubtitleThree      StatusThree       ' 60px
      'GlobalAcPowerFour  GlobalAcPowerFour' 60px
      'GlobalDcPowerFour  GlobalDcPowerFour' 60px /
      210px 210px;
  }

  @media (max-width: 440px) {
    grid-template:
      'Title' 60px
      'GlobalAcPower ' 60px
      'GlobalDcPower' 60px
      'Subtitle ' 60px
      'Status' 60px
      'GlobalAcPowerTwo' 60px
      'GlobalDcPowerTwo' 60px
      'SubtitleTwo' 60px
      'StatusTwo' 60px
      'GlobalAcPowerThree' 60px
      'GlobalDcPowerThree' 60px
      'SubtitleThree' 60px
      'StatusThree' 60px
      'GlobalAcPowerFour' 60px
      'GlobalDcPowerFour' 60px /
      210px;
  }

  align-items: center;
`;

export const PhotovoltaicSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" />
      <SDoubleCard area="GlobalAcPower" />
      <SDoubleCard area="GlobalDcPower" />
      <Title area="Subtitle" size="small" marginBottom />
      <SStatusCard area="Status" />
      <SDoubleCard area="GlobalAcPowerTwo" />
      <SDoubleCard area="GlobalDcPowerTwo" />
      <Title area="SubtitleTwo" size="small" marginBottom />
      <SStatusCard area="StatusTwo" />
      <SDoubleCard area="GlobalAcPowerThree" />
      <SDoubleCard area="GlobalDcPowerThree" />
      <Title area="SubtitleThree" size="small" marginBottom />
      <SStatusCard area="StatusThree" />
      <SDoubleCard area="GlobalAcPowerFour" />
      <SDoubleCard area="GlobalDcPowerFour" />
    </StyledContainer>
  );
};
