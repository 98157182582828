import React from 'react';

import { CardContent } from '../../components/CardContent';
import { Icon, IconProps } from '../../components/Icons';
import { Quantity } from '../../components/Quantity';
import { Title } from '../../components/Title';
import { VerticalCard, VerticalCardProps } from '../../components/VerticalCard';
import { Units } from '../../scripts/types';

export type SVerticalCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Main icon at the top of the card. A giant icon with the symbol of the card.
   */
  icon?: IconProps['name'];
  /**
   * Exact number of decimals of the card.
   */
  decimals: number;
  /**
   * Color of the card.
   */
  color?: VerticalCardProps['color'];
  /**
   * Size of the card, as in vertical size.
   */
  size?: VerticalCardProps['size'];
  /**
   * Quantity displayed at the top of the card.
   */
  top: {
    title: string;
    value: number;
    unit: Units;
  };
  /**
   * Quantity displayed at the bottom of the card.
   */
  bottom: {
    title: string;
    value: number;
    unit: Units;
  };
};

/**
 * A vertical card with two contents and a giant icon.
 */
export const SVerticalCard: React.FC<SVerticalCardProps> = ({
  top,
  bottom,
  decimals,
  size = 'medium',
  area = 'unset',
  icon = 'Wave',
  color = 'main',
}: SVerticalCardProps) => {
  return (
    <VerticalCard area={area} size={size} halign='center' valign='center' color={color}>
      <Icon name={icon} size='xxlarge' color='white' halign='center' valign='center' />
      <CardContent area='content-top' halign='center'>
        <Title value={top.title} size='xsmall' color='white' bold halign='start' valign='end' />
        <Quantity
          value={top.value}
          unit={top.unit}
          minDecimals={decimals}
          maxDecimals={decimals}
          size='large'
          color='white'
          bold
          halign='start'
          valign='baseline'
        />
      </CardContent>
      <CardContent area='content-bottom' halign='center'>
        <Title value={bottom.title} size='xsmall' color='white' bold halign='start' valign='end' />
        <Quantity
          value={bottom.value}
          unit={bottom.unit}
          minDecimals={decimals}
          maxDecimals={decimals}
          size='large'
          color='white'
          bold
          halign='start'
          valign='baseline'
        />
      </CardContent>
    </VerticalCard>
  );
};
