import { AxiosInstance } from 'axios';
import urlcat from 'urlcat';

/**
 * Returns a list of bmus
 *
 * @param bess The id of the bess to fetch the bmu from.
 * @param api Axios instance with correct headers.
 * @param limit The number of bmus to be retorned.
 * @param offset The number of bmus to be skipped, counting from the
 * more recent.
 * @throws Error if there is no such bess or bms.
 */
export async function hasMeters(
  api: AxiosInstance,
  bess: string
): Promise<Record<string, boolean>> {
  return await api
    .get<Record<string, boolean>>(
      urlcat('/besses/:bess/meters/hasMeters', { bess })
    )
    .then((res) => res.data);
}