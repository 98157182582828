export const titleSizes = {
  small: '150px',
  medium: '250px',
  large: '100%',
} as const;
export type TitleSizes = keyof typeof titleSizes;

export const profilePicSizes = {
  small: '32px',
  medium: '100px',
} as const;
export type ProfilePicSizes = keyof typeof profilePicSizes;

export const inputSizes = {
  medium: '196px',
  large: '344px',
} as const;
export type InputSizes = keyof typeof inputSizes;

export const labelSizes = {
  xxsmall: '40px',
  xsmall: '65px',
  small: '85px',
  medium: '105px',
  large: '125px',
} as const;
export type LabelSizes = keyof typeof labelSizes;

export const buttonSizes = {
  xxsmall: '40px',
  xsmall: '74px',
  small: '112px',
  large: '260px',
  dropdown: '240px',
  auto: '100%',
} as const;
export type ButtonSizes = keyof typeof buttonSizes;
