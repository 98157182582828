import React from 'react';
import styled from 'styled-components';
import {
  Title,
  SBreaksImage,
  SSingleCard
} from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
 const StyledContainer = styled.div`
 display: grid;
 gap: 15px 100px;
 justify-content: center;

 padding-top: calc(12vh - 50px);
 padding-bottom: calc(12vh - 50px);

 grid-template:
   'LeftContainer  RightContainer' max-content /
   max-content max-content;

 @media (max-width: 1255px) {
   grid-template:
     'LeftContainer'  max-content
     'RightContainer' max-content /
     max-content;
 }

 @media (max-width: 875px) {
   grid-template:
     'LeftContainer'  max-content
     'RightContainer' max-content /
     max-content;
 }

 @media (max-width: 580px) {
   grid-template:
     'LeftContainer'  max-content
     'RightContainer' max-content /
     260px;
 }
`;

const LeftContainer = styled.div`
  display: grid;
  gap: 15px;

  grid-template:
   'Title'        max-content
   'BreaksImage'  max-content /
   max-content;
`;

const RightContainer = styled.div`
  display: grid;
  gap: 50px;
  justify-content: center;

  grid-template:
   'BreaksStatus'   max-content
   'BreaksControl'  max-content /
   max-content;
`;

const BreaksStatus = styled.div`
 display: grid;
 gap: 15px;
 justify-content: center;

 grid-template:
   'Break1Status  Break2Status' max-content
   'Break3Status  Break4Status' max-content
   'CAStatus      CCStatus'     max-content /
   max-content max-content;
  
  @media (max-width: 580px) {
   grid-template:
     'Break1Status' max-content
     'Break2Status' max-content
     'Break3Status' max-content
     'Break4Status' max-content
     'CAStatus' max-content
     'CCStatus' max-content /
     max-content;
  }
`;

const BreaksControl = styled.div`
 display: grid;
 gap: 15px;
 justify-content: center;

 grid-template:
   'Break1Control  Break2Control' max-content
   'Break3Control  Break4Control' max-content
   'CAControl      CCControl'     max-content
   '.              Button'        max-content /
   max-content max-content;

   @media (max-width: 580px) {
    grid-template:
      'Break1Control' max-content
      'Break2Control' max-content
      'Break3Control' max-content
      'Break4Control' max-content
      'CAControl'     max-content
      'CCControl'     max-content
      'Button'        max-content /
      max-content;
    }
`;

/**
 * BeraksConfig View
 */
 export const BreaksConfigSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <LeftContainer>
        <Title area="Title" />
        <SBreaksImage area="BreaksImage" />
      </LeftContainer>
      <RightContainer>
        <BreaksStatus>
          <SSingleCard area="Break1Status" />
          <SSingleCard area="Break2Status" />
          <SSingleCard area="Break3Status" />
          <SSingleCard area="Break4Status" />
          <SSingleCard area="CAStatus" />
          <SSingleCard area="CCStatus" />
        </BreaksStatus>
        <BreaksControl>
          <SSingleCard area="Break1Control" />
          <SSingleCard area="Break2Control" />
          <SSingleCard area="Break3Control" />
          <SSingleCard area="Break4Control" />
          <SSingleCard area="CAControl" />
          <SSingleCard area="CCControl" />
          <SSingleCard area="Button" />
        </BreaksControl>
      </RightContainer>
    </StyledContainer>
  );
};