import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowUp: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4897 5.85379L7.73307 0.620041C7.2862 0.128344 6.50129 0.0714016 5.97993 0.492856C5.93159 0.531932 5.88651 0.574452 5.84508 0.620041L1.08841 5.85379C0.64153 6.34549 0.701907 7.08574 1.22326 7.5072C1.44861 7.68936 1.73561 7.78949 2.03241 7.78949L11.5457 7.78949C12.2324 7.78949 12.7891 7.2645 12.7891 6.6169C12.7891 6.33699 12.6829 6.06631 12.4897 5.85379Z"
        fill={fill}
      />

      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
