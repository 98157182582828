import React from "react";

import { DoubleCard, DoubleCardProps } from "../../components/DoubleCard";
import { CardContent } from "../../components/CardContent";
import { Icon, IconProps } from "../../components/Icons";
import { Title, TitleProps } from "../../components/Title";
import { Quantity, QuantityProps } from "../../components/Quantity";

import { Units } from "../../scripts/types";
import { SSingleCard } from "../SSingleCard";

const iconColor: Record<
  Exclude<DoubleCardProps["color"], undefined>,
  IconProps["color"]
> = {
  main: "white",
  white: "light",
  dark: "white",
  black: "white",
  light: "white",
  gradient: "white",
  modedarkcard: "white",
};

const textColor: Record<
  Exclude<DoubleCardProps["color"], undefined>,
  TitleProps["color"] & QuantityProps["color"]
> = {
  main: "white",
  white: "main",
  dark: "white",
  black: "white",
  light: "white",
  gradient: "white",
  modedarkcard: "white",
};

export type SDoubleCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Color of the card.
   */
  color: DoubleCardProps["color"];
  /**
   * Exact number of decimals for both values.
   */
  decimals?: number;
  /**
   * Left card content.
   */
  left: {
    title: string;
    value: number | string;
    unit: Units;
    icon: IconProps["name"];
    index?: number;
  };
  /**
   * Right card content.
   */
  right?: {
    title: string;
    value: number | string;
    unit: Units;
    icon?: IconProps["name"];
    index?: number;
  };
  /**
   * Splits SDoubleCard in two SSingleCards. The area names will be the current
   * area name passed as param with a hifen followed by 'left' and 'right'
   * repectively. If the original area is unset, both areas will be
   * unset.
   *
   *
   */
  split?: boolean;
};

/**
 * Card with two horizontal contents, that can be anything. There is a optional
 * divider at the center that is hidden when the right content is ommited and
 * two indexes to show a numeric information about the quantity being displayed.
 */
export const SDoubleCard: React.FC<SDoubleCardProps> = ({
  area = "unset",
  split = false,
  color = "main",
  decimals = 2,
  left,
  right,
}: SDoubleCardProps) => {
  if (split === false)
    return (
      <DoubleCard
        color={color}
        valign="center"
        halign="center"
        indexes={
          left.index !== undefined && right?.index !== undefined
            ? [left.index, right.index]
            : undefined
        }
        hideDivider={right === undefined}
        area={area}
      >
        <CardContent halign="center" area="content-left">
          <Icon
            name={left.icon}
            size="small"
            color={iconColor[color]}
            valign="center"
            padAll={{ right: "small" }}
          />
          <Title
            value={left.title}
            size="xsmall"
            color={textColor[color]}
            halign="start"
          />
          <Quantity
            value={left.value}
            unit={left.unit}
            size="large"
            color={textColor[color]}
            halign="start"
            valign="baseline"
            minDecimals={decimals}
            maxDecimals={decimals}
            bold
          />
        </CardContent>
        {right && (
          <CardContent halign="center" area="content-right">
            {right.icon && (
              <Icon
                name={right.icon}
                size="small"
                color={iconColor[color]}
                valign="center"
                padAll={{ right: "small" }}
              />
            )}
            <Title
              value={right.title}
              size="xsmall"
              color={textColor[color]}
              halign="start"
            />
            <Quantity
              value={right.value}
              unit={right.unit}
              size="large"
              color={textColor[color]}
              halign="start"
              valign="baseline"
              minDecimals={decimals}
              maxDecimals={decimals}
              bold
            />
          </CardContent>
        )}
      </DoubleCard>
    );
  /** Fallback to two single cards when split is true */
  return (
    <>
      <SSingleCard
        area={area === "unset" ? "unset" : `${area}-left`}
        title={left.title}
        unit={left.unit}
        icon={left.icon}
        color={color}
        value={left.value}
        decimals={decimals}
      />
      {right && ( // Only show if there is a right
        <SSingleCard
          area={area === "unset" ? "unset" : `${area}-right`}
          title={right.title}
          unit={right.unit}
          icon={right.icon ?? left.icon} // if right isn't provided, repeat left
          color={color}
          value={right.value}
          decimals={decimals}
        />
      )}
    </>
  );
};
