import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltC: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.23598 5.7561C1.80787 5.7561 1.4236 5.67079 1.08317 5.50016C0.742747 5.32953 0.477112 5.09309 0.286267 4.79084C0.0954223 4.48371 0 4.13758 0 3.75245C0 3.36732 0.0954223 3.02362 0.286267 2.72137C0.477112 2.41911 0.740168 2.18267 1.07544 2.01205C1.41586 1.84142 1.80271 1.7561 2.23598 1.7561C2.64346 1.7561 2.99936 1.8341 3.30368 1.99011C3.61315 2.14611 3.84526 2.37036 4 2.66287L3.25725 3.07237C3.13862 2.892 2.98904 2.75793 2.80851 2.67018C2.63314 2.57755 2.43972 2.53124 2.22824 2.53124C1.86718 2.53124 1.56802 2.64337 1.33075 2.86762C1.09349 3.087 0.974855 3.38194 0.974855 3.75245C0.974855 4.12295 1.09091 4.42033 1.32302 4.64459C1.56028 4.86397 1.86202 4.97365 2.22824 4.97365C2.43972 4.97365 2.63314 4.92978 2.80851 4.84203C2.98904 4.7494 3.13862 4.6129 3.25725 4.43252L4 4.84203C3.8401 5.13453 3.60542 5.36122 3.29594 5.5221C2.99162 5.6781 2.6383 5.7561 2.23598 5.7561Z"
        fill={fill}
      />
      <path
        d="M13.301 0.0431531C13.4881 0.132712 13.5873 0.340797 13.539 0.542569L11.6937 8.25625H15.5799C15.7455 8.25625 15.897 8.34914 15.9722 8.49666C16.0473 8.64418 16.0333 8.82137 15.9359 8.95526L8.03521 19.8187C7.91177 19.9884 7.68566 20.0474 7.49506 19.9596C7.30445 19.8717 7.20238 19.6615 7.25121 19.4574L9.09659 11.7437H4.7165C4.54865 11.7437 4.39541 11.6483 4.32143 11.4976C4.24745 11.3469 4.2656 11.1673 4.36823 11.0345L12.7627 0.171046C12.8896 0.00688143 13.1138 -0.046406 13.301 0.0431531ZM5.61283 10.8635H9.65443C9.78918 10.8635 9.9165 10.9252 9.99997 11.031C10.0834 11.1368 10.1138 11.275 10.0825 11.406L8.62043 17.5174L14.7156 9.13652H11.1358C11.0011 9.13652 10.8737 9.07479 10.7903 8.969C10.7068 8.86322 10.6764 8.72503 10.7078 8.59398L12.2061 2.33105L5.61283 10.8635Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
