import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltPlus: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9572 1.01112C15.1444 1.10044 15.2436 1.30797 15.1953 1.5092L13.3499 9.20236H17.2362C17.4017 9.20236 17.5533 9.295 17.6284 9.44213C17.7035 9.58925 17.6895 9.76598 17.5921 9.8995L9.69146 20.734C9.56802 20.9033 9.34191 20.9621 9.15131 20.8745C8.9607 20.7869 8.85863 20.5773 8.90746 20.3737L10.7528 12.6806H6.37275C6.2049 12.6806 6.05166 12.5854 5.97768 12.4351C5.9037 12.2848 5.92185 12.1057 6.02448 11.9732L14.419 1.13867C14.5458 0.974942 14.7701 0.921796 14.9572 1.01112ZM7.26908 11.8026H11.3107C11.4454 11.8026 11.5728 11.8642 11.6562 11.9697C11.7397 12.0752 11.7701 12.213 11.7387 12.3437L10.2767 18.4389L16.3719 10.0803H12.7921C12.6573 10.0803 12.53 10.0187 12.4465 9.91321C12.3631 9.80771 12.3327 9.66989 12.364 9.53919L13.8623 3.29293L7.26908 11.8026Z"
        fill={fill}
      />
      <path
        d="M3.7841 3.98927H5.82836C6.12928 3.98927 6.37322 4.25607 6.37322 4.58517C6.37322 4.91428 6.12928 5.18107 5.82836 5.18107H3.7841V7.21989C3.7841 7.52001 3.5166 7.7633 3.18661 7.7633C2.85663 7.7633 2.58912 7.52001 2.58912 7.21989V5.18107H0.544861C0.243943 5.18107 0 4.91428 0 4.58517C0 4.25607 0.243943 3.98927 0.544861 3.98927H2.58912V1.95045C2.58912 1.65034 2.85663 1.40704 3.18661 1.40704C3.5166 1.40704 3.7841 1.65034 3.7841 1.95045V3.98927Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
