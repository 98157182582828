import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Crown: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 190 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.75 133H33.25C30.6375 133 28.5 135.137 28.5 137.75V147.25C28.5 149.863 30.6375 152 33.25 152H156.75C159.363 152 161.5 149.863 161.5 147.25V137.75C161.5 135.137 159.363 133 156.75 133ZM175.75 38C167.883 38 161.5 44.3828 161.5 52.25C161.5 54.3578 161.975 56.3172 162.806 58.1281L141.312 71.0125C136.741 73.7437 130.833 72.2 128.191 67.5688L103.995 25.2344C107.172 22.6219 109.25 18.7031 109.25 14.25C109.25 6.38281 102.867 0 95 0C87.1328 0 80.75 6.38281 80.75 14.25C80.75 18.7031 82.8281 22.6219 86.0047 25.2344L61.8094 67.5688C59.1672 72.2 53.2297 73.7437 48.6875 71.0125L27.2234 58.1281C28.025 56.3469 28.5297 54.3578 28.5297 52.25C28.5297 44.3828 22.1469 38 14.2797 38C6.4125 38 0 44.3828 0 52.25C0 60.1172 6.38281 66.5 14.25 66.5C15.0219 66.5 15.7938 66.3812 16.5359 66.2625L38 123.5H152L173.464 66.2625C174.206 66.3812 174.978 66.5 175.75 66.5C183.617 66.5 190 60.1172 190 52.25C190 44.3828 183.617 38 175.75 38Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
