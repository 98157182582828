import React from 'react';

import { Title, Button, SPagination, STable } from '../../Skeleton';
import styled from 'styled-components';

const StyledPaginationContainer = styled.div`
  display: grid;
  grid-area: Pagination;
  justify-items: end;
`;

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 25px 60px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title      .' 27px
    'Table      RightContainer' max-content
    'Pagination .' 32px /
    720px 260px;

  @media (max-width: 1070px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Table' max-content
      'Pagination' 32px /
      720px;
  }

  @media (max-width: 800px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Table' max-content
      'Pagination' 32px /
      100%;
  }

  @media (max-width: 420px) {
    grid-template:
      'Title' max-content
      'RightContainer' 90px
      'Table' max-content
      'Pagination' 32px /
      100%;
  }
`;

const StyledRightContainer = styled.div`
  display: grid;
  grid-area: RightContainer;
  gap: 10px;
  height: 40px;

  grid-template:
    'NewUser' 100%
    'Profile' 100% /
    100%;

  @media (max-width: 1070px) {
    grid-template:
      'NewUser Profile' max-content /
      1fr 1fr;
  }

  @media (max-width: 420px) {
    grid-template:
      'NewUser' 100%
      'Profile' 100% /
      100%;
  }
`;

/**
 * Users Skeleton
 */
export const UsersSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" size="medium" />
      <STable area="Table" />
      <StyledRightContainer>
        <Button area="NewUser" size="auto" />
        <Button area="Profile" size="auto" />
      </StyledRightContainer>
      <StyledPaginationContainer>
        <SPagination area="Pagination" />
      </StyledPaginationContainer>
    </StyledContainer>
  );
};
