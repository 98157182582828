import { AxiosInstance } from 'axios';
import urlcat from 'urlcat';
import { CountableList } from '../types';
import { SystemControlCommand } from './types';

/**
 * Applies current control with the required two factor authentication.
 *
 * @param data Charge and Dischage, Pcs and Mode data to be applied.
 * @param pin Two factor authentication code.
 * @param bess Bess id to apply to.
 * @param api Axios instance with correct headers.
 */
export async function applySystemControl(
  api: AxiosInstance,
  bess: string,
  pin: string,
  data: SystemControlCommand
): Promise<SystemControlCommand> {
  return await api
    .post<SystemControlCommand>(urlcat('/besses/:bess/system', bess), {
      ...data,
      pin,
    })
    .then((res) => res.data);
}

/**
 * Lists system control's configurations applied.
 *
 * @param offset Start at how many history entries into the past.
 * @param limit Total of history entries counting from offset into the past.
 * @param bess Bess id to get control history from.
 * @param api Axios instance with correct headers.
 */
export async function listSystemControl(
  api: AxiosInstance,
  bess: string,
  offset = 0,
  limit = 10
): Promise<CountableList<SystemControlCommand>> {
  return await api
    .get<CountableList<SystemControlCommand>>(
      urlcat('/besses/:bess/system', { bess, offset, limit })
    )
    .then((res) => res.data);
}
