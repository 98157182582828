import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowDown: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.08936 2.14627L5.84603 7.38002C6.29291 7.87172 7.07781 7.92866 7.59917 7.50721C7.64751 7.46813 7.69259 7.42561 7.73403 7.38002L12.4907 2.14627C12.9376 1.65458 12.8772 0.914319 12.3558 0.492865C12.1305 0.310701 11.8435 0.210571 11.5467 0.210571L2.03336 0.210571C1.34669 0.210571 0.790039 0.735558 0.790039 1.38316C0.790039 1.66307 0.896209 1.93375 1.08936 2.14627Z"
        fill={fill}
      />

      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
