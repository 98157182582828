import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const LaptopSquare: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 640 640"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <path
            d="M640 0C640 384.006 640 597.333 640 640C255.996 640 42.6628 640 0 640C0 256.004 0 42.6673 0 0C384.004 0 597.337 0 640 0Z"
            fill="white"
          />
          <path
            d="M485.24 440.91C489.54 440.91 493.02 437.42 493.02 433.12C493.02 432.42 493.02 426.81 493.02 426.11C493.03 425.99 493.06 425.88 493.06 425.77C493.06 424.49 492.75 423.29 492.21 422.24C489.96 416.56 478.69 388.2 458.42 337.16C458.42 242.21 458.42 189.46 458.42 178.91C458.42 174.61 454.93 171.12 450.63 171.12C424.5 171.12 215.47 171.12 189.34 171.12C185.04 171.12 181.55 174.61 181.55 178.91C181.55 189.46 181.55 242.21 181.55 337.16C161.11 388.6 149.76 417.18 147.49 422.89C147.34 423.27 147.23 423.67 147.14 424.06C147.12 424.16 147.1 424.26 147.08 424.36C147 424.82 146.95 425.28 146.94 425.73C146.94 425.74 146.94 425.76 146.94 425.77C146.94 426.5 146.94 432.38 146.94 433.12C146.94 437.42 150.43 440.91 154.73 440.91C220.83 440.91 452.19 440.91 485.24 440.91ZM286.49 415.77L293.61 393.74H346.35L353.47 415.77H286.49ZM204.44 190.99H435.52V326.57H204.44V190.99Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <path
        mask={`url(#${idCut})`}
        d="M640 0C640 384.006 640 597.333 640 640C255.996 640 42.6628 640 0 640C0 256.004 0 42.6673 0 0C384.004 0 597.337 0 640 0Z"
        fill={fill}
      />
    </svg>
  );
};
