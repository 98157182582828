import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const CrossCircle: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <circle cx="16" cy="16" r="16" fill="white" />
          <path
            d="M23.285 11.0012C23.4084 10.8782 23.5064 10.7321 23.5732 10.5712C23.64 10.4103 23.6744 10.2378 23.6744 10.0635C23.6744 9.88932 23.64 9.71682 23.5732 9.55592C23.5064 9.39502 23.4084 9.2489 23.285 9.12592C23.1863 9.04452 23.0623 9 22.9343 9C22.8063 9 22.6823 9.04452 22.5835 9.12592L16.5 15.2127L11.0012 9.71497C10.8782 9.59156 10.7321 9.49365 10.5712 9.42684C10.4103 9.36003 10.2378 9.32564 10.0635 9.32564C9.88932 9.32564 9.71682 9.36003 9.55592 9.42684C9.39502 9.49365 9.2489 9.59156 9.12592 9.71497C9.04452 9.81374 9 9.93775 9 10.0657C9 10.1937 9.04452 10.3178 9.12592 10.4165L15.2127 16.5L9.71497 21.9988C9.59156 22.1218 9.49365 22.2679 9.42684 22.4288C9.36003 22.5897 9.32564 22.7622 9.32564 22.9365C9.32564 23.1107 9.36003 23.2832 9.42684 23.4441C9.49365 23.605 9.59156 23.7511 9.71497 23.8741C9.81374 23.9555 9.93775 24 10.0657 24C10.1937 24 10.3178 23.9555 10.4165 23.8741L16.5 17.7873L21.9988 23.2861C22.1218 23.4095 22.2679 23.5075 22.4288 23.5743C22.5897 23.6411 22.7622 23.6755 22.9365 23.6755C23.1107 23.6755 23.2832 23.6411 23.4441 23.5743C23.605 23.5075 23.7511 23.4095 23.8741 23.2861C23.9555 23.1874 24 23.0634 24 22.9354C24 22.8074 23.9555 22.6834 23.8741 22.5846L17.7873 16.5L23.285 11.0012Z"
            fill="black"
          />
        </mask>
      </defs>
      <circle mask={`url(#${idCut})`} cx="16" cy="16" r="16" fill={fill} />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
