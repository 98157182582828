import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const sizes = {
  medium: { height: '270px', bottomPad: '35px' },
  small: { height: '200px', bottomPad: '5px' },
} as const;
type Sizes = keyof typeof sizes;

export const colors = (theme: DefaultTheme) =>
  ({
    white: css`
      background-color: white;
    `,
    black: css`
      background-color: black;
    `,
    light: css`
      background-color: ${theme.pallete.primary.light};
    `,
    main: css`
      background-color: ${theme.pallete.primary.main};
    `,
    dark: css`
      background-color: ${theme.pallete.border.main};
    `,
    gradient: css`
      background-image: linear-gradient(
        180.14deg,
        ${theme.pallete.accent.main} 30.49%,
        ${theme.pallete.accent.dark} 230.93%
      );
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

type StyledGridProps = {
  color: Color;
  size: Sizes;
  area: string;
  pad: Directional<Pad>;
  gap?: string;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const StyledGrid = styled.div<StyledGridProps>`
  display: grid;

  grid-area: ${(props) => props.area};
  row-gap: ${(props) => props.gap ?? 0};

  grid-template:
    'icon' auto
    'content-top' 54px
    '.' 5px
    'content-bottom' 54px
    '.' ${(props) => sizes[props.size].bottomPad}
    / 210px;

  ${(props) => colors(props.theme)[props.color]};
  box-shadow: rgba(77, 124, 254, 0.08) 0em 0.4375em 0.625em;
  border-radius: 8px;

  width: 210px;
  height: ${(props) => sizes[props.size].height};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};
`;

export type VerticalCardProps = {
  children?: React.ReactNode;
  /**
   * Background color of the card.
   */
  color?: Color;
  /**
   * Size of the card, as in vertical size.
   */
  size?: Sizes;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions
   */
  pad?: Pad;
  /**
   * Padding in each directions
   */
  padAll?: Directional<Pad>;
  /**
   * Padding in cross directions (vertical, horizontal)
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items. It will do nothing since the items occupy
   * all width. Instead, change the aligment of the inner cardContent and Icon.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
};

/**
 * This "vertical" card container can hold one icon, and two titles with a quantiy below.
 *  The usage of this component, albeit a bit verbose, but highly readable will be:
 *
 * ```tsx
  <VerticalCard color="gradient">
      <Icon
        name="Wave"
        size="xxlarge"
        color="white"
        halign="center"
        valign="center"
      />
      <CardContent area="content-top" halign="center">
        <Title
          value="Frequência Off-Grid"
          size="xsmall"
          color="white"
          halign="start"
          valign="end"
          bold
        />
        <Quantity
          value={0.0}
          unit="frequency"
          minDecimals={1}
          size="large"
          color="white"
          bold
          halign="start"
          valign="baseline"
        />
      </CardContent>
      <CardContent area="content-bottom" halign="center">
        <Title
          value="Frequência On-Grid"
          size="xsmall"
          color="white"
          halign="start"
          valign="end"
          bold
        />
        <Quantity
          value={1.0}
          unit="frequency"
          minDecimals={1}
          size="large"
          color="white"
          bold
          halign="start"
          valign="baseline"
        />
      </CardContent>
    </VerticalCard>
 * ```
 */

export const VerticalCard: React.FC<VerticalCardProps> = ({
  area = 'unset',
  color = 'main',
  size = 'medium',
  ...props
}: VerticalCardProps) => {
  const { children, pad, padAll, padCross, halign, valign } = props;
  return (
    <StyledGrid
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      color={color}
      size={size}
    >
      {children}
    </StyledGrid>
  );
};
