import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Warning: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 429 398"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M244.644 328.177V282.829C244.644 280.601 243.888 278.731 242.377 277.22C240.865 275.708 239.075 274.952 237.007 274.952H191.181C189.113 274.952 187.323 275.708 185.811 277.22C184.299 278.731 183.544 280.601 183.544 282.829V328.177C183.544 330.404 184.299 332.274 185.811 333.786C187.323 335.297 189.113 336.053 191.181 336.053H237.007C239.075 336.053 240.865 335.297 242.377 333.786C243.888 332.274 244.644 330.404 244.644 328.177ZM244.167 238.913L248.463 129.361C248.463 127.452 247.667 125.94 246.076 124.826C244.008 123.076 242.098 122.201 240.348 122.201H187.84C186.089 122.201 184.18 123.076 182.112 124.826C180.52 125.94 179.725 127.611 179.725 129.839L183.782 238.913C183.782 240.504 184.578 241.817 186.169 242.851C187.76 243.885 189.67 244.402 191.897 244.402H236.052C238.28 244.402 240.149 243.885 241.661 242.851C243.172 241.817 244.008 240.504 244.167 238.913ZM240.825 15.9911L424.127 352.044C429.696 362.068 429.537 372.093 423.65 382.117C420.945 386.731 417.245 390.391 412.551 393.096C407.857 395.801 402.806 397.154 397.396 397.154H30.7923C25.3823 397.154 20.3304 395.801 15.6365 393.096C10.9425 390.391 7.24309 386.731 4.53812 382.117C-1.34917 372.093 -1.50829 362.068 4.06077 352.044L187.362 15.9911C190.067 11.0585 193.807 7.1602 198.58 4.29611C203.354 1.43202 208.525 -2.09808e-05 214.094 -2.09808e-05C219.663 -2.09808e-05 224.834 1.43202 229.608 4.29611C234.381 7.1602 238.12 11.0585 240.825 15.9911Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
