import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const AirConditioner: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6904 13.2218L12.5527 11.5292C12.5251 11.2261 12.2614 10.9939 11.9544 11.0214C11.6473 11.0451 11.419 11.3127 11.4466 11.6198L11.5844 13.3123C11.608 13.6036 11.8678 13.8398 12.1827 13.8201C12.4897 13.8004 12.7141 13.5288 12.6904 13.2218Z"
        fill={fill}
      />
      <path
        d="M16.953 12.4306L16.5397 10.7853C16.4649 10.4861 16.1618 10.309 15.8666 10.3798C15.5675 10.4546 15.3864 10.7577 15.4612 11.0529L15.8745 12.6983C15.9375 12.9502 16.2091 13.1824 16.5476 13.1037C16.8467 13.0329 17.0278 12.7258 16.953 12.4306Z"
        fill={fill}
      />
      <path
        d="M8.04149 11.0215C7.73447 10.9939 7.4668 11.2222 7.44318 11.5293L7.30542 13.2218C7.2818 13.5289 7.50616 13.8044 7.81319 13.8201C8.13202 13.8398 8.38788 13.6036 8.4115 13.3124L8.54927 11.6198C8.57682 11.3167 8.34852 11.049 8.04149 11.0215Z"
        fill={fill}
      />
      <path
        d="M4.12864 10.3798C3.82949 10.305 3.53034 10.4861 3.45555 10.7853L3.04224 12.4306C2.96746 12.7298 3.14852 13.0329 3.44768 13.1037C3.79013 13.1824 4.05779 12.9502 4.12077 12.6983L4.53408 11.0529C4.60886 10.7577 4.4278 10.4546 4.12864 10.3798Z"
        fill={fill}
      />
      <path
        d="M17.3431 4.17242C17.0361 4.17242 16.7881 4.42041 16.7881 4.72743V4.91244C16.7881 5.21946 17.0361 5.46744 17.3431 5.46744C17.6501 5.46744 17.8981 5.21946 17.8981 4.91244V4.72743C17.8981 4.42434 17.6501 4.17242 17.3431 4.17242Z"
        fill={fill}
      />
      <path
        d="M18.3901 0H1.60598C0.720331 0 0 0.720331 0 1.60598V7.92364C0 8.80929 0.720331 9.52962 1.60598 9.52962H18.394C19.2797 9.52962 20 8.80929 20 7.92364V1.60598C19.9961 0.720331 19.2757 0 18.3901 0ZM18.886 7.92364C18.886 8.19524 18.6656 8.41567 18.394 8.41567H1.60598C1.33438 8.41567 1.11395 8.19524 1.11395 7.92364V1.60598C1.11395 1.33438 1.33438 1.11395 1.60598 1.11395H18.394C18.6656 1.11395 18.886 1.33438 18.886 1.60598V7.92364Z"
        fill={fill}
      />
      <path
        d="M17.2921 6.2901H2.70442C2.3974 6.2901 2.14941 6.53808 2.14941 6.84511C2.14941 7.15213 2.3974 7.40012 2.70442 7.40012H17.296C17.6031 7.40012 17.851 7.15213 17.851 6.84511C17.851 6.53808 17.5991 6.2901 17.2921 6.2901Z"
        fill={fill}
      />
      <path
        d="M17.3431 2.07043C17.0361 2.07043 16.7881 2.31842 16.7881 2.62544V2.81045C16.7881 3.11747 17.0361 3.36546 17.3431 3.36546C17.6501 3.36546 17.8981 3.11747 17.8981 2.81045V2.62544C17.8981 2.31842 17.6501 2.07043 17.3431 2.07043Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
