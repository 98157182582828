import {
  eachDayOfInterval,
  startOfWeek,
  addDays,
  startOfMonth,
  format,
  isBefore,
  isAfter,
  isSameDay,
  addMonths,
  startOfToday,
  endOfToday,
  isToday,
  isSameMonth,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { TitleProps } from '../../components/Title';

/**
 * Options to create a complete calendar
 */
export type CalendarOptions = {
  /**
   * This date is used to identify the current month being
   * displayed in the calendar
   */
  dateOfMonth: Date;
  /**
   * The day which is currently selected in the
   * interface
   */
  actualSelectedDay?: Date;
  /**
   * List of days with any specified programming for the
   * current month (fallback weekly programmings or schedules are ignored)
   */
  programming?: Date[];
  /**
   * List of days with any maintenance event for the current
   * month
   */
  maintenance?: Date[];
  /**
   * Whether we should consider the actual day of week
   * selected, for example, if you select a day which is a monday, all mondays
   * are selected (visually only)
   */
  dayOfWeekIsSelected?: boolean;
  /**
   * Disables all dates in the past
   */
  disablePast?: boolean;
  /**
   * Disables all dates on the future
   */
  disableFuture?: boolean;
};

/**
 * Calendar information to build pages.
 */
export type ProtoCalendar = {
  /**
   * Total range of days to show in that month.
   */
  daysRange: {
    firstDay: number;
    lastDay: number;
  };

  calendarHeader: string;

  /**
   * Information about the each day, such as date, disabled,
   * value, today's date and if is selected.
   */
  dayArray: {
    asDate: Date;
    asNumber: number;
    isDisabled: boolean;
    isToday: boolean;
    isSelected: boolean;
    hasProgramming?: boolean;
    hasMaintenance?: boolean;
  }[];

  /**
   * Information about the each day of the week, such as date value and
   * abbreviation.
   */
  weekDayArray: {
    asDate: Date;
    asDayOfWeek: number;
    asAbbreviation: string;
    isSelected?: boolean;
    aDayOfWeekIsSelected?: boolean;
  }[];
};

/**
 * Decides which days of the month should be disabled of the current ones
 * being displayed. Returns true to disable the day, false to keep it
 * enabled.
 *
 * @param considering Day we are considering here to be disabled
 * @param currentMonth Date object representing the current month displayed
 * on the calendar
 * @param disablePast If true, disables any day in the past
 * @param disableFuture If true, disables any day in the future
 */
function shouldDisable(
  considering: Date,
  currentMonth: Date,
  disablePast: boolean,
  disableFuture: boolean
) {
  if (!isSameMonth(considering, currentMonth)) return true;
  else if (isBefore(considering, startOfToday()) && disablePast) return true;
  else if (isAfter(considering, endOfToday()) && disableFuture) return true;
  return false;
}

/**
 * Returns whether the provided day is actually selected or not
 *
 * @param day The day in trial
 * @param realSelected The actual selected day which we are comparing to
 * @param dayOfWeekIsSelected If we should consider selected the day or all days
 * of the week that matches this guy here
 */
function isThisDaySelected(
  day: Date,
  realSelected?: Date,
  dayOfWeekIsSelected?: boolean
) {
  const dayOfWeek = day.getDay();
  const selectedDayOfWeek = realSelected?.getDay();

  if (day === undefined || realSelected === undefined) return false;
  else if (dayOfWeekIsSelected && dayOfWeek === selectedDayOfWeek) return true;
  else if (!dayOfWeekIsSelected && isSameDay(day, realSelected)) return true;
  return false;
}

/**
 * Returns whether the provided day of the week is actually selected or not
 *
 * @param day The day in trial
 * @param realSelected The actual selected day which we are comparing to
 */
function isThisDayOfWeekSelected(day: Date, realSelected?: Date) {
  const dayOfWeek = day.getDay();
  const selectedDayOfWeek = realSelected?.getDay();

  if (day === undefined || realSelected === undefined) return false;
  else if (dayOfWeek === selectedDayOfWeek) return true;
  return false;
}

/**
 * Generates all information necessary to build a calendar.
 *
 * @param options All options to create a complete calendar
 * @returns An information type object with all necessary data to actually build
 * a calendar. Everytime anything changes, rebuild the calendar with the new
 * information computed from this function.
 */
export function createProtoCalendar({
  dateOfMonth,
  actualSelectedDay = undefined,
  dayOfWeekIsSelected = false,
  disableFuture = false,
  disablePast = false,
  maintenance = [],
  programming = [],
}: CalendarOptions): ProtoCalendar {
  const start = startOfWeek(startOfMonth(dateOfMonth));
  const end = addDays(start, 41);

  const header = format(dateOfMonth, "MMMM 'de' yyyy", {
    locale: ptBR,
  }).toUpperCase();

  const dayArray = eachDayOfInterval({ start, end }).map((day) => ({
    asDate: day,
    asNumber: day.getDate(),
    isDisabled: shouldDisable(day, dateOfMonth, disablePast, disableFuture),
    isToday: isToday(day),
    isSelected: isThisDaySelected(day, actualSelectedDay, dayOfWeekIsSelected),
    hasProgramming: programming.some((item) => isSameDay(day, item)),
    hasMaintenance: maintenance.some((item) => isSameDay(day, item)),
  }));

  const weekDayArray = eachDayOfInterval({ start, end: addDays(start, 6) }).map(
    (day) => {
      const fullName = format(day, 'ccc', { locale: ptBR });
      return {
        asDate: day,
        asDayOfWeek: day.getDay(),
        asAbbreviation: fullName.toUpperCase().slice(0, 3),
        isSelected: isThisDayOfWeekSelected(day, actualSelectedDay),
        aDayOfWeekIsSelected: dayOfWeekIsSelected,
      };
    }
  );

  return {
    calendarHeader: header,
    daysRange: {
      firstDay: start.getDate(),
      lastDay: end.getDate(),
    },
    weekDayArray,
    dayArray,
  };
}

/**
 * Increments the month by a fixed value
 *
 * @param currentMonth Current month's date object
 * @param increment Increment in months
 */
export function changeMonth(currentMonth: Date, increment: number): Date {
  return addMonths(currentMonth, increment);
}

/**
 * Solves the color of the specified cell accordinly to its attributes
 *
 * @param isDisabled Whether the current calendar day cell is disabled
 * @param isToday Whether the current calendar day cell is actually today
 * @param isSelected Whether the current calendar day cell is selected
 * @returns The color of the given cell
 */
export function solveCalendarCellColor(
  isDisabled: boolean,
  isToday: boolean,
  isSelected: boolean
): TitleProps['color'] {
  if (isDisabled) return 'neutral';
  else if (isToday) return 'error';
  else if (isSelected) return 'white';
  return 'black';
}
