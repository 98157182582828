export type Message<T> = {
  when: T;
  say: [text: string, accent: string];
};

/**
 * Returns t a suitable message accordinly with the current system status
 *
 * @param messages Message mapping accordingly to page status
 * @param status Current page status
 * @returns
 */
export function makeMessages<T>(
  messages: Message<T>[],
  status?: T
): { text: string; accent: string } {
  const current = messages.find((message) => message.when === status);
  if (current) return { text: current.say[0], accent: current.say[1] };
  return { text: 'Evento', accent: 'Desconhecido' };
}
