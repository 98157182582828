import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { GradientFade } from '../../css/Gradient';
import { SolidRipple } from '../../css/Ripple';
import { solve } from '../../scripts/common';
import { Cross, Directional, ItemAlign, LineAlign, valueof } from '../../scripts/types';

const sizes = {
  xxxsmall: { width: '24px', height: '23px' },
  xxsmall: { width: '40px', height: '24px' },
  xsmall: { width: '74px', height: '25.6px' },
  small: { width: '112px', height: '26px' },
  xmedium: { width: '130px', height: '31.6px' },
  medium: { width: '170px', height: '31.6px' },
  vmedium: { width: '170px', height: '36px' },
  xxmedium: { width: '200px', height: '39.6px' },
  xxxmedium: { width: '160px', height: '30px' },
  lmedium: { width: '200px', height: '40px' },
  large: { width: '260px', height: '39.6px' },
  xlarge: { width: '320px', height: '39.6px' },
  xxlarge: { width: '210px', height: '60px' },
  dialog: { width: '100%', height: '60px' },
  alert: { width: '400px', height: '200px' },
  alertContent: { width: '150vh', height: '400px' },
  dropdown: { width: '240px', height: '40px' },
  acordeon: { width: '255px', height: '38px' },
  auto: { width: '100%', height: '38px' },
  max: { width: 'max-content', height: '20px' },
  maxall: { width: 'max-content', height: 'max-content' },
} as const;
type Size = keyof typeof sizes;

const margins = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Margin = keyof typeof margins;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const radii = {
  none: '0px',
  small: '4px',
  medium: '10px',
  xmedium: '15px',
  large: '20px',
  xlarge: '100px',
} as const;
type Radius = keyof typeof radii;

export const colors = (theme: DefaultTheme) =>
  ({
    black: css`
      ${SolidRipple('black', 'white')}
    `,
    neutral: css`
      ${SolidRipple(theme.pallete.neutral.light, 'black')}
    `,
    darkgray: css`
      ${SolidRipple('#626262', 'white')}
    `,
    white: css`
      ${SolidRipple('white', theme.pallete.border.main)}
    `,
    light: css`
      ${SolidRipple(theme.pallete.primary.light, theme.pallete.border.main)}
    `,
    main: css`
      ${SolidRipple(theme.pallete.primary.main, theme.pallete.border.main)}
    `,
    dark: css`
      ${SolidRipple(theme.pallete.border.main, theme.pallete.primary.main)}
    `,
    success: css`
      ${SolidRipple(theme.pallete.success.main, 'white')}
    `,
    error: css`
      ${SolidRipple(theme.pallete.error.main, 'white')}
    `,
    secondary: css`
      ${SolidRipple(theme.pallete.secondary.main, theme.pallete.primary.main)}
    `,
    gradient: css`
      ${GradientFade(theme)};
    `,
    inactive: css`
      ${SolidRipple(theme.pallete.neutral.light, theme.pallete.neutral.main)}
    `,
    lighter: css`
      ${SolidRipple(theme.pallete.neutral.lighter, theme.pallete.neutral.main)}
    `,
    orange: css`
      ${SolidRipple('#ef9301', '#6e4503')}
    `,
    Alarmlevel1: css`
      background-color: #ffd525;
    `,
    Alarmlevel2: css`
      background-color: #ef9301;
    `,
    Alarmlevel3: css`
      background-color: #eb4e2c;
    `,
    transparent: css`
      background: transparent;
    `,
    disabled: css`
      background-color: ${theme.pallete.neutral.light};
      cursor: default;
    `,
    activeClient: css`
      background-color: #1f324e;
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

type StyledButtonProps = {
  color: Color;
  size: valueof<typeof sizes>;
  radius: valueof<typeof radii>;
  border: string;
  bold: boolean;
  area: string;
  pad: Directional<Pad>;
  margin: Directional<Margin>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledButton = styled.button<StyledButtonProps>`
  border: ${(props) => props.border};
  border-radius: ${(props) => props.radius};
  outline: 0;

  width: ${(props) => props.size.width};
  height: ${(props) => props.size.height};

  transition: all 0.5s;

  text-decoration: none;
  cursor: pointer;

  ${(props) => colors(props.theme)[props.color]};

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  margin-left: ${(props) => margins[props.margin.left ?? 'none']};
  margin-right: ${(props) => margins[props.margin.right ?? 'none']};
  margin-top: ${(props) => margins[props.margin.top ?? 'none']};
  margin-bottom: ${(props) => margins[props.margin.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

export type ButtonProps = {
  children?: React.ReactNode;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /**
   * Radius of the button. It defaults to medium.
   */
  radius?: Radius;
  /**
   * Theme of the button. It defaults to main. The theme changes the color of
   * both the button and its text in a way that it's always in constrast.
   */
  color?: Color;
  /*
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "button".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  margin?: Margin;

  marginCross?: Cross<Margin>;

  marginAll?: Directional<Margin>;
  /**
   * Individual margin for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * If disabled is true, the onClick action is ignored and the color is set to
   * a gray tone. Defaults to false.
   */
  disabled?: boolean;
  /**
   * Activated when the hover event starts
   */
  onMouseOver?: () => void;
  /**
   * Activated when the hover event finishes.
   */
  onMouseOut?: () => void;
  /**
   * Click action. If no click action is provided, the button does nothing, but
   * doesn't fail.
   */
  onClick?: () => void;
  border?: string;
};

/**
 * General purpose button component. It accepts one action when clicked and can
 * be disabled at will.
 */
export const Button: React.FC<ButtonProps> = ({
  size = 'medium',
  border = 'transparent',
  radius = 'medium',
  color = 'main',
  disabled = false,
  bold = false,
  area = 'button',
  salign,
  pad,
  margin,
  marginCross,
  marginAll,
  padAll,
  padCross,
  halign,
  valign,
  children,
  onMouseOver,
  onMouseOut,
  onClick,
}: ButtonProps) => {
  return (
    <StyledButton
      border={border}
      size={sizes[size]}
      radius={radii[radius]}
      color={disabled ? 'disabled' : color}
      disabled={disabled}
      bold={bold}
      area={area}
      pad={solve(pad, padCross, padAll)}
      margin={solve(margin, marginCross, marginAll)}
      halign={halign}
      valign={valign}
      salign={salign}
      onClick={() => onClick?.call(this)}
      onMouseOver={() => onMouseOver?.call(this)}
      onMouseOut={() => onMouseOut?.call(this)}>
      {children}
    </StyledButton>
  );
};
