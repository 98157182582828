import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const CircleStroke: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46382 5.8769C4.72099 5.8769 5.74013 4.86687 5.74013 3.62095C5.74013 2.37502 4.72099 1.36499 3.46382 1.36499C2.20664 1.36499 1.1875 2.37502 1.1875 3.62095C1.1875 4.86687 2.20664 5.8769 3.46382 5.8769Z"
        stroke={fill}
        strokeWidth="1.51684"
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
