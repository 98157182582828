import React from 'react';
import styled from 'styled-components';

const sizes = {
  small: { width: '200px', height: '80px' },
  medium: { width: '300px', height: '120px' },
  large: { width: '400px', height: '150px' },
} as const;
type Size = keyof typeof sizes;

const round = {
  none: '0px',
  small: '4px',
  medium: '10px',
  large: '20px',
} as const;
type Radius = keyof typeof round;

export type TextAreaProps = {
  /**
   * It can be any text or number, it fills in the typing field.
   */
  value?: string;
  /**
   * This fills the marker,
   * it only appears when the field is empty.
   */
  placeholder?: string;
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /**
   * Radius of the button. It defaults to medium.
   */
  radius?: Radius;
  /*
   * If true, the box is resizable.
   */
  resize?: boolean;
  /*
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * This enables or disables the input field.
   */
  disabled?: boolean;
};

type StyledTextAreaProps = {
  size: { width: string; height: string };
  bold: boolean;
  radius: string;
  resize: boolean;
};

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  font-family: ${(props) => props.theme.global.fontFamily};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  width: ${(props) => props.size.width};
  height: ${(props) => props.size.height};
  padding: 4px 12px;
  font-size: 15px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: ${(props) => props.radius};
  resize: ${(props) => (props.resize ? 'both' : 'none')};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

/**
 * General proposal of the component,
 * accepts input of big texts
 */
export const TextArea: React.FC<TextAreaProps> = ({
  size = 'medium',
  radius = 'medium',
  bold = false,
  resize = false,
  disabled = false,
  ...props
}: TextAreaProps) => {
  const { value, placeholder } = props;
  return (
    <StyledTextArea
      value={value}
      placeholder={placeholder}
      size={sizes[size]}
      radius={round[radius]}
      bold={bold}
      resize={resize}
      disabled={disabled}
    />
  );
};
