import React from 'react';

import { Title, SBString, SPagination } from '../../Skeleton';
import { useMedia } from '../../../scripts/hooks/mediaQueryHook';
import styled from 'styled-components';

const StyledPaginationContainer = styled.div`
  display: grid;
  grid-area: Pagination;
  justify-items: end;
`;

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title       .              .' 60px
    'Grid        Grid           Grid' auto
    '.           .              Pagination' min-content /
    280px min-content 300px;

  row-gap: 10px;

  @media (max-width: 600px) {
    grid-template:
      'Title' 60px
      '. ' 40px
      'Grid' auto
      '.   ' 10px
      'Pagination ' min-content /
      300px;

    row-gap: 10px;
    gap: 10px;
  }
`;

/**
 * BMSStrings Skeleton
 */
export const BMSStringsSkeleton: React.FC = () => {
  const col11 = useMedia('(max-width: 888px)');
  const col10 = useMedia('(max-width: 798px)');
  const col09 = useMedia('(max-width: 720px)');
  const col08 = useMedia('(max-width: 660px)');
  const col04 = useMedia('(max-width: 600px)');
  const colsSize =
    4 * Number(col04) +
    Number(col08) +
    Number(col09) +
    Number(col10) +
    Number(col11);
  const columns = 12 - colsSize;
  return (
    <StyledContainer>
      <Title area="Title" />
      <SBString area="Grid" columns={columns} />
      <StyledPaginationContainer>
        <SPagination />
      </StyledPaginationContainer>
    </StyledContainer>
  );
};
