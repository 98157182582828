import React from 'react';
import styled from 'styled-components';

import { SSingleCard, SThreePhaseCard, SVerticalCard } from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 10px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'ActivePower   DCVoltage DCCurrent   Temperature' 60px
    '.             Voltage   Current     Frequency' 14px
    'ReactivePower Voltage   Current     Frequency' 60px
    '.             Voltage   Current     Frequency' 14px
    'ApparentPower Voltage   Current     Frequency' 60px
    '.             Voltage   Current     Frequency' 12px
    'PowerFactor   DCCharge  DCDischarge Frequency' 60px /
    210px 210px 210px 210px;

  @media (max-width: 900px) {
    grid-template:
      'ActivePower ReactivePower ApparentPower' 60px
      'PowerFactor DCVoltage     DCCurrent' 60px
      'Voltage     Current       Temperature' 60px
      'Voltage     Current       Frequency' 60px
      'Voltage     Current       Frequency' 60px
      'DCCharge    DCDischarge   Frequency' 60px /
      210px 210px 210px;
  }

  @media (max-width: 690px) {
    grid-template:
      'ActivePower   ReactivePower' 60px
      'ApparentPower PowerFactor' 60px
      'DCVoltage     DCCurrent' 60px
      'DCCharge      DCDischarge' 60px
      'Voltage       Temperature' 60px
      'Voltage       Frequency' 60px
      'Voltage       Frequency' 60px
      'Current       Frequency' 60px
      'Current       Frequency' 60px
      'Current       Frequency' 60px /
      210px 210px;
  }

  @media (max-width: 480px) {
    grid-template:
      'ActivePower' 60px
      'ReactivePower' 60px
      'ApparentPower' 60px
      'PowerFactor' 60px
      'DCVoltage' 60px
      'DCCurrent' 60px
      'DCCharge' 60px
      'DCDischarge' 60px
      'Voltage' 60px
      'Voltage' 60px
      'Voltage' 60px
      'Current' 60px
      'Current' 60px
      'Current' 60px
      'Temperature' 60px
      'Frequency' 270px /
      210px;
  }
`;

export const PCSSkeleton: React.FC = () => (
  <StyledContainer>
    <SSingleCard area="ActivePower" />
    <SSingleCard area="ReactivePower" />
    <SSingleCard area="ApparentPower" />
    <SSingleCard area="PowerFactor" />
    <SSingleCard area="DCVoltage" />
    <SSingleCard area="DCCurrent" />
    <SSingleCard area="Temperature" />
    <SThreePhaseCard area="Voltage" />
    <SThreePhaseCard area="Current" />
    <SVerticalCard area="Frequency" />
    <SSingleCard area="DCCharge" />
    <SSingleCard area="DCDischarge" />
  </StyledContainer>
);
