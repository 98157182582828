import React from 'react';
import styled from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

export interface LogoProps {
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;

  width?: "600" | "400" | "250";

  height?: "300" | "200" | "150"

}

type ContentProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};


const Content = styled.div<ContentProps>`
  display: grid;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

/**
 * The logo will be rendered in two single places at first: the login form and
 * at the top left part of the menu. There are no props.
 */
export const Logo: React.FC<LogoProps> = ({ area = 'logo', ...props }: LogoProps) => {
  const { pad, padAll, padCross, halign, valign, width, height } = props;
  return (
    <Content area={area} pad={solve(pad, padCross, padAll)} halign={halign} valign={valign}>
      <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width={width}
   height={height}
   viewBox="0 0 185.20834 185"
   version="1.1"
   id="svg8"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
   sodipodi:docname="moura-logo.svg"
   inkscape:export-filename="C:\Users\suitedoalex\sites\moura-logo-0.png"
   inkscape:export-xdpi="561.73712"
   inkscape:export-ydpi="561.73712">
  <defs
     id="defs2">
    <clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath274">
      <path
         d="M 0,2291.556 H 595.276 V 0 H 0 Z"
         id="path272"
         inkscape:connector-curvature="0" />
    </clipPath>
    <clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath274-3">
      <path
         d="M 0,2291.556 H 595.276 V 0 H 0 Z"
         id="path272-6"
         inkscape:connector-curvature="0" />
    </clipPath>
    <clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath274-3-7">
      <path
         d="M 0,2291.556 H 595.276 V 0 H 0 Z"
         id="path272-6-4"
         inkscape:connector-curvature="0" />
    </clipPath>
  </defs>
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0.0"
     inkscape:pageshadow="2"
     inkscape:zoom="0.49497475"
     inkscape:cx="439.31449"
     inkscape:cy="208.01161"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     units="px"
     inkscape:window-width="1600"
     inkscape:window-height="837"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
   //   inkscape:label="Layer 1"
   //   inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-111.79161)">
    <g
       transform="matrix(0.77586751,0,0,-0.77586751,-153.97103,869.37759)"
       id="g268"
       inkscape:export-filename="C:\Users\suitedoalex\sites\moura-logo-4.png"
       inkscape:export-xdpi="100.21175"
       inkscape:export-ydpi="100.21175">
      <g
         id="g270"
         clip-path="url(#clipPath274-3)">
        <g
           id="g276"
           transform="translate(323.4987,698.2058)"
           style="fill:#FFFF">
          <path
             d="m 0,0 -27.131,-27.128 8.147,-0.004 13.56,13.565 V -27.128 L 8.172,-13.49 v -13.642 l 5.431,0.004 L 13.6,0.024 0,-13.574 Z"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path278"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g280"
           transform="translate(354.069,675.8162)"
           style="fill:#FFFF">
          <path
             d="m 0,0 c -4.874,0 -8.826,3.951 -8.826,8.826 0,4.874 3.952,8.825 8.826,8.825 4.874,0 8.825,-3.951 8.825,-8.825 C 8.825,3.951 4.874,0 0,0 m 0,23.082 c -7.874,0 -14.257,-6.383 -14.257,-14.256 0,-7.874 6.383,-14.257 14.257,-14.257 7.874,0 14.256,6.383 14.256,14.257 0,7.873 -6.382,14.256 -14.256,14.256"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path282"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g284"
           transform="translate(408.0446,693.4822)"
           style="fill:#FFFF">
          <path
             d="m 0,0 1.015,-0.005 4.756,4.753 h -6.45 c -6.561,0 -11.881,-5.319 -11.881,-11.881 v -15.275 h 5.432 V -7.129 C -7.128,-3.191 -3.937,0 0,0"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path286"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g288"
           transform="translate(381.9079,671.074)"
           style="fill:#FFFF">
          <path
             d="m 0,0 c -5.999,0 -10.862,4.863 -10.862,10.862 v 16.294 h 5.43 V 10.862 C -5.432,7.863 -3,5.432 0,5.432 h 4.752 v 21.724 h 5.431 V 0 Z"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path290"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g292"
           transform="translate(418.3981,682.9548)"
           style="fill:#FFFF">
          <path
             d="m 0,0 c -1.781,0 -3.226,-1.443 -3.226,-3.225 0,-1.781 1.445,-3.224 3.226,-3.224 H 8.316 V 0 Z m 0.847,10.527 -6.109,-0.005 4.749,4.753 h 2.38 c 6.562,0 11.88,-5.319 11.88,-11.88 V -11.881 H -0.356 c -4.593,0 -8.317,3.724 -8.317,8.317 0,4.607 3.731,8.316 8.333,8.316 L 8.131,4.71 C 7.359,8.112 4.335,10.527 0.847,10.527"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path294"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g296"
           transform="translate(203.4665,677.5046)">
          <path
             d="M 0,0 V -19.295 L 47.165,16.079 V 35.374 Z"
             style="fill:#f9af1d;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path298"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g300"
           transform="translate(272.071,658.2097)">
          <path
             d="M 0,0 17.15,12.863 V 40.175 L 0,27.312 Z"
             style="fill:#3f54ac;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path302"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g304"
           transform="translate(250.6315,693.5837)">
          <path
             d="M 0,0 -15.008,-11.256 V -35.372 L 0,-24.142 Z"
             style="fill:#de9a20;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path306"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g308"
           transform="translate(289.2213,698.3845)">
          <path
             d="M 0,0 -38.59,-28.942 V -4.801 L 0,24.142 Z"
             style="fill:#4780ca;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path310"
             inkscape:connector-curvature="0" />
        </g>
      </g>
    </g>
    <g
       transform="matrix(1.0390848,0,0,-1.0390848,-150.957,905.9117)"
       id="g268-5"
       inkscape:export-filename="C:\Users\suitedoalex\sites\moura-logo-5.png"
       inkscape:export-xdpi="148.69868"
       inkscape:export-ydpi="148.69868">
      <g
         id="g270-8"
         clip-path="url(#clipPath274-3-7)">
        <g
           id="g276-0"
           transform="matrix(0.84751262,0,0,0.84751262,197.31071,653.34619)"
           style="fill:#FFFF">
          <path
             d="m 0,0 -27.131,-27.128 8.147,-0.004 13.56,13.565 V -27.128 L 8.172,-13.49 v -13.642 l 5.431,0.004 L 13.6,0.024 0,-13.574 Z"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path278-0"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g280-3"
           transform="matrix(0.84751262,0,0,0.84751262,223.21942,634.37072)"
           style="fill:#FFFF">
          <path
             d="m 0,0 c -4.874,0 -8.826,3.951 -8.826,8.826 0,4.874 3.952,8.825 8.826,8.825 4.874,0 8.825,-3.951 8.825,-8.825 C 8.825,3.951 4.874,0 0,0 m 0,23.082 c -7.874,0 -14.257,-6.383 -14.257,-14.256 0,-7.874 6.383,-14.257 14.257,-14.257 7.874,0 14.256,6.383 14.256,14.257 0,7.873 -6.382,14.256 -14.256,14.256"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path282-0"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g284-4"
           transform="matrix(0.84751262,0,0,0.84751262,268.96442,649.34288)"
           style="fill:#FFFF">
          <path
             d="m 0,0 1.015,-0.005 4.756,4.753 h -6.45 c -6.561,0 -11.881,-5.319 -11.881,-11.881 v -15.275 h 5.432 V -7.129 C -7.128,-3.191 -3.937,0 0,0"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path286-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g288-3"
           transform="matrix(0.84751262,0,0,0.84751262,246.81324,630.35164)"
           style="fill:#FFFF">
          <path
             d="m 0,0 c -5.999,0 -10.862,4.863 -10.862,10.862 v 16.294 h 5.43 V 10.862 C -5.432,7.863 -3,5.432 0,5.432 h 4.752 v 21.724 h 5.431 V 0 Z"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path290-0"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g292-3"
           transform="matrix(0.84751262,0,0,0.84751262,277.73914,640.42078)"
           style="fill:#FFFF">
          <path
             d="m 0,0 c -1.781,0 -3.226,-1.443 -3.226,-3.225 0,-1.781 1.445,-3.224 3.226,-3.224 H 8.316 V 0 Z m 0.847,10.527 -6.109,-0.005 4.749,4.753 h 2.38 c 6.562,0 11.88,-5.319 11.88,-11.88 V -11.881 H -0.356 c -4.593,0 -8.317,3.724 -8.317,8.317 0,4.607 3.731,8.316 8.333,8.316 L 8.131,4.71 C 7.359,8.112 4.335,10.527 0.847,10.527"
             style="fill:#FFFF;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path294-6"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g296-1"
           transform="translate(203.4665,677.5046)">
          <path
             d="M 0,0 V -19.295 L 47.165,16.079 V 35.374 Z"
             style="fill:#f9af1d;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path298-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g300-9"
           transform="translate(272.071,658.2097)">
          <path
             d="M 0,0 17.15,12.863 V 40.175 L 0,27.312 Z"
             style="fill:#3f54ac;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path302-1"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g304-5"
           transform="translate(250.6315,693.5837)">
          <path
             d="M 0,0 -15.008,-11.256 V -35.372 L 0,-24.142 Z"
             style="fill:#de9a20;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path306-6"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g308-2"
           transform="translate(289.2213,698.3845)">
          <path
             d="M 0,0 -38.59,-28.942 V -4.801 L 0,24.142 Z"
             style="fill:#4780ca;fill-opacity:1;fill-rule:evenodd;stroke:none"
             id="path310-6"
             inkscape:connector-curvature="0" />
        </g>
      </g>
    </g>
  </g>
  </svg>
</Content>
  );
};