import React from 'react';
import styled from 'styled-components';
import { SCalendarCell } from '../SCalendarCell';
import { HeaderLike } from '../../components/HeaderLike';
import { CardContent } from '../../components/CardContent';
import { Title } from '../../components/Title';
import { ProtoCalendar } from '../../scripts/common';
import { Icon } from '../../components/Icons';
import { SCalendarWeekDayCell } from '../SCalendarWeekDayCell';

const radii = {
  none: '0px',
  small: '4px',
  medium: '10px',
  large: '20px',
} as const;
type Radius = keyof typeof radii;

const Month = styled.main`
  color: blue;
  text-align: center;
  font-weight: 500;
  font-family: ${(props) => props.theme.global.fontFamily};
`;

const DateGrid = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(7, 1fr);
  width: 210px;
  justify-items: center;
  align-items: center;
  margin: 15px;
`;

type DivCalendarProps = {
  area: string;
  radius: { top?: Radius; bottom?: Radius };
};
export const DivCalendar = styled.div<DivCalendarProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  box-sizing: border-box;
  border: solid 1px #ccc;
  width: 260px;
  border-top-left-radius: ${(props) => radii[props.radius.top ?? 'none']};
  border-top-right-radius: ${(props) => radii[props.radius.top ?? 'none']};
  border-bottom-left-radius: ${(props) => radii[props.radius.bottom ?? 'none']};
  border-bottom-right-radius: ${(props) =>
    radii[props.radius.bottom ?? 'none']};
  height: max-content;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export type SCalendarProps = {
  /**
   * Calendar information, generated by function calendarControlFrom
   */
  info: ProtoCalendar;
  /**
   * Action fired when a day is selected.
   */
  onSelectDay: (date: Date, isSelectedDayOfWeek?: boolean) => void;
  /**
   * Action fired when the next month button is clicked.
   */
  onNext?: () => void;
  /**
   * Action fired when the prev month button is clicked.
   */
  onPrev?: () => void;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Enable or disable all weekdays selectable
   */
  weekDaysAreSelectable?: boolean;
  /**
   * Radius of the div. It defaults to none.
   */
  radius?: { top?: Radius; bottom?: Radius };
};

export const SCalendar: React.FC<SCalendarProps> = ({
  info,
  onSelectDay,
  onPrev,
  onNext,
  radius = { top: 'medium', bottom: 'medium' },
  area = 'unset',
  weekDaysAreSelectable = false,
}: SCalendarProps) => {
  return (
    <DivCalendar area={area} radius={radius}>
      <HeaderLike
        color="dark"
        radius={{ top: 'medium', bottom: 'none' }}
        valign="center"
        halign="center"
        width="xlarge"
      >
        <CardContent
          template="iconsAround"
          halign="center"
          valign="center"
          salign={{ horizontal: 'center', vertical: 'center' }}
        >
          <Icon
            area="icon-left"
            name="ArrowLeftCircle"
            color="white"
            size="small"
            halign="start"
            onClick={() => onPrev?.call(this)}
            padAll={{ right: 'small' }}
          />
          <Title
            value={info.calendarHeader}
            size="small"
            color="white"
            halign="center"
            valign="center"
          />
          <Icon
            area="icon-right"
            name="ArrowRightCircle"
            color="white"
            size="small"
            halign="end"
            onClick={() => onNext?.call(this)}
            padAll={{ left: 'small' }}
          />
        </CardContent>
      </HeaderLike>
      <div>
        <Month />
        <DateGrid>
          {info.weekDayArray.map((weekDay) => (
            <SCalendarWeekDayCell
              key={weekDay.asAbbreviation}
              label={weekDay.asAbbreviation}
              dayOfTheWeek={weekDay.asDate}
              isSelected={weekDay.isSelected && weekDay.aDayOfWeekIsSelected}
              isSelectable={weekDaysAreSelectable}
              onClick={(day) => onSelectDay(day, true)}
            />
          ))}
          {info.dayArray.map((date) => (
            <SCalendarCell
              key={date.asDate.toISOString()}
              asValue={date.asNumber}
              onClick={() => onSelectDay(date.asDate, false)}
              isDisabled={date.isDisabled}
              isToday={date.isToday}
              isSelected={date.isSelected}
              hasMaintenance={date.hasMaintenance}
              hasProgramming={date.hasProgramming}
            />
          ))}
        </DateGrid>
      </div>
    </DivCalendar>
  );
};
