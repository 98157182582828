import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { DropDown, DropDownBorder } from '../../components/DropDown';
import { Icon, IconProps } from '../../components/Icons';
import { Title } from '../../components/Title';

const ContainerDropDown = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: min-content max-content 20px;
`;

const Clip = styled.div`
  clip-path: circle(15.5px at 16.5px 16px);
`;

export type SUserDropDownProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Depending on the user privillege, there are different icons
   */
  icon: IconProps['name'];
  /**
   * Static link to the user's profile picture
   */
  profilePicture?: string;
  /**
   * Name of the current user
   */
  title?: string;
  /**
   * User options and actions in this menu
   */
  options: { name: string; action: () => void }[];
  /**
   * Any option selected triggers this prop.
   */
  anyAction?: () => void;

  color?: "main" | "black" | "white" | "dark" | "light" | "secondary" | "success" | "error" | "neutral" | "darkgray" | "inactive" | "disabled" | "gray" | "gradient" | undefined;

  colorIcon?:  "main" | "black" | "white" | "dark" | "light" | "secondary" | "success" | "error" | "darkgray" | "disabled" | "info" | "warning" | undefined;

  colorIconComputer?:  "main" | "black" | "white" | "dark" | "light" | "secondary" | "success" | "error" | "darkgray" | "disabled" | "info" | "warning" | undefined;
};

/**
 * User dropdown at the menu
 */
export const SUserDropDown: React.FC<SUserDropDownProps> = ({
  area = 'unset',
  icon,
  title,
  options,
  anyAction,
  color = 'black',
  colorIcon = 'black',
  colorIconComputer = 'main'
}: SUserDropDownProps) => {
  const [drop, setDrop] = useState(true);
  const [hover, setHover] = useState<number | undefined>(undefined);
  const action = () => {
    setDrop(!drop);
  };

  return (
    <DropDown
      area={area}
      topOffset={32}
      collapse={drop}
      size="auto"
      dropButton={
        <ContainerDropDown onClick={action}>
          <Clip>
            <Icon
              area="unset"
              name={icon}
              valign="start"
              size="large"
              padAll={{ right: 'small' }}
              color={colorIconComputer}
            />
          </Clip>
          {title && (
            <Title area="unset" size="xsmall" color={color} value={title} />
          )}
          <Icon
            area="unset"
            color={colorIcon}
            name="ArrowDownThin"
            size="xsmall"
            valign="center"
            halign="center"
          />
        </ContainerDropDown>
      }
    >
      {options.map((v, i) => {
        return (
          <React.Fragment key={i}>
            <Button
              onClick={() => {
                if (anyAction) anyAction();
                v.action();
              }}
              radius="none"
              size="xmedium"
              color={hover === i ? 'lighter' : 'white'}
              onMouseOver={() => setHover(i)}
              onMouseOut={() => setHover(undefined)}
            >
              <Title size="xsmall" color="gradient" value={v.name} />
            </Button>
            {i < options.length - 1 && <DropDownBorder />}
          </React.Fragment>
        );
      })}
    </DropDown>
  );
};
