import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Gear: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8556 2.73159C11.5436 1.13213 9.25522 1.13213 8.94318 2.73159C8.60844 4.44745 6.71706 5.35829 5.16684 4.55018C3.72178 3.7969 2.29499 5.58603 3.35095 6.82724C4.48375 8.15877 4.01662 10.2054 2.41827 10.9136L2.18899 10.3961L2.41827 10.9136C0.928347 11.5737 1.43756 13.8047 3.06635 13.753C4.81368 13.6976 6.12255 15.3388 5.67966 17.03C5.26682 18.6065 7.32859 19.5994 8.3037 18.2937C9.34978 16.893 11.449 16.893 12.4951 18.2937C13.4702 19.5993 15.532 18.6065 15.1192 17.03C14.6763 15.3388 15.9852 13.6976 17.7325 13.753C19.3613 13.8047 19.8705 11.5737 18.3806 10.9136C16.7822 10.2054 16.3151 8.15877 17.4479 6.82724C18.5038 5.58603 17.077 3.7969 15.632 4.55018C14.0818 5.35829 12.1904 4.44745 11.8556 2.73159ZM7.83215 2.51484C8.38226 -0.304914 12.4166 -0.304914 12.9667 2.51484C13.1566 3.48813 14.2294 4.00479 15.1087 3.5464C17.6563 2.2184 20.1716 5.37256 18.3101 7.56073C17.6675 8.31602 17.9325 9.47694 18.8391 9.87863C21.4658 11.0424 20.568 14.9756 17.6966 14.8844C16.7054 14.853 15.963 15.7839 16.2142 16.7432C16.942 19.5224 13.3072 21.2729 11.5882 18.971C10.9948 18.1765 9.80403 18.1765 9.21066 18.971C7.49159 21.2729 3.8568 19.5224 4.58462 16.7432C4.83584 15.7839 4.0934 14.853 3.10226 14.8844C0.230798 14.9756 -0.666923 11.0424 1.95972 9.87863C2.86636 9.47694 3.13133 8.31602 2.48877 7.56073C0.627181 5.37256 3.14254 2.2184 5.69009 3.5464C6.56943 4.00479 7.64228 3.48813 7.83215 2.51484ZM10.3994 8.26617C9.11843 8.26617 8.07998 9.30462 8.07998 10.5856C8.07998 11.8666 9.11843 12.905 10.3994 12.905C11.6804 12.905 12.7189 11.8666 12.7189 10.5856C12.7189 9.30462 11.6804 8.26617 10.3994 8.26617ZM6.94801 10.5856C6.94801 8.67945 8.49325 7.1342 10.3994 7.1342C12.3056 7.1342 13.8508 8.67945 13.8508 10.5856C13.8508 12.4918 12.3056 14.037 10.3994 14.037C8.49325 14.037 6.94801 12.4918 6.94801 10.5856Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
