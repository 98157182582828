import { SafeUser, userRoles } from '../api/types';

/**
 *
 * Consirering the user's permission, this function calculates to where a user
 * should route to after having both a user and a bess defined.
 *
 * @param user Current logged-in user
 * @param bess Current selected bess. An empty string if no bess is selected.
 * @param goto Function that takes a route argument and goes to that route,
 * it can use, for example, window.location or a specific function of a virtual
 * DOM router, like the preact-router "route" function.
 */
export function routeToOwnArea(user: SafeUser | undefined, bess: string, goto: (url: string) => boolean): void {
  if (user !== undefined && user.permissions.length > 0 && bess.length > 0) {
    const { code, bess: allowedBess } = user.permissions[0];
    const isBessesOncorp = process.env.IS_BESS_ONCORP;
    if (bess === allowedBess || code <= userRoles.operator || allowedBess === isBessesOncorp) {
      if (code <= userRoles.mouraDev || code === userRoles.manager) {
        goto('/dev');
      } else if (code === userRoles.operator || code === userRoles.manager) {
        goto('/operator');
      } else if (code >= userRoles.financial || code === userRoles.administrative) {
        goto('/client');
      } else {
        throw new Error('Not Allowed');
      }
    }
  }
}
