import React from 'react';
import styled from 'styled-components';

import { PageCell } from '../../components/PageCell';
import { PaginationInfo, solve } from '../../scripts/common';
import { Title } from '../../components/Title';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

type StyledPageGridProps = {
  area: string;
  pad: Directional<Pad>;
  cells: number;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};
const StyledPageGrid = styled.div<StyledPageGridProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  grid-template-columns: repeat(${(props) => props.cells + 4}, 32px);
  gap: 5px;

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  user-select: none;

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

export type SPaginationProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Information to build the pagination control. Use the function
   * paginationFrom to generate this information.
   */
  pagination: PaginationInfo;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal alignment of the page buttons.
   */
  halign?: LineAlign;
  /**
   * Vertical alignment of the page buttons.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * External function to be called to set the current page number.
   */
  setCurrentPage: (pageIndex: number) => void;
};

/**
 * Page button holder. It solves the pagination for a single page.
 */
export const SPagination: React.FC<SPaginationProps> = ({
  area = 'unset',
  halign,
  valign,
  salign,
  pad,
  padCross,
  padAll,
  setCurrentPage,
  pagination: {
    controls: {
      showFirst,
      showLast,
      showPrev,
      showNext,
      showPagesFrom,
      totalPagesToShow,
    },
    current: { pageIndex },
    pageRange: { lastPage },
  },
}: SPaginationProps) => (
  <StyledPageGrid
    area={area}
    halign={halign}
    valign={valign}
    salign={salign}
    cells={totalPagesToShow}
    pad={solve(pad, padCross, padAll)}
  >
    {showFirst && (
      <PageCell
        area="1 / 1"
        valign="center"
        halign="center"
        onClick={() => setCurrentPage(0)}
      >
        <Title value="<<" size="small" color="light" bold />
      </PageCell>
    )}
    {showPrev && (
      <PageCell
        area="1 / 2"
        valign="center"
        halign="center"
        onClick={() => setCurrentPage(pageIndex - 1)}
      >
        <Title value="<" size="small" color="light" bold />
      </PageCell>
    )}
    {totalPagesToShow > 0 &&
      new Array(totalPagesToShow).fill(0).map((_, i) => (
        <PageCell
          area={`1 / ${i + 3}`}
          onClick={() => setCurrentPage(i + showPagesFrom)}
          active={pageIndex === i + showPagesFrom}
          key={`pageBtn${i + showPagesFrom}`}
          valign="center"
          halign="center"
        >
          <Title
            value={`${i + showPagesFrom + 1}`}
            size="small"
            color={pageIndex === i + showPagesFrom ? 'white' : 'light'}
            bold
          />
        </PageCell>
      ))}
    {showNext && (
      <PageCell
        area={`1 / ${totalPagesToShow + 3}`}
        onClick={() => setCurrentPage(pageIndex + 1)}
        valign="center"
        halign="center"
      >
        <Title value=">" size="small" color="light" bold />
      </PageCell>
    )}
    {showLast && (
      <PageCell
        area={`1 / ${totalPagesToShow + 4}`}
        onClick={() => setCurrentPage(lastPage)}
        valign="center"
        halign="center"
      >
        <Title value=">>" size="small" color="light" bold />
      </PageCell>
    )}
  </StyledPageGrid>
);
