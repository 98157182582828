import React from 'react';
import styled, { DefaultTheme, StyledProps, css } from 'styled-components';

import { solve } from '../../scripts/common';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
/*import { Color } from 'plotly.js';*/

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '8px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const margins = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Margin = keyof typeof margins;

const sizes = {
  xxsmall: { value: '9px' },
  xsmall: { value: '11.2px' },
  smallx: { value: '12.5px' },
  small: { value: '14px' },
  lsmall: { value: '16px' },
  xmedium: { value: '18px' },
  medium: { value: '22px' },
  large: { value: '28px' },
  xlarge: { value: '32px' },
} as const;
export type Sizes = keyof typeof sizes;

type Aligns = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';

type Border = string;

const colors = (theme: DefaultTheme) => ({
  white: css`
    color: white;
  `,
  black: css`
    color: black;
  `,
  dark: css`
    color: ${theme.pallete.border.main};
  `,
  main: css`
    color: ${theme.pallete.primary.main};
  `,
  light: css`
    color: ${theme.pallete.primary.light};
  `,
  secondary: css`
    color: ${theme.pallete.secondary.main};
  `,
  success: css`
    color: ${theme.pallete.success.main};
  `,
  error: css`
    color: ${theme.pallete.error.main};
  `,
  neutral: css`
    color: ${theme.pallete.neutral.light};
  `,
  darkgray: css`
    color: #626262;
  `,
  inactive: css`
    color: ${theme.pallete.neutral.light};
  `,
  disabled: css`
    color: ${theme.pallete.neutral.light};
  `,
  gray: css`
    color: ${theme.pallete.neutral.main};
  `,
  orange: css`
    color: #ef9301;
  `,
  darkblue: css`
    color: #1e2838;
  `,
  gradient: css`
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      172.14deg,
      ${theme.pallete.accent.main} 12.49%,
      ${theme.pallete.accent.dark} 198.93%
    );
  `,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledTextProps = {
  fontSize: string;
  color?: Colors;
  bold: boolean;
  area: string;
  pad: Directional<Pad>;
  margin: Directional<Margin>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  textAlign: Aligns;
  border?: Border;
};

const StyledText = styled.span<StyledProps<StyledTextProps>>`
  display: grid;
  ${(props) => colors(props.theme)[props.color ?? 'black']}

  transition: color 0.5s, opacity 0.3s;

  font-size: ${(props) => props.fontSize};
  font-family: ${(props) => props.theme.global.fontFamily};
  font-weight: ${(props) => (props.bold ? 'bolder' : 'normal')};
  text-align: ${(props) => props.textAlign};
  user-select: none;

  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  margin-left: ${(props) => margins[props.margin.left ?? 'none']};
  margin-right: ${(props) => margins[props.margin.right ?? 'none']};
  margin-top: ${(props) => margins[props.margin.top ?? 'none']};
  margin-bottom: ${(props) => margins[props.margin.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
  border-bottom: ${(props) => props?.border ?? 'none'};

  ${(props) =>
    props.onClick &&
    css`
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    `}
`;

export interface TitleProps {
  /**
   * Text value to display.
   */
  value: string;
  /**
   * Size of the value and unit. When large, the unit is smaller than the value.
   * It defaults to medium.
   */
  size?: Sizes;
  /**
   * Color of the text. If no gradient and no color is provided,
   * the default text color is black.
   */
  color?: Colors;
  /**
   * Font weight as bolder if true.
   */
  bold?: boolean;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "title".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Margin for all directions (top, bottom, left, right)
   */
  margin?: Margin;
  /**
   * Individual margin for each direction.
   */
  marginAll?: Directional<Margin>;
  /**
   * Margin vertical and horizontal.
   */
  marginCross?: Cross<Margin>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Horizontal text alignment.
   */
  txtalign?: Aligns;
  /**
   * Fired when the user clicks the text.
   */
  border?: Border;

  onClick?: () => void;
}

/**
 * Every title, present in headers of pages and other components, will be defined
 * as a variation of a title.
 */
export const Title: React.FC<TitleProps> = ({
  txtalign = 'initial',
  bold = false,
  size = 'medium',
  area = 'title',
  value,
  color,
  pad,
  padAll,
  padCross,
  margin,
  marginAll,
  marginCross,
  onClick,
  halign,
  valign,
  salign,
  border,
}: TitleProps) => {
  return (
    <StyledText
      area={area}
      pad={solve(pad, padCross, padAll)}
      margin={solve(margin, marginCross, marginAll)}
      halign={halign}
      valign={valign}
      salign={salign}
      fontSize={sizes[size].value}
      textAlign={txtalign}
      onClick={onClick}
      color={color}
      bold={bold}
      border={border}>
      {value}
    </StyledText>
  );
};
