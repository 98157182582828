import React from 'react';

import { Title, Button, SPagination, STable } from '../../Skeleton';
import styled from 'styled-components';

const StyledPaginationContainer = styled.div`
  display: grid;
  grid-area: Pagination;
  justify-items: end;
`;

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 25px 60px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title      .' 27px
    'Table      RightContainer' max-content
    'Pagination .' 32px /
    720px 240px;

  @media (max-width: 1045px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Table' max-content
      'Pagination' 32px /
      720px;
  }

  @media (max-width: 800px) {
    grid-template:
      'Title' max-content
      'RightContainer' max-content
      'Table' max-content
      'Pagination' 32px /
      100%;
  }
`;

const StyledRightContainer = styled.div`
  display: grid;
  grid-area: RightContainer;
  gap: 10px;
  justify-items: center;

  grid-template:
    'YearSelect' 38px
    'DownloadButton' 40px /
    100%;

  @media (max-width: 1045px) {
    grid-template:
      'YearSelect DownloadButton' max-content /
      1fr 1fr;
  }
  @media (max-width: 550px) {
    grid-template:
      'YearSelect' 38px
      'DownloadButton' 40px /
      1fr;
  }
`;

/**
 * Financial Skeleton
 */
export const FinancialSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" size="medium" />
      <STable area="Table" />
      <StyledPaginationContainer>
        <SPagination area="Pagination" />
      </StyledPaginationContainer>
      <StyledRightContainer>
        <Button area="YearSelect" size="dropdown" />
        <Button area="DownloadButton" size="dropdown" />
      </StyledRightContainer>
    </StyledContainer>
  );
};
