import React from 'react';
import styled, { css, StyledProps } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const templates = {
  default: css`
    grid-template:
      'icon title' 1fr
      'icon subtitle' min-content
      'icon quantity' min-content
      / min-content minmax(60px, max-content);
  `,
  iconsAround: css`
    grid-template:
      'icon-left title    icon-right' 1fr
      'icon-left quantity icon-right' min-content
      / min-content minmax(146px, max-content) min-content;
  `,
  inline: css`
    grid-template:
      'icon-left title  quantity icon-right' 1fr
      / min-content minmax(60px, max-content) max-content min-content;
  `,
  alert: css`
    grid-template:
      'icon title    time' 1fr
      'line description description' min-content
      / min-content minmax(200px, max-content) min-content;
  `,
  timepicker: css`
    grid-template:
      'title time button  ' 1fr
      / min-content min-content auto;
    column-gap: 5px;
  `,

  alertCard: css`
    gap: 5px 20px;
    justify-content: center;
    grid-template:
      'icon  icon' max-content
      'title title' max-content
      'description quantity' max-content /
      max-content;
  `,
  noAlertCard: css`
    gap: 5px;
    justify-content: center;
    grid-template:
      'icon' max-content
      'title' max-content
      'description' max-content /
      max-content;
  `,
};

type Template = keyof typeof templates;

type StyledGridProps = {
  area: string;
  template: Template;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};

const StyledGrid = styled.div<StyledProps<StyledGridProps>>`
  display: grid;
  grid-area: ${(props) => props.area};
  ${(props) => templates[props.template]};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

export type CardContentProps = {
  children?: React.ReactNode;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "cardcontent".
   */
  area?: string;
  /**
   * Grid template for the card content.
   */
  template?: Template;
};

/**
 * This component holds an icon, a title and a quantity component. These
 * specific components actually can be anything with the required areas.
 */
export const CardContent: React.FC<CardContentProps> = ({
  area = 'cardcontent',
  template = 'default',
  children,
  pad,
  padCross,
  padAll,
  halign,
  valign,
  salign,
}: CardContentProps) => {
  return (
    <StyledGrid
      area={area}
      template={template}
      halign={halign}
      valign={valign}
      salign={salign}
      pad={solve(pad, padCross, padAll)}>
      {children}
    </StyledGrid>
  );
};
