import React from 'react';

import { Title, FormField, Button, ProfilePic, DropDown } from '../../Skeleton';
import { useMedia } from '../../../scripts/hooks/mediaQueryHook';
import styled from 'styled-components';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 25px;

  grid-template:
    '.            .' 5px
    'ProfilePic   ProfilePic' 100px
    'Role         Role' max-content
    'User         Email' 60px
    'Password     ConfirmPassword' 60px
    '.            .' 10px
    'Buttons      Buttons' max-content /
    max-content max-content;

  @media (max-width: 740px) {
    grid-template:
      '.              ' 5px
      'ProfilePic     ' 100px
      'Role           ' max-content
      'User           ' 60px
      'Email          ' 60px
      'Password       ' 60px
      'ConfirmPassword' 60px
      '.              ' 2px
      'Buttons        ' max-content /
      max-content;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-area: Buttons;
  width: 100%;
  justify-content: center;
  gap: 10px;

  grid-template:
    'CreateButton BackButton' max-content /
    max-content max-content;

  @media (max-width: 740px) {
    grid-template:
      'CreateButton' max-content
      'BackButton' max-content /
      1fr;
  }
`;

/**
 * UsersAdd Skeleton
 */
export const UserAddSkeleton: React.FC = () => {
  const tooSmall = useMedia('(max-width: 380px)');
  const Medium = useMedia('(max-width: 740px)');

  return (
    <>
      <Title size="medium" />
      <StyledContainer>
        <ProfilePic area="ProfilePic" size="medium" />
        <DropDown area="Role" />
        <FormField area="User" size={tooSmall ? 'medium' : 'large'} />
        <FormField area="Email" size={tooSmall ? 'medium' : 'large'} />
        <FormField area="Password" size={tooSmall ? 'medium' : 'large'} />
        <FormField
          area="ConfirmPassword"
          size={tooSmall ? 'medium' : 'large'}
        />
        <ButtonContainer>
          <Button area="CreateButton" size={Medium ? 'auto' : 'large'} />
          <Button area="BackButton" size={Medium ? 'auto' : 'large'} />
        </ButtonContainer>
      </StyledContainer>
    </>
  );
};
