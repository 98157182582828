import React, { useLayoutEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SChart } from "..";
import { Button, CardContent, Quantity, Scrollable, Title } from "../../components";
import { ChartReviewItem, ReviewItem, PerkeurtReviewItem } from "../../scripts/common/reviews";
import { Dialog } from "../Dialog";

import dotenv from "dotenv";
import { SPeukert } from "../SPeukert";
dotenv.config();

// const IS_LOCAL = process.env.IS_LOCAL;

const Review = styled.div`
  display: grid;
  grid-area: review;

  height: 95%;
  width: 98%;

  background-color: ${(props) => props.theme.pallete.neutral.lighter};
  border-radius: 10px;

  justify-self: center;
  align-self: center;
  justify-items: center;

  padding-bottom: 10px;
  padding-top: 10px;
`;

const ReviewContent = styled.div`
  display: grid;
  align-items: center;
  justify-items: stretch;
  grid-auto-rows: min-content;
`;

const ChartContent = styled.div`
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 780px) {
    width: 320px;
  }

  @media (max-width: 340px) {
    width: 240px;
  }
`;

const PerkeurtContent = styled.div`
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 780px) {
    width: 320px;
  }

  @media (max-width: 340px) {
    width: 240px;
  }
`;

type ParagraphProps = {
  area: string;
  textAlign: "justify" | "center";
  fontSize?: string;
};
const Pragraph = styled.p<ParagraphProps>`
  grid-area: ${({ area }) => area};

  color: ${(props) => props.theme.pallete.primary.main};
  //font-size: 10px;
  font-size: ${(props) => props.fontSize ?? "10px"};
  font-family: ${(props) => props.theme.global.fontFamily};
  text-align: ${({ textAlign }) => textAlign};
`;

// const BigInput = styled.input.attrs(() => ({type: IS_LOCAL=="true"?"password":"text"}))`
const BigInput = styled.input.attrs(() => ({ type: "password" }))`
  grid-area: twofactor;
  justify-self: center;
  font-family: ${(props) => props.theme.global.fontFamily};
  width: 70%;
  line-height: 45px;
  padding: 4px 12px;
  font-size: 42px;
  letter-spacing: 4px;
  color: ${(props) => props.theme.pallete.neutral.main};
  background-color: white;
  border: 1px solid ${(props) => props.theme.pallete.neutral.light};
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.pallete.primary.light};
    caret-color: ${(props) => props.theme.pallete.primary.light};
    color: ${(props) => props.theme.pallete.primary.light};
  }
`;

export const colors = () =>
  ({
    white: "white",
    light: "light",
    main: "main",
    dark: "dark",
    success: "success",
    error: "error",
    secondary: "secondary",
    gradient: "gradient",
    inactive: "inactive",
    disabled: "disabled",
    black: "black",
    neutral: "neutral",
    darkgray: "darkgray",
  } as const);
type Color = keyof ReturnType<typeof colors>;

export type SDialogControlProps = {
  /*
   * Color of the message accent and the button.
   */
  color?: Color;
  /**
   * If true, the dialog is hidden.
   */
  hide?: boolean;
  /**
   * Fired when the user clicks the close button, or outside of the dialog area.
   */
  onClose: () => void;
  /**
   * Fired when the user hits the confirmation button. The pin is the typed
   * two-factors authentication code.
   */
  onConfirm: (pin: string) => void;
  /**
   * Fired when the user hits the recovering button. A new pin will be sent to
   * the user's email.
   */
  onRecover: () => void;
  /**
   * In order to the user review what will be sent over, the configuration
   * is sent here. Name is the user-friendly name of each variable,
   * while value is the value of the variable.
   */
  valuesReview?: ReviewItem[];
  /**
   * A chart to be reviewed by the user, in order to confirm that the
   * configuration is as intended.
   */
  chartReview?: ChartReviewItem;
  /**
   * If the user is a developer, naturally, the user would want to check the
   * inner variable name of each parameter. Think of this as a debug mode.
   */
  isDev?: boolean;

  perkeurtReview?: PerkeurtReviewItem;
};

/**
 * Dialog for confirmation of control to send to the user.
 */
export const SDialogControl: React.FC<SDialogControlProps> = ({
  color,
  hide,
  onConfirm,
  // onRecover,
  onClose,
  valuesReview,
  chartReview,
  perkeurtReview,
}: SDialogControlProps) => {
  const [visible, setVisible] = useState(!hide);

  const [pin, setPin] = useState("");
  // const [askedNewCode, setAskedNewCode] = useState(false);
  const isStale = useMemo(
    () => valuesReview?.every((value) => !value.updated) && !chartReview && !perkeurtReview?.updated,
    [chartReview, valuesReview, perkeurtReview]
  );

  useLayoutEffect(() => {
    setVisible(!hide);
  }, [hide]);

  return (
    <Dialog
      hide={!visible}
      onClose={() => {
        onClose();
        setPin("");
        // setAskedNewCode(false);
      }}
      template="control"
    >
      <Title area="message" size="large" value="Confirmação de" />
      <Title area="operation" size="large" color={color} value="Envio" />

      {/* {IS_LOCAL == "false" && (<Pragraph area="information" textAlign="justify">
        Use o códido de autenticação no app{' '}
        <strong>Google Authenticator</strong> em seu celular.
        <br />
        <br />
        Se não possui mais o celular habilitado, escolha &ldquo;Recuperar Código
        de Autenticação&rdquo; abaixo e siga as instruções no seu{' '}
        <strong>email</strong> para recuperar o código no{' '}
        <strong>Google Authenticator</strong>.
        {askedNewCode && (
          <>
            <br />
            <br />
            <h2>Instruções enviadas para seu email.</h2>
          </>
        )}
      </Pragraph>)} */}
      {
        /*IS_LOCAL == "true" &&*/ <Pragraph fontSize="30px" area="information" textAlign="center">
          <strong>Insira sua senha</strong>
        </Pragraph>
      }
      <BigInput
        value={pin}
        disabled={isStale}
        onChange={({ currentTarget: { value } }) => {
          //if (/^[0-9]*$/.test(value)) {
          setPin(value /*.slice(0, 6)*/);
          //}
        }}
        placeholder=""
      />
      <Button
        area="button-left"
        color={color}
        size="dialog"
        disabled={isStale}
        onClick={() => {
          if (pin.length > 0) {
            onConfirm(pin);
            onClose();
            setPin("");
            // setAskedNewCode(false);
          }
        }}
      >
        <Title area="unset" size="medium" color="white" value="Confirmar" />
      </Button>
      <Button
        area="button-right"
        color="white"
        size="dialog"
        onClick={() => {
          onClose();
          setPin("");
          // setAskedNewCode(false);
        }}
      >
        <Title area="unset" size="medium" color="main" value="Cancelar" />
      </Button>
      {/* {!isStale && IS_LOCAL=="false" && (
        <Title
          bold
          area="button-bottom"
          size="xsmall"
          color="dark"
          value="Recuperar Código de Autenticação"
          padAll={{ top: "large", bottom: "large" }}
          onClick={() => {
            setAskedNewCode(true);
            onRecover();
          }}
        />
      )}
      <Title
        area="warning"
        txtalign="center"
        size="small"
        color={isStale ? "error" : "dark"}
        value={
          isStale
            ? "Nenhum parâmetro mudou de valor"
            : chartReview
            ? chartReview.operationMessage
            : "Confira os valores dos parâmetros configurados"
        }
        padAll={{ bottom: "medium" }}
        bold
      />
      <Review>
        <Scrollable area="unset" size="large">
          <ReviewContent>
            {valuesReview &&
              valuesReview.map(({ key, value, name, updated }) => (
                <CardContent
                  key={key}
                  area="unset"
                  template="inline"
                  padAll={{ bottom: "small" }}
                  halign="center"
                  valign="center"
                >
                  <Title
                    size="small"
                    color={updated ? "main" : "disabled"}
                    value={name}
                    padAll={{ right: "small" }}
                    bold
                  />
                  {typeof value === "number" ? (
                    <Quantity
                      value={value}
                      unit="dimensionless"
                      size="small"
                      color={updated ? "light" : "disabled"}
                      bold

      )} */}
      {
        <>
          <Title
            area="warning"
            txtalign="center"
            size="small"
            color={isStale ? "error" : "dark"}
            value={
              isStale
                ? "Nenhum parâmetro mudou de valor"
                : chartReview
                ? chartReview.operationMessage
                : "Confira os valores dos parâmetros configurados"
            }
            padAll={{ bottom: "medium" }}
            bold
          />
          <Review>
            <Scrollable area="unset" size="large">
              <ReviewContent>
                {valuesReview &&
                  valuesReview.map(({ key, value, name, updated }) => (
                    <CardContent
                      key={key}
                      area="unset"
                      template="inline"
                      padAll={{ bottom: "small" }}
                      halign="center"
                      valign="center"
                    >
                      <Title
                        size="small"
                        color={updated ? "main" : "disabled"}
                        value={name}
                        padAll={{ right: "small" }}
                        bold
                      />
                      {typeof value === "number" ? (
                        <Quantity
                          value={value}
                          unit="dimensionless"
                          size="small"
                          color={updated ? "light" : "disabled"}
                          bold
                        />
                      ) : typeof value === "boolean" ? (
                        <Title
                          area="quantity"
                          size="small"
                          color={updated ? "light" : "disabled"}
                          value={value ? "Ativo" : "Inativo"}
                          bold
                        />
                      ) : (
                        <Title area="quantity" size="small" color={updated ? "light" : "disabled"} value={value} bold />
                      )}
                    </CardContent>
                  ))}

                <ChartContent>
                  {chartReview && (
                    <SChart
                      shoudPlotBeStatic={true}
                      menu={[]}
                      layout={{
                        title: chartReview.new.title,
                        type: chartReview.new.type,
                        axis: chartReview.new.axis,
                        review: true,
                      }}
                    />
                  )}
                </ChartContent>
                <ChartContent>
                  {chartReview && chartReview.previous && (
                    <SChart
                      shoudPlotBeStatic={true}
                      menu={[]}
                      layout={{
                        title: chartReview.previous.title,
                        type: chartReview.previous.type,
                        axis: chartReview.previous.axis,
                        review: true,
                      }}
                    />
                  )}
                </ChartContent>
                <PerkeurtContent>
                  {perkeurtReview && (
                    <div className="perkeurt" style={{ width: "900px" }}>
                      <SPeukert title={""} value={perkeurtReview.new} onChange={() => {}} text={""} disabled />
                    </div>
                  )}
                </PerkeurtContent>
                <PerkeurtContent>
                  {perkeurtReview && perkeurtReview.previous && (
                    <SPeukert title={""} value={perkeurtReview.previous} onChange={() => {}} text={""} disabled />
                  )}
                </PerkeurtContent>
              </ReviewContent>
            </Scrollable>
          </Review>
        </>
      }
    </Dialog>
  );
};
