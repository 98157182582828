/**
 * Pagination information to build pages.
 */
export type PaginationInfo = {
  /**
   * Total range of pages available, shows the last and first pages.
   */
  pageRange: {
    firstPage: number;
    lastPage: number;
  };
  /**
   * Information about the current page, such as visible elements
   * and the current page's index
   */
  current: {
    pageIndex: number;
    /**  Inclusive range. */
    fromElement: number;
    /**  Inclusive range. */
    toElement: number;
  };
  /**
   * Controls to show to the user, including "show last page",
   * "show first page", "next page", "previous page", also,
   * this includes the range of page buttons to show to the user
   * (showPagesFrom, showPagesTo) and the total number of page buttons
   * to show.
   */
  controls: {
    /** Total number of pages to show*/
    totalPagesToShow: number;
    /** First page button to show. Inclusive range. */
    showPagesFrom: number;
    /** Last page button to show. Inclusive range. */
    showPagesTo: number;
    /** Show button "go to last page" */
    showLast: boolean;
    /** Show button "go to first page" */
    showFirst: boolean;
    /** Show button "go to next page" */
    showNext: boolean;
    /** Show button "go to previous page" */
    showPrev: boolean;
  };
};

/**
 * Calculates pagination information given some parameters.
 *
 * @param page Current page, defaults to 0.
 * @param showPages Number of pages to show at any given time to the
 * user. The user can click the button of such pages to go there
 * right away.
 * @param itemsPerPage Total items shown per page. A page will always
 * show at most this amount of elements.
 * @param totalItems The total number of items to show paginated. For
 * example, if you have 100 users to show in a table, that's 100
 * items.
 */
export function paginationFrom(
  page = 0,
  showPages: number,
  itemsPerPage: number,
  totalItems: number
): PaginationInfo {
  const lastPage = Math.ceil(totalItems / itemsPerPage) - 1;
  const currentPage = Math.max(Math.min(page, lastPage), 0);

  const from = currentPage * itemsPerPage;
  const to = Math.min((currentPage + 1) * itemsPerPage, totalItems) - 1;

  const pagesLeft = Math.floor((showPages - 1) / 2);
  const showPagesFrom = currentPage - pagesLeft;
  const showPagesTo = currentPage + (showPages - pagesLeft - 1);

  let error = 0;
  if (showPagesFrom < 0) error = showPagesFrom;
  if (showPagesTo > lastPage) error = showPagesTo - lastPage;

  return {
    pageRange: {
      firstPage: 0,
      lastPage: Math.max(lastPage, 0) /** Zero if negative */,
    },
    current: {
      pageIndex: currentPage,
      fromElement: from,
      toElement: Math.max(to, 0),
    },
    controls: {
      totalPagesToShow: Math.max(Math.min(showPages, lastPage + 1), 1),
      showPagesFrom: Math.max(showPagesFrom - error, 0),
      showPagesTo: Math.max(Math.min(showPagesTo - error, lastPage), 0),
      /**
       * button goToLast and goToFirst only displayed if at least showPages
       * pages apart from the last or the first, respectively.
       **/
      showLast: currentPage < lastPage - (showPages - pagesLeft - 1),
      showFirst: currentPage > pagesLeft,
      showNext: currentPage < lastPage,
      showPrev: currentPage > 0,
    },
  };
}
