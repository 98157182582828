import React from 'react';
import styled from 'styled-components';

import { SingleCard } from '../../components/SingleCard';
import { CardContent } from '../../components/CardContent';
import { Icon, IconProps } from '../../components/Icons';
import { Title } from '../../components/Title';
import { Directional, LineAlign, ItemAlign, Cross } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

type StyledContainerProps = {
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
};
const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-area: ${({ area }) => area};

  grid-auto-flow: row;

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-self: ${(props) => props.salign?.horizontal};
  align-self: ${(props) => props.salign?.vertical};
`;

const StyledDivider = styled.div`
  display: grid;
  border-bottom: '1px' solid ${(props) => props.theme.pallete.neutral.lighter};

  width: 80%;
  height: 2px;

  align-self: center;
  justify-self: center;
`;

export type SVerticalStatusCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * CSS horizontal and vertical self-aligment of component.
   */
  salign?: { horizontal: LineAlign; vertical: ItemAlign };
  /**
   * Top status card props
   */
  top: {
    /**
     * Title text value for the status card.
     */
    title: string;
    /**
     * All possible states that the status card can assume.
     */
    states: string[];
    /**
     * Current state of the status card. It must be positive and less than the
     * number of possible states.
     */
    currentState: number;
    /**
     * Icon of the status card. It can be ommited (There is StoryBook issue
     * regarding importing types from other files. This may look like a
     * simple textBox, but you can see the possible Icons on Docs).
     */
    icon?: IconProps['name'];
  };
  /**
   * Bottom status card props
   */
  bottom?: {
    /**
     * Title text value for the status card.
     */
    title: string;
    /**
     * All possible states that the status card can assume.
     */
    states: string[];
    /**
     * Current state of the status card. It must be positive and less than the
     * number of possible states.
     */
    currentState: number;
    /**
     * Icon of the status card. It can be ommited (There is StoryBook issue
     * regarding importing types from other files. This may look like a
     * simple textBox, but you can see the possible Icons on Docs).
     */
    icon?: IconProps['name'];
  };
};

/**
 * Resumed version of the SingleCard for Cards with a status value.
 */
export const SVerticalStatusCard: React.FC<SVerticalStatusCardProps> = ({
  area = 'unset',
  salign,
  pad,
  padAll,
  padCross,
  halign,
  valign,
  top: {
    title: titleTop = '',
    states: statesTop = ['Padrão'],
    currentState: currentStateTop = 0,
    icon: iconTop,
  },
  // bottom: {
  //   title: titleBottom = '',
  //   states: statesBottom = ['Padrão'],
  //   currentState: currentStateBottom = 0,
  //   icon: iconBottom,
  // },
}: SVerticalStatusCardProps) => {
  return (
    <StyledContainer
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      salign={salign}
    >
      <SingleCard area="unset" color="white" valign="center" halign="start">
        <CardContent
          valign="center"
          halign="center"
          padAll={{ left: 'medium' }}
        >
          {iconTop && (
            <Icon
              name={iconTop}
              size="medium"
              color="main"
              valign="center"
              padAll={{ right: 'small' }}
            />
          )}
          <Title value={titleTop} size="xsmall" color="main" halign="start" />
          <Title
            value={statesTop[currentStateTop]}
            area="quantity"
            size="medium"
            color="light"
            valign="baseline"
          />
        </CardContent>
      </SingleCard>
      <StyledDivider />
      {/* <SingleCard area="unset" color="white" valign="center" halign="start">
        <CardContent
          valign="center"
          halign="center"
          padAll={{ left: 'medium' }}
        >
          {iconBottom && (
            <Icon
              name={iconBottom}
              size="medium"
              color="main"
              valign="center"
              padAll={{ right: 'small' }}
            />
          )}
          <Title
            value={titleBottom}
            size="xsmall"
            color="main"
            halign="start"
          />
          <Title
            value={statesBottom[currentStateBottom]}
            area="quantity"
            size="medium"
            color="light"
            valign="baseline"
          />
        </CardContent>
      </SingleCard> */}
    </StyledContainer>
  );
};
