import React from 'react';
import { BStringCell, BStringCellProps } from '../../components/BStringCell';
import { CardContent } from '../../components/CardContent';
import { Quantity } from '../../components/Quantity';
import { Title } from '../../components/Title';

export interface SBStringCellProps {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Color of the cell.
   */
  color?: BStringCellProps['color'];
  /**
   * Voltage of the cell.
   */
  voltage: number;
  /**
   * Temperature of the cell.
   */
  temperature: number;
  /**
   * If true only shows a white card withou content.
   */
  content?: boolean;
  /**
   * Id of the cell
   */
   id: string;
}

/**
 * String Cell of BMS, content cards with title, quantity and another quantity.
 *
 * ```tsx
 *  <SBStringCell color="main" voltage={1.16} temperature={40.0} />
 * ```
 */
export const SBStringCell: React.FC<SBStringCellProps> = ({
  area = 'unset',
  color = 'main',
  content = true,
  voltage,
  temperature,
  id
}: SBStringCellProps) => {
  return (
    <BStringCell area={area} color={color} valign="center" halign="center">
      {content && (
        <CardContent
          halign="center"
          valign="center"
          salign={{ horizontal: 'center', vertical: 'center' }}
        >
          <Title
            area="title"
            value={id}
            size="xsmall"
            color="white"
            halign="center"
            valign="baseline"
            bold
          />
          <Quantity
            area="subtitle"
            value={voltage!=0?voltage.toString():"-"}
            unit={voltage!=0?"voltage":"dimensionless"}
            size="xsmall"
            color="white"
            halign="center"
            valign="baseline"
            minDecimals={2}
            bold
          />
          <Quantity
            value={temperature!=0?temperature:"-"}
            unit={temperature!=0?"temperature":"dimensionless"}
            size="xsmall"
            color="light"
            minDecimals={1}
            halign="center"
            valign="baseline"
            bold
          />
        </CardContent>
      )}
    </BStringCell>
  );
};
