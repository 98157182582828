import React from 'react';
import styled from 'styled-components';

type StyledWeekDayProps = {
  selected: boolean;
  selectable: boolean;
};
const StyledWeekDay = styled.span<StyledWeekDayProps>`
  cursor: ${(props) => (props.selectable ? 'pointer' : 'default')};
  position: relative;
  border: 0;
  width: 28px;
  height: 13px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: transparent;
  color: ${(props) =>
    props.selected ? 'white' : props.theme.pallete.primary.light};
  font-family: ${(props) => props.theme.global.fontFamily};
  text-align: center;
  font-size: 10px;
  user-select: none;
  font-weight: bold;
  background-color: ${(props) => (props.selected ? '#171586' : 'none')};
  background-position: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  font-weight: ${(props) => (props.selected ? 'bold' : 'none')};
`;

export type SCalendarWeekDayCellProps = {
  /**
   * Label corresponding to this day of the week
   */
  label: string;
  /**
   * First day of the week of the current month corresponding to this day
   */
  dayOfTheWeek: Date;
  /**
   * Whether this week day is selected
   */
  isSelected?: boolean;
  /**
   * Whether this component is selectable
   */
  isSelectable?: boolean;
  /*
   * Action when receiving clicks. The day argument is the first day of the
   * month that has the desired week day.
   */
  onClick?: (day: Date) => void;
};

/*
 * This component is the day of the week. That can be selected in the calendar.
 */
export const SCalendarWeekDayCell: React.FC<SCalendarWeekDayCellProps> = ({
  label,
  dayOfTheWeek,
  isSelectable = false,
  isSelected = false,
  onClick,
}: SCalendarWeekDayCellProps) => {
  return (
    <StyledWeekDay
      onClick={() => {
        if (isSelectable && onClick) onClick(dayOfTheWeek);
      }}
      selected={isSelectable && isSelected === true}
      selectable={isSelectable}
    >
      {label}
    </StyledWeekDay>
  );
};
