import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Thermometer: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26997 1.465C3.73711 1.12852 4.35131 0.99469 4.98333 0.99469C5.61535 0.99469 6.22956 1.12852 6.6967 1.465C7.18251 1.81493 7.46667 2.35443 7.46667 3.05586V12.4573C8.97673 13.3088 9.96667 14.9183 9.96667 16.7693C9.96667 19.5245 7.74591 21.7393 4.98333 21.7393C2.22075 21.7393 0 19.5245 0 16.7693C0 14.9183 0.989933 13.3088 2.5 12.4573V3.05586C2.5 2.35443 2.78416 1.81493 3.26997 1.465ZM3.73836 2.11181C3.47417 2.30211 3.3 2.59372 3.3 3.05586V12.6968C3.3 12.8479 3.2144 12.986 3.07889 13.0536C1.7127 13.7349 0.8 15.1354 0.8 16.7693C0.8 19.0838 2.66258 20.9415 4.98333 20.9415C7.30409 20.9415 9.16667 19.0838 9.16667 16.7693C9.16667 15.1354 8.25397 13.7349 6.88778 13.0536C6.75227 12.986 6.66667 12.8479 6.66667 12.6968V3.05586C6.66667 2.59372 6.49249 2.30211 6.2283 2.11181C5.94544 1.90807 5.51798 1.79256 4.98333 1.79256C4.44869 1.79256 4.02123 1.90807 3.73836 2.11181Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.13368C2.5 4.91335 2.67909 4.73474 2.9 4.73474H7.06666C7.28758 4.73474 7.46666 4.91335 7.46666 5.13368C7.46666 5.354 7.28758 5.53261 7.06666 5.53261H2.9C2.67909 5.53261 2.5 5.354 2.5 5.13368Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 5.13368C6.66602 4.91335 6.8451 4.73474 7.06602 4.73474H8.73268C8.9536 4.73474 9.13268 4.91335 9.13268 5.13368C9.13268 5.354 8.9536 5.53261 8.73268 5.53261H7.06602C6.8451 5.53261 6.66602 5.354 6.66602 5.13368Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 6.79585C6.66602 6.57552 6.8451 6.39691 7.06602 6.39691H8.73268C8.9536 6.39691 9.13268 6.57552 9.13268 6.79585C9.13268 7.01617 8.9536 7.19478 8.73268 7.19478H7.06602C6.8451 7.19478 6.66602 7.01617 6.66602 6.79585Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 8.45808C6.66602 8.23775 6.8451 8.05914 7.06602 8.05914H8.73268C8.9536 8.05914 9.13268 8.23775 9.13268 8.45808C9.13268 8.6784 8.9536 8.85701 8.73268 8.85701H7.06602C6.8451 8.85701 6.66602 8.6784 6.66602 8.45808Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 10.1204C6.66602 9.90005 6.8451 9.72144 7.06602 9.72144H8.73268C8.9536 9.72144 9.13268 9.90005 9.13268 10.1204C9.13268 10.3407 8.9536 10.5193 8.73268 10.5193H7.06602C6.8451 10.5193 6.66602 10.3407 6.66602 10.1204Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66602 11.7826C6.66602 11.5623 6.8451 11.3837 7.06602 11.3837H8.73268C8.9536 11.3837 9.13268 11.5623 9.13268 11.7826C9.13268 12.0029 8.9536 12.1815 8.73268 12.1815H7.06602C6.8451 12.1815 6.66602 12.0029 6.66602 11.7826Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
