import React from 'react';

import { TripleVerticalCard } from '../../components/TripleVerticalCard';
import { Icon } from '../../components/Icons';
import { HeaderLike, HeaderLikeProps } from '../../components/HeaderLike';
import { CardContent } from '../../components/CardContent';

import { Title } from '../../components/Title';

export type SThreeSwitchCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Title text value for the status card.
   */
  title: string;
  /**
   * Current state value for each line of the card.
   */
  currentStates: [boolean, boolean, boolean];
  /**
   * Possible state values of the card for the switches on and off.
   */
  states: {
    on: string;
    off: string;
  };
  /**
   * Color of the card.
   */
  color?: HeaderLikeProps['color'];
};

/**
 * A 1x3 card for displaying values measured of three different phases.
 */
export const SThreeSwitchCard: React.FC<SThreeSwitchCardProps> = ({
  title,
  area = 'unset',
  currentStates,
  states,
  color,
}: SThreeSwitchCardProps) => {
  return (
    <TripleVerticalCard area={area} valign="center" halign="center">
      <HeaderLike color={color} valign="center" halign="center">
        <CardContent
          halign="center"
          valign="center"
          salign={{ horizontal: 'center', vertical: 'center' }}
        >
          <Title
            value={title}
            size="small"
            color="white"
            halign="center"
            valign="center"
            bold
          />
        </CardContent>
      </HeaderLike>
      <CardContent area="content-top" halign="space-around">
        <Icon
          name="Number1"
          size="small"
          color="light"
          halign="start"
          valign="center"
        />
        <Title
          value={currentStates[0] ? states.on : states.off}
          size="small"
          color="light"
          bold
          halign="end"
          valign="center"
        />
      </CardContent>
      <CardContent area="content-middle" halign="space-around">
        <Icon
          name="Number2"
          size="small"
          color="dark"
          halign="start"
          valign="center"
        />
        <Title
          value={currentStates[1] ? states.on : states.off}
          size="small"
          color="dark"
          bold
          halign="end"
          valign="center"
        />
      </CardContent>
      <CardContent area="content-bottom" halign="space-around">
        <Icon
          name="Number3"
          size="small"
          color="main"
          halign="start"
          valign="center"
        />
        <Title
          value={currentStates[2] ? states.on : states.off}
          size="small"
          color="main"
          bold
          halign="end"
          valign="center"
        />
      </CardContent>
    </TripleVerticalCard>
  );
};
