import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { Spinner } from '../../components/Spinner';

const BodyStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.pallete.darkMode.main};
  }
`;

/**
 * Loading Page
 */
export const ViewLoading: React.FC = () => {
  return (
    <>
      <BodyStyle />
      <Spinner color='white' background='dark' valign='center' halign='center' />
    </>
  );
};
