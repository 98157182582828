import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';

const pads = {
  none: '0px',
  xsmall: '5px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const round = {
  none: '0px',
  diagonal1: '10px 0px 0px 0px',
  diagonal2: '0px 10px 0px 0px',
  diagonal3: '0px 0px 10px 0px',
  diagonal4: '0px 0px 0px 10px',
  small: '4px',
  medium: '10px',
  large: '20px',
} as const;
type Radius = keyof typeof round;

const bord = {
  top: '1px solid black',
  none: 'none',
} as const;
type Borders = keyof typeof bord;

export const colors = (theme: DefaultTheme) =>
  ({
    light: css`
      background-color: ${theme.pallete.primary.light};
    `,
    main: css`
      background-color: ${theme.pallete.primary.main};
    `,
    black: css`
      background-color: black;
    `,
    white: css`
      background-color: white;
    `,
    grey: css`
      background-color: #d3d3d3;
    `,

    dark: css`
      background-color: ${theme.pallete.border.main};
    `,
    gradient: css`
      background-image: linear-gradient(
        172.14deg,
        ${theme.pallete.accent.main} 12.49%,
        ${theme.pallete.accent.dark} 198.93%
      );
    `,
  } as const);
type Color = keyof ReturnType<typeof colors>;

type StyledSinglePeukertProps = {
  color: Color;
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: LineAlign;
  radius?: string;
  bordert?: string;
  borderb?: string;
};
const StyledSinglePeukert = styled.div<StyledSinglePeukertProps>`
  display: grid;
  grid-template:
    'cardcontent' 1fr /
    auto;

  ${(props) => colors(props.theme)[props.color]};
  box-shadow: rgba(77, 124, 254, 0.08) 0em 0.4375em 0.625em;
  border-radius: ${(props) => props.radius};

  width: 86px;
  height: 38px;

  grid-area: ${(props) => props.area};
  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'start'};
  align-items: ${(props) => props?.valign ?? 'stretch'};
  justify-self: ${(props) => props.salign ?? 'center'};
  border-top: ${(props) => props.bordert ?? 'none'};
  border-bottom: ${(props) => props.borderb ?? 'none'};
`;

export type SinglePeukertProps = {
  children?: React.ReactNode;
  /**
   * Background color of the card.
   */
  color?: Color;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "SinglePeukert".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  salign?: LineAlign;

  radius?: Radius;

  bordert?: Borders;
  borderb?: Borders;
};

/**
 * This "single" card container can hold one card content, centralized. The usage
 * of this component, albeit a bit verbose, but highly readable will be:
 *
 * ```tsx
 *   <SinglePeukert color="light">
 *    <CardContent>
 *      <Title value="Corrente do PCS" />
 *      <Icon name="BoltCCircle" size="small" color="white" />
 *      <Quantity value={12.4} unit="current" color="white" size="large" />
 *    </CardContent>
 * </SinglePeukert>
 * ```
 */

export const SinglePeukert: React.FC<SinglePeukertProps> = ({
  color = 'main',
  area = 'SinglePeukert',
  radius = 'medium',
  bordert = 'none',
  borderb = 'none',
  ...props
}: SinglePeukertProps) => {
  const { children, pad, padAll, padCross, halign, valign, salign } = props;
  return (
    <StyledSinglePeukert
      color={color}
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
      salign={salign}
      radius={round[radius]}
      bordert={bord[bordert]}
      borderb={bord[borderb]}
    >
      {children}
    </StyledSinglePeukert>
  );
};
