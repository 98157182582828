import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowDownThin: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 3.33502L6.82268 0.51234C7.09201 0.243013 7.52868 0.243013 7.798 0.51234C8.06733 0.781667 8.06733 1.21833 7.798 1.48766L4.48766 4.798C4.21833 5.06733 3.78167 5.06733 3.51234 4.798L0.201995 1.48766C-0.0673319 1.21833 -0.067332 0.781667 0.201995 0.51234C0.471322 0.243013 0.907988 0.243013 1.17731 0.51234L4 3.33502Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
