import React from 'react';

import { TripleVerticalCard } from '../../components/TripleVerticalCard';
import { Icon } from '../../components/Icons';
import { HeaderLike, HeaderLikeProps } from '../../components/HeaderLike';
import { CardContent } from '../../components/CardContent';
import { Quantity } from '../../components/Quantity';

import { Units } from '../../scripts/types';
import { Title } from '../../components/Title';

export type SThreePhaseCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Title text value for the status card.
   */
  title: string;
  /**
   * Value for each phase of the card.
   */
  values: [number, number, number];
  /**
   * Unit of all three phases, this card doesn't support multiple different
   * units as for now.
   */
  unit: Units;
  /**
   * Exact number of decimal places for all three phases.
   */
  decimals?: number;
  /**
   * Color of the card.
   */
  color?: HeaderLikeProps['color'];
};

/**
 * A 1x3 card for displaying values measured of three different phases.
 */
export const SThreePhaseCard: React.FC<SThreePhaseCardProps> = ({
  title,
  values = [0, 0, 0],
  decimals = 2,
  unit,
  area = 'unset',
  color,
}: SThreePhaseCardProps) => {
  return (
    <TripleVerticalCard area={area} valign="center" halign="center">
      <HeaderLike color={color} valign="center" halign="center">
        <CardContent
          halign="center"
          valign="center"
          salign={{ horizontal: 'center', vertical: 'center' }}
        >
          <Title
            value={title}
            size="small"
            color="white"
            halign="center"
            valign="center"
            bold
          />
        </CardContent>
      </HeaderLike>
      <CardContent area="content-top" halign="space-evenly">
        <Icon
          name="PhaseA"
          size="small"
          color="light"
          halign="center"
          valign="center"
        />
        <Quantity
          value={values[0]}
          unit={unit}
          minDecimals={decimals}
          maxDecimals={decimals}
          size="small"
          color="light"
          bold
          halign="end"
          valign="center"
        />
      </CardContent>
      <CardContent area="content-middle" halign="space-evenly">
        <Icon
          name="PhaseB"
          size="small"
          color="dark"
          halign="center"
          valign="center"
        />
        <Quantity
          value={values[1]}
          unit={unit}
          minDecimals={decimals}
          maxDecimals={decimals}
          size="small"
          color="dark"
          bold
          halign="end"
          valign="center"
        />
      </CardContent>
      <CardContent area="content-bottom" halign="space-evenly">
        <Icon
          name="PhaseC"
          size="small"
          color="main"
          halign="center"
          valign="center"
        />
        <Quantity
          value={values[2]}
          unit={unit}
          minDecimals={decimals}
          maxDecimals={decimals}
          size="small"
          color="main"
          bold
          halign="end"
          valign="center"
        />
      </CardContent>
    </TripleVerticalCard>
  );
};
