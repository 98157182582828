import React, { useEffect, useState } from 'react';
import { FormField } from '../../components/FormField';
import { Colors } from '../../components/FormField/FormField';
import { Input } from '../../components/Input';
import { Size } from '../../components/Input/Input';
import { ItemAlign, LineAlign } from '../../scripts/types';

export type SNumericInputProps = {
  /**
   * Grid area of the component
   */
  area?: string;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Label of the dropdown when the user isn't over it
   */
  label: string;
  /**
   * Controlled input value
   */
  value: number;
  /**
   * Size of the Input. Default is medium.
   */
  size?: Size;
  /** Color of the label. Default is Black */
  color?: Colors;
  /**
   * Whether or not the input is disabled, as of css.
   */
  disabled?: boolean;
  /**
   * Triggered when the user types anything on the input
   */
  onChange: (value: number) => void;
};

/**
 * Field for numeric input of a single value for control pages.
 */
export const SNumericInput: React.FC<SNumericInputProps> = ({
  label,
  value,
  onChange,
  halign,
  valign,
  area = 'unset',
  size = 'medium',
  color,
  disabled = false,
}: SNumericInputProps) => {
  /**
   * Since state will accept intermediate values, we will treat it as a text,
   * however, once the user no longer is active in that button, for example,
   * clicking on send, it will collapse to a number. If the number is NaN, then
   * it will be 0. This demands a confirmation fron the user if every value is
   * indeed ok.
   */
  const [state, setState] = useState('');

  useEffect(() => setState(value?.toString()), [value]);

  return (
    <FormField
      area={area}
      label={label}
      color={color}
      halign={halign}
      valign={valign}
      disabled={disabled}
      bold
    >
      <Input
        value={state}
        onChange={({ currentTarget: { value: newValue } }) => {
          /**
           * Let pass not only numbers, but some intermediate states, for the
           * user hapiness.
           */
          if (/^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(newValue)) {
            setState(newValue);
          }
        }}
        onBlur={() => {
          const intermediate = parseFloat(state); // Parse to number as float
          const decimal = isNaN(intermediate) ? 0 : intermediate; // Ignore NaN
          setState(decimal?.toString()); // For the user see
          onChange(decimal); // This is the one that will be sent over
        }}
        placeholder="0"
        size={size}
        disabled={disabled}
      />
    </FormField>
  );
};
