import { useEffect } from "preact/hooks";
import React, { useState } from "react";
import styled, { css, StyledProps } from "styled-components";
import { CardContent } from "../../components";
import { Colors } from "../../components/FormField/FormField";
import { Input } from "../../components/Input/Input";
import { SinglePeukert } from "../../components/SinglePeukert";
import { Sizes, Title } from "../../components/Title/Title";
import { solve } from "../../scripts/common";
import { useMedia } from "../../scripts/hooks/mediaQueryHook";

import { Cross, Directional, ItemAlign, LineAlign } from "../../scripts/types";

const pads = {
  none: "0px",
  xsmall: "1px",
  small: "10px",
  medium: "20px",
  large: "30px",
  xlarge: "50px",
} as const;

type Pad = keyof typeof pads;

const templates = {
  default: css`
    display: grid;
    justify-content: center;

    grid-template:
      "card a0 a1 a2 a3 a4 a5 a6 a7 a8  a9" max-content
      "card2 a10 a11 a12 a13 a14 a15 a16 a17 a18 a19" max-content/
      max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content;

    @media (max-width: 1000px) {
      grid-template:
        "card card2" max-content
        "a0 a10" max-content
        "a1 a11" max-content
        "a2 a12" max-content
        "a3 a13" max-content
        "a4 a14" max-content
        "a5 a15" max-content
        "a6 a16" max-content
        "a7 a17" max-content
        "a8 a18" max-content
        "a9 a19" max-content/
        max-content max-content;
    }
  `,
};
type Template = keyof typeof templates;

type StyledGridProps = {
  area: string;
  template: Template;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
  salign?: LineAlign;
  zalign?: ItemAlign;
};

type PeukertType = {
  Crate: number;
  I: number;
};

const StyledGrid = styled.div<StyledProps<StyledGridProps>>`
  display: grid;
  grid-area: ${(props) => props.area};
  ${(props) => templates[props.template]};

  padding-left: ${(props) => pads[props.pad.left ?? "none"]};
  padding-right: ${(props) => pads[props.pad.right ?? "none"]};
  padding-top: ${(props) => pads[props.pad.top ?? "none"]};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? "none"]};

  justify-content: ${(props) => props.halign ?? "start"};
  align-items: ${(props) => props?.valign ?? "stretch"};

  justify-self: ${(props) => props.salign ?? "center"};
  align-self: ${(props) => props.zalign ?? "center"};
`;

export type SPeukertProps = {
  children?: React.ReactNode;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Grid area of the component
   */
  area?: string;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Controlled input value
   */
  //value?: string | number;
  /**
   * Size of the Input. Default is medium.
   */
  size?: Sizes;
  /** Color of the label. Default is Black */
  color?: Colors;
  /**
   * Whether or not the input is disabled, as of css.
   */
  disabled?: boolean;
  /**
   * Triggered when the user types anything on the input
   */

  title: string;

  template?: Template;

  salign?: LineAlign;

  onChange: (value: Array<PeukertType>) => void;

  text: string;
  value: Array<PeukertType>;
};

const obj = [
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
  { Crate: 0, I: 0 },
];

export const SPeukert: React.FC<SPeukertProps> = ({
  area = "info",
  template = "default",
  children,
  pad,
  padCross,
  padAll,
  halign,
  valign,
  value = obj,
  salign,
  onChange,
  disabled=false
}: SPeukertProps) => {
  const [state, setState] = useState<Array<PeukertType>>(value);

  useEffect(() => {
    setState(value)
  }, [value]);

  const handleCheck = () => {
    setState(value);
    console.log(value);
  };

  const isMobile = useMedia("(max-width: 1000px)");
  return (
    <StyledGrid
      area={area}
      template={template}
      halign={halign}
      valign={valign}
      salign={salign}
      pad={solve(pad, padCross, padAll)}
    >
      {children}
      <SinglePeukert
        borderb={isMobile ? "none" : "top"}
        color="dark"
        radius="diagonal1"
        area="card"
      >
        <CardContent padAll={{ left: "small" }}>
          <Title value="C-rate(h)" size="small" bold color="white" />
        </CardContent>
      </SinglePeukert>
      <Input
        area="a0"
        size="xxsmall"
        value={state[0]? state[0].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            let aux = JSON.parse(JSON.stringify(state));
            aux[0].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />

      <Input
        area="a1"
        size="xxsmall"
        value={state[1]? state[1].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[1].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a2"
        size="xxsmall"
        value={state[2]? state[2].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[2].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a3"
        size="xxsmall"
        value={state[3]? state[3].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[3].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a4"
        size="xxsmall"
        value={state[4]? state[4].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[4].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a5"
        size="xxsmall"
        value={state[5]? state[5].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[5].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a6"
        size="xxsmall"
        value={state[6]? state[6].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[6].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a7"
        size="xxsmall"
        value={state[7]? state[7].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[7].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a8"
        size="xxsmall"
        value={state[8]? state[8].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[8].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a9"
        size="xxsmall"
        value={state[9]? state[9].Crate : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[9].Crate = Number(event.target.value);
            setState(aux);
          }
        }}
        radius={isMobile ? "diagonal4" : "diagonal2"}
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <SinglePeukert
        bordert={isMobile ? "none" : "top"}
        color="dark"
        radius={isMobile ? "diagonal2" : "diagonal4"}
        area="card2"
      >
        <CardContent>
          <Title
            value="I(A)"
            size="small"
            halign="end"
            valign="center"
            bold
            color="white"
          />
        </CardContent>
      </SinglePeukert>
      <Input
        area="a10"
        size="xxsmall"
        value={state[0]? state[0].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[0].I = Number(event.target.value);
            setState(aux);
            console.log(state[0].I);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a11"
        size="xxsmall"
        value={state[1]? state[1].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[1].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a12"
        size="xxsmall"
        value={state[2]? state[2].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[2].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a13"
        size="xxsmall"
        value={state[3]? state[3].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[3].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a14"
        size="xxsmall"
        value={state[4]? state[4].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[4].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a15"
        size="xxsmall"
        value={state[5]? state[5].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[5].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a16"
        size="xxsmall"
        value={state[6]? state[6].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[6].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a17"
        size="xxsmall"
        value={state[7]? state[7].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[7].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a18"
        size="xxsmall"
        value={state[8]? state[8].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[8].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="none"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
      <Input
        area="a19"
        size="xxsmall"
        value={state[9]? state[9].I : 0}
        onChange={(event) => {
          if (
            /^((-)|(-?[0-9]+((\.)|(\.[0-9]+))?))?$/.test(event.target.value)
          ) {
            /* eslint-disable */
            var aux = JSON.parse(JSON.stringify(state));
            aux[9].I = Number(event.target.value);
            setState(aux);
          }
        }}
        radius="diagonal3"
        onBlur={() => {
          handleCheck;
          onChange(state);
        }}
        disabled={disabled}
      />
    </StyledGrid>
  );
};
