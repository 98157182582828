import React from 'react';
import styled from 'styled-components';
import { Title, FormField, SlideButton } from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  @media (min-width: 1080px) {
    grid-template:
      'Title              Title              Title                      Title                ' 70px
      'SettingPcs         AutoSwitchMode     ModeChoice                 ModeOp               ' 70px
      'PpcsMax            SocMin             Cfactor                    VoltageMaxTotal      ' 70px
      'QpcsMax            SocMinOff          EffRecharge                VoltageMinTotal      ' 70px
      'SpcsMax            NominalEnergyBat   Pstep                      SocMax     ' 70px
      'Pauxs              PotIdeal           CellVoltageMaxLimit        Vequalization     ' 70px
      'TimeRefreshLogic   Vcharge            CellVoltageMinLimit        CellVoltageDeltaMaxLimit' 70px
      '.                  .                  .                          .                    ' 30px /
      260px 260px 260px 260px;
  }

  @media (max-width: 1080px) {
    grid-template:
      'Title              Title                 Title                   ' 70px
      'PpcsMax            SettingPcs            AutoSwitchMode          ' 70px
      'QpcsMax            ModeChoice            ModeOp                  ' 70px
      'SpcsMax            PotIdeal              CellVoltageMinLimit     ' 70px
      'Pauxs              Vcharge               CellVoltageDeltaMaxLimit' 70px
      'TimeRefreshLogic   Vequalization         VoltageMaxTotal         ' 70px
      'SocMax             Cfactor               VoltageMinTotal         ' 70px
      'SocMin             EffRecharge           NominalEnergyBat        ' 70px
      'SocMinOff          Pstep                 CellVoltageMaxLimit     ' 70px
      '.                  .                     .                       ' 30px
      '.                  .                     .                       ' /
      260px 260px 260px;
  }

  @media (max-width: 835px) {
    grid-template:
      'Title              Title                   ' 70px
      'PpcsMax            SettingPcs              ' 70px
      'QpcsMax            AutoSwitchMode          ' 70px
      'SpcsMax            ModeChoice              ' 70px
      'Pauxs              ModeOp                  ' 70px
      'TimeRefreshLogic   Pstep                   ' 70px
      'SocMax             CellVoltageMaxLimit     ' 70px
      'SocMin             CellVoltageMinLimit     ' 70px
      'SocMinOff          CellVoltageDeltaMaxLimit' 70px
      'NominalEnergyBat   VoltageMaxTotal         ' 70px
      'PotIdeal           VoltageMinTotal         ' 70px
      'Vcharge            Vequalization           ' 70px
      'Cfactor            EffRecharge             ' 70px
      '.                  .                       ' 30px /
      260px 260px;
    justify-content: center;
  }

  @media (max-width: 600px) {
    grid-template:
      'Title                   ' 70px
      'SettingPcs              ' 70px
      'AutoSwitchMode          ' 70px
      'ModeChoice              ' 70px
      'ModeOp                  ' 70px
      'PpcsMax                 ' 70px
      'QpcsMax                 ' 70px
      'SpcsMax                 ' 70px
      'Pauxs                   ' 70px
      'TimeRefreshLogic        ' 70px
      'SocMax                  ' 70px
      'SocMin                  ' 70px
      'SocMinOff               ' 70px
      'NominalEnergyBat        ' 70px
      'PotIdeal                ' 70px
      'Vcharge                 ' 70px
      'Vequalization           ' 70px
      'Cfactor                 ' 70px
      'EffRecharge             ' 70px
      'Pstep                   ' 70px
      'CellVoltageMaxLimit     ' 70px
      'CellVoltageMinLimit     ' 70px
      'CellVoltageDeltaMaxLimit' 70px
      'VoltageMaxTotal         ' 70px
      'VoltageMinTotal         ' 70px /
      260px;
    gap: 10px;
  }
`;

/**
 * Config Skeleton
 */
export const ConfigSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Title area="Title" />
      <SlideButton area="SettingPcs" />
      <SlideButton area="AutoSwitchMode" />
      <SlideButton area="ModeChoice" />
      <SlideButton area="ModeOp" />
      <FormField area="PpcsMax" />
      <FormField area="QpcsMax" />
      <FormField area="SpcsMax" />
      <FormField area="Pauxs" />
      <FormField area="TimeRefreshLogic" />
      <FormField area="SocMax" />
      <FormField area="SocMin" />
      <FormField area="SocMinOff" />
      <FormField area="NominalEnergyBat" />
      <FormField area="PotIdeal" />
      <FormField area="Vcharge" />
      <FormField area="Vequalization" />
      <FormField area="Cfactor" />
      <FormField area="EffRecharge" />
      <FormField area="Pstep" />
      <FormField area="CellVoltageMaxLimit" />
      <FormField area="CellVoltageMinLimit" />
      <FormField area="CellVoltageDeltaMaxLimit" />
      <FormField area="VoltageMaxTotal" />
      <FormField area="VoltageMinTotal" />
    </StyledContainer>
  );
};
