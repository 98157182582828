import React from 'react';
import styled from 'styled-components';

import { SSingleCard, SThreePhaseCard, Title } from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  gap: 10px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'Title         .              .       .      ' 60px
    'ActivePower   PowerFactor    Voltage Current' 60px
    'ReactivePower AccPeakTime    Voltage Current' 60px
    'ApparentPower AccOffPeakTime Voltage Current' 60px /
    210px 210px 210px 210px;

  @media (max-width: 900px) {
    grid-template:
      'Title         .              .      ' 60px
      'ActivePower   AccPeakTime    Voltage' 60px
      'ReactivePower AccOffPeakTime Voltage' 60px
      'ApparentPower .              Voltage' 60px
      'PowerFactor   .              Current' 60px
      '.             .              Current' 60px
      '.             .              Current' 60px /
      210px 210px 210px 210px;
  }

  @media (max-width: 690px) {
    grid-template:
      'Title          .      ' 60px
      'ActivePower    Voltage' 60px
      'ReactivePower  Voltage' 60px
      'ApparentPower  Voltage' 60px
      'PowerFactor    Current' 60px
      'AccPeakTime    Current' 60px
      'AccOffPeakTime Current' 60px /
      210px 210px;
  }

  @media (max-width: 480px) {
    grid-template:
      'Title          ' 60px
      'ActivePower    ' 60px
      'ReactivePower  ' 60px
      'ApparentPower  ' 60px
      'PowerFactor    ' 60px
      'AccPeakTime    ' 60px
      'AccOffPeakTime ' 60px
      'Voltage        ' 60px
      'Voltage        ' 60px
      'Voltage        ' 60px
      'Current        ' 60px
      'Current        ' 60px
      'Current        ' 60px /
      210px;
  }
`;

export const MetersSkeleton: React.FC = () => (
  <>
    <StyledContainer>
      <Title area="Title" marginTop />
      <SSingleCard area="ActivePower" />
      <SSingleCard area="PowerFactor" />
      <SThreePhaseCard area="Voltage" />
      <SThreePhaseCard area="Current" />
      <SSingleCard area="ReactivePower" />
      <SSingleCard area="ApparentPower" />
      <SSingleCard area="AccPeakTime" />
      <SSingleCard area="AccOffPeakTime" />
    </StyledContainer>
    <StyledContainer>
      <Title area="Title" marginTop />
      <SSingleCard area="ActivePower" />
      <SSingleCard area="PowerFactor" />
      <SThreePhaseCard area="Voltage" />
      <SThreePhaseCard area="Current" />
      <SSingleCard area="ReactivePower" />
      <SSingleCard area="ApparentPower" />
      <SSingleCard area="AccPeakTime" />
      <SSingleCard area="AccOffPeakTime" />
    </StyledContainer>
  </>
);
