import { AxiosInstance } from 'axios';
import urlcat from 'urlcat';
import { CountableList } from '../types';
import { Notification } from '../api/types';
import { Warnings } from '../../../models/Warnings';

/**
 * Returns a list of notifications from a given bess, that can be filtered
 * by read state of a specific user in a time interval.
 *
 * @param api Axios instance with correct headers.
 * @param unread If true, also show unread notifications, if false, it shows
 * them all.
 * @param bess The id of the bess to fetch the notifications from.
 * @param limit The number of notifications to be retorned.
 * @param offset The number of notifications to be skipped, counting from the
 * @param from Initial date range for the notifications
 * @param to Final date range for the notifications
 * more recent.
 */

export async function listNotifications(
  api: AxiosInstance,
  unread = false,
  bess: string,
  limit = 10,
  offset = 0,
  from?: Date,
  to?: Date
): Promise<CountableList<Notification>> {
  return await api
    .get<CountableList<Notification>>(
      urlcat('/besses/:bess/notifications', {
        bess,
        offset,
        limit,
        from,
        to,
        unread,
      })
    )
    .then((res) => res.data);
}

/**
 * Returns a list of notifications as the type Warning required for some views,
 * from a given bess, that can be filtered by read state of a specific user in
 * a time interval.
 *
 * @param api Axios instance with correct headers.
 * @param unread If true, also show unread notifications, if false, it shows
 * them all.
 * @param bess The id of the bess to fetch the notifications from.
 * @param limit The number of notifications to be retorned.
 * @param offset The number of notifications to be skipped, counting from the
 * @param from Initial date range for the notifications
 * @param to Final date range for the notifications
 * more recent.
 */
export async function listWarnings(
  api: AxiosInstance,
  unread = false,
  bess: string,
  limit = 10,
  offset = 0,
  from?: Date,
  to?: Date
): Promise<Warnings[]> {
  return await listNotifications(
    api,
    unread,
    bess,
    limit,
    offset,
    from,
    to
  ).then((res) =>
    res.items.map(({ createdAt, Code, Name, Message }) => ({
      Timestamp: createdAt,
      Data: { Code, Name, Message },
    }))
  );
}

/**
 * It marks the given notification as read.
 *
 * @param api Axios instance with correct headers.
 * @param id The id of the notification to be marked as read.
 */
export async function markNotificationAsRead(
  api: AxiosInstance,
  id: string,
  bess: string
): Promise<unknown> {
  return await api.post(
    urlcat('/besses/:bess/notifications/read/:id', { bess, id })
  );
}

/**
 * It marks all notifications as read.
 *
 * @param api Axios instance with correct headers.
 */
export async function markAllNotificationsAsRead(
  api: AxiosInstance,
  bess: string,
  from: Date,
  to: Date
): Promise<unknown> {
  return await api.post(
    urlcat('/besses/:bess/notifications/allread', { bess, from, to })
  );
}
