import React, { useLayoutEffect, useState } from 'react';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { Dialog } from '../Dialog';

const animations = {
  trash:
    'https://bess-static.s3.us-east-2.amazonaws.com/delete-confirmation_200.gif',
  signout: 'https://bess-static.s3.us-east-2.amazonaws.com/sign-out_200.gif',
  warning: 'https://bess-static.s3.us-east-2.amazonaws.com/warning-2_200.gif',
} as const;
type Animations = keyof typeof animations;

export const colors = () =>
  ({
    white: 'white',
    light: 'light',
    main: 'main',
    dark: 'dark',
    success: 'success',
    error: 'error',
    secondary: 'secondary',
    gradient: 'gradient',
    inactive: 'inactive',
    disabled: 'disabled',
    black: 'black',
    neutral: 'neutral',
    darkgray: 'darkgray',
  } as const);
type Color = keyof ReturnType<typeof colors>;

export type SDialogConfirmationProps = {
  /**
   * This is an animated gif, displayed in the dialog.
   */
  animation: Animations;
  /**
   * The message displayed. You can find it by looking for the text in black.
   */
  message: string;
  /**
   * Message that says which operation is being performed. It's the blueish
   * text.
   */
  operation: string;
  /**
   * Text of the buttons, default is "Confirmar" and "Cancelar".
   */
  options?: string[];
  /*
   * Color of the message accent and the button.
   */
  color?: Color;
  /**
   * If true, the dialog is hidden.
   */
  hide?: boolean;
  /**
   * Action activated on clicking one of the option buttons, where the argument
   * is the button clicked.
   */
  onChooseOption: (button: 'left' | 'right') => void;
  /**
   * Fired when the user clicks the close button, or outside of the dialog area.
   */
  onClose?: () => void;
};

/**
 * Confirmation dialog. For now, three action confirmations are supported:
 * delete action, warning action and logout action.
 */
export const SDialogConfirmation: React.FC<SDialogConfirmationProps> = ({
  hide = true,
  animation,
  message,
  operation,
  onChooseOption,
  onClose,
  options = ['Confirmar', 'Cancelar'],
  color = 'light',
}: SDialogConfirmationProps) => {
  const [visible, setVisible] = useState(!hide);

  useLayoutEffect(() => {
    setVisible(!hide);
  }, [hide]);

  return (
    <Dialog hide={!visible} onClose={onClose} template="confirmation">
      <img
        style={{ display: 'grid', gridArea: 'gif', justifySelf: 'center' }}
        width="75"
        height="75"
        src={animations[animation]}
        alt="gif"
      />
      <Title area="message" size="large" value={message} />
      <Title area="operation" size="large" color={color} value={operation} />
      <Button
        area="button-left"
        color={color}
        size="dialog"
        onClick={() => onChooseOption('left')}
      >
        <Title area="unset" size="medium" color="white" value={options[0]} />
      </Button>
      <Button
        area="button-right"
        color="white"
        size="dialog"
        onClick={() => onChooseOption('right')}
      >
        <Title area="unset" size="medium" color="main" value={options[1]} />
      </Button>
    </Dialog>
  );
};
