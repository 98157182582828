import React from 'react';
import styled from 'styled-components';

import { SSingleCard, SThreePhaseCard } from '../../Skeleton';

/**
 * Template layout of this page for each screen size supported
 */
const StyledContainer = styled.div`
  display: grid;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  @media (min-width: 900px) {
    grid-template:
      'Title           Title         Title        Title       ' 60px
      'ActivePower     ExecHour      RPM          FuelTank    ' 60px
      'ReactivePower   OutVoltage    OutCurrent   Frequency   ' 60px
      'ApparentPower   OutVoltage    OutCurrent   Frequency   ' 60px
      'PowerFactor     OutVoltage    OutCurrent   Frequency   ' 60px /
      210px 210px 210px 210px;
    gap: 10px;
  }

  @media (max-width: 899px) {
    grid-template:
      'Title           Title          Title        ' 60px
      'ActivePower     ExecHour       RPM          ' 60px
      'ReactivePower   ApparentPower  PowerFactor  ' 60px
      'FuelTank   .              .            ' 60px
      'OutVoltage      OutCurrent     Frequency    ' 60px
      'OutVoltage      OutCurrent     Frequency    ' 60px
      'OutVoltage      OutCurrent     Frequency    ' 60px /
      210px 210px 210px;
    gap: 10px;
  }

  @media (max-width: 690px) {
    grid-template:
      'Title           Title          ' 60px
      'ActivePower     ExecHour       ' 60px
      'ReactivePower   ApparentPower  ' 60px
      'RPM             Frequency      ' 60px
      'PowerFactor     Frequency      ' 60px
      'FuelTank        Frequency      ' 60px
      'OutVoltage      OutCurrent     ' 60px
      'OutVoltage      OutCurrent     ' 60px
      'OutVoltage      OutCurrent     ' 60px /
      210px 210px;
    gap: 10px;
  }

  @media (max-width: 450px) {
    grid-template:
      'Title           ' 60px
      'ActivePower     ' 60px
      'ExecHour        ' 60px
      'ReactivePower   ' 60px
      'ApparentPower   ' 60px
      'RPM             ' 60px
      'PowerFactor     ' 60px
      'FuelTank        ' 60px
      'Frequency       ' 200px
      'OutVoltage      ' 200px
      'OutCurrent      ' 200px /
      210px;
    gap: 10px;
  }
`;

/**
 * Fuel Generator Skeleton
 */
export const FuelGeneratorSkeleton: React.FC = () => (
  <StyledContainer>
    <SSingleCard area="ActivePower" />
    <SSingleCard area="ReactivePower" />
    <SSingleCard area="ApparentPower" />
    <SSingleCard area="PowerFactor" />
    <SSingleCard area="ExecHour" />
    <SSingleCard area="RPM" />
    <SSingleCard area="FuelTank" />
    <SThreePhaseCard area="OutVoltage" />
    <SThreePhaseCard area="OutCurrent" />
    <SThreePhaseCard area="Frequency" />
  </StyledContainer>
);
