import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Number3: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect width="23" height="23" fill="white" />
          <path
            d="M12.3822 10.664C13.4614 10.7976 14.2847 11.1647 14.8522 11.7655C15.4196 12.3663 15.7033 13.1173 15.7033 14.0185C15.7033 14.6971 15.5308 15.3146 15.1859 15.8709C14.841 16.4272 14.3181 16.8722 13.6172 17.206C12.9274 17.5286 12.0818 17.69 11.0805 17.69C10.2461 17.69 9.43947 17.5732 8.66067 17.3395C7.89299 17.0947 7.23657 16.761 6.69141 16.3382L7.62597 14.6526C8.04875 15.0087 8.56054 15.2924 9.16133 15.5038C9.77324 15.704 10.4018 15.8042 11.0471 15.8042C11.8148 15.8042 12.4156 15.6484 12.8495 15.3369C13.2945 15.0142 13.5171 14.5803 13.5171 14.0352C13.5171 13.49 13.3057 13.0672 12.8829 12.7668C12.4712 12.4553 11.8371 12.2995 10.9804 12.2995H9.91232V10.8142L12.5658 7.66009H7.25882V5.84103H15.2193V7.29294L12.3822 10.664Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <rect
        mask={`url(#${idCut})`}
        width="23"
        height="23"
        rx="11.5"
        fill={fill}
      />
    </svg>
  );
};
