import { useState, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';
import urlcat from 'urlcat';

/**
 * Socket connection to remote server.
 *
 * @param baseUrl Base url to connect to the socket
 * @param bessId Id of the bess to listen from
 * @param token  User token to connect to the socket. If undefined, it will
 * attempt to connect without a token.
 * @param maxAttempts Number of attempts of reconnection before giving
 * up.
 */
export function useSocket(
  baseUrl: string,
  bessId: string,
  token?: string,
  maxAttempts = 50
): [socket: typeof Socket | undefined, attempts: number, failed: boolean] {
  const [socket, setSocket] = useState<typeof Socket>();
  const [failed, setFailed] = useState(false);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const client = io(token ? urlcat(baseUrl, { token: `Bearer ${token}` }) : baseUrl, {
      reconnectionAttempts: maxAttempts,
    }).connect();

    client.on('connect', () => {
      if (bessId) {
        client.emit('joinRoom', { room: bessId });
        setSocket(client);
      }
      setFailed(false);
    });

    return () => {
      setSocket(undefined);
      client.disconnect();
    };
  }, [token, baseUrl, bessId, maxAttempts]);

  const reconnect = () => {
    setAttempts(0);
    setFailed(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on('error', () => setFailed(true));
      socket.on('disconnect', () => setFailed(true));
      socket.on('reconnect_error', () => setFailed(true));
      socket.on('reconnect_failed', () => setFailed(true));
      socket.on('reconnect_attempt', () => setAttempts((value) => value + 1));
      socket.on('reconnect', () => reconnect);
    }
  }, [socket]);

  return [socket, attempts, failed];
}
