import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Exhaust: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.90919 17.5213C4.46527 17.1505 4.79727 16.5302 4.79727 15.8619C4.79727 15.1936 4.46527 14.5732 3.90919 14.2025C3.63997 14.0231 3.27613 14.0958 3.09666 14.365C2.91718 14.6343 2.98992 14.9981 3.25918 15.1776C3.4919 15.3327 3.6254 15.5822 3.6254 15.8619C3.6254 16.1416 3.4919 16.3911 3.25918 16.5463C2.70309 16.9171 2.37109 17.5447 2.37109 18.2253C2.37109 18.9058 2.70313 19.5335 3.25918 19.9041C3.52722 20.0828 3.89145 20.0121 4.07171 19.7416C4.25118 19.4724 4.17844 19.1086 3.90919 18.9291C3.67646 18.774 3.54293 18.5174 3.54293 18.2252C3.54293 17.9331 3.67642 17.6765 3.90919 17.5213Z"
        fill={fill}
      />
      <path
        d="M8.01075 17.5213C8.56684 17.1505 8.89883 16.5302 8.89883 15.8619C8.89883 15.1936 8.56684 14.5732 8.01075 14.2025C7.74157 14.0231 7.3777 14.0958 7.19822 14.365C7.01874 14.6343 7.09149 14.9981 7.36074 15.1776C7.59347 15.3327 7.72696 15.5822 7.72696 15.8619C7.72696 16.1416 7.59347 16.3911 7.36074 16.5463C6.80465 16.9171 6.47266 17.5447 6.47266 18.2253C6.47266 18.9058 6.80469 19.5335 7.36074 19.9041C7.62878 20.0828 7.99301 20.0121 8.17327 19.7416C8.35275 19.4724 8.28 19.1086 8.01075 18.9291C7.77802 18.774 7.64449 18.5174 7.64449 18.2252C7.64449 17.9331 7.77798 17.6765 8.01075 17.5213Z"
        fill={fill}
      />
      <path
        d="M16.2139 17.5213C16.77 17.1505 17.102 16.5302 17.102 15.8619C17.102 15.1936 16.77 14.5732 16.2139 14.2025C15.9447 14.0231 15.5809 14.0958 15.4013 14.365C15.2219 14.6343 15.2946 14.9981 15.5639 15.1776C15.7966 15.3327 15.9301 15.5822 15.9301 15.8619C15.9301 16.1416 15.7966 16.3911 15.5639 16.5463C15.0078 16.9171 14.6758 17.5447 14.6758 18.2253C14.6758 18.9058 15.0078 19.5335 15.5639 19.9041C15.8319 20.0828 16.1961 20.0121 16.3764 19.7416C16.5559 19.4724 16.4831 19.1086 16.2139 18.9291C15.9811 18.774 15.8476 18.5174 15.8476 18.2252C15.8476 17.9331 15.9811 17.6765 16.2139 17.5213Z"
        fill={fill}
      />
      <path
        d="M12.1123 17.5213C12.6684 17.1505 13.0004 16.5302 13.0004 15.8619C13.0004 15.1936 12.6684 14.5732 12.1123 14.2025C11.8431 14.0231 11.4793 14.0958 11.2998 14.365C11.1203 14.6343 11.193 14.9981 11.4623 15.1776C11.695 15.3327 11.8285 15.5822 11.8285 15.8619C11.8285 16.1416 11.695 16.3911 11.4623 16.5463C10.9062 16.9171 10.5742 17.5447 10.5742 18.2253C10.5742 18.9058 10.9063 19.5335 11.4623 19.9041C11.7303 20.0828 12.0946 20.0121 12.2748 19.7416C12.4543 19.4724 12.3816 19.1086 12.1123 18.9291C11.8795 18.774 11.746 18.5174 11.746 18.2252C11.746 17.9331 11.8795 17.6765 12.1123 17.5213Z"
        fill={fill}
      />
      <path
        d="M19.7786 9.54129L14.1015 5.02972V0.585936C14.1015 0.262339 13.8392 0 13.5156 0H6.48432C6.16073 0 5.89839 0.262339 5.89839 0.585936V5.02972L0.221357 9.54129C0.0864175 9.64837 0 9.81847 0 9.99998V12.3437C0 12.6673 0.262339 12.9297 0.585936 12.9297H19.4141C19.7377 12.9297 20 12.6673 20 12.3437V9.99998C20 9.81695 19.9124 9.64728 19.7786 9.54129ZM7.07026 1.17187H12.9296V4.72656H7.07026V1.17187ZM6.6888 5.89843H13.3111L17.7349 9.41404H2.26498L6.6888 5.89843ZM18.8281 11.7578H1.17183V10.586H18.8281V11.7578Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
