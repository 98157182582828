import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Warnings } from '../../../models/Warnings';
import { Button } from '../../components/Button';
import { DropDown } from '../../components/DropDown';
import { Size } from '../../components/DropDown/DropDown';
import { Scrollable } from '../../components/Scrollable';
import { Title } from '../../components/Title';
import { SAlertCell } from '../SAlertCell';
import { SAlertsCount } from '../SAlertsCount';

const StyledContainer = styled.div`
  text-align: center;
  justify-content: center;
`;

export type SAlertsDropDownProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Distance that dropdown offsets from left button.
   */
  size?: Size;
  /**
   * List of alerts yet to be read by the user.
   */
  alerts?: Warnings[];
  /**
   * Show one cell with number of new alerts on system.
   */
  count?: number;
  /**
   * Button to open Alerts page.
   */
  onAlertPage: () => void;

  sizeIcon?: 'small' | 'xsmall' | 'medium' | 'large' | 'xlarge' | 'xxsmall' | 'lsmall' | 'xxlarge' | undefined;
  color?:
    | 'main'
    | 'white'
    | 'black'
    | 'dark'
    | 'light'
    | 'info'
    | 'secondary'
    | 'success'
    | 'error'
    | 'disabled'
    | 'warning'
    | 'darkgray'
    | undefined;
};

export const SAlertsDropDown: React.FC<SAlertsDropDownProps> = ({
  area = 'unset',
  size = 'menuDrop',
  sizeIcon,
  count,
  alerts,
  onAlertPage,
  color = 'main',
}: SAlertsDropDownProps) => {
  const [drop, setDrop] = useState(true);
  const action = () => {
    setDrop(!drop);
  };

  return (
    <DropDown
      area={area}
      topOffset={32}
      collapse={drop}
      size={size}
      halign='center'
      pad='small'
      dropButton={<SAlertsCount size={sizeIcon} color={color} count={count} onClick={action} />}>
      <StyledContainer>
        <Scrollable halign='center' size='small' marginAll={{ bottom: 'small' }}>
          {alerts && alerts.length > 0 ? (
            alerts.map((v, i) => (
              <SAlertCell
                size={sizeIcon}
                key={i}
                code={v.Data.Code}
                title={v.Data.Name}
                time={formatDistanceToNow(new Date(v.Timestamp), {
                  locale: ptBR,
                  includeSeconds: true,
                })}
                description={v.Data.Message}
                hideLine={i === alerts.length - 1}
              />
            ))
          ) : (
            <Title
              size='xsmall'
              color='black'
              halign='center'
              value='Não há notificações recentes.'
              txtalign='center'
              pad='small'
            />
          )}
        </Scrollable>
        <Button halign='center' onClick={onAlertPage} radius='medium' size='medium' color='light'>
          <Title size='xsmall' color='white' value='Ver Alertas do Sistema' />
        </Button>
      </StyledContainer>
    </DropDown>
  );
};
