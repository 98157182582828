import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const StarSquare: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 640 640"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <path
            d="M640 0C640 384 640 597.333 640 640C256.004 640 42.6673 640 0 640C0 256 0 42.6667 0 0C383.996 0 597.333 0 640 0Z"
            fill="white"
          />
          <path
            d="M454.57 267.55C448.69 266.7 419.3 262.43 366.4 254.74C342.75 206.81 329.6 180.18 326.98 174.85C325.57 172.01 322.68 170.21 319.51 170.21C316.33 170.21 313.44 172.01 312.03 174.85C309.41 180.18 296.26 206.81 272.6 254.74C219.71 262.43 190.32 266.7 184.44 267.55C181.31 268.01 178.7 270.21 177.72 273.22C176.74 276.24 177.56 279.55 179.83 281.76C184.08 285.91 205.34 306.64 243.62 343.95C234.58 396.63 229.57 425.9 228.56 431.75C228.03 434.88 229.31 438.04 231.88 439.9C233.33 440.96 235.05 441.49 236.77 441.49C238.1 441.49 239.43 441.18 240.65 440.54C245.91 437.77 272.19 423.95 319.5 399.08C366.82 423.95 393.1 437.77 398.36 440.54C401.16 442.01 404.56 441.77 407.13 439.9C409.7 438.04 410.98 434.88 410.45 431.75C409.44 425.9 404.42 396.63 395.38 343.95C433.66 306.64 454.93 285.91 459.18 281.76C461.45 279.55 462.27 276.24 461.29 273.22C460.31 270.21 457.7 268.01 454.57 267.55Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <path
        mask={`url(#${idCut})`}
        d="M640 0C640 384 640 597.333 640 640C256.004 640 42.6673 640 0 640C0 256 0 42.6667 0 0C383.996 0 597.333 0 640 0Z"
        fill={fill}
      />
    </svg>
  );
};
