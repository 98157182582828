import React from 'react';
import styled from 'styled-components';
import { Title, SDoubleCard } from '../../Skeleton';
import { useMedia } from '../../../scripts/hooks/mediaQueryHook';

const StyledContainer = styled.div`
  display: grid;
  gap: 10px;
  justify-content: center;

  padding-top: calc(12vh - 50px);
  padding-bottom: calc(12vh - 50px);

  grid-template:
    'GlobalTitle  GlobalTitle  GlobalMeasure GlobalMeasure GlobalBattery     GlobalBattery' 60px
    'GlobalEnergy GlobalEnergy GlobalVoltage GlobalVoltage GlobalTemperature GlobalTemperature' 60px
    'FirstTitle   FirstTitle   FirstMeasure  FirstMeasure  FirstBattery      FirstBattery' 60px
    'FirstBMUs    FirstBMUs    FirstVoltage  FirstVoltage  FirstTemperature  FirstTemperature' 60px
    'SecondTitle  SecondTitle  SecondMeasure SecondMeasure SecondBattery     SecondBattery' 60px
    'SecondBMUs   SecondStatus SecondVoltage SecondVoltage SecondTemperature SecondTemperature' 60px /
    210px 210px 210px 210px 210px 210px;

  @media (max-width: 1340px) {
    grid-template:
      'GlobalTitle   GlobalTitle   GlobalBattery     GlobalBattery' 60px
      'GlobalMeasure GlobalMeasure GlobalEnergy      GlobalEnergy' 60px
      'GlobalVoltage GlobalVoltage GlobalTemperature GlobalTemperature ' 60px
      '.             .             .                 .' 20px
      'FirstTitle    FirstTitle    FirstBMUs         FirstBMUs' 60px
      'FirstMeasure  FirstMeasure  FirstBattery      FirstBattery' 60px
      'FirstVoltage  FirstVoltage  FirstTemperature  FirstTemperature' 60px
      '.             .             .                 .' 20px
      'SecondTitle   SecondTitle   SecondBMUs        SecondBMUs' 60px
      'SecondMeasure SecondMeasure SecondBattery     SecondBattery' 60px
      'SecondVoltage SecondVoltage SecondTemperature SecondTemperature ' 60px /
      210px 210px 210px 210px;
  }

  @media (max-width: 900px) {
    grid-template:
      'GlobalTitle       GlobalTitle' 60px
      'GlobalBattery     GlobalBattery' 60px
      'GlobalMeasure     GlobalMeasure' 60px
      'GlobalEnergy      GlobalEnergy' 60px
      'GlobalTemperature GlobalTemperature' 60px
      'GlobalVoltage     GlobalVoltage' 60px
      'FirstTitle        FirstTitle' 60px
      'FirstBMUs         FirstBMUs' 60px
      'FirstMeasure      FirstMeasure' 60px
      'FirstBattery      FirstBattery' 60px
      'FirstTemperature  FirstTemperature' 60px
      'FirstVoltage      FirstVoltage' 60px
      'SecondTitle       SecondTitle' 60px
      'SecondBMUs        SecondBMUs' 60px
      'SecondMeasure     SecondMeasure' 60px
      'SecondBattery     SecondBattery' 60px
      'SecondTemperature SecondTemperature' 60px
      'SecondVoltage     SecondVoltage' 60px /
      210px 210px;
  }

  @media (max-width: 450px) {
    grid-template:
      'GlobalTitle' 60px
      'GlobalBattery-left' 60px
      'GlobalBattery-right' 60px
      'GlobalMeasure-left' 60px
      'GlobalMeasure-right' 60px
      'GlobalEnergy-left' 60px
      'GlobalEnergy-right' 60px
      'GlobalTemperature-left' 60px
      'GlobalTemperature-right' 60px
      'GlobalVoltage-left' 60px
      'GlobalVoltage-right' 60px
      '.' 30px
      'FirstTitle' 60px
      'FirstBMUs-left' 60px
      'FirstMeasure-left' 60px
      'FirstMeasure-right' 60px
      'FirstBattery-left' 60px
      'FirstBattery-right' 60px
      'FirstVoltage-left' 60px
      'FirstVoltage-right' 60px
      'FirstTemperature-left' 60px
      'FirstTemperature-right' 60px
      '.' 30px
      'SecondTitle' 60px
      'SecondBMUs-left' 60px
      'SecondMeasure-left' 60px
      'SecondMeasure-right' 60px
      'SecondBattery-left' 60px
      'SecondBattery-right' 60px
      'SecondVoltage-left' 60px
      'SecondVoltage-right' 60px
      'SecondTemperature-left' 60px
      'SecondTemperature-right' 60px /
      210px;
  }
`;

export const BMSSkeleton: React.FC = () => {
  const tooSmall = useMedia('(max-width: 450px)');
  return (
    <StyledContainer>
      <Title area="GlobalTitle" marginTop />
      <SDoubleCard area="GlobalMeasure" split={tooSmall} />
      <SDoubleCard area="GlobalBattery" split={tooSmall} />
      <SDoubleCard area="GlobalEnergy" split={tooSmall} />
      <SDoubleCard area="GlobalVoltage" split={tooSmall} />
      <SDoubleCard area="GlobalTemperature" split={tooSmall} />
      <Title area="FirstTitle" marginTop />
      <SDoubleCard area="FirstMeasure" split={tooSmall} />
      <SDoubleCard area="FirstBattery" split={tooSmall} />
      <SDoubleCard area="FirstBMUs" split={tooSmall} single />
      <SDoubleCard area="FirstVoltage" split={tooSmall} />
      <SDoubleCard area="FirstTemperature" split={tooSmall} />
      <Title area="SecondTitle" marginTop />
      <SDoubleCard area="SecondMeasure" split={tooSmall} />
      <SDoubleCard area="SecondBattery" split={tooSmall} />
      <SDoubleCard area="SecondBMUs" split={tooSmall} single />
      <SDoubleCard area="SecondVoltage" split={tooSmall} />
      <SDoubleCard area="SecondTemperature" split={tooSmall} />
    </StyledContainer>
  );
};
