import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Number2: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect width="23" height="23" fill="white" />
          <path
            d="M15.8715 15.6873V17.5231H7.26016V16.0712L11.8996 11.6654C12.4225 11.1647 12.773 10.7308 12.951 10.3636C13.1401 9.98537 13.2347 9.61265 13.2347 9.2455C13.2347 8.70034 13.0511 8.28312 12.684 7.99385C12.3168 7.70458 11.7772 7.55995 11.0652 7.55995C9.87472 7.55995 8.96241 7.96604 8.32824 8.77822L6.80957 7.61001C7.26573 6.9981 7.87764 6.52525 8.64532 6.19148C9.42413 5.84658 10.2919 5.67413 11.2488 5.67413C12.5171 5.67413 13.5295 5.97453 14.2861 6.57532C15.0426 7.17611 15.4209 7.99385 15.4209 9.02855C15.4209 9.66272 15.2874 10.2579 15.0204 10.8142C14.7534 11.3705 14.2416 12.0047 13.485 12.7167L10.3643 15.6873H15.8715Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <rect
        mask={`url(#${idCut})`}
        width="23"
        height="23"
        rx="11.5"
        fill={fill}
      />
    </svg>
  );
};
