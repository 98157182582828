import { useEffect, useState } from 'react';
import axios, { AxiosInstance } from 'axios';

/**
 * Creates a new instance of axios for requisitions to the API.
 *
 * @param baseUrl Base url of the API
 * @param token Token returned on signin, signup or even slash me.
 */
function makeAxiosInstance(baseUrl: string, token?: string): AxiosInstance {
  return axios.create({
    baseURL: baseUrl,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });
}

/**
 * Manages axios requisitions in an easier way.
 *
 * @param baseUrl Base url of the API
 * @param token Token returned on signin, signup or even slash me.
 */
export function useAxios(baseUrl: string, token?: string): [AxiosInstance] {
  const [axiosInstance, setAxiosInstance] = useState<AxiosInstance>(() =>
    makeAxiosInstance(baseUrl, token)
  );

  useEffect(() => {
    setAxiosInstance(makeAxiosInstance(baseUrl, token));
  }, [baseUrl, token]);

  return [axiosInstance];
}
