import { Bmu, BmuLimits } from './types';

import { AxiosInstance } from 'axios';
import { CountableList } from '../types';
import urlcat from 'urlcat';

/**
 * Returns a list of bmus
 *
 * @param bess The id of the bess to fetch the bmu from.
 * @param bms The id of the bms to fetch the bmu from.
 * @param api Axios instance with correct headers.
 * @param limit The number of bmus to be retorned.
 * @param offset The number of bmus to be skipped, counting from the
 * more recent.
 * @throws Error if there is no such bess or bms.
 */
export async function listBmus(
  api: AxiosInstance,
  bess: string,
  bms: string,
  limit = 10,
  offset = 0
): Promise<CountableList<Bmu>> {
  return await api
    .get<CountableList<Bmu>>(
      urlcat('/besses/:bess/bms/:bms/bmus', { bess, bms, limit, offset })
    )
    .then((res) => res.data);
}

/**
 * Returns a bmu with a given id.
 *
 * @param bmu The id of the bmu to fetch.
 * @param api Axios instance with correct headers.
 * @throws Error if there is no such bmu.
 */
export async function fetchBmu(api: AxiosInstance, bmu: string): Promise<Bmu> {
  return await api.get<Bmu>(`/bmus/${bmu}`).then((res) => res.data);
}

/**
 * Returns the cell limits for the bmus of a given bess.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the limits from. The bmu limits
 * are set on the bess level. All bmus of a bms of the same bess will have the
 * same limits.
 * @throws Error if there is no such bess or there is no limits set.
 */
export async function limitBmus(
  api: AxiosInstance,
  bess: string
): Promise<BmuLimits> {
  return await api
    .get<BmuLimits>(urlcat('/besses/:bess/bmus/limits', { bess }))
    .then((res) => res.data);
}
