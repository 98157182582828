import type { ModeBarButton, Shape } from 'plotly.js';
import React from 'react';
import Plot from 'react-plotly.js';
import { useTheme } from 'styled-components';

import { GeneratedChart } from '../../scripts/api/types';
import { TabItem, MenuItem, menuIcons, assertHasActive, parseX, colorOfCurve } from '../../scripts/common/chart';
import { useMedia } from '../../scripts/hooks/mediaQueryHook';

export type ChartProps = {
  /**
   * Chart options and data to be displayed to the user
   */
  layout: GeneratedChart;
  /**
   * Menu items are normally icons at the top right side of the chart
   */
  menu?: (MenuItem | undefined)[];
  /**
   * Tabs are button-like elements within the chart
   */
  tabs?: TabItem[];
  /**
   * Which tab is active, if there tabs isn't undefined
   */
  activeTab?: number;
  /**
   * Change the revision to force the update and reset of the ui (layout
   * buttons, zoom, etc)
   */
  uiRevision?: string;
  /**
   * If true, the plot is completely static, you can't move or zoom on it.
   */
  shoudPlotBeStatic?: boolean;
  /**
   * Shapes to be drawn inside the chart, as any shape allowed by plotly
   */
  shapes?: Partial<Shape>[];
};

export const SChart: React.FC<ChartProps> = ({
  layout: { title, axis, type, review = false },
  shoudPlotBeStatic = false,
  uiRevision = 'keep',
  activeTab = 0,
  tabs = [],
  menu = [],
  shapes,
}: ChartProps) => {
  const theme = useTheme();
  const isMobile = useMedia('(max-width: 600px)');
  const dataSet = axis?.map(({ X, Y = [], label }, i) => ({
    name: label,
    x: X ? parseX(X) : undefined,
    y: Y,
    mode: type === 'line' ? ('lines' as const) : undefined,
    type: type === 'bar' ? ('bar' as const) : undefined,
    line: { color: colorOfCurve(type, i, theme), width: 3 },
    marker: { color: colorOfCurve(type, i, theme) },
  }));

  return (
    <Plot
      useResizeHandler
      style={{ width: '100%' }}
      data={dataSet}
      config={{
        responsive: false,
        displaylogo: false,
        staticPlot: shoudPlotBeStatic,
        displayModeBar: menu !== undefined && menu.length > 0,
        locale: 'pt-BR',
        modeBarButtons: menu
          .filter<MenuItem>((item): item is MenuItem => item !== undefined)
          .filter((item) => typeof item === 'string' || item.visible)
          .map((item) => {
            if (typeof item === 'string') return [item];
            return [
              {
                name: item.icon,
                icon: menuIcons[item.icon],
                title: item.hint,
                click: () => item.click(),
              } as ModeBarButton,
            ];
          }),
      }}
      layout={{
        ...{
          uirevision: uiRevision,
          modebar: {
            color: theme.pallete.primary.main,
            activecolor: theme.pallete.primary.light,
          },
        },
        updatemenus: [
          {
            buttons: tabs.map((tab) => {
              if (tab.formatting)
                return {
                  args: [{ visible: tab.formatting.toggleAxis.map((e) => e === 1) }, { title: tab.formatting.title }],
                  label: tab.label,
                  method: 'update',
                };
              return {
                label: tab.label,
                method: 'skip',
              };
            }),
            bgcolor: theme.pallete.neutral.light,
            font: {
              color: theme.pallete.border.main,
              size: 14,
            },
            direction: 'right',
            pad: { r: -60, t: isMobile ? -40 : -60 },
            showactive: true,
            active: activeTab,
            type: 'buttons',
            x: 1,
            xanchor: 'right',
            y: 1,
            yanchor: 'top',
          },
        ],
        shapes,
        autosize: true,
        title: {
          x: 0.07,
          font: {
            size: 16,
            color: theme.pallete.neutral.main,
          },
          text: title,
        },
        legend: {
          xanchor: 'auto',
          yanchor: 'top',
          y: -0.2,
          orientation: 'h',
        },
        plot_bgcolor: review ? theme.pallete.neutral.lighter : '#f3f4f8',
        paper_bgcolor: review ? theme.pallete.neutral.lighter : '#f3f4f8',
      }}
      onButtonClicked={(item) => {
        assertHasActive(item);
        if (tabs && typeof item.active === 'number') tabs[item.active].click();
      }}
    />
  );
};
