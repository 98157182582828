import { ThemeContext } from 'styled-components';
import React, { useContext } from 'react';

export const gradientName = 'url(#gradient_linear)';
export const Gradient: React.FC = () => {
  const theme = useContext(ThemeContext);
  return (
    <linearGradient
      id="gradient_linear"
      x1="11.9496"
      y1="0.0251463"
      x2="14.8748"
      y2="23.0467"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.00193099" stopColor={theme.pallete.accent.main} />
      <stop offset="1" stopColor={theme.pallete.accent.dark} />
    </linearGradient>
  );
};
