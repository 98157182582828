import React from 'react';
import { format, add, startOfDay } from 'date-fns';
import styled from 'styled-components';

const sizes = {
  small: { font: '12.5px', width: '50px', height: '24px' },
  medium: {
    font: '15px',
    width: '80px',
    height: '38px',
  },
  large: { font: '15px', width: '145px', height: '38px' },
} as const;

type Size = keyof typeof sizes;

const colors = {
  default: { color: '#495057', background: '#fff', border: '#ced4da' },
  light: { color: '#fff', background: '#179af2', border: '#fff' },
} as const;

type Colors = keyof typeof colors;

type StyledTimeInputProps = {
  scale: { font: string; width: string; height: string };
  skin: { color: string; background: string; border: string };
  bold: boolean;
  area?: string;
};

const StyledTimeInput = styled.select<StyledTimeInputProps>`
  grid-area: ${(props) => (props.area ? props.area : 'unset')};
  font-family: ${(props) => props.theme.global.fontFamily};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  font-size: ${(props) => props.scale.font};

  color: ${(props) => props.skin.color};
  background: transparent;
  background-color: ${(props) => props.skin.background};

  width: ${(props) => props.scale.width};
  height: ${(props) => props.scale.height};
  border: 1px solid ${(props) => props.skin.border};
  border-radius: 3px;

  text-align-last: center;
  -webkit-appearance: none;
  overflow-y: auto;
  scrollbar-width: none;

  &:focus {
    outline: none;
    -webkit-appearance: none;
    overflow-y: auto;
    scrollbar-width: none;
    text-align-last: center;
    border: 1px solid ${(props) => props.skin.border};
    text-align: center;
    border-radius: 3px;
    background-color: ${(props) => props.skin.background};
    color: ${(props) => props.skin.color};
  }
`;

const Option = styled.option`
  text-align: center;
`;

export type TimeInputProps = {
  onSelect: (index: number) => void;
  selected: number;
  /**
   * Maximum hours limit that can be shown
   */
  max?: number;
  /**
   * Minimum hours limit that can be shown
   */
  min?: number;
  /**
   * Size of the value and unit. When large, the unit is smaller than the value.
   * It defaults to medium.
   */
  size?: Size;
  /**
   * If true, the font weight be a bold, otherwise, it will use the
   * regular weight.
   */
  bold?: boolean;
  /**
   * Select color scheme, can be clear or standard
   */
  skin?: Colors;
  /**
   * TimeInput area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
};

const midnight = startOfDay(new Date());
export const OPTIONS = new Array(96)
  .fill(midnight)
  .map((v, i) => format(add(v, { minutes: 15 * i }), 'HH:mm'));

export const TimeInput: React.FC<TimeInputProps> = ({
  skin = 'default',
  size = 'medium',
  bold = false,
  area,
  ...props
}: TimeInputProps) => {
  const { onSelect, selected, min, max } = props;

  const minValue = min !== undefined ? min + 1 : 0;
  const maxValue = max !== undefined ? max - 1 : 95;

  const currentOptions = OPTIONS.slice(minValue, maxValue + 1);

  return (
    <StyledTimeInput
      area={area}
      scale={sizes[size]}
      skin={colors[skin]}
      bold={bold}
      onChange={(ev) => {
        onSelect(ev.currentTarget.selectedIndex + minValue);
      }}
      value={OPTIONS[selected]}
    >
      {currentOptions.map((name, index) => (
        <Option key={index} value={name}>
          {name}
        </Option>
      ))}
    </StyledTimeInput>
  );
};
