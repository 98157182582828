import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { solve } from '../../scripts/common';
import { Icon } from '../Icons';

import * as names from '../Icons/components';
import {
  diameter,
  leftIconDisplacement,
  topIconDisplacement,
} from '../../scripts/common/spinner';
type Name = 'none' | keyof typeof names;

export const sizes = {
  xxsmall: {
    icon: 5,
    thickness: 1,
  },
  xsmall: {
    icon: 12,
    thickness: 3,
  },
  small: {
    icon: 20,
    thickness: 4,
  },
  medium: {
    icon: 25,
    thickness: 6,
  },
  large: {
    icon: 32,
    thickness: 8,
  },
  xlarge: {
    icon: 50,
    thickness: 12,
  },
  xxlarge: {
    icon: 70,
    thickness: 15,
  },
} as const;
type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const colors = (theme: DefaultTheme) => ({
  white: 'white',
  black: 'black',
  main: theme.pallete.primary.main,
  info: theme.pallete.primary.light,
  dark: theme.pallete.border.main,
  error: theme.pallete.error.main,
  warning: theme.pallete.secondary.main,
  success: theme.pallete.success.main,
});
type Colors = keyof ReturnType<typeof colors>;

type StyledSpinnerProps = {
  color: Colors;
  background: Colors;
  size: {
    thickness: number;
    icon: number;
  };
};

const StyledSpinner = styled.div<StyledSpinnerProps>`
  height: ${({ size: { icon, thickness } }) =>
    diameter(icon, thickness) + 2 * thickness}px;
  opacity: 1;
  position: relative;
  transition: opacity cubic-bezier(0.95, 0.05, 0.795, 0.035) 0.1s;

  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  &:before {
    opacity: inherit;
    border-style: solid;
    border-width: ${(props) => props.size.thickness}px;
    border-color: ${(props) => colors(props.theme)[props.background]};
    border-bottom-color: ${(props) => colors(props.theme)[props.color]};
    border-right-color: ${(props) => colors(props.theme)[props.color]};
    border-radius: 50%;

    content: '';

    height: ${({ size: { icon, thickness } }) => diameter(icon, thickness)}px;
    width: ${({ size: { icon, thickness } }) => diameter(icon, thickness)}px;
    position: absolute;

    left: 50%;
    top: 50%;

    animation: 2s ease infinite spinner;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    will-change: transform;
  }
`;

type IconContentProps = {
  size: { icon: number; thickness: number };
};

const IconContent = styled.div<IconContentProps>`
  position: absolute;
  left: ${({ size: { icon } }) => leftIconDisplacement(icon)}px;
  top: ${({ size: { thickness, icon } }) =>
    topIconDisplacement(icon, thickness)}px;
  z-index: 1;
`;

type ContentProps = {
  visible: boolean;
  area: string;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};

const Content = styled.div<ContentProps>`
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  grid-area: ${(props) => props.area};

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};
`;

export type SpinnerProps = {
  /**
   * Show/Hide Icon
   */
  icon?: Name;
  /**
   * Make the component visible
   */
  visible?: boolean;
  /**
   * Color of the spinner and icon.
   */
  color?: Colors;
  /**
   * Background of the spinner and icon.
   */
  background?: Colors;
  /**
   * Size of the icon, defaults to small.
   */
  size?: Size;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "spinner".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
};

/**
 * General proposal of the component,
 * show the spinner loader
 */
export const Spinner: React.FC<SpinnerProps> = ({
  color = 'main',
  area = 'spinner',
  background = 'white',
  size = 'xxlarge',
  visible = true,
  icon = 'Bolt',
  pad,
  padAll,
  padCross,
  halign,
  valign,
}: SpinnerProps) => {
  return (
    <Content
      visible={visible}
      area={area}
      pad={solve(pad, padCross, padAll)}
      halign={halign}
      valign={valign}
    >
      <StyledSpinner color={color} background={background} size={sizes[size]}>
        {icon ? (
          <IconContent size={sizes[size]}>
            {icon != 'none' && <Icon name={icon} color={color} size={size} />}
          </IconContent>
        ) : (
          <IconContent size={sizes[size]} />
        )}
      </StyledSpinner>
    </Content>
  );
};
