import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Comment: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 512 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M256 0C114.6 0 0 93.1 0 208C0 257.6 21.4 303 57 338.7C44.5 389.1 2.7 434 2.2 434.5C0 436.8 -0.6 440.2 0.7 443.2C2 446.2 4.8 448 8 448C74.3 448 124 416.2 148.6 396.6C181.3 408.9 217.6 416 256 416C397.4 416 512 322.9 512 208C512 93.1 397.4 0 256 0Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
