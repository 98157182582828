import type { Theme } from './theme';
export default {
  global: {
    fontFamily: 'Montserrat',
  },
  pallete: {
    primary: {
      main: '#295097', // Moura Blue
      light: '#179AF2', // Light Blue
    },
    secondary: {
      main: '#FFD525', // Moura Yellow
    },
    neutral: {
      dark: '#373435', // Dark Gray
      main: '#96989A', // Gray
      light: '#D0C9D6', // Light Gray
      lighter: '#ECEBED', // Lighter Gray
    },
    success: {
      main: '#3CBA70', // Green
    },
    error: {
      main: '#F16E41', // Red, Orange-ish
    },
    accent: {
      // This is for the gradient
      main: '#179AF2', // Light Blue
      dark: '#233B86', // Dark Blue
    },
    border: {
      main: '#171586', // Yet another Dark Blue for Borders
    },
    skeleton: {
      dark: '#E6E4E4',
      main: '#ECEBED',
    },
    darkMode: {
      main: '#010D23',
      light: '#BED1F1',
    },
  },
} as Theme;
