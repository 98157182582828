import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowRight: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.47893 14.6258L9.18146 8.68001C9.81115 8.12142 9.88407 7.14028 9.34434 6.48859C9.2943 6.42816 9.23985 6.37181 9.18146 6.32002L2.47893 0.37418C1.84924 -0.184417 0.901245 -0.108944 0.361515 0.542751C0.12823 0.82443 -6.03952e-07 1.18319 -5.87735e-07 1.55418L-6.79341e-08 13.4459C-3.04151e-08 14.3042 0.672317 15 1.50166 15C1.86013 15 2.20676 14.8673 2.47893 14.6258Z"
        fill={fill}
      />

      <defs>{gradient && <Gradient />}</defs>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="11.0508" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
