import React from 'react';

import { CardContent } from '../../components/CardContent';
import { Icon, IconProps } from '../../components/Icons';
import { SingleCard } from '../../components/SingleCard';
import { Title } from '../../components/Title';

export type SStatusCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Title text value for the status card.
   */
  title: string;
  /**
   * All possible states that the status card can assume.
   */
  states: string[];
  /**
   * Current state of the status card. It must be positive and less than the
   * number of possible states.
   */
  currentState: number;
  /**
   * Icon of the status card. It can be ommited (There is StoryBook issue
   * regarding importing types from other files. This may look like a
   * simple textBox, but you can see the possible Icons on Docs).
   */
  icon?: IconProps['name'];

  color?: 'white' | 'DC' | 'DS' | 'CP' | 'CD' | 'FUSE' | 'MODEDARK';

  onClick?: () => void;
};

/**
 * Resumed version of the SingleCard for Cards with a status value.
 */
export const SStatusCard: React.FC<SStatusCardProps> = ({
  area = 'unset',
  title = '',
  states = ['Padrão'],
  currentState = 0,
  icon,
  color = 'white',
  onClick,
}: SStatusCardProps) => {
  return (
    <SingleCard color={color} area={area} valign='center' halign='start' onClick={() => onClick?.call(this)}>
      <CardContent valign='center' halign='center' padAll={{ left: 'medium' }}>
        {icon && <Icon name={icon} size='medium' color='main' valign='center' padAll={{ right: 'small' }} />}
        <Title value={title} size='xsmall' color='main' halign='start' />
        <Title value={states[currentState]} area='quantity' size='medium' color='light' valign='baseline' />
      </CardContent>
    </SingleCard>
  );
};
