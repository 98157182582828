import React from 'react';
import { Gradient, gradientName } from '../Gradient';
import { nanoid } from 'nanoid';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Number1: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const idCut = nanoid();
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={idCut}>
          <rect width="23" height="23" rx="11.5" fill="white" />
          <path
            d="M12.9842 6.67546V18.3575H10.8146V8.49452H8.34473V6.67546H12.9842Z"
            fill="black"
          />
        </mask>
        {gradient && <Gradient />}
      </defs>
      <rect
        mask={`url(#${idCut})`}
        width="23"
        height="23"
        rx="11.5"
        fill={fill}
      />
    </svg>
  );
};
