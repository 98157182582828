import React from 'react';
import styled from 'styled-components';
import {
  Logo,
  SMenuItem,
  SClock,
  ProfilePic,
  ProfileLabel,
  SAlertsDropDown,
} from '../../Skeleton';

const Content = styled.div`
  display: grid;
  grid-template-columns: 120px auto min-content;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
`;

const MenuContainer = styled.div`
  grid-area: '1 / 2';
  display: grid;
  grid-template-columns: repeat(7, auto);
  gap: 5px;
  align-items: center;
  justify-content: end;
`;

const UserContainer = styled.div`
  grid-area: '1 / 2';
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: end;
  justify-self: end;
`;

const ContainerDropDown = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 4px;
`;

export const MenuSkeleton: React.FC = () => {
  return (
    <Content>
      <Logo area="1 / 1" />
      <MenuContainer>
        <SMenuItem />
        <SMenuItem />
        <SMenuItem />
        <SMenuItem />
        <SMenuItem />
        <SMenuItem />
        <SMenuItem />
      </MenuContainer>
      <UserContainer>
        <ContainerDropDown>
          <SAlertsDropDown />
          <ProfilePic />
          <ProfileLabel />
        </ContainerDropDown>
        <SClock />
      </UserContainer>
    </Content>
  );
};
