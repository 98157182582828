import React from 'react';
import { Cross, Directional, LineAlign, ItemAlign } from '../../scripts/types';
import { SFormStatus } from '../SFormStatus';

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

const sizes = {
  small: '410px',
  medium: '448px',
  large: '500px',
  auto: '100%',
} as const;
type Size = keyof typeof sizes;

const allStatus = {
  nonEmptyEmail: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Campo de email obrigatório',
  },
  invalidEmailError: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Email inválido',
  },
  sendingEmailError: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Não foi possível enviar o email de recuperação',
  },
  loggedOut: {
    color: 'success',
    icon: 'exit',
    spinner: false,
    message: 'Você saiu',
  },
  loggedIn: {
    color: 'success',
    icon: 'check',
    spinner: true,
    message: 'Bem Vindo ao EMS',
  },
  pinCheckMail: {
    color: 'success',
    icon: 'check',
    spinner: false,
    message: 'Verifique seu email para recuperar seu pin',
  },
  pinCheckMailError: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Erro para recuperar o pin',
  },
  checkMail: {
    color: 'success',
    icon: 'check',
    spinner: false,
    message: 'Verifique seu email para recuperar sua senha',
  },
  operation: {
    color: 'info',
    icon: 'operation',
    spinner: true,
    message: 'Aguardando resposta do servidor',
  },
  checking: {
    color: 'info',
    icon: 'operation',
    spinner: true,
    message: 'Checando credenciais',
  },
  empty: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Todos os campos são obrigatórios',
  },
  passSecurity: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Senha requer letra maiúscula, minúscula, número e símbolo ( !@#$%^&*()_+-={};:" )',
  },

  timeOut: {
    color: 'error',
    icon: 'plug',
    spinner: false,
    message: 'Sessão expirada',
  },
  pinError: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Pin Inválido',
  },
  error: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Não foi possível recuperar sua senha',
  },
  waitForUserInfo: {
    color: 'info',
    icon: 'operation',
    spinner: true,
    message: 'Recuperando dados do usuário',
  },
  serverError: {
    color: 'error',
    icon: 'error',
    spinner: false,
    message: 'Erro ao se comunicar com o servidor',
  },
  wrong: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Email e / ou senha incorreto(s)',
  },
  noMatch: {
    color: 'error',
    icon: 'warning',
    spinner: false,
    message: 'Senhas não conferem.',
  },
  noConection: {
    color: 'error',
    icon: 'plug',
    spinner: false,
    message: 'Sem conexão com a internet. O acesso offline está disponível.',
  },
  noSocket: {
    color: 'error',
    icon: 'plug',
    spinner: false,
    message: 'Você foi deslogado por erro de conexão.',
  },
  recovered: {
    color: 'success',
    icon: 'check',
    spinner: false,
    message: 'Sua senha foi modificada com successo.',
  },
  unauthorized: {
    color: 'error',
    icon: 'error',
    spinner: false,
    message: 'Acesso negado.',
  },
} as const;
type Status = keyof typeof allStatus;

export type SLoginStatusProps = {
  /**
   * It can be anything, including percents like 100%, viewport units like 100vw
   * or just pixel values like 100px. Auto is also a viable value.
   */
  size?: Size;
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Current Status.
   */
  status: Status;
};

/**
 * Login Status component. It accepts one status and its message.
 *
 * ```tsx
 * <SLoginStatus status="operation" message="Aguardando resposta do servidor" />
 * ```
 */
export const SLoginStatus: React.FC<SLoginStatusProps> = ({
  area = 'unset',
  status = 'operation',
  size = 'small',
  ...props
}: SLoginStatusProps) => {
  const { pad, padAll, padCross, halign, valign } = props;
  return (
    <SFormStatus
      area={area}
      pad={pad}
      padAll={padAll}
      padCross={padCross}
      halign={halign}
      valign={valign}
      size={size}
      icon={allStatus[status].icon}
      spinner={allStatus[status].spinner}
      color={allStatus[status].color}
      message={allStatus[status].message}
    />
  );
};
