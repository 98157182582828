import React, { useCallback, useRef, useState } from 'react';
import { Button } from '../../components/Button';
import { DropDown } from '../../components/DropDown';
import { Title } from '../../components/Title';

import { useOutsideClick } from '../../scripts/hooks';
import { CardContent } from '../../components/CardContent';
import { Icon, IconProps } from '../../components/Icons';
import { RadioButton } from '../../components/RadioButton';
import { GridContainer } from '../../components/GridContainer';

export type SAcordeonProps = {
  /*
   * Title of the Acordeon, it's the header of the component.
   */
  title: string;
  /*
   *  Icon on beggining of the Title.
   */
  icon?: IconProps['name'];
  /*
   * Options is a list of items names that composes the acordeon.
   */
  options: string[];
  /*
   * onClickItem is the function called when item is selected. It
   * receives the item index as parameter.
   */
  onClickItem?: (index: number) => void;
};

export const SAcordeon: React.FC<SAcordeonProps> = ({
  title,
  options = [],
  icon,
  onClickItem,
}: SAcordeonProps) => {
  const [drop, setDrop] = useState(true);
  const action = () => {
    setDrop(!drop);
  };

  const optionsObj: { [key: string]: boolean } = {};
  options.map((v) => (optionsObj[v] = false));
  const [option, setOption] = useState(optionsObj);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, useCallback(() => setDrop(true), [setDrop]));

  return (
    <div ref={wrapperRef} style={{ padding: '5px', width: 'min-content' }}>
      <DropDown
        size="acordeon"
        staticPos
        collapse={drop}
        dropButton={
          <Button
            color="main"
            halign="center"
            size="acordeon"
            radius="small"
            onClick={action}
          >
            <CardContent halign="center">
              {icon && (
                <Icon
                  name={icon}
                  valign="start"
                  size="small"
                  color="secondary"
                  padAll={{ right: 'small' }}
                />
              )}
              <Title size="small" color="white" value={title} halign="center" />
            </CardContent>
          </Button>
        }
      >
        <GridContainer
          row="small"
          column="large"
          valignItems="center"
          valignLines="center"
          padAll={{ top: 'small' }}
        >
          {options.map((v, i) => {
            return (
              <div
                key={i}
                style={{
                  width: '255px',
                }}
              >
                <RadioButton
                  id={`check${i}`}
                  label={v}
                  size="medium"
                  halign="start"
                  bold
                  valign="center"
                  padAll={{ left: 'large', bottom: 'small' }}
                  checked={option[v]}
                  onChange={(evt) => {
                    setOption({ ...optionsObj, [v]: evt.target.checked });
                    onClickItem?.call(this, i);
                  }}
                />
              </div>
            );
          })}
        </GridContainer>
      </DropDown>
    </div>
  );
};
