import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltCCircle: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0568 0.0431531C16.244 0.132712 16.3432 0.340797 16.2949 0.542569L14.4495 8.25625H18.3358C18.5014 8.25625 18.6529 8.34914 18.728 8.49666C18.8031 8.64418 18.7891 8.82137 18.6918 8.95526L10.7911 19.8187C10.6676 19.9884 10.4415 20.0474 10.2509 19.9596C10.0603 19.8717 9.95824 19.6615 10.0071 19.4574L11.8524 11.7437H7.47236C7.30451 11.7437 7.15127 11.6483 7.07729 11.4976C7.00331 11.3469 7.02146 11.1673 7.12409 11.0345L15.5186 0.171046C15.6454 0.00688143 15.8697 -0.046406 16.0568 0.0431531ZM8.36869 10.8635H12.4103C12.545 10.8635 12.6724 10.9252 12.7558 11.031C12.8393 11.1368 12.8697 11.275 12.8383 11.406L11.3763 17.5174L17.4715 9.13652H13.8917C13.7569 9.13652 13.6296 9.07479 13.5461 8.969C13.4627 8.86322 13.4323 8.72503 13.4636 8.59398L14.9619 2.33105L8.36869 10.8635Z"
        fill={fill}
      />
      <path
        d="M3.71917 5.26832C3.34326 5.26832 3.00586 5.19341 2.70694 5.04359C2.40803 4.89377 2.17479 4.68616 2.00722 4.42076C1.83965 4.15109 1.75586 3.84717 1.75586 3.509C1.75586 3.17084 1.83965 2.86905 2.00722 2.60366C2.17479 2.33826 2.40577 2.13065 2.70015 1.98083C2.99906 1.83101 3.33873 1.7561 3.71917 1.7561C4.07696 1.7561 4.38946 1.82459 4.65666 1.96157C4.9284 2.09855 5.13221 2.29546 5.26808 2.55229L4.6159 2.91186C4.51174 2.75348 4.3804 2.63576 4.22188 2.55871C4.0679 2.47738 3.89806 2.43672 3.71237 2.43672C3.39535 2.43672 3.13267 2.53517 2.92433 2.73208C2.716 2.9247 2.61183 3.18368 2.61183 3.509C2.61183 3.83433 2.71374 4.09544 2.91754 4.29235C3.12587 4.48497 3.39082 4.58129 3.71237 4.58129C3.89806 4.58129 4.0679 4.54276 4.22188 4.46571C4.3804 4.38438 4.51174 4.26452 4.6159 4.10614L5.26808 4.46571C5.12768 4.72255 4.92161 4.92159 4.64987 5.06285C4.38266 5.19983 4.07243 5.26832 3.71917 5.26832Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5625 3.6C6.5625 5.23614 5.23614 6.5625 3.6 6.5625C1.96386 6.5625 0.6375 5.23614 0.6375 3.6C0.6375 1.96386 1.96386 0.6375 3.6 0.6375C5.23614 0.6375 6.5625 1.96386 6.5625 3.6ZM3.6 7.2C5.58822 7.2 7.2 5.58822 7.2 3.6C7.2 1.61177 5.58822 0 3.6 0C1.61177 0 0 1.61177 0 3.6C0 5.58822 1.61177 7.2 3.6 7.2ZM3.0153 6.60675C1.6036 6.33384 0.5375 5.09142 0.5375 3.6C0.5375 1.90863 1.90863 0.5375 3.6 0.5375C3.80307 0.5375 4.00152 0.557265 4.19354 0.594975C4.00136 0.557196 3.80272 0.537396 3.59947 0.537396C1.9081 0.537396 0.536967 1.90852 0.536967 3.5999C0.536967 5.0915 1.60334 6.33405 3.0153 6.60675ZM3.6 7.1C5.533 7.1 7.1 5.533 7.1 3.6C7.1 1.8991 5.8867 0.481572 4.27837 0.165693C5.88644 0.48178 7.09947 1.89918 7.09947 3.5999C7.09947 5.53289 5.53246 7.0999 3.59947 7.0999C3.37115 7.0999 3.14794 7.07804 2.93181 7.03629C3.14811 7.0781 3.3715 7.1 3.6 7.1Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
