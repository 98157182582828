import { FunctionalComponent } from 'preact';

import { Global } from '../ems-core/css/Global.styles';
import { ViewLoading } from '../ems-core/views/Loading';

/**
 * Loading
 */
const Page: FunctionalComponent = () => {
  return (
    <>
      <Global />
      <ViewLoading />
    </>
  );
};
export default Page;
