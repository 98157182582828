import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { solve } from '../../scripts/common';
import {
  Cross,
  Directional, ItemAlign, LineAlign, valueof
} from '../../scripts/types';

const sizes = {
  xsmall: { width: '74px', height: '25.6px' },
  small: { width: '112px', height: '26px' },
  xxxsmall: { width: '60px', height: '31.6px' },
  medium: { width: '132px', height: '31.6px' },
  xxmedium: { width: '220px', height: '40px' },
  large: { width: '260px', height: '39.6px' },
  xlarge: { width: '320px', height: '39.6px' },
  xxlarge: { width: '210px', height: '60px' },
  dialog: { width: '132px', height: '60px' },
  dropdown: { width: '240px', height: '40px' },
  menuDrop: { width: '50px', height: '40px' },
  acordeon: { width: '255px', height: '38px' },
  auto: { width: '100%', height: '31.6px' },
} as const;
export type Size = keyof typeof sizes;

const pads = {
  none: '0px',
  xsmall: '1px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '50px',
} as const;
type Pad = keyof typeof pads;

type StyledDropDownProps = {
  collapse: boolean;
  clickable: boolean;
  static: boolean;
  topOffset: number;
  leftOffset: string;
  zoffset: number;
  pad: Directional<Pad>;
  halign?: LineAlign;
  valign?: ItemAlign;
};
const StyledDropDown = styled.div<StyledDropDownProps>`
  grid-area: 'dropdown';
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);

  z-index: ${(props) => props.zoffset};
  width: min-content;
  top: ${(props) => props.topOffset}px;
  left: ${(props) => props.leftOffset};
  position: ${(props) => (props.static ? 'static' : 'absolute')};
  border-radius: 5px;

  overflow: hidden;
  max-height: ${(props) =>
    props.clickable && !props.collapse ? '100vh' : '0'};
  visibility: ${(props) =>
    props.clickable && !props.collapse ? 'visible' : 'hidden'};
  opacity: ${(props) => (props.clickable && !props.collapse ? '1' : '0')};
  transition: visibility 0.25s linear, opacity 0.25s linear,
    max-height 0.8s ease-out;

  padding-left: ${(props) => pads[props.pad.left ?? 'none']};
  padding-right: ${(props) => pads[props.pad.right ?? 'none']};
  padding-top: ${(props) => pads[props.pad.top ?? 'none']};
  padding-bottom: ${(props) => pads[props.pad.bottom ?? 'none']};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-self: ${(props) => props.halign ?? 'center'};
`;

type StyledContainerProps = {
  clickable: boolean;
  static: boolean;
  size: valueof<typeof sizes>;
  halign?: LineAlign;
  valign?: ItemAlign;
  area?: string;
};
const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-area: ${(props) => props.area};

  width: ${(props) => props.size.width};
  height: min-content;

  grid-template:
    'button' min-content
    'dropdown' auto / max-content;
  position: ${(props) => (props.static ? 'static' : 'relative')};

  ${(props) =>
    !props.clickable &&
    css`
      &&:hover > ${StyledDropDown} {
        visibility: visible;
        opacity: 1;
        max-height: 100vh;
        transition: visibility 0.25s linear, opacity 0.25s linear,
          max-height 0.25s ease-in;
      }
    `};

  justify-content: ${(props) => props.halign ?? 'center'};
  align-items: ${(props) => props?.valign ?? 'center'};

  justify-self: center;
`;

export const DropDownBorder = styled.div`
  display: grid;
  border-bottom: 1px solid ${(props) => props.theme.pallete.neutral.lighter};

  height: 1px;
  margin-left: 10%;
  align-self: center;
  justify-self: center;
`;

export type DropDownProps = {
  children: React.ReactNode;
  /**
   * What will be inside the button of the dropdown, it can be a title or an
   * icon for example.
   */
  dropButton: React.ReactNode;
  ref?: RefObject<HTMLDivElement>;
  /**
   * If the DropDown is clickable or not. Default is false.
   */
  clickable?: boolean;
  /**
   * If clickable, collapser or not by collapse boolean.
   */
  collapse?: boolean;
  /**
   * Number of pixes that dropdown offsets from top button.
   */
  topOffset?: number;
  /**
   * Distance that dropdown offsets from left button.
   */
  leftOffset?: string;
  /**
   * Three-dimentional offset, use it to make the dropdown be over another
   * dropdown.
   */
  zoffset?: number;
  /**
   * True if the button and dropdown should be static.
   */
  staticPos?: boolean;
  /**
   * Size of dropdown according to button's size.
   */
  size?: Size;
  /**
   * Padding for all directions (top, bottom, left, right)
   */
  pad?: Pad;
  /**
   * Individual padding for each direction.
   */
  padAll?: Directional<Pad>;
  /**
   * Padding vertical and horizontal.
   */
  padCross?: Cross<Pad>;
  /**
   * Horizontal aligment of items.
   */
  halign?: LineAlign;
  /**
   * Vertical aligment of items.
   */
  valign?: ItemAlign;
  /**
   * Grid area name, default to 'unset'.
   */
  area?: string;
};

export const DropDown: React.FC<DropDownProps> = ({
  clickable = false,
  collapse = false,
  children,
  dropButton,
  ref,
  topOffset = 0,
  leftOffset = '0px',
  zoffset = 1,
  staticPos = false,
  size = 'medium',
  pad,
  padAll,
  padCross,
  halign,
  valign,
  area = 'unset',
}: DropDownProps) => {
  return (
    <StyledContainer
      clickable={clickable}
      size={sizes[size]}
      ref={ref}
      static={staticPos}
      halign={halign}
      valign={valign}
      area={area}
    >
      {dropButton}
      <StyledDropDown
        clickable={clickable}
        collapse={collapse}
        pad={solve(pad, padCross, padAll)}
        halign={halign}
        valign={valign}
        topOffset={topOffset}
        leftOffset={leftOffset}
        zoffset={zoffset}
        static={staticPos}
      >
        {children}
      </StyledDropDown>
    </StyledContainer>
  );
};
