import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const Check: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 512 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M173.898 374.404L7.49799 208.004C-2.49901 198.007 -2.49901 181.798 7.49799 171.8L43.701 135.596C53.698 125.598 69.908 125.598 79.905 135.596L192 247.69L432.095 7.59602C442.092 -2.40098 458.302 -2.40098 468.299 7.59602L504.502 43.8C514.499 53.797 514.499 70.006 504.502 80.004L210.102 374.405C200.104 384.402 183.895 384.402 173.898 374.404V374.404Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
