import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const ArrowUpStroke: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10857 8.58307e-06C3.00044 -0.000358105 2.89218 0.0439854 2.81439 0.133017L0.0960448 3.24413C-0.0452729 3.40586 -0.0287203 3.65154 0.133016 3.79286C0.294752 3.93417 0.540426 3.91762 0.681744 3.75588L2.72231 1.42049L2.72231 6.61111C2.72231 6.82589 2.89642 7 3.11119 7C3.32597 7 3.50008 6.82589 3.50008 6.61111L3.50008 1.42731L5.54085 3.7563C5.6824 3.91783 5.92809 3.93404 6.08963 3.79249C6.25117 3.65095 6.26737 3.40525 6.12583 3.24371L3.43331 0.17093C3.36338 0.067781 3.2452 0 3.11119 0C3.11032 0 3.10945 2.86102e-06 3.10857 8.58307e-06Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
