import React from 'react';
import { Gradient, gradientName } from '../Gradient';

export type Props = {
  /**
   * Size of the icon in px, em, etc.
   */
  size: string;
  /*
   * Color of the icon as a hexadecimal.
   */
  color: string;
  /*
   * If true, the color will be a linear gradient, otherwise, it will use the
   * provided color.
   */
  gradient?: boolean;
};

export const BoltMinus: React.FC<Props> = ({
  gradient = false,
  ...props
}: Props) => {
  const { size, color } = props;
  const fill = gradient ? gradientName : color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9572 1.00843C15.1444 1.09775 15.2436 1.30528 15.1953 1.50652L13.3499 9.19967H17.2362C17.4017 9.19967 17.5533 9.29232 17.6284 9.43944C17.7035 9.58657 17.6895 9.76329 17.5921 9.89682L9.69146 20.7314C9.56802 20.9006 9.34191 20.9594 9.15131 20.8718C8.9607 20.7842 8.85863 20.5746 8.90746 20.371L10.7528 12.6779H6.37275C6.2049 12.6779 6.05166 12.5827 5.97768 12.4324C5.9037 12.2821 5.92185 12.103 6.02448 11.9705L14.419 1.13598C14.5458 0.972256 14.7701 0.919111 14.9572 1.00843ZM7.26908 11.8H11.3107C11.4454 11.8 11.5728 11.8615 11.6562 11.967C11.7397 12.0725 11.7701 12.2104 11.7387 12.3411L10.2767 18.4362L16.3719 10.0776H12.7921C12.6573 10.0776 12.53 10.016 12.4465 9.91053C12.3631 9.80502 12.3327 9.6672 12.364 9.5365L13.8623 3.29024L7.26908 11.8Z"
        fill={fill}
      />
      <path
        d="M5.82836 4.58252C6.12928 4.58252 6.37322 4.84931 6.37322 5.17842C6.37322 5.50753 6.12928 5.77432 5.82836 5.77432H0.544861C0.243943 5.77432 0 5.50753 0 5.17842C0 4.84931 0.243943 4.58252 0.544861 4.58252H5.82836Z"
        fill={fill}
      />
      <defs>{gradient && <Gradient />}</defs>
    </svg>
  );
};
