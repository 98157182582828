import React from "react";

import { SingleCard, SingleCardProps } from "../../components/SingleCard";
import { CardContent } from "../../components/CardContent";
import { Icon, IconProps } from "../../components/Icons";
import { Title, TitleProps } from "../../components/Title";
import { Quantity, QuantityProps } from "../../components/Quantity";

import { Units } from "../../scripts/types";

const iconColor: Record<
  Exclude<SingleCardProps["color"], undefined>,
  IconProps["color"]
> = {
  main: "white",
  white: "light",
  dark: "white",
  black: "white",
  light: "white",
  gradient: "white",
};

const textColor: Record<
  Exclude<SingleCardProps["color"], undefined>,
  TitleProps["color"] & QuantityProps["color"]
> = {
  main: "white",
  white: "main",
  dark: "white",
  black: "white",
  light: "white",
  gradient: "white",
};

export type SSingleCardProps = {
  /**
   * Grid area name. Use different areas for different grids. Defaults to
   * "unset".
   */
  area?: string;
  /**
   * Title of the card, at the top.
   */
  title: string;
  /**
   * Quantity being displayed on the card.
   */
  value: number | string;
  /**
   * Exact number of decimal places in the card's quantity.
   */
  decimals?: number;
  /**
   * Unit of the quantity.
   */
  unit: Units;
  /**
   * Icon of the card.
   */
  icon: IconProps["name"];
  /**
   * Color of the card.
   */
  color: SingleCardProps["color"];
};

/**
 * Most basic building block, a single card with a numeric value being
 * displayed.
 */
export const SSingleCard: React.FC<SSingleCardProps> = ({
  area = "unset",
  unit = "dimensionless",
  color = "main",
  value = 0,
  decimals = 2,
  title,
  icon,
}: SSingleCardProps) => (
  <SingleCard color={color} valign="center" halign="center" area={area}>
    <CardContent>
      <Icon
        name={icon}
        size="small"
        color={iconColor[color]}
        valign="center"
        padAll={{ right: "small" }}
      />
      <Title
        value={title}
        size="xsmall"
        color={textColor[color]}
        halign="start"
      />
      <Quantity
        value={value}
        unit={unit}
        minDecimals={decimals}
        maxDecimals={decimals}
        size="large"
        color={textColor[color]}
        valign="baseline"
        bold
      />
    </CardContent>
  </SingleCard>
);
